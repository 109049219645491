/* eslint-disable react/no-unknown-property */

import React, { useMemo, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "@react-three/drei";
import { useBuildingHeightStore, useLandPolygonStore } from "./store";
import { getMaxAbsCoord, SKYSCRAPER_HEIGHT } from "./utils";
import { useFrame, useThree } from "@react-three/fiber";

// Three.js 설정
export default function SceneConfig() {
    const { landPolygon } = useLandPolygonStore();
    const { buildingHeight } = useBuildingHeightStore();

    if (!landPolygon || buildingHeight === null) return null;

    return (
        <>
            {/* 조명 */}
            <ambientLight intensity={1} color={"white"} />
            <directionalLight
                intensity={2}
                position={[5, 10, 5]}
                castShadow
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-left={-50}
                shadow-camera-right={50}
                shadow-camera-top={50}
                shadow-camera-bottom={-50}
            />
            <directionalLight intensity={0.3} position={[0, 10, 0]} />
            {/* 카메라 */}
            <CameraConfig landPolygon={landPolygon} buildingHeight={buildingHeight} />
        </>
    );
}

function CameraConfig({ landPolygon, buildingHeight }: { landPolygon: [number, number][]; buildingHeight: number }) {
    const { camera } = useThree();
    const [maxX, maxY] = getMaxAbsCoord(landPolygon);
    const maxValue = Math.max(maxX, maxY, buildingHeight / 2);
    const angle = useRef(0);
    const progress = useRef(0);
    const cameraTarget =
        buildingHeight < SKYSCRAPER_HEIGHT ? new THREE.Vector3(0, 1, 0) : new THREE.Vector3(0, buildingHeight / 2, 0);

    const easeInOutCubic = useMemo(() => (t: number) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2, []);

    useFrame((_, delta) => {
        if (progress.current >= 1) return;

        progress.current += delta * 0.3;
        if (progress.current > 1) progress.current = 1;

        const easedProgress = easeInOutCubic(progress.current);
        angle.current = easedProgress * Math.PI * 2;

        const radius = maxValue * 2;
        camera.position.set(-Math.sin(angle.current) * radius, maxValue, Math.cos(angle.current) * radius);
        camera.lookAt(cameraTarget);
    });

    return (
        <>
            {/* 컨트롤 */}
            <OrbitControls
                zoomSpeed={0.8}
                minDistance={5}
                maxDistance={Math.max(30, maxValue * 3)}
                target={cameraTarget}
                minPolarAngle={THREE.MathUtils.degToRad(0)}
                maxPolarAngle={THREE.MathUtils.degToRad(90)}
                enablePan={false}
            />
        </>
    );
}
