import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import HandlebarIcon from "@src/assets/ico_handle_bar.svg";
import StoreGoStopSortTypeContent from "./storeGoStopSortTypeContent";

const sortTypeContents = [
    { name: "높은순", value: 19 },
    { name: "낮은순", value: 20 },
    { name: "높은순", value: 15 },
    { name: "낮은순", value: 16 },
    { name: "가까운순", value: 5 },
    { name: "먼순", value: 6 },
    { name: "최신순", value: 0 },
    { name: "오래된순", value: 21 },
    { name: "높은순", value: 1 },
    { name: "낮은순", value: 2 },
    { name: "높은순", value: 3 },
    { name: "낮은순", value: 4 },
    { name: "많은순", value: 7 },
    { name: "낮은순", value: 8 },
];

interface Props {
    showSortBox: boolean;
    sortType: number;
    onChangeSortType: (val: number) => void;
    onCloseSortBox: () => void;
}

const StoreGoStopFilterBox = ({ showSortBox, sortType, onChangeSortType, onCloseSortBox }: Props) => {
    const rootBoxRef = useRef<HTMLDivElement | null>(null);
    const [touchStart, setTouchStart] = useState<boolean>(false);

    return (
        <div css={rootBoxStyle(showSortBox)}>
            <div className={showSortBox ? "dim active" : "dim"} aria-hidden onClick={onCloseSortBox} />
            <div
                css={buttonBoxStyle(showSortBox)}
                onTouchMove={(evt) => {
                    setTouchStart(true);
                    230 - (window.innerHeight - evt.touches[0].clientY) > 0 &&
                        rootBoxRef.current?.style.setProperty(
                            "transform",
                            `translateY(${230 - (window.innerHeight - evt.touches[0].clientY)}px)`,
                        );
                    rootBoxRef.current?.style.setProperty("transition", "all 0.1s");
                }}
                onTouchEnd={(evt) => {
                    if (touchStart) {
                        setTouchStart(false);
                        rootBoxRef.current?.style.removeProperty("transform");
                        rootBoxRef.current?.style.removeProperty("transition");
                        210 - (window.innerHeight - evt.changedTouches[0].clientY) > 0 && onCloseSortBox();
                    }
                }}
            >
                {/* <BottomSheetHeader /> */}
                <div css={contentDiv}>
                    <StoreGoStopSortTypeContent
                        title="수익률"
                        button={sortTypeContents[0]}
                        secondButton={sortTypeContents[1]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="시세차익"
                        button={sortTypeContents[2]}
                        secondButton={sortTypeContents[3]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="매각기일"
                        button={sortTypeContents[4]}
                        secondButton={sortTypeContents[5]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="업데이트"
                        button={sortTypeContents[6]}
                        secondButton={sortTypeContents[7]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="감정가"
                        button={sortTypeContents[8]}
                        secondButton={sortTypeContents[9]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="최저가"
                        button={sortTypeContents[10]}
                        secondButton={sortTypeContents[11]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                    <StoreGoStopSortTypeContent
                        title="유찰횟수"
                        button={sortTypeContents[12]}
                        secondButton={sortTypeContents[13]}
                        selectedType={sortType}
                        onChangeSortType={onChangeSortType}
                    />
                </div>
            </div>
        </div>
    );
};

const rootBoxStyle = (isShow: boolean) => css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    opacity: 0;
    visibility: hidden;
    * {
        transition: all 0.3s;
    }
    ${isShow && { zIndex: 996, opacity: 1, visibility: "visible" }}
    .dim {
        height: 100vh;
    }
    .dim.active {
        background-color: rgba(40, 40, 40, 0.7);
        height: 100vh;
        width: 100vw;
    }
`;
const buttonBoxStyle = (isShow: boolean) => css`
    position: fixed;
    bottom: 0;
    z-index: 997;
    height: 478px;
    width: 100vw;
    transform: ${!isShow ? "translateY(406px)" : "translateY(0)"};
    background: #ffffff url(${HandlebarIcon}) no-repeat 50% 14px;
    border-radius: 10px 10px 0 0;
    padding: 14px 14px 0px 14px;
`;

const contentDiv = css`
    margin-top: 10px;
`;

export default StoreGoStopFilterBox;
