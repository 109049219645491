import styled from "@emotion/styled";
import React from "react";
import cancle_icon from "@src/assets/ico_close.png";

import {
    pyeongCaseType,
    usePyeongCardDetailListStore,
    usePyeongCardDetailListStoreType,
    usePyeongCardPopupStore,
    usePyeongCardPopupStoreType,
    useSelctedPyeongCardDetailStore,
    useSelctedPyeongCardDetailStoreType,
} from "@src/store/RealPriceFilterStore";

const PyeongCard = () => {
    const setPyeongCardPopup = usePyeongCardPopupStore((state: usePyeongCardPopupStoreType) => state.setPyeongCardPopupState);
    const pyeongCaseList = usePyeongCardDetailListStore(
        (state: usePyeongCardDetailListStoreType) => state.pyeongCardDetailListState,
    );
    const selectedPyeongCard = useSelctedPyeongCardDetailStore(
        (state: useSelctedPyeongCardDetailStoreType) => state.selctedPyeongCardDetailState,
    );
    const setSelectePyeongCard = useSelctedPyeongCardDetailStore(
        (state: useSelctedPyeongCardDetailStoreType) => state.setSelctedPyeongCardDetailState,
    );

    function close() {
        setPyeongCardPopup(false);
    }

    function caseClick(data: pyeongCaseType) {
        // 이미 선택한 평형을 클릭했다면 선택 취소
        if (selectedPyeongCard === data) setSelectePyeongCard(null);
        else setSelectePyeongCard(data);
        setPyeongCardPopup(false);
    }
    const rows = pyeongCaseList?.map((aa, index) => {
        let check = aa.area === selectedPyeongCard?.area;
        return (
            <div
                className={check ? "active row" : "row"}
                key={`${aa.area}.no${index}`}
                onClick={() => {
                    caseClick(aa);
                }}
            >
                <div className="left">
                    <h3>{aa.area}</h3>
                    <div className="detail">
                        <p>공급 {aa.supply}</p>
                        <p>전용 {aa.exclusive}</p>
                    </div>
                </div>
                <div className="right">
                    {aa.sale && <p>{aa.sale}</p>}
                    {aa.rent && <p>{aa.rent}</p>}
                    {aa.month && <p>{aa.month}</p>}
                </div>
            </div>
        );
    });
    return (
        <ContentWrap>
            <div className="header">
                <h3>평형선택</h3>
                <div className="img_box" onClick={close}>
                    <img src={cancle_icon} width={14} height={14} alt="cancle" />
                </div>
            </div>
            <div className="rows">{rows}</div>
        </ContentWrap>
    );
};

export default PyeongCard;

const ContentWrap = styled.div`
    width: 97%;
    max-height: 266px;
    overflow: auto;
    background: #fff;
    border-radius: 14px;
    padding: 0 14px;
    border-bottom: 14px solid #fff;
    position: relative;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;
        width: 100%;
        background: #fff;

        h3 {
            height: 40px;
            font-size: 16px;
            display: flex;
            align-items: center;
        }
        .img_box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
        }
    }

    .rows {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 64px;
            padding: 8px 9.5px;
            cursor: pointer;

            .left {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 6px;
                p {
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    color: #505050;
                    text-align: right;
                }
            }

            &:not(:last-child) {
                border-bottom: 0.6px solid #dadada;
            }
            h3 {
                font-size: 18px;
            }
            .detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 12px;
                gap: 6px;
            }

            &.active {
                background: #0047ff;
                color: #fff;

                p {
                    color: #fff;
                }
            }
        }
    }

    @media screen and (max-width: 350px) {
        padding: 14px 8px;

        .rows {
            margin-top: 20px;
            white-space: nowrap;

            .row {
                padding: 8px 4px;

                .left {
                    gap: 8px;
                }

                .right {
                    gap: 6px;
                    p {
                        font-size: 10px;
                        line-height: 10px;
                    }
                }

                h3 {
                    font-size: 13px;
                }
                .detail {
                    font-size: 10px;
                    gap: 6px;
                }
            }
        }
    }
    @media screen and (max-width: 265px) {
        .rows {
            .row {
                .right {
                    p {
                        font-size: 9px;
                        line-height: 9px;
                    }
                }
            }
        }
    }
`;
