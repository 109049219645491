import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { removeAll } from "@src/store/filterStore";
import { initialFilterParams, useNewFilterStore } from "@src/store/newFilterStore";
import { AxiosError } from "axios";
import { sortBy } from "lodash";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";

const initialParams: any = {
    keyword: "",
    filter: initialFilterParams,
    next_it: "",
};

const fetchData = async (params?: any): Promise<GetItemResponseType> => {
    // if (params?.keyword?.length !== 1) {
    const response = await axiosInstance.post(`${axiosPath.items}`, params);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload || [];

    return responseData;
    // } else {
    //     return {
    //         errorCode: 0,
    //         list: [],
    //         total: 0,
    //     };
    // }
};
const useGetItem = async (params: any) => {
    const resultParams = {
        ...params,
        filter: {
            ...params.filter,
            loc: removeAll(params.filter.loc),
            use: params.filter.use.includes("All") ? [] : params.filter.use,
        },
    };
    return await fetchData(resultParams);
};

const getItemList = async (params: any) => {
    const newEstPrice = params.itemFilter?.est_price?.map((v: any) => (v ? `${v}` : v));
    const newMinPrice = params.itemFilter?.min_price?.map((v: any) => (v ? `${v}` : v));
    const newParams = {
        ...params,
        itemFilter: { ...params.itemFilter, est_price: newEstPrice, min_price: newMinPrice },
        sortType: params.itemFilter?.sort ?? 5,
        limit: 30,
        searchText: params.searchText,
        // cursor: { sortType: params.itemFilter?.sort ?? 5 },
    };

    const compareParams = JSON.parse(JSON.stringify(newParams));
    delete compareParams?.userLocation;

    if (JSON.stringify(initialFilterParams) !== JSON.stringify(compareParams.itemFilter) || params.searchText.length > 0) {
        if ("userLocation" in params.itemFilter) {
            compareParams.userLocation = params.itemFilter.userLocation;
        }

        const response = await axiosInstance.post(
            axiosPath.itemsV3,
            {
                ...compareParams,
            },
            { timeout: 10000 },
        );
        const responseData = response?.data ?? [];

        return responseData;
    } else {
        const response = await axiosInstance.post(
            axiosPath.itemsV3,
            {
                ...newParams,
            },
            { timeout: 10000 },
        );
        const responseData = response?.data ?? [];
        return responseData;
    }
};

export const useFindAllItemList = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery<any>(
        ["itemList", params],
        ({ pageParam }) => useGetItem({ ...params, next_it: pageParam ? pageParam : undefined }),
        {
            getNextPageParam: (lastPage: any, pages: any) => {
                const newPage =
                    pages?.[0]?.total > pages?.length * 20 ? lastPage?.list?.[lastPage?.list?.length - 1]?.next_it : undefined;
                return newPage;
            },
            enabled,
            cacheTime: 1000,
        },
    );
};

export const useFindAllSearchItemListV2 = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["totalSearchListScrollData", params],
        queryFn: ({ pageParam }) =>
            getItemList({
                ...params,
                afterKey: pageParam ? pageParam : params.afterKey,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 30 && lastPage?.afterKey;
        },
        enabled: false,
    });
};

//* 필터 히스토리 가져오기
export const useFindAllFilterHistory = (enabled?: boolean) => {
    return useQuery(
        ["filterHistory"],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.RecentFilter)
                .then((res) => {
                    return res?.data?.payload?.list;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        {
            enabled,
        },
    );
};

//* 필터 히스토리 저장
export const useUpdateFilterHistory = () => {
    return useMutation(async (params: any) => {
        try {
            const res = await axiosInstance.post(axiosPath.RecentFilter, {
                value: { ...params },
            });
            return res;
        } catch (Err) {
            console.log("saveError>>", Err);
            throw Err;
        }
    });
};

//* 필터 히스토리 삭제
export const useRemoverFilterHistory = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: number) => {
            try {
                const res = await axiosInstance.delete(`${axiosPath.removeFilterHistory}/${params}`);
                return res;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//* 필터 히스토리 전체 삭제
export const useRemoverFilterHistoryAll = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async () => {
            try {
                const res = await axiosInstance.delete(`${axiosPath.RecentFilter}/all`);
                return res;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export type GetItemResponseType = {
    errorCode: number;
    list: GetItemListType[];
    total: number;
};

export type GetItemListType = {
    item_id: string;
    bid_type: number;
    case_number: string;
    is_favorited: boolean;
    use: string;
    skp_cnt: number;
    skp_cnt_str: string;
    image: string;
    address: string;
    mid_adrs: string;
    est_price: number;
    est_price_str: string;
    min_price: number;
    min_price_str: string;
    rate: string;
    status: string;
    status_detail: string;
    d_day: string;
    due_date_str: string;
    due_date: number;
    somethings: string[];
    updated_at: string;
    next_it: string;
    sale_price_str: string;
    sale_price_rate: string;
    card_type: number;
    date_info: string;
};
export { useGetItem, initialParams };
