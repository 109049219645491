import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { css } from "@emotion/react";

import { Common } from "@src/styles/Common";

import filterOff from "@src/assets/filterOff.png";
import filterOn from "@src/assets/filterOn.png";
import inputXBtn from "@src/assets/inputXBtn.png";
import ico_search_black_bold from "@src/assets/ico_search_black_bold.svg";
import micIcon from "@src/assets/basicIcon/micIcon.svg";

import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";

import { useFilterStore } from "@src/store/filterStore";
import RecentSearchKeywordList from "@src/components/searchItems/RecentSearchKeywordList";
import { usePutSearchKeyword } from "@src/hooks/searchItems/useRecentSearchKeywordList";

interface ItemsSearchInputProps {
    isShowFilter: boolean;
    setIsShowFilter: Dispatch<SetStateAction<boolean>>;
    openOutFilter: string;
    onFilterOff: () => void;
    isOpenRecentKeywordList: boolean;
    setIsOpenRecentKeywordList: Dispatch<SetStateAction<boolean>>;
}

const InputSearchingItems = ({
    isShowFilter,
    setIsShowFilter,
    openOutFilter,
    onFilterOff: closeOpenOutFilter,
    isOpenRecentKeywordList,
    setIsOpenRecentKeywordList,
}: ItemsSearchInputProps) => {
    const { mutate: putSearchKeyword } = usePutSearchKeyword();
    const searchInputValue = useFilterStore((state) => state.searchKeyword);
    const setSearchInputValue = useFilterStore((state) => state.setSearchKeyword);

    const inputRef = useRef<HTMLInputElement | null>(null);

    WebAppBridge.setSpeechResult = (val: string) => {
        setSearchInputValue(val);
    };

    const handleFocusCapture = (evt: React.FocusEvent<HTMLInputElement>) => {
        const element = evt.target;
        element.selectionStart = element.value.length; // 커서 마지막으로 이동
    };

    const handleToggleFilter = () => {
        if (openOutFilter.length !== 0) {
            closeOpenOutFilter();
        }
        setIsShowFilter((prev) => !prev);
    };

    const handleSearchKeyword = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = evt.target.value;
        setSearchInputValue(currentValue);
    };

    const handleSearchCancle = () => {
        setSearchInputValue("");
        setIsShowFilter(true);
        transferToNative("", "changeSearchItemsKeyword");
    };

    const handleCloseRecentKeywordList = () => {
        transferToNative("default", "resizeWebview");
        setIsOpenRecentKeywordList(false);
        searchInputValue.length === 0 && setIsShowFilter(true);
    };

    const handleFocusSearchInput = (evt: React.FocusEvent<HTMLInputElement>) => {
        setIsShowFilter(false);
        setIsOpenRecentKeywordList(true);
        closeOpenOutFilter();
    };

    const handleClickMic = (evt: React.MouseEvent<HTMLImageElement>) => {
        evt.stopPropagation();
        setIsShowFilter(false);
        transferToNative("webSearch", "onClickSearchMic");
    };

    const handleFocusOut = (evt: React.FocusEvent<HTMLInputElement>) => {
        if (searchInputValue.length === 0 && !isOpenRecentKeywordList) {
            setIsShowFilter(true);
        }
    };

    const postSearchKeyword = useCallback(
        debounce((value: string) => {
            if (value.length === 0) {
                transferToNative(value, "changeSearchItemsKeyword");
                return;
            }
            putSearchKeyword(value);
            transferToNative(value, "changeSearchItemsKeyword");
        }, 500),
        [],
    );

    const isIphone = navigator.userAgent.toLowerCase().includes("iphone");

    useEffect(() => {
        postSearchKeyword(searchInputValue);
    }, [searchInputValue]);

    return (
        <>
            <div css={InputWrapper(isIphone)}>
                <div className="searchWrap">
                    <img src={ico_search_black_bold} className="magnifyGlass" />
                </div>

                <input
                    placeholder="소재지, 사건번호 검색"
                    value={searchInputValue}
                    ref={inputRef}
                    onChange={handleSearchKeyword}
                    onFocus={handleFocusSearchInput}
                    onFocusCapture={handleFocusCapture}
                    onBlur={handleFocusOut}
                />

                <div className="imgWrapper">
                    {searchInputValue && <img src={inputXBtn} className="XBtn" onClick={handleSearchCancle} />}
                    {isIphone && <img src={micIcon} alt="마이크 아이콘" css={micIconStyle} onClick={handleClickMic} />}
                </div>
                <div className="imgDiv">
                    <img src={isShowFilter ? filterOn : filterOff} alt="" onClick={handleToggleFilter} />
                </div>
            </div>
            {isOpenRecentKeywordList && (
                <RecentSearchKeywordList onClickKeyword={setSearchInputValue} onClose={handleCloseRecentKeywordList} />
            )}
        </>
    );
};

export default InputSearchingItems;

const InputWrapper = (isIphone: boolean) => css`
    position: relative;
    width: 100%;
    height: max-content;
    padding: 4px 0px;
    box-sizing: border-box;
    z-index: 3;
    display: flex;
    gap: 8px;
    input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        padding: 9px 36px 8px 40px;
        ${isIphone && `padding:9px 64px 8px 40px; `}
        min-height: 40px;
        height: 40px;
        max-height: 63px;
        resize: none;
        opacity: 1;
        outline: none;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        font-size: 14px;
        background: #ffffff;
        overflow: "scroll";
        line-height: "24px";
        color: ${Common.colors.normalTextColor};
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    textarea::-webkit-scrollbar {
        display: none;
    }
    textarea:disabled {
        background-color: #fff;
        opacity: 1;
    }

    textarea:focus {
        outline: none;
        border: 1px solid ${Common.colors.normalTextColor};
    }

    textarea:disabled {
        background-color: #fff;
        opacity: 1;
    }

    textarea:focus {
        outline: none;
        border: 1px solid ${Common.colors.normalTextColor};
    }

    .searchWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 14px;
        top: 14px;
        gap: 8px;
        height: 24px;
        .magnifyGlass {
            width: 20px;
            height: 20px;
        }
    }
    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 56px;
        top: 12px;
        height: 24px;
        gap: 8px;
        .XBtn {
            width: 16px;
            height: 16px;
        }
    }
    .imgDiv {
        width: 42px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
`;

const micIconStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
`;
