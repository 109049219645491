import React, { useRef } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useCompassRotationStore } from "./store";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

// 나침반
export function Compass() {
    const location = useLocation();
    const { compassRotation } = useCompassRotationStore();

    return (
        <CompassWrapper
            left={location.pathname === "/three" ? 14 : 28}
            style={{
                transform: `rotate(${compassRotation}deg)`,
            }}
        >
            <CompassNeedle />
            <CompassLabel>N</CompassLabel>
        </CompassWrapper>
    );
}

export function CompassRotation() {
    const { camera } = useThree();
    const { compassRotation, setCompassRotation } = useCompassRotationStore();
    const dirRef = useRef(new THREE.Vector3());
    const compassRotationRef = useRef(compassRotation);

    useFrame(() => {
        const dir = dirRef.current;
        camera.getWorldDirection(dir);

        const rotation = THREE.MathUtils.radToDeg(Math.atan2(dir.x, dir.z)) - 180;

        if (Math.abs(rotation - compassRotationRef.current) > 5) {
            compassRotationRef.current = rotation;
            setCompassRotation(rotation);
        }
    });

    return null;
}

const CompassWrapper = styled.div<{ left: number }>`
    position: absolute;
    top: 44px;
    left: ${({ left }) => `${left}px`};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #2d2d2d;
    will-change: transform;
`;

const CompassNeedle = styled.div`
    width: 1px;
    height: 12px;
    background-color: #2d2d2d;
    position: absolute;
    top: 0;
`;

const CompassLabel = styled.div`
    position: absolute;
    top: -12px;
    font-size: 8px;
    font-weight: bold;
    color: #2d2d2d;
`;
