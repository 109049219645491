import { useBuildingHeightStore, useBuildingPolygonStore, useLandPolygonStore } from "@src/components/three/store";
import { getCenter, getRelativeCoords, lngLatToXY, SCALE_FACTOR } from "@src/components/three/utils";
import type { NonNullableLandUsePolygon } from "@src/hooks/landUse/dto/landUse.dto";
import { useGetLandUseBuild } from "@src/hooks/landUse/useLandUse";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useEffect } from "react";

const DEFAULT_VIEW_HEIGHT = "712";
const CONSTRUCTION_BAN_HEIGHT = "428";
const SUN_RIGHTS_VIEW_HEIGHT = "742";

export const useLandUseData = ({ pnu, debouncedRatio }: { pnu: string; debouncedRatio: number }) => {
    const query = useGetLandUseBuild(
        { pnu, buildRatio: debouncedRatio },
        { keepPreviousData: true, staleTime: Number.POSITIVE_INFINITY, refetchOnWindowFocus: false },
    );
    const { data, isError } = query;
    const { setBuildingPolygon } = useBuildingPolygonStore();
    const { setLandPolygon } = useLandPolygonStore();
    const { setBuildingHeight } = useBuildingHeightStore();

    // 데이터 정제
    useEffect(() => {
        if (!data || data.result !== 200 || isError) return;

        if (!data.buildPolygon || data.buildPolygon.length === 0) return;
        const buildingData = data.buildPolygon
            .map((polygon) => {
                if (polygon.coordinates && polygon.floor && polygon.height) {
                    const coords = polygon.coordinates.map(lngLatToXY);
                    return {
                        floor: polygon.floor,
                        height: polygon.height,
                        coordinates: coords,
                    };
                }
                return null;
            })
            .filter((polygon): polygon is NonNullableLandUsePolygon => polygon !== null);
        if (buildingData.length === 0) return;
        const centerCoord = getCenter(buildingData[0].coordinates);
        const relativeBuildingCoords = buildingData.map((polygon) => ({
            floor: polygon.floor,
            height: polygon.height,
            coordinates: getRelativeCoords(polygon.coordinates, centerCoord),
        }));

        if (!data.landPolygon || !data.landPolygon.coordinates || data.landPolygon.coordinates.length === 0) return;
        const landData = data.landPolygon.coordinates.map((coords) => lngLatToXY(coords));
        const relativeLandCoord = getRelativeCoords(landData, centerCoord);

        const buildingHeight = data.buildPolygon.reduce((acc, cur) => acc + (cur.height ?? 0), 0) / SCALE_FACTOR;

        setBuildingPolygon(relativeBuildingCoords);
        setLandPolygon(relativeLandCoord);
        setBuildingHeight(buildingHeight);

        return () => {
            setBuildingPolygon(null);
            setLandPolygon(null);
            setBuildingHeight(null);
        };
    }, [data, isError]);

    // 건축 불가 필지인 경우 웹뷰 높이 변경 필요
    useEffect(() => {
        if ((data && data.result !== 200) || isError) {
            transferToNative(CONSTRUCTION_BAN_HEIGHT, "updateViewHeight");
        }
    }, [data, isError]);

    // 일조권 적용 대상 여부에 따라 웹뷰 높이 변경 필요
    useEffect(() => {
        if (data && data.result === 200) {
            if (data.ancientLights === true) {
                transferToNative(SUN_RIGHTS_VIEW_HEIGHT, "updateViewHeight");
            } else {
                transferToNative(DEFAULT_VIEW_HEIGHT, "updateViewHeight");
            }
        }
    }, [data]);

    return query;
};
