import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import { NameAndValue, useSavedUseFilter } from "@src/store/newFilterStore";
import { useFilterTextList } from "./useFilterList";
import AuctionFilterHeader from "./AuctionFilterHeader";
import CommonFilterHeader from "./CommonFilterHeader";
import { Common } from "@src/styles/Common";
import ToastMessage from "./ToastMessage";

interface Props {
    backFn: () => void;
    isNotParfOfMainFilter: boolean;
    savedUseList: NameAndValue[];
    setSavedUseList: (filterState: NameAndValue[]) => void;
}

const TOTAL_USE_LENGTH_MAX = 10;
const ALL_USE = { name: "전체", value: null };

const UseFilterV3Detail: React.FC<Props> = ({ backFn, isNotParfOfMainFilter, savedUseList, setSavedUseList }) => {
    const [tempUseCategory, setTempUseCategory] = useState<NameAndValue[]>([]);
    const [currentMajorCategory, setCurrentMajorCategory] = useState<NameAndValue | typeof ALL_USE>(ALL_USE);

    const [message, setMessage] = useState("");

    const handleInitialize = () => {
        setSavedUseList([]);
        setTempUseCategory([]);
        setCurrentMajorCategory(ALL_USE);
    };

    const MAJOR_CATEGORIES = useFilterTextList.reduce((acc, item) => {
        return item.value.length === 1 ? [...acc, { name: item.name.split(">")[0].trim(), value: item.value }] : acc;
    }, [] as { name: string; value: string }[]);

    const handleMinorCategory = (category: NameAndValue) => {
        let updated = [...savedUseList];
        // 전체 선택시
        if (category.value.length === 1) {
            setSavedUseList([category, ...updated.filter((item) => !item.value.includes(category.value))]);
            setTempUseCategory([category]);
            return;
        }

        // 존재하는 아이템일 경우 제외
        if (savedUseList.includes(category)) {
            updated = [...savedUseList].filter((item) => item.value !== category.value);
        } else {
            // 전체 외 용도 선택시, 기존 '전체' 선택 해제
            if (savedUseList.find((item) => category.value.includes(item.value))) {
                updated = [category, ...savedUseList.filter((item) => item.value.length !== 1)];
            } else {
                if (savedUseList.length >= TOTAL_USE_LENGTH_MAX) {
                    setMessage("최대 10개까지 선택 가능합니다.");
                    return;
                }
                updated.push(category);
            }
        }

        setTempUseCategory(updated);
        setSavedUseList(updated);
    };

    const handleAllUse = () => {
        setSavedUseList([]);
        setTempUseCategory([]);
        setCurrentMajorCategory(ALL_USE);
    };

    useEffect(() => {
        if (savedUseList.length === 0) {
            setTempUseCategory([]);
            setCurrentMajorCategory(ALL_USE);
        }
    }, [savedUseList]);

    return (
        <RootBoxStyle $isNotParfOfMainFilter={isNotParfOfMainFilter}>
            {isNotParfOfMainFilter ? (
                <AuctionFilterHeader title="용도" out={isNotParfOfMainFilter} backFn={backFn} />
            ) : (
                <CommonFilterHeader onGoback={backFn}>용도 선택</CommonFilterHeader>
            )}
            <div css={selectBoxStyle(isNotParfOfMainFilter)}>
                <button css={majorButtonStyle(currentMajorCategory.name === "전체")} onClick={handleAllUse}>
                    전체
                </button>
                {MAJOR_CATEGORIES.map((category) => (
                    <button
                        key={category.value}
                        css={majorButtonStyle(category.value.includes(currentMajorCategory.value!))}
                        onClick={() => {
                            setCurrentMajorCategory(category);
                        }}
                    >
                        {category.name}
                    </button>
                ))}
            </div>

            {currentMajorCategory.value !== null && (
                <>
                    <div css={lineStyle(isNotParfOfMainFilter)} />
                    <div css={scrollBoxStyle}>
                        <div css={selectScrollBoxStyle(isNotParfOfMainFilter)}>
                            {currentMajorCategory.value !== null &&
                                getMinorCategories(currentMajorCategory.value).map((category) => (
                                    <button
                                        key={category.value}
                                        css={selectButtonStyle(tempUseCategory.includes(category))}
                                        onClick={() => handleMinorCategory(category)}
                                    >
                                        {category.name.split(">")[1]}
                                    </button>
                                ))}
                        </div>
                    </div>
                </>
            )}

            <div css={footerRootStyle}>
                {savedUseList.length > 0 && (
                    <div css={summaryBoxStyle(isNotParfOfMainFilter)}>
                        <>
                            {savedUseList.map((use) => (
                                <ShowSelectedUseLocValueBox
                                    key={use.value}
                                    value={use.name}
                                    onDeleteValue={() => {
                                        const updated = savedUseList.filter((item) => item.value !== use.value);
                                        setSavedUseList(updated);
                                    }}
                                />
                            ))}
                        </>
                    </div>
                )}
                <div css={footerButtonBoxStyle(isNotParfOfMainFilter)}>
                    <button onClick={handleInitialize}>
                        <span>초기화</span>
                    </button>
                    <button className="active" onClick={backFn}>
                        <span>적용하기</span>
                    </button>
                </div>
            </div>

            <ToastMessage message={message} setMessage={setMessage} bottom={90} />
        </RootBoxStyle>
    );
};

export default UseFilterV3Detail;

const RootBoxStyle = styled.div<{ $isNotParfOfMainFilter: boolean }>`
    background-color: #ffffff;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: ${(props) => (props.$isNotParfOfMainFilter ? "14px" : 0)};
`;

const selectBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    max-height: 332px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    ${isNotParfOfMainFilter ? { padding: "0" } : { padding: "12px 16px" }}
    gap: 8px;
`;

const selectScrollBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    max-height: 48dvh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    ${isNotParfOfMainFilter ? { padding: "0" } : { padding: "0px 16px" }}
    gap: 8px;
    overflow-y: scroll;
`;

const majorButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 16px 0;
    color: ${Common.colors.navyPrimary};
    border: 1px solid #f0f0f0;
    background: #f0f0f0;
    font-size: 16px;
    box-sizing: border-box;
    max-height: 48px;
    cursor: pointer;

    ${active && {
        background: Common.colors.aucMarkerColor,
        color: Common.colors.white,
    }}
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 0 4px;
    color: ${Common.colors.aucMarkerColor};
    border: 1px solid ${Common.colors.aucMarkerColor};
    background: ${Common.colors.white};
    font-size: 16px;
    box-sizing: border-box;
    height: 48px;
    cursor: pointer;

    ${active && {
        background: Common.colors.aucMarkerColor,
        color: Common.colors.white,
    }}
`;

const summaryBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    max-height: 10dvh;
    ${isNotParfOfMainFilter ? { margin: "12px 0" } : { margin: "4px 16px 4px" }};
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow-y: scroll;
    box-sizing: border-box;
`;

const footerButtonBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    display: flex;
    ${isNotParfOfMainFilter ? { padding: "0 0 12px 0" } : { padding: " 14px" }}
    button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #c3c3c3;
        span {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
        }
    }
    button.active {
        background: ${Common.colors.aucMarkerColor};
    }
    button + button {
        margin-left: 12px;
    }
`;

const footerRootStyle = css`
    margin-top: auto;
    width: 100%;
    background-color: #ffffff;
`;

const scrollBoxStyle = css`
    width: 100%;
`;

const lineStyle = (isNotParfOfMainFilter: boolean) => css`
    margin: ${isNotParfOfMainFilter ? "14px 0 24px" : "2px 0 12px"};
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
`;

//** */
export const getMinorCategories = (majorCategoryValue: string) => {
    return useFilterTextList.filter((item) => item.value.includes(majorCategoryValue));
};
