import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { differenceInSeconds } from "date-fns";
import { css } from "@emotion/react";
import { ConvertPeriodSubs } from "@src/util/ConvertPeriodSubs";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import timer from "@src/assets/stateLand/sandglassIcon.json";
import recommend from "@src/assets/subs/recommend.png";
import convertNumberToKorean, { convertNumberToKoreanWithThousand } from "@src/util/convertNumToKorean";
import { Radio } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import strikethroughIcon from "@src/assets/subs/strikethroughIcon.svg";
import eventText from "@src/assets/subs/eventMessage.png";
import eventDateText from "@src/assets/subs/eventDateText.png";
import {
    PREMIUM_SPRING_PROMOTION_IOS_CODES,
    PremiumSpringPromotionTimer,
    PremiumSpringPromotionTimeTooltip,
} from "@src/pages/subscription/PremiumSpringPromotion";
import twoMonthImg from "@src/assets/subscription/springPromotion2Month.png";
import discount40 from "@src/assets/subscription/springPromotion40.png";
import redCurlyArrow from "@src/assets/subscription/product_arrow.svg";

interface Props {
    product: IProduct;
    selectedProduct?: IProduct;
    onClickProduct: (val: IProduct) => void;
    type?: number;
}

const IosNewStateLandProduct: React.FC<Props> = ({ product, onClickProduct, selectedProduct, type = 3 }) => {
    const [now, setNow] = useState<any>(new Date());

    const getEventDuration = (now: any) => {
        const diffTime = differenceInSeconds(new Date(product.showEndAt).getTime(), now.getTime());
        const secound = diffTime % 60;
        const min = ((diffTime - secound) % 3600) / 60;
        const hours = ((diffTime - min * 60 - secound) % 86400) / 3600;
        const day = ((diffTime - min * 60 - secound - hours * 3600) % 31536000) / 86400;
        const formatTime = `${day}일 ${hours}시간 ${min}분 ${secound}초`;

        return formatTime;
    };

    useEffect(() => {
        return () => {
            setInterval(() => {
                setNow(new Date());
            }, 1000);
        };
    }, []);

    return (
        <>
            <div
                css={productCardBoxStyle(
                    selectedProduct?.code === product.code,
                    product?.isEvent && now >= new Date(product.showStartAt) && now <= new Date(product.showEndAt),
                )}
                onClick={() => onClickProduct(product)}
            >
                <PremiumSpringPromotionTimeTooltip top="-24px" left="75%" />
                {product.recommend === 1 && <img src={recommend} alt="" className="recommend" width={45} />}
                {Date.now() < 1710687600000 && Date.now() >= 1708959600000 && (
                    <div className="eventText">
                        <img src={eventText} alt="eventText" width={88} />
                        <div css={lastDiscountTextStyle}>{product?.priceDiscountDisplay1}</div>
                    </div>
                )}
                <div css={cardContentsStyle}>
                    <div css={titleWrapStyle}>
                        <Radio
                            checked={true}
                            onChange={() => {}}
                            sx={{
                                width: 16,
                                height: 16,
                                color: selectedProduct?.code === product.code ? "#2275EE !important" : "#C9C9C9 !important",
                            }}
                        />
                        <div css={titleStyle}>{product.name}</div>
                    </div>
                    <div css={bottomContent}>
                        <div
                            className="stickerContainer"
                            style={{ flexDirection: PREMIUM_SPRING_PROMOTION_IOS_CODES[0] === product.code ? "column" : "row" }}
                        >
                            <img src={twoMonthImg} alt="2개월 추가" width={100} />
                            <img src={discount40} alt="40% 할인" width={60} height={60} />
                        </div>
                        <div css={priceWrapStyle}>
                            {product?.term1DiscountedPrice !== product.price && (
                                <p css={priceInfoStyle}>{convertNumberToKorean(product.price)}원</p>
                            )}
                            {(PREMIUM_SPRING_PROMOTION_IOS_CODES[0] === product.code ||
                                PREMIUM_SPRING_PROMOTION_IOS_CODES[1] === product.code) && (
                                <div css={term2PriceStyle}>
                                    <p css={discountTextStyle}>
                                        {/* -{Math.floor(100 - (product?.term2DiscountedPrice / product?.price) * 100)}% */}
                                        기존
                                    </p>
                                    <p css={discountPriceTextStyle}>
                                        {/* {convertNumberToKorean(product?.term2DiscountedPrice)}원 */}
                                        {PREMIUM_SPRING_PROMOTION_IOS_CODES[0] === product.code ? <>859,000원</> : <>689,000원</>}
                                    </p>
                                </div>
                            )}

                            {product?.term2DiscountedPrice ? (
                                <>
                                    <div css={term2PriceStyle}>
                                        <p css={discountTextStyle}>
                                            {/* -{Math.floor(100 - (product?.term2DiscountedPrice / product?.price) * 100)}% */}
                                            기존
                                        </p>
                                        <p css={discountPriceTextStyle}>
                                            {/* {convertNumberToKorean(product?.term2DiscountedPrice)}원 */}
                                            760,000원
                                        </p>
                                    </div>
                                    {Date.now() < 1714489200000 &&
                                        type === 3 &&
                                        convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.7).split("만")?.[0] !==
                                            convertNumberToKorean(product?.term1DiscountedPrice)?.replaceAll("만", "") && (
                                            <div css={term2PriceStyle}>
                                                <p css={discountTextStyle}>-30%</p>
                                                <p css={discountPriceTextStyle}>
                                                    {
                                                        convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.75).split(
                                                            "만",
                                                        )?.[0]
                                                    }
                                                    만원
                                                </p>
                                            </div>
                                        )}
                                    {product.code === "SUBSILE365GS02" && (
                                        <div css={term2PriceStyle}>
                                            <p css={discountTextStyle}>-23%</p>
                                            <p css={discountPriceTextStyle}>
                                                {convertNumberToKorean(
                                                    Math.round(((product?.term2DiscountedPrice ?? 0) * 0.77) / 10000) * 10000,
                                                )}
                                                원
                                            </p>
                                        </div>
                                    )}
                                    {product.name.includes("VIP") && (
                                        <div css={term2PriceStyle}>
                                            <p css={discountTextStyle}>-10%</p>
                                            <p css={discountPriceTextStyle}>
                                                {
                                                    convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.9).split(
                                                        "만",
                                                    )?.[0]
                                                }
                                                만원
                                            </p>
                                        </div>
                                    )}
                                    <div css={premiumTerm1PriceStyle}>
                                        <div css={lastPremiumDiscountTextStyle}>
                                            {/* {product?.priceDiscountDisplay1
                                                ? product?.priceDiscountDisplay1
                                                : `-${Math.floor(100 - (product?.term1DiscountedPrice / product?.price) * 100)}%`} */}
                                            -40%
                                        </div>
                                        <span className="priceText">
                                            {convertNumberToKoreanWithThousand(product?.term1DiscountedPrice)}원
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {PREMIUM_SPRING_PROMOTION_IOS_CODES[0] === product.code ||
                                    PREMIUM_SPRING_PROMOTION_IOS_CODES[1] === product.code ? (
                                        <>
                                            <div css={premiumTerm1PriceStyle}>
                                                <div css={lastPremiumDiscountTextStyle}>
                                                    {/* {product?.priceDiscountDisplay1
                                                ? product?.priceDiscountDisplay1
                                                : `-${Math.floor(100 - (product?.term1DiscountedPrice / product?.price) * 100)}%`} */}
                                                    -40%
                                                </div>
                                                <span className="priceText">
                                                    {convertNumberToKoreanWithThousand(product?.term1DiscountedPrice)}원
                                                </span>
                                            </div>
                                            {PREMIUM_SPRING_PROMOTION_IOS_CODES[0] === product.code && (
                                                <div css={monthlyStyle}>
                                                    <img src={redCurlyArrow} alt="curlyArrow" width={30} />
                                                    <span>한 달에 약 37,071원!!</span>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div css={term1PriceStyle}>
                                            {/* <div css={lastDiscountTextStyle}>
                                    {`-${Math.floor(100 - (product?.term1DiscountedPrice / product?.price) * 100)}%`}
                                </div> */}
                                            <span className="priceText">
                                                {convertNumberToKorean(product?.term1DiscountedPrice)}원
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {product.isEvent && now >= new Date(product.showStartAt) && now <= new Date(product.showEndAt) && (
                    <div css={eventBoxStyle}>
                        <Lottie className="lottie" animationData={timer} />
                        {getEventDuration(now)}
                    </div>
                )}
                <div css={timerStyle}>
                    <PremiumSpringPromotionTimer />
                </div>
            </div>
        </>
    );
};

export default IosNewStateLandProduct;

const productCardBoxStyle = (active: boolean, isEvent: boolean) => css`
    border-radius: 14px;
    border: ${active ? "2px solid #2275EE" : "1px solid #dadada"};
    background-color: #ffffff;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    flex-direction: column;
    gap: 4px;

    margin: 14px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    & > .recommend {
        position: absolute;
        transform: translate(-50%, -50%);
        top: -1px;
    }
    & > .eventText {
        position: absolute;
        bottom: ${isEvent ? "33px" : "2px"};
        left: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        /* width: 88px;
        height: 58px; */
    }
    & > .eventDateText {
        position: absolute;
        top: -15px;
        right: 14px;
    }
    ${isEvent && {
        paddingBottom: "44px",
    }};
`;

const cardContentsStyle = css`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 14px;
    padding-bottom: 4px;
`;

const titleWrapStyle = css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;
const titleStyle = css`
    overflow: hidden;
    color: #0c002c;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
`;

const bottomContent = css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .stickerContainer {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
        width: 100px;
    }
`;

const priceWrapStyle = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    box-sizing: border-box;
    /* width: 45%; */
`;

const premiumTerm1PriceStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    .priceText {
        color: #3c3c3c;
        font-family: "M PLUS ExtraBold";
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        padding: 4px;
        background-color: #7bfd80;
    }
`;

const term1PriceStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    .priceText {
        color: red;
        font-family: "M PLUS ExtraBold";
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        padding: 4px;
    }
`;

const term2PriceStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    display: flex;
    align-items: center;
    gap: 4px;
`;

const discountTextStyle = css`
    color: #0047ff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

const lastPremiumDiscountTextStyle = css`
    color: red;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    min-width: 36px;
`;

const lastDiscountTextStyle = css`
    color: #09aa4b;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    min-width: 36px;
`;

const priceInfoStyle = css`
    color: #3c3c3c;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const monthlyStyle = css`
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    img {
        display: block;
        position: relative;
        left: 43%;
    }
    span {
        color: red;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
    }
`;

const discountPriceTextStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const eventBoxStyle = css`
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff500;
    border-radius: 0 0 14px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lottie {
        height: 40px;
        width: 40px;
    }
`;

const timerStyle = css`
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff500;
    font-size: 14px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    .lottie {
        height: 30px;
        width: 30px;
    }
`;
