import { css } from "@emotion/react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import { IssueLandRegisterResponseDto, useIssueLandRegister } from "@src/hooks/landRegister/useLandRegister";
import { useFindOneUserSubsInfo } from "@src/hooks/user/userApi";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LAND_REGISTER_ERROR_MODAL_TYPE, LAND_REGISTER_MAX_REQUEST_ERROR_MESSAGE } from "./LandRegisterMain";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { transferToNative } from "@src/lib/WebAppBridge";

// 필지, 매물 상세 등의 페이지에서 토지•임야대장 열람 버튼 클릭 > 바로 발급 받는 경우
const LandRegisterIssueWaiting = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedFieldPnu = searchParams.get("pnu");

    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<LAND_REGISTER_ERROR_MODAL_TYPE>(
        LAND_REGISTER_ERROR_MODAL_TYPE.닫기,
    );

    const { data: userSubsInfo } = useFindOneUserSubsInfo(true);
    const { mutate: issueLandRegister } = useIssueLandRegister();

    const goToIssueStatusPage = (issued: IssueLandRegisterResponseDto) => {
        navigate({
            pathname: "/landRegister/status",
            search: `?id=${issued._id}&address=${issued.address}`,
        });
    };

    useEffect(() => {
        if (selectedFieldPnu === null) return;
        if (!userSubsInfo) return;
        if (userSubsInfo && !!userSubsInfo.subsNo) {
            issueLandRegister(
                { pnu: selectedFieldPnu },
                {
                    onSuccess: (data) => goToIssueStatusPage(data),
                    onError: (error) => {
                        if (error.message.includes(LAND_REGISTER_MAX_REQUEST_ERROR_MESSAGE)) {
                            setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.발급제한);
                            return;
                        }
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.발급실패);
                    },
                },
            );
        } else {
            setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.구독필요);
        }
    }, [userSubsInfo]);

    return (
        <div css={rootBoxStyle}>
            <div css={LoadingBoxStyle}>
                <div>
                    <span>발급 등록중...</span>
                    <Lottie className="lottie" animationData={lottie_loading} />
                </div>
            </div>

            {isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.구독필요 && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>구독 회원만 무료로 열람할 수 있어요</p>
                            <p css={dialogContentStyle}>
                                지금 구독을 시작하고 데이터맵과 맞춤형 <br />
                                매물 정보 열람을 이용해 보세요!
                            </p>
                        </>
                    }
                    onCancel={() => {
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                        transferToNative("done", "closeWebView");
                    }}
                    onConfirm={() => {
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                        transferToNative("done", "closeWebView");
                        transferToNative("msgaapp://deeplink/product/subscription", "moveToDeepLink");
                    }}
                    buttonText="시작하기"
                    cancelText="닫기"
                />
            )}
            {(isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급제한 ||
                isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급실패) && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>
                                {isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급제한
                                    ? "1일 20장까지 발급 가능합니다"
                                    : "발급 등록에 실패하였습니다."}
                            </p>
                        </>
                    }
                    onConfirm={() => {
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                        transferToNative("done", "closeWebView");
                    }}
                    buttonText="확인"
                />
            )}
        </div>
    );
};

export default LandRegisterIssueWaiting;

const rootBoxStyle = css`
    background-color: transparent;
`;

const LoadingBoxStyle = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.55);
    animation: fadeIn 0.2s ease;
    z-index: 5;

    & > div {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        top: 0;
        bottom: 0;
        margin: auto;

        .lottie {
            width: 100px;
        }
        & > span {
            color: #fff;
            font-size: 22px;
        }
    }
`;

const dialogTitleStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
    word-break: break-word;
`;
