import { css } from "@emotion/react";
import React from "react";
import removeIcon from "@src/assets/ico_remove_grey.svg";

interface Props {
    value: string;
    onDeleteValue?: () => void;
}

const ShowSelectedUseLocValueBox: React.FC<Props> = ({ value, onDeleteValue }) => {
    return (
        <div css={rootBoxStyle}>
            <p>{value}</p>
            {onDeleteValue && <img src={removeIcon} alt="" onClick={onDeleteValue} />}
        </div>
    );
};

export default ShowSelectedUseLocValueBox;

const rootBoxStyle = css`
    padding: 6px 8px;
    border-radius: 24px;
    background-color: #f6faff;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0c002c;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    width: max-content;
    white-space: nowrap;
`;
