import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Document, Page, pdfjs } from "react-pdf";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { transferToNative } from "@src/lib/WebAppBridge";
import Header from "@src/components/Header";
import useLandRegisterPdf from "@src/hooks/landRegister/useLandRegisterPdf";
import { useNavigate } from "react-router-dom";
import { useLandRegisterPdfUrl } from "@src/hooks/landRegister/useLandRegister";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LandRegisterPdfViewer = () => {
    const { id, address, fileName } = useLandRegisterPdf();

    const { data: pdfUrl } = useLandRegisterPdfUrl(id, !!id && !!address);
    const navigate = useNavigate();

    const [totalPages, setTotalPages] = useState(0);
    const [loadedPages, setLoadedPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const downloadFile = () => {
        transferToNative(
            JSON.stringify({
                pdfUrl,
                fileName,
            }),
            "downloadPdf",
        );
    };

    const onPageLoadSuccess = () => {
        setLoadedPages((prev) => prev + 1);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setTotalPages(numPages);
    };

    useEffect(() => {
        if (totalPages > 0 && loadedPages === totalPages) setIsLoading(false);
    }, [loadedPages, totalPages]);

    return (
        <>
            <Container>
                <Header
                    title="실시간 토지•임야대장 열람"
                    hasCloseBtn={true}
                    onClose={() => navigate({ pathname: "/landRegister/list" })}
                />

                {isLoading && (
                    <div className="loadingBox">
                        <div className="inner">
                            <Lottie className="lottie" animationData={lottie_loading}></Lottie>
                            <div className="count">{`${loadedPages}/${totalPages}`}</div>
                        </div>
                    </div>
                )}

                <TransformWrapper alignmentAnimation={{ sizeX: 0, sizeY: 0 }}>
                    <TransformComponent
                        wrapperStyle={{
                            position: "absolute",
                            top: "50px",
                            bottom: "80px",
                            height: "initial",
                            margin: "auto",
                        }}
                        contentStyle={{}}
                    >
                        <Document
                            className={isLoading ? "document" : "document show"}
                            file={pdfUrl}
                            loading=""
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(totalPages), (_, index) => (
                                <div key={index}>
                                    <Page
                                        width={1024}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        loading=""
                                        onRenderSuccess={onPageLoadSuccess}
                                    />
                                    <hr />
                                </div>
                            ))}
                        </Document>
                    </TransformComponent>
                </TransformWrapper>
                <div className="inputBox">
                    <input type="button" value="다운로드" onClick={() => downloadFile()} />
                </div>
                <label>손가락으로 확대 및 축소가 가능합니다</label>
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: #fff;
    animation: fadeIn 1s forwards;

    .exitBtn {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;

        & > img {
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }
    h3 {
        font-weight: 700;
        font-size: 18px;
        color: #0c0c0c;
        margin: 16px 0;
    }
    .document {
        opacity: 0;
        &.show {
            animation: fadeIn 0.5s forwards;
        }
        hr {
            border: 2px solid #ccc;
        }
        .page {
            width: 1024px;
            height: auto !important;
            transition: all 0.3s ease;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            margin: auto;
            margin-bottom: 20px;
        }
        canvas {
            width: 100% !important;
            height: auto !important;
            margin: auto;
        }
    }
    .loadingBox {
        position: absolute;
        top: 45%;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .inner {
            position: relative;
        }
        .lottie {
            width: 75px;
            margin: auto;
        }
        .count {
            position: absolute;
            width: 100%;
            bottom: 0;
            font-size: 13px;
            color: #0c0c0c;
            text-align: center;
        }
    }
    .inputBox {
        position: absolute;
        width: 100%;
        bottom: 14px;
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding: 0 14px;

        input {
            width: 100%;
            height: 55px;
            background: #2275ee;
            border: none;
            border-radius: 14px;
            color: #fff;
            font-size: 18px;
            margin-top: 15px;
            padding: 0 10px;
            transition: all 0.5s ease;

            &.hidden {
                display: none;
            }
        }
    }
    & > label {
        position: fixed;
        bottom: 90px;
        left: 14px;
        right: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        padding: 8px 14px;
        border-radius: 14px;
        animation: fadeOut 0.5s 2s forwards;
        text-align: center;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

export default LandRegisterPdfViewer;
