import styled from "@emotion/styled";

import React, { PropsWithChildren } from "react";

import leftArrowIcon from "@src/assets/leftArrowIcon.png";

interface FilterHeaderProps extends PropsWithChildren {
    onGoback: () => void;
}

const CommonFilterHeader = ({ children, onGoback }: FilterHeaderProps) => {
    return (
        <Header>
            <div className="img_box">
                <img src={leftArrowIcon} className="gobackIcon" alt="gobackIcon" onClick={onGoback} />
            </div>
            <h3>{children}</h3>
        </Header>
    );
};

export default CommonFilterHeader;

const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 56px;
    justify-content: center;
    background: white;
    position: relative;

    h3 {
        font-size: 18px;
        font-weight: 700;
    }

    .img_box {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;
