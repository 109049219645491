import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

import React from "react";

import refreshIcon from "@src/assets/dataMap_refesh_blue.svg";
import leftArrowIcon from "@src/assets/leftArrowIcon.png";
import clearIcon from "@src/assets/clearIcon.svg";

interface Props {
    title: string;
    out: boolean;
    refresh?: boolean;
    resetFn?: () => void;
    backFn: () => void;
    closeFn?: () => void;
}

const AuctionFilterHeader: React.FC<Props> = ({
    title,
    out,
    refresh = false,
    resetFn = () => {},
    backFn,
    closeFn = () => {},
}) => {
    return (
        <ContentWrap $isNotPartOfMainFilter={out}>
            <div css={headerBoxStyle(true)} style={{ marginBottom: "10px" }}>
                <p css={titleTextStyle(true)}>최근 검색 조건</p>
                <div css={historyCloseButtonStyle}>
                    <img
                        src={clearIcon}
                        alt="clearIcon"
                        className="clearIcon"
                        onClick={() => {
                            backFn();
                            closeFn();
                        }}
                    />
                </div>
            </div>
        </ContentWrap>
    );
};

export default AuctionFilterHeader;

const ContentWrap = styled.div<{ $isNotPartOfMainFilter: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .img_box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const headerBoxStyle = (isNotPartOfMainFilter: boolean) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    gap: ${isNotPartOfMainFilter ? "8px" : "0"};

    .title {
        display: flex;
        font-size: 18px;
        gap: 10px;
    }

    .back {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 24px;
        height: 24px;
    }
`;

const titleTextStyle = (out: boolean) => css`
    font-size: ${out ? "20px" : "16px"};
    font-weight: 700;
`;

const historyCloseButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const resetButtonStyle = css`
    color: #0047ff;
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;

    .img_box {
        width: 16px;
        height: 16px;
    }
`;
