import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import AuctionTypeFilter from "@src/pages/mapFilter/AuctionTypeFilter";

import AuctionMasterItemFilter from "@src/pages/mapFilter/AuctionMasterItemFilter";
import AuctionStatusFilter from "@src/pages/mapFilter/AuctionStatusFilter";
import AuctionSkipFilter from "@src/pages/mapFilter/AuctionSkipFilter";
import DueDateFilter from "@src/pages/mapFilter/DueDateFilter";
import EstPriceFilter from "@src/pages/mapFilter/EstPriceFilter";
import MinPriceFilter from "@src/pages/mapFilter/MinPriceFilter";
import FilterHeader from "@src/components/filter/Header";
import AiResetConfirmModal from "../aiFilter/AiResetConfirmModal";
import {
    initialFilterParams,
    useLocationParamSavedUseFilter,
    useNewFilterMapCheckStore,
    useNewFilterStore,
    useSavedLocationFilter,
    useSavedUseFilter,
    useTempNameAndNoList,
} from "@src/store/newFilterStore";
import { Common } from "@src/styles/Common";
import AuctionCounterForceFilter from "./AuctionCounterForceFilter";
import { transferToNative } from "@src/lib/WebAppBridge";

import LocationFilterV3Preview from "./LocationFilterV3Preview";
import UseFilterV3Preview from "./UseFilterV3Preview";
import LocationFilterV3Detail from "./LocationFilterV3Detail";
import UseFilterV3Detail, { getMinorCategories } from "./UseFilterV3Detail";
import { useUpdateFilterHistory } from "@src/hooks/item/useGetItem";

interface Props {}

const AllFilter: React.FC<Props> = () => {
    const navigate = useNavigate();
    const filterStoreState = useNewFilterStore((state) => state.newFilterState);
    const setFilterStoreState = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const [openMainFilterSub, setOpenMainFilterSub] = useState("");

    const savedLocation = useSavedLocationFilter((state) => state.filterLocationState);
    const setSavedLocation = useSavedLocationFilter((state) => state.setFilterLocationState);
    const savedUseList = useSavedUseFilter((state) => state.filterUseState);
    const setSavedUseList = useSavedUseFilter((state) => state.setFilterUseState);
    const setTempNameAndNo = useTempNameAndNoList((state) => state.setTempNameAndNoListState);

    // 소재지 좌표 저장
    const savedLocationParam = useLocationParamSavedUseFilter((state) => state.filterLocationParamState);
    const setSavedLocationParam = useLocationParamSavedUseFilter((state) => state.setFilterLocationParamState);

    const { mutate: updateFilterHistory } = useUpdateFilterHistory();

    const handleFilterSelectDone = () => {
        setFilterCheck(false);
        transferToNative(JSON.stringify(filterStoreState), "setFilterDone");
        if (savedLocationParam) transferToNative(JSON.stringify(savedLocationParam), "mapCenterPost");
        updateFilterHistory(filterStoreState);
        navigate(-1);
    };
    const handleReset = () => {
        setFilterStoreState(initialFilterParams);
        setResetModalVisible(false);
        setSavedLocation(null);
        setSavedUseList([]);
        setTempNameAndNo([]);
        setSavedLocationParam(null);
        transferToNative(JSON.stringify(initialFilterParams), "setFilterDone");
        navigate(-1);
    };

    useEffect(() => {
        transferToNative("full", "resizeWebview");
    }, []);

    // 최상단에 띄울 팝업을 추가
    function changeSubFilter(sub: string) {
        setOpenMainFilterSub(sub);
    }

    useEffect(() => {
        let newLoc = {
            ...filterStoreState,
            loc: savedLocation ? [savedLocation.value] : [],
        };
        setFilterStoreState(newLoc);
    }, [savedLocation]);

    useEffect(() => {
        const updated = savedUseList
            .map((use) => {
                // 해당 용도의 전체일 경우
                if (use.value.length === 1) {
                    // 용도가 기타일 경우 예외 처리
                    if (use.value === "E") {
                        return ["E01"];
                    } else {
                        return getMinorCategories(use.value)
                            .map((item) => item.value)
                            .filter((item) => item.length !== 1);
                    }
                } else {
                    return use.value;
                }
            })
            .flat();
        const copy = { ...filterStoreState, use: updated };
        setFilterStoreState(copy);
    }, [savedUseList]);

    return (
        <div css={rootBoxStyle}>
            <FilterHeader>지도종합검색 설정</FilterHeader>
            <div css={{ height: "60px" }} />
            <div css={contentWrap}>
                <AuctionTypeFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <LocationFilterV3Preview
                    changeSubFilter={changeSubFilter}
                    setSavedLocation={setSavedLocation}
                    savedLocation={savedLocation}
                    setTempNameAndNo={setTempNameAndNo}
                />
                <div css={lineStyle} />
                <UseFilterV3Preview
                    changeSubFilter={changeSubFilter}
                    savedUseList={savedUseList}
                    setSavedUseList={setSavedUseList}
                />
                <div css={lineStyle} />
                <AuctionCounterForceFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <AuctionMasterItemFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <AuctionStatusFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <AuctionSkipFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <DueDateFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <EstPriceFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />
                <div css={lineStyle} />
                <MinPriceFilter filterParams={filterStoreState} setFilterParams={setFilterStoreState} />

                <div css={{ height: "200px" }} />
                <div css={footerRootStyle}>
                    <div css={footerButtonBoxStyle}>
                        <button onClick={() => setResetModalVisible(true)}>
                            <span>초기화</span>
                        </button>
                        <button className="active" onClick={handleFilterSelectDone}>
                            <span>적용하기</span>
                        </button>
                    </div>
                </div>
            </div>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={handleReset}
                />
            )}
            {openMainFilterSub === "mainFilter-loc" && (
                <LocationFilterV3Detail
                    backFn={() => {
                        changeSubFilter("");
                    }}
                    isNotParfOfMainFilter={false}
                    setSavedLocation={setSavedLocation}
                />
            )}
            {openMainFilterSub === "mainFilter-use" && (
                <UseFilterV3Detail
                    backFn={() => {
                        changeSubFilter("");
                    }}
                    isNotParfOfMainFilter={false}
                    savedUseList={savedUseList}
                    setSavedUseList={setSavedUseList}
                />
            )}
        </div>
    );
};

export default AllFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    height: 100vh;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
`;

const contentWrap = css`
    height: calc(100vh - 60px);
    /* height: calc(100vh - 250px); */
    overflow-y: auto;
    &::-webkit-scrollbar {
        border-radius: 4px;
        width: 6px;
        height: 4px;
        margin-right: 10px;
        margin-top: 20px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: #f0f0f0;
    }
`;

const footerButtonBoxStyle = css`
    display: flex;
    padding: 14px;
    button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #c3c3c3;
        span {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
        }
    }
    button.active {
        background: ${Common.colors.aucMarkerColor};
    }
    button + button {
        margin-left: 12px;
    }
`;

const footerRootStyle = css`
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const lineStyle = css`
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
`;
