"use client";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";

import landRegisterDefaultBackground from "@src/assets/landRegister/land_register_default_bg.png";
import landRegisterDefaultText from "@src/assets/landRegister/land_register_default_text.png";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import Header from "@src/components/Header";
import { useNavigate } from "react-router-dom";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useFindOneUserSubsInfo } from "@src/hooks/user/userApi";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import LandRegisterTab from "@src/components/landRegister/LandRegisterTab";
import LandReigsterSearchBar from "@src/components/landRegister/LandReigsterSearchBar";
import LandRegisterSearchAddressList from "@src/components/landRegister/LandRegisterSearchAddressList";
import {
    IssueLandRegisterResponseDto,
    SearchAddressItemDto,
    useIssueLandRegister,
    useLandRegisterSearchAddress,
} from "@src/hooks/landRegister/useLandRegister";

export enum LAND_REGISTER_ERROR_MODAL_TYPE {
    "닫기",
    "구독필요",
    "발급제한",
    "발급실패",
}

export const LAND_REGISTER_MAX_REQUEST_ERROR_MESSAGE = "발급";

const LandRegisterMain = () => {
    const navigate = useNavigate();

    const [isSearchDone, setIsSearchDone] = useState(false);
    const [searchSubmitText, setSearchSubmitText] = useState("");

    const { data: userSubsInfo } = useFindOneUserSubsInfo(true);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<LAND_REGISTER_ERROR_MODAL_TYPE>(
        LAND_REGISTER_ERROR_MODAL_TYPE.닫기,
    );

    const {
        data: addressList,
        isLoading: isSearchListLoading,
        fetchNextPage,
    } = useLandRegisterSearchAddress(searchSubmitText, isSearchDone);
    const { mutate: issueLandRegister } = useIssueLandRegister();

    const handleSearchDone = (searchText: string) => {
        setIsSearchDone(true);
        setSearchSubmitText(searchText);
    };

    const goToIssueStatusPage = (issued: IssueLandRegisterResponseDto) => {
        navigate({
            pathname: "/landRegister/status",
            search: `?id=${issued._id}&address=${issued.address}`,
        });
    };

    const handleSelectAddress = ({ pnu }: SearchAddressItemDto) => {
        if (userSubsInfo && userSubsInfo.subsNo) {
            issueLandRegister(
                { pnu },
                {
                    onSuccess: (data) => goToIssueStatusPage(data),
                    onError: (error) => {
                        if (error.message.includes(LAND_REGISTER_MAX_REQUEST_ERROR_MESSAGE)) {
                            setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.발급제한);
                            return;
                        }
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.발급실패);
                    },
                },
            );
        } else {
            setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.구독필요);
        }
    };

    return (
        <Container>
            <Header title="실시간 토지•임야대장 열람" />
            <LandRegisterTab />
            <LandReigsterSearchBar onSearchDone={handleSearchDone} />

            {addressList && (
                <LandRegisterSearchAddressList
                    addressList={addressList}
                    onSelectAddress={handleSelectAddress}
                    onLoadMore={fetchNextPage}
                />
            )}
            {isSearchListLoading && (
                <div className={"loadingBox"}>
                    <div>
                        <span>검색 진행중...</span>
                        <Lottie className="lottie" animationData={lottie_loading} />
                    </div>
                </div>
            )}
            {!addressList && (
                <div className={"bannerBox"}>
                    <img className="bg" src={landRegisterDefaultBackground} alt="토지•임야대장 기본 배경사진" />
                    <div className="dimCover" />
                    <img className="text" src={landRegisterDefaultText} alt="토지•임야대장 기본 배경 텍스트" />
                </div>
            )}

            {isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.구독필요 && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>구독 회원만 무료로 열람할 수 있어요</p>
                            <p css={dialogContentStyle}>
                                지금 구독을 시작하고 데이터맵과 맞춤형 <br />
                                매물 정보 열람을 이용해 보세요!
                            </p>
                        </>
                    }
                    onCancel={() => {
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                    }}
                    onConfirm={() => {
                        transferToNative("msgaapp://deeplink/product/subscription", "moveToDeepLink");
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                    }}
                    buttonText="시작하기"
                    cancelText="닫기"
                />
            )}
            {(isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급제한 ||
                isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급실패) && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>
                                {isOpenConfirmModal === LAND_REGISTER_ERROR_MODAL_TYPE.발급제한
                                    ? "1일 20장까지 발급 가능합니다"
                                    : "발급 등록에 실패하였습니다."}
                            </p>
                        </>
                    }
                    onConfirm={() => {
                        setIsOpenConfirmModal(LAND_REGISTER_ERROR_MODAL_TYPE.닫기);
                        transferToNative("done", "closeWebView");
                    }}
                    buttonText="확인"
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;

    &.hidden {
        display: none;
    }
    & > h3 {
        text-align: center;
        font-size: 20px;
        margin: 18px 0 0;

        & > small {
            font-size: 12px;
            font-weight: 500;
            margin-left: 5px;
        }
    }
    .bannerBox {
        position: absolute;
        top: 196px;
        bottom: -4px;
        left: 0;
        right: 0;
        overflow: scroll;
        width: 100%;
        animation: fadeIn 0.5s forwards;

        .bg {
            width: 100%;
            height: 100vh;
        }

        .dimCover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: rgba(0, 0, 0, 0.6);
        }

        .text {
            width: 360px;
            position: absolute;
            left: 50%;
            margin-left: -180px;
            top: 20px;
            z-index: 2;
        }
    }
    .loadingBox {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.55);
        animation: fadeIn 0.2s ease;
        z-index: 5;

        & > div {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: fit-content;
            top: 0;
            bottom: 0;
            margin: auto;

            .lottie {
                width: 100px;
            }
            & > span {
                color: #fff;
                font-size: 22px;
            }
        }
    }

    .searchInput {
        width: -webkit-fill-available;
        height: 40px;
        border: 1px solid #ddd;
        padding: 0 14px;
        margin: 18px 12px;
        font-size: 16px;
        box-sizing: border-box;
    }
    .totalCntBox {
        font-size: 14px;
        margin: 10px 14px 14px;
        color: #3e3e3e;

        & > span {
            font-size: 16px;
            font-weight: 700;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const dialogTitleStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
    word-break: break-word;
`;

export default LandRegisterMain;
