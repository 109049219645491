"use client";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState, KeyboardEvent, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";

import building_register_banner from "@src/assets/buildingRegister/building-register-banner.png";
import input_close_icon from "@src/assets/buildingRegister/input_close_icon.svg";

import { DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import {
    useBuildingRegister,
    useBuildingRegisterCheck,
    useBuildingRegisterItems,
    useBuildingRegisterSearch,
} from "@src/hooks/buildingRegister/useBuildingRegister";
import { BuildingRegisterServerResponseDto } from "@src/hooks/buildingRegister/dto/BuildingRegister.dto";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import Header from "@src/components/Header";
import DeungiPopup from "@src/components/deungi/DeungiPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import BuildingRegisterTab from "@src/components/buildingRegister/BuildingRegisterTab";
import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";
import micIcon from "@src/assets/basicIcon/micIcon.svg";
import { useFindOneUserSubsInfo } from "@src/hooks/user/userApi";
import ConfirmModal from "@src/components/popup/ConfirmModal";

const BuildingRegisterMain = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [mainAddress, setMainAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);
    const [searchFlag, setSearchFlag] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [searchList, setSearchList] = useState(null as any);
    const [regstrKindCd, setRegstrKindCd] = useState(1);
    const [registerList, setRegisterList] = useState(null as any);
    const [searchFilter, setSearchFilter] = useState("");
    const [dongInfo, setDongInfo] = useState<string>("");

    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.WAITING);
    const [popupToggle, setPopupToggle] = useState(false);
    const [popupData, setPopupData] = useState({});
    const searchTextAreaRef = useRef(null as any);
    const searchInfoRef = useRef(null as any);

    const regstrKindCdArr = ["", "총괄표제부", "일반건축물", "표제부", "전유부"];

    const { data, refetch } = useFindOneUserSubsInfo();
    const [showSubsInfo, setShowSubsInfo] = useState<boolean>(false);

    useEffect(() => {
        searchText.length && !data?.subsNo && setShowSubsInfo(true);
        console.log("data", data);
    }, [data]);

    WebAppBridge.setSpeechResult = async (val: string) => {
        setSearchText(val);
        setSearchFlag(true);
        if (val.length > 0) {
            console.log("검색 시작");
            try {
                const serverCheckRes = await useBuildingRegisterCheck();
                const serverCheckInfo: BuildingRegisterServerResponseDto = serverCheckRes.data;
                if (!serverCheckInfo.isCheck) {
                    setPopupType(DEUNGI_POPUP_TYPE.ERROR_BUILDING_REGISTER);
                    setPopupData(serverCheckInfo);
                    setPopupToggle(true);
                    return;
                }
                const res = await useBuildingRegisterSearch(val);
                setSearchList(res.data.list);
            } catch (e) {
                console.log("error", e);
            }
        } else {
            setSearchList(null);
        }
        onFocusSearchTextHandler(false);
    };

    const getSearchList = async () => {
        if (searchText.length > 0) {
            console.log("검색 시작");

            try {
                const serverCheckRes = await useBuildingRegisterCheck();
                const serverCheckInfo: BuildingRegisterServerResponseDto = serverCheckRes.data;

                if (!serverCheckInfo.isCheck) {
                    setPopupType(DEUNGI_POPUP_TYPE.ERROR_BUILDING_REGISTER);
                    setPopupData(serverCheckInfo);
                    setPopupToggle(true);
                    return;
                }

                const res = await useBuildingRegisterSearch(searchText);
                setSearchList(res.data.list);
                setDongInfo(res?.data?.dongHo?.trim());
            } catch (e) {
                console.log("error", e);
            }
        } else {
            setSearchList(null);
        }
    };

    const onClearSearchTextHandler = () => {
        setSearchText("");
    };

    const onAddressClickHandler = async (address: string, splotNm: string, block: string, addInfo?: string) => {
        console.log("대장 가져오기");
        const filterAddress = address.replace(/( {2})/g, " ");
        const queryString = `splotNm=${splotNm}&blockNm=${block}&platGbCd=${address.indexOf(" 산 ") >= 0 ? 1 : -1}`;
        try {
            const serverCheckRes = await useBuildingRegisterCheck();
            const serverCheckInfo: BuildingRegisterServerResponseDto = serverCheckRes.data;

            if (!serverCheckInfo.isCheck) {
                setPopupType(DEUNGI_POPUP_TYPE.ERROR_BUILDING_REGISTER);
                setPopupData(serverCheckInfo);
                setPopupToggle(true);
                return;
            }

            setSearchFlag(false);
            setIsLoading(true);

            const res = await useBuildingRegisterItems(filterAddress, queryString);
            setRegisterList(res.data);

            navigate({ search: `?address=${address}&splotNm=${splotNm}&block=${block}` }, { replace: true });
        } catch (e) {
            console.log("error", e);
        } finally {
            setIsLoading(false);
            setSearchList(null);
            if (dongInfo || addInfo) {
                setSearchFilter(
                    dongInfo?.replaceAll("undefined", "")
                        ? dongInfo?.replaceAll("undefined", "")
                        : addInfo?.replaceAll("undefined", "") ?? "",
                );
            } else {
                setSearchFilter("");
            }
            setDongInfo("");
            setSearchText(filterAddress);
            setMainAddress(filterAddress);
            onFocusSearchTextHandler(false);
            refetch();
        }
    };

    const onPdfClickHandler = async (el: any) => {
        console.log("PDF 가져오기");
        setIsLoading(true);
        setIsOpen(true);
        try {
            const detailAddress =
                mainAddress +
                (el.dongNm ? " " + el.dongNm : "") +
                (el.hoNm ? " " + el.hoNm : "") +
                (el.mainPrposNm ? ` [${el.mainPrposNm.trimEnd()}]` : "");

            const res = await useBuildingRegister(
                el.bldrgstSeqno,
                el.untClsfCd,
                el.regstrKindCd,
                mainAddress,
                detailAddress,
                el.bldNm,
                el.dongNm,
                el.hoNm,
                el.mainPrposNm,
                `${el.sigunguCd}_${el.bjdongCd}_0_${el.mnnm}_${el.slno}`,
            );
            navigate({
                pathname: "/buildingRegister/viewer",
                search: `?id=${res.data}&address=${encodeURIComponent(`${detailAddress}_${regstrKindCdArr[regstrKindCd]}`)}`,
            });
        } catch (e: any) {
            setPopupData(e?.response?.data?.message || "건축행정시스템 점검으로 인하여 대장 열람이 제한됩니다.");
            setPopupType(DEUNGI_POPUP_TYPE.LIMIT_BUILDING_REGISTER);
            setPopupToggle(true);
        } finally {
            setIsLoading(false);
            setIsOpen(false);
        }
    };

    const onFocusSearchTextHandler = (isFocus: boolean) => {
        setSearchFocus(isFocus);
        if (searchTextAreaRef.current) {
            searchTextAreaRef.current.style.height = "auto";

            if (!isFocus) {
                searchTextAreaRef.current.style.height = "44px";
                searchTextAreaRef.current.blur();
            } else if (searchTextAreaRef.current.scrollHeight > 40) {
                searchTextAreaRef.current.style.height = searchTextAreaRef.current.scrollHeight + "px";
            }
        }
    };

    const onSearchHandler = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.nativeEvent.isComposing) return;
        if (event.key.match(/Enter/g)) event.preventDefault();

        switch (event.key) {
            case "Enter":
                setSearchFlag(true);
                getSearchList();
                onFocusSearchTextHandler(false);
                break;
        }
    };

    useEffect(() => {
        if (searchParams.get("address")) {
            const address = searchParams.get("address") || "";
            const splotNm = searchParams.get("splotNm") || "";
            const block = searchParams.get("block") || "";
            const dongInfoCheck = address?.split(",")?.[1]?.split("(")?.[0];
            const newDong = dongInfoCheck?.includes("동") ? dongInfoCheck?.split("동")?.[0] : "";
            const newHo = dongInfoCheck?.split("층")?.[1] ?? dongInfoCheck?.split("동")?.[1] ?? dongInfoCheck;
            const newDongInfo = `${newDong} ${newHo?.replaceAll("호", "")}`.trim();
            const restStartIndex = address?.indexOf("제");
            const rest = address?.slice(restStartIndex)?.split("[")[0]?.replaceAll("제", "");
            const restSplit = rest
                ?.split(" ")
                ?.filter((val) => val.includes("동") || val.includes("호"))
                ?.map((v) => v?.replaceAll(/\D/gi, " "))
                ?.join("");
            setDongInfo(restSplit?.replaceAll("undefined", "") ? restSplit : newDongInfo?.replaceAll("undefined", ""));
            onAddressClickHandler(
                address,
                splotNm,
                block,
                restSplit?.replaceAll("undefined", "") ? restSplit : newDongInfo?.replaceAll("undefined", ""),
            );
        }
    }, []);

    useEffect(() => {
        onFocusSearchTextHandler(true);
    }, [searchText]);

    useEffect(() => {
        if (registerList) {
            if (dongInfo && registerList && registerList.count?.expos > 0) {
                setRegstrKindCd(4);
            } else if (registerList.count?.recapTitle > 0) setRegstrKindCd(1);
            else if (registerList.count?.rgstgnrl > 0) setRegstrKindCd(2);
            else if (registerList.count?.title > 0) setRegstrKindCd(3);
            else if (registerList.count?.expos > 0) setRegstrKindCd(4);

            searchTextAreaRef.current.style.height = "44px";
        }
    }, [registerList]);

    return (
        <Container>
            <Header title="실시간 건축물대장 열람" />
            <BuildingRegisterTab />
            <BuildingRegisterSearchBar>
                <div className="searchBox">
                    <textarea
                        rows={1}
                        ref={searchTextAreaRef}
                        placeholder="주소 또는 건물명 입력"
                        onKeyDown={(e) => {
                            onSearchHandler(e);
                        }}
                        onFocus={(e) => onFocusSearchTextHandler(true)}
                        onBlur={(e) => onFocusSearchTextHandler(false)}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                    ></textarea>
                    <SearchIcon />
                    {searchText.length > 0 && (
                        <div className={"closeBtn"} onClick={onClearSearchTextHandler}>
                            <img src={input_close_icon} />
                        </div>
                    )}
                    {navigator.userAgent.toLowerCase().includes("iphone") && (
                        <div
                            className={"micBtn"}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                transferToNative("webSearch", "onClickSearchMic");
                            }}
                        >
                            <img src={micIcon} />
                        </div>
                    )}
                </div>
            </BuildingRegisterSearchBar>

            {/* 주소 리스트 */}
            {searchList && (
                <div className={"searchInfo"} ref={searchInfoRef}>
                    {searchList.length > 0 && (
                        <h3>
                            {searchList.length < 100 ? (
                                <>
                                    총 <span>{searchList.length.toLocaleString()}건</span>의 소재지가 조회되었습니다
                                </>
                            ) : (
                                <>검색 결과가 많습니다. 소재지를 상세히 입력해주세요.</>
                            )}
                        </h3>
                    )}
                    {searchList.length === 0 && (
                        <div className={"notFoundInfo"}>
                            해당 소재지에 대장 정보가 존재하지 않습니다 <br />
                            검색어를 다시 확인해주세요
                            <br />
                            <br />※ 검색결과가 나오지 않는 경우 상세주소를
                            <br />
                            제외하고 검색해주세요
                        </div>
                    )}
                    {searchList.map((el: any, idx: number) => {
                        return (
                            <div
                                key={idx}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                    onAddressClickHandler(el._source.jibunAddr, el._source.splotNm || "", el._source.block || "");
                                }}
                            >
                                {el._source.jibunAddr && (
                                    <p>
                                        <label>지번</label>
                                        {el._source.jibunAddr}
                                    </p>
                                )}
                                {el._source.roadAddr && (
                                    <p>
                                        <label>도로명</label>
                                        {el._source.roadAddr}
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}

            {registerList && (
                <>
                    <div className={"registerBox"}>
                        <div
                            className={regstrKindCd === 1 ? "recapTitle active" : "recapTitle"}
                            onClick={() => setRegstrKindCd(1)}
                        >
                            <h3>총괄표제부</h3>
                            <label>
                                {registerList?.count?.recapTitle || 0}
                                <span>건</span>
                            </label>
                        </div>
                        <div className={regstrKindCd === 2 ? "rgstgnrl active" : "rgstgnrl"} onClick={() => setRegstrKindCd(2)}>
                            <h3>일반건축물</h3>
                            <label>
                                {registerList?.count?.rgstgnrl || 0}
                                <span>건</span>
                            </label>
                        </div>
                        <div className={regstrKindCd === 3 ? "title active" : "title"} onClick={() => setRegstrKindCd(3)}>
                            <h3>표제부</h3>
                            <label>
                                {registerList?.count?.title || 0}
                                <span>건</span>
                            </label>
                        </div>
                        <div
                            className={regstrKindCd === 4 ? "expos active" : "expos"}
                            onClick={() => {
                                searchFilter === "undefined" && setSearchFilter("");
                                setRegstrKindCd(4);
                            }}
                        >
                            <h3>전유부</h3>
                            <label>
                                {registerList?.count?.expos || 0}
                                <span>건</span>
                            </label>
                        </div>
                    </div>
                </>
            )}

            {/* 조호된 대장 */}
            {registerList && (
                <div className={"registerList"}>
                    {regstrKindCd === 1 ? (
                        <>
                            <h3>
                                조회된 총괄표제부 <span>{registerList?.count?.recapTitle || 0}건</span>
                            </h3>
                            <div className={"listBox"}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>건축물 이름</th>
                                            <th></th>
                                            <th>열람</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registerList?.list?.recapTitle?.map((el: any, idx: number) => {
                                            return (
                                                <tr key={idx} onClick={() => onPdfClickHandler(el)}>
                                                    <td colSpan={3}>{el.bldNm || "건물명없음"}</td>
                                                    <td colSpan={1}>
                                                        <span>열람</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {registerList?.count?.recapTitle === 0 && <p>조회 결과가 없습니다</p>}
                            </div>
                        </>
                    ) : regstrKindCd === 2 ? (
                        <>
                            <h3>
                                조회된 일반건축물 <span>{registerList?.count?.rgstgnrl || 0}건</span>
                            </h3>
                            <div className={"listBox"}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>건축물 이름</th>
                                            <th>동 명칭</th>
                                            <th>주 용도</th>
                                            <th>열람</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registerList?.list?.rgstgnrl?.map((el: any, idx: number) => {
                                            return (
                                                <tr key={idx} onClick={() => onPdfClickHandler(el)}>
                                                    <td>{el.bldNm || "건물명없음"}</td>
                                                    <td>{el.dongNm || "동명칭없음"}</td>
                                                    <td>{el.mainPrposNm}</td>
                                                    <td>
                                                        <span>열람</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {registerList?.count?.rgstgnrl === 0 && <p>조회 결과가 없습니다</p>}
                            </div>
                        </>
                    ) : regstrKindCd === 3 ? (
                        <>
                            <h3>
                                조회된 표제부 <span>{registerList?.count?.title || 0}건</span>
                            </h3>
                            <div className={"listBox"}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>건축물 이름</th>
                                            <th>동 명칭</th>
                                            <th>주 용도</th>
                                            <th>열람</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registerList?.list?.title?.map((el: any, idx: number) => {
                                            return (
                                                <tr key={idx} onClick={() => onPdfClickHandler(el)}>
                                                    <td>{el.bldNm || "건물명없음"} </td>
                                                    <td>{el.dongNm || "동명칭없음"} </td>
                                                    <td>{el.mainPrposNm || " "}</td>
                                                    <td>
                                                        <span>열람</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {registerList?.count?.title === 0 && <p>조회 결과가 없습니다</p>}
                            </div>
                        </>
                    ) : regstrKindCd === 4 ? (
                        <>
                            <h3>
                                조회된 전유부 <span>{registerList?.count?.expos || 0}건</span>
                            </h3>
                            <input
                                type="text"
                                onChange={(e) => setSearchFilter(e.target.value)}
                                value={searchFilter}
                                placeholder={"동 및 호수 상세 검색"}
                            />
                            <div className={"listBox"}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>건축물 이름</th>
                                            <th>동 명칭</th>
                                            <th>호 명칭</th>
                                            <th>열람</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registerList?.list?.expos
                                            ?.filter((el: any) => {
                                                if (searchFilter.indexOf(" ") >= 0) {
                                                    return (
                                                        el.dongNm?.indexOf(searchFilter.split(" ")[0]) >= 0 &&
                                                        el.hoNm?.indexOf(searchFilter.split(" ")[1]) >= 0
                                                    );
                                                } else {
                                                    return (
                                                        el.dongNm?.indexOf(searchFilter) >= 0 ||
                                                        el.hoNm?.indexOf(searchFilter) >= 0
                                                    );
                                                }
                                            })
                                            .splice(0, 100)
                                            .map((el: any, idx: number) => {
                                                return (
                                                    <tr key={idx} onClick={() => onPdfClickHandler(el)}>
                                                        <td>{el.bldNm || "건물명없음"}</td>
                                                        <td>{el.dongNm || "동명칭없음"}</td>
                                                        <td>{el.hoNm || "호수없음"}</td>
                                                        <td>
                                                            <span>열람</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                {registerList?.count?.expos === 0 && <p>조회 결과가 없습니다</p>}
                                {registerList?.list?.expos?.filter((el: any) => {
                                    if (searchFilter.indexOf(" ") >= 0) {
                                        return (
                                            el.dongNm?.indexOf(searchFilter.split(" ")[0]) >= 0 &&
                                            el.hoNm?.indexOf(searchFilter.split(" ")[1]) >= 0
                                        );
                                    } else {
                                        return el.dongNm?.indexOf(searchFilter) >= 0 || el.hoNm?.indexOf(searchFilter) >= 0;
                                    }
                                }).length > 100 && (
                                    <p>
                                        검색 결과는 최대 100건만 조회됩니다
                                        <br />동 및 호수 상세 검색을 이용해주세요
                                    </p>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
            {isLoading && (
                <div className={"loadingBox"}>
                    <div>
                        <span>{(isOpen && "열람 진행중...") || "검색 진행중..."}</span>
                        <Lottie className="lottie" animationData={lottie_loading} />
                    </div>
                </div>
            )}
            {!registerList && !searchList && (
                <div className={"bannerBox"}>
                    <img src={building_register_banner} />
                </div>
            )}
            <DeungiPopup type={popupType} data={popupData} toggle={popupToggle} setToggle={setPopupToggle} />

            {/* 열람 */}
            {showSubsInfo && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>유료구독 회원만 무료로 열람할 수 있어요</p>
                            <p css={dialogContentStyle}>지금 구독을 시작하고 데이터맵과 맞춤형 매물 정보 열람을 이용해 보세요!</p>
                        </>
                    }
                    onCancel={() => {
                        setShowSubsInfo(false);
                        navigate("/buildingRegister");
                        navigate(0);
                    }}
                    onConfirm={() => {
                        transferToNative("msgaapp://deeplink/product/subscription", "moveToDeepLink");
                        setShowSubsInfo(false);
                    }}
                    buttonText="시작하기"
                    cancelText="닫기"
                />
            )}
        </Container>
    );
};

const BuildingRegisterSearchBar = styled.div`
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin: 0 14px 14px;

    .searchBox {
        position: absolute;
        display: flex;
        align-items: center;
        width: calc(100% - 28px);
        border: 2px solid #2275ee !important;
        border-radius: 8px;
        z-index: 4;

        & > textarea {
            width: 100%;
            height: 44px;
            max-height: 65px;
            line-height: 1.6;
            font-weight: 400;
            font-size: 16px;
            padding: 6px 48px 12px 45px;
            border: 4px solid #fff !important;
            border-radius: 8px;
            background: #fff;
            box-sizing: border-box;
            text-align: left;
            resize: none;
            overflow: hidden;
            outline: none;
            z-index: 2;
            transition: all 0.2s ease;

            &::placeholder {
                color: #c9c9c9;
            }
            &.hidden {
                padding: 0 15px;
            }
        }
        & > svg {
            position: absolute;
            top: 50%;
            transform: translate(0%, -50%);
            left: 14px;
            font-size: 27.45px;
            color: #2275ee;
            z-index: 2;
        }
        .closeBtn {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 36px;
            z-index: 2;
            cursor: pointer;

            & > img {
                height: 15px;
            }
        }
        .micBtn {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 14px;
            z-index: 2;
            cursor: pointer;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.hidden {
        display: none;
    }
    & > h3 {
        text-align: center;
        font-size: 20px;
        margin: 18px 0 0;

        & > small {
            font-size: 12px;
            font-weight: 500;
            margin-left: 5px;
        }
    }
    .bannerBox {
        position: absolute;
        top: 200px;
        bottom: -5px;
        overflow: scroll;
        width: 100%;
        animation: fadeIn 0.5s forwards;

        & > img {
            width: 100%;
        }
    }
    .loadingBox {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.55);
        animation: fadeIn 0.2s ease;
        z-index: 5;

        & > div {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: fit-content;
            top: 0;
            bottom: 0;
            margin: auto;

            .lottie {
                width: 100px;
            }
            & > span {
                color: #fff;
                font-size: 22px;
            }
        }
    }
    .searchInfo {
        position: absolute;
        top: 195px;
        bottom: 0;
        width: 100%;
        background: #fff;
        overflow: scroll;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        transition: all 0.2s ease;
        animation: fadeIn 0.2s ease;
        z-index: 3;

        & > h3 {
            margin: 0 12px 12px;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.5;

            & > span {
                font-size: 14px;
                color: #f6494b;
            }
        }
        .notFoundInfo {
            font-size: 14px;
            line-height: 1.8;
            text-align: center;
            margin-top: 5px;

            &:hover {
                background: #fff;
            }
        }
        & > div {
            border-top: 1px solid #ccc;
            padding: 5px 12px;
            transition: all 0.2s ease;
            cursor: pointer;

            &.active {
                background: #dff0ff;
            }
            &:hover {
                background: #dff0ff;
            }
            & > p {
                display: flex;
                align-items: flex-start;
                gap: 6px;
                line-height: 20px;
                font-size: 13px;
                margin: 5px 0;

                & > label {
                    display: inline-block;
                    flex: none;
                    width: 40px;
                    font-size: 11px;
                    padding: 0 3px;
                    border-radius: 5px;
                    color: #fff;
                    text-align: center;
                }
                &:nth-last-of-type(1) {
                    border: none;
                }
            }
            & > p:nth-of-type(1) > label {
                background: #2275ee;
            }
            & > p:nth-of-type(2) > label {
                background: #ff9500;
            }
            &:nth-last-of-type(1) {
                margin-bottom: 30px;
                border-bottom: 1px solid #ccc;
            }
        }
    }
    .searchInput {
        width: -webkit-fill-available;
        height: 40px;
        border: 1px solid #ddd;
        padding: 0 14px;
        margin: 18px 12px;
        font-size: 16px;
        box-sizing: border-box;
    }
    .totalCntBox {
        font-size: 14px;
        margin: 10px 14px 14px;
        color: #3e3e3e;

        & > span {
            font-size: 16px;
            font-weight: 700;
        }
    }
    .registerBox {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        gap: 5px;
        margin: 14px 14px 0;

        & > div {
            flex: 1;
            height: 60px;
            border-radius: 15px 0 0 0;
            text-align: center;
            padding: 5px 0;
            transition: all 0.2s ease;
            cursor: pointer;

            &.recapTitle {
                color: #2275ee;
                border: 1px solid #2275ee;
            }
            &.rgstgnrl {
                color: #f6494b;
                border: 1px solid #f6494b;
            }
            &.title {
                color: #00b8be;
                border: 1px solid #00b8be;
            }
            &.expos {
                color: #ff9500;
                border: 1px solid #ff9500;
            }

            &.active {
                color: #fff;

                &.recapTitle {
                    background: #2275ee;
                }
                &.rgstgnrl {
                    background: #f6494b;
                }
                &.title {
                    background: #00b8be;
                }
                &.expos {
                    background: #ff9500;
                }
            }

            & > h3 {
                font-size: 13px;
                font-weight: 500;
                margin: 3px 0 0;
            }
            & > label {
                display: block;
                width: 100%;
                font-size: 20px;
                line-height: 1.9;
                cursor: pointer;

                & > span {
                    font-size: 12px;
                }
            }
        }
    }
    .registerList {
        position: absolute;
        top: 255px;
        bottom: 0;
        width: -webkit-fill-available;
        margin: 6px 12px;

        & > h3 {
            display: inline-block;
            font-size: 13px;
            font-weight: 500;
            margin: 12px 0 12px 2px;

            & > span {
                font-weight: 700;
                color: #f6494b;
            }
        }
        & > input {
            position: absolute;
            width: 150px;
            right: 4px;
            top: 5px;
            border: 1px solid #2275ee;
            line-height: 24px;
            padding: 2px 8px;
            border-radius: 4px;
        }
        .listBox {
            height: calc(100% - 50px);
            overflow: scroll;
            padding-bottom: 50px;

            & > table {
                border-collapse: separate;
                border-spacing: 0;
                width: 100%;
                text-align: center;

                & > thead {
                    position: sticky;
                    z-index: 2;
                    top: 0;
                    background: #f9f9f9;
                }
                & > thead > tr > th {
                    width: 25%;
                    padding: 10px 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #595959;
                }
                & > tbody > tr {
                    transition: all 0.2s ease;

                    &:hover {
                        background: #dff0ff;
                        cursor: pointer;
                    }
                }
                & > tbody > tr > td {
                    padding: 10px 0;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.6;
                    vertical-align: middle;
                    /* word-break: keep-all; */
                    border-bottom: 1px solid #e0e0e0;
                    animation: fadeIn 0.4s ease;

                    & > span {
                        background: #0047ff;
                        color: #fff;
                        padding: 8px;
                        border-radius: 24px;
                    }
                }
            }
            & > p {
                text-align: center;
                margin: 24px auto;
                font-size: 14px;
                line-height: 1.6;
                color: #3e3e3e;
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const dialogTitleStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
    word-break: break-word;
`;

export default BuildingRegisterMain;
