import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

import timer from "@src/assets/stateLand/sandglassIcon.json";
import timeTooltip from "@src/assets/subscription/springPromotionTime.png";

export const PREMIUM_SPRING_PROMOTION_AOS_CODES = ["SUBSAL4365VIP", "SUBSAL4365BT", "SUBSALE365GS02", "SUBSALE365RJ02"];
export const PREMIUM_SPRING_PROMOTION_IOS_CODES = ["SUBSIL4365VIP", "SUBSIL4365BT", "SUBSILE365GS02", "SUBSILE365RJ02"];

export const PremiumSpringPromotionTitle = () => {
    return (
        <StyledTitle>
            <span>🌸 Premium Spring Promotion 🌸</span>
            <h1>
                VIP 1년권
                <br />
                (5개 기능 포함)
            </h1>
        </StyledTitle>
    );
};

export const PremiumSpringPromotionTimeTooltip = ({ top, left }: { top: string; left: string }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date("2025-04-30");

        if (currentDate > targetDate) {
            setIsVisible(false);
        }
    }, []);

    if (!isVisible) return null;

    return (
        <StyledTimeTooltip style={{ top, left }}>
            <img src={timeTooltip} alt="4/30까지" width={82} />
        </StyledTimeTooltip>
    );
};

export const PremiumSpringPromotionTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState("");
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date("2025-04-30");

        if (currentDate > targetDate) {
            setIsVisible(false);
        }
    }, []);

    useEffect(() => {
        const targetDate = new Date("2025-04-30T00:00:00");
        const interval = setInterval(() => {
            const currentDate = new Date();
            const timeDifference = targetDate.getTime() - currentDate.getTime();

            if (timeDifference <= 0) {
                clearInterval(interval);
                setTimeRemaining("0일 0시간 0분 0초");
            } else {
                const days = Math.floor(timeDifference / (1000 * 3600 * 24));
                const hours = Math.floor((timeDifference % (1000 * 3600 * 24)) / (1000 * 3600));
                const minutes = Math.floor((timeDifference % (1000 * 3600)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
                setTimeRemaining(`${days}일 ${hours}시간 ${minutes}분 ${seconds}초`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    if (!isVisible) return null;

    return (
        <>
            <Lottie className="lottie" animationData={timer} />
            {timeRemaining}
        </>
    );
};

const StyledTitle = styled.div`
    h1 {
        font-family: Sandoll Samliphopangche;
        font-size: 46px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        color: #0047ff;
    }

    span {
        font-family: Sandoll Samliphopangche !important;
        color: #de4542;
        font-weight: 400;
        font-size: 22px;
        line-height: 58px;
    }
`;

const StyledTimeTooltip = styled.div`
    position: absolute;
`;
