import { css } from "@emotion/react";
import styled from "@emotion/styled";

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import clearIcon from "@src/assets/clearIcon.svg";
import foldBtn from "@src/assets/fold_white.svg";
import moreBtn from "@src/assets/more_white.svg";

import { historyType } from "./RecentFilterHistory";
import { useRemoverFilterHistory } from "@src/hooks/item/useGetItem";
import { IFilterParamsType, NameAndValue, LocationFilterParams, useLocationParamSavedUseFilter } from "@src/store/newFilterStore";
import FilterHistotyList from "./FilterHistotyList";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useFilterTextList } from "./useFilterList";
import { IRegion, useFindOneLocation } from "@src/hooks/newFilter/useGetLocation";
import { Common } from "@src/styles/Common";

interface props {
    history: historyType;
    refetch: () => void;
    onClose: () => void;
    setFilter: (filter: IFilterParamsType) => void;
    open: boolean;
    setSavedLocation: (filterState: NameAndValue | null) => void;
    setSavedUseList: (filterState: NameAndValue[]) => void;
    setTempNameAndNo: (filterState: IRegion[]) => void;
}

const RecentFilterHistoryItem = ({
    history,
    refetch,
    onClose,
    open = false,
    setFilter,
    setSavedLocation,
    setSavedUseList,
    setTempNameAndNo,
}: props) => {
    const setSavedLocationParam = useLocationParamSavedUseFilter((state) => state.setFilterLocationParamState);
    const [more, setMore] = useState(false); // 최근기록 펼쳐보기

    const removeFilterHistory = useRemoverFilterHistory(
        () => {
            refetch();
        },
        (err) => {
            console.log("err>", err);
        },
    );

    useEffect(() => {
        setMore(open);
    }, [open]);

    const onClickApplyHistory = async (val: IFilterParamsType) => {
        let copy = { ...val };
        let locSaved: NameAndValue | null = null;
        let param: LocationFilterParams | null = null;

        if (val.loc[0]) {
            const loc = await useFindOneLocation(val.loc[0]);

            let zoom = 15;

            switch (loc.region.cortarType) {
                case "city":
                    loc.region.cortarName.includes("도") ? (zoom = 9) : (zoom = 11);
                    if (loc.region.cortarName === "제주도") zoom = 11;
                    break;
                case "dvsn":
                    zoom = 12;
                    break;
                case "sec":
                    zoom = 15;
                    break;
            }

            param = {
                lat: loc.region.centerLat,
                lng: loc.region.centerLon,
                zoom,
            };
            setSavedLocationParam(param);

            let locArr: IRegion[] = [];

            // 조회하려는 지역이 시/도가 아니라면
            if (loc.region.cortarType !== "city") {
                // 부모 지역이 시/도가 아니라면
                if (loc.parent.cortarType !== "city") {
                    const grandParent = {
                        centerLat: 0,
                        centerLon: 0,
                        cortarName: loc.parent.parentName,
                        cortarNo: loc.parent.parentNo,
                        cortarType: "city",
                    };
                    locArr.push(grandParent);
                }
                const parent = {
                    centerLat: 0,
                    centerLon: 0,
                    cortarName: loc.parent.cortarName,
                    cortarNo: loc.parent.cortarNo,
                    cortarType: "dvsn",
                };
                locArr.push(parent);
            }
            const self = {
                centerLat: loc.region.centerLat,
                centerLon: loc.region.centerLon,
                cortarName: loc.region.cortarName,
                cortarNo: loc.region.cortarNo,
                cortarType: loc.region.cortarType,
            };
            locArr.push(self);

            setTempNameAndNo(locArr);
            locSaved = {
                name: getDisplayLocationText(locArr),
                value: loc.region.cortarNo,
            };
            copy = { ...copy, loc: [loc.region.cortarNo] };
        } else {
            setSavedLocationParam(null);
            setTempNameAndNo([]);
            locSaved = null;
            copy = { ...copy, loc: [] };
        }

        if (param) transferToNative(JSON.stringify(param), "mapCenterPost");

        // 원본 배열의 길이
        const AlenOri = useFilterTextList.filter((aa) => aa.value.includes("A")).length - 1;
        const BlenOri = useFilterTextList.filter((aa) => aa.value.includes("B")).length - 1;
        const ClenOri = useFilterTextList.filter((aa) => aa.value.includes("C")).length - 1;
        const DlenOri = useFilterTextList.filter((aa) => aa.value.includes("D")).length - 1;

        // 검색 배열의 길이
        const Alen = val.use.filter((aa) => aa.includes("A"));
        const Blen = val.use.filter((aa) => aa.includes("B"));
        const Clen = val.use.filter((aa) => aa.includes("C"));
        const Dlen = val.use.filter((aa) => aa.includes("D"));

        let useArrForFilter: string[] = [];

        useArrForFilter = AlenOri === Alen.length ? [...useArrForFilter, "A"] : [...useArrForFilter, ...Alen];
        useArrForFilter = BlenOri === Blen.length ? [...useArrForFilter, "B"] : [...useArrForFilter, ...Blen];
        useArrForFilter = ClenOri === Clen.length ? [...useArrForFilter, "C"] : [...useArrForFilter, ...Clen];
        useArrForFilter = DlenOri === Dlen.length ? [...useArrForFilter, "D"] : [...useArrForFilter, ...Dlen];

        let useArr: NameAndValue[] = [];
        useArrForFilter.map((aa) => {
            const obj = getUseFilterNameAndValue(aa);
            if (obj) useArr.push(obj);
        });

        copy = { ...val, use: useArrForFilter };

        setFilter(copy);
        setSavedUseList(useArr);
        setSavedLocation(locSaved);

        transferToNative(JSON.stringify(val), "setFilterDone");
        onClose();
    };

    function moreToggle() {
        setMore((toggle) => !toggle);
    }

    // 지역 이름 활용하여 breadcrumb 과 유사한 디스플레이 텍스트 제공
    const getDisplayLocationText = (locations: IRegion[]) => {
        return locations.map((loc) => loc.cortarName).join(" > ");
    };

    function getUseFilterNameAndValue(value: string) {
        const obj = useFilterTextList.find((aa) => {
            return aa.value === value;
        });

        return obj;
    }

    return (
        <ContainerStyle key={history.hash} $more={more}>
            <div css={historyTitleBoxStyle}>
                <div className="textBox">
                    <p className="date">{history?.updated_at}</p>
                </div>
                <div className="btns">
                    <p css={historyApplyButtonStyle} onClick={() => onClickApplyHistory(history.value)}>
                        검색
                    </p>
                    <img
                        className="clearIcon"
                        src={clearIcon}
                        alt="clearIcon"
                        onClick={() => removeFilterHistory.mutate(history.no)}
                    />
                </div>
            </div>
            <FilterHistotyList value={history.value} hash={history.hash} more={more} />
            <div className="more" onClick={moreToggle}>
                <div className="btn">
                    <p>{more ? "접어보기" : "더보기"}</p>
                    <div className="img_box">
                        <img src={more ? foldBtn : moreBtn} width={20} height={20} alt="more" />
                    </div>
                </div>
            </div>
        </ContainerStyle>
    );
};

export default RecentFilterHistoryItem;

const ContainerStyle = styled.div<{ $more: boolean }>`
    padding: 14px 0px;
    height: ${(props) => (props.$more ? "max-content" : "180px")};
    box-sizing: border-box;
    border-radius: 14px;
    border: 1px solid rgba(141, 141, 141);
    margin-bottom: 14px;
    overflow: hidden;
    position: relative;
    cursor: default;

    .more {
        position: absolute;
        bottom: 0;
        background: #fff;
        border-radius: 14px;
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .btn {
            display: flex;
            background: #0047ff;
            padding: 0 8px;
            border-radius: 4px;
            gap: 4px;
            align-items: center;
            height: 24px;
        }
        p {
            color: #fff;
            font-size: 14px;
        }
        .img_box {
            width: 20px;
            height: 20px;
        }
    }
`;

const historyTitleBoxStyle = css`
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date {
        color: #505050;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .textBox {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .clearIcon {
        cursor: pointer;
    }
    .btns {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;
const historyApplyButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    background-color: ${Common.colors.blue};
    border-radius: 24px;
    color: #ffffff;
    padding: 0px 8px;
    cursor: pointer;
`;
