export const nestPath = process.env.REACT_APP_NEST_API_URL;

const LAND_REGISTER = "land-register";

// 필지 검색
const searchAddress = `${nestPath}/${LAND_REGISTER}/search/address`;

// 발급 내역
const issueHistory = `${nestPath}/${LAND_REGISTER}/issue/history`;

// 해당 토지•임야대장 읽음 처리
const changeIsRead = (landRegisterId: string) => `${nestPath}/${LAND_REGISTER}/${landRegisterId}/read`;

// 발급 중인 토지•임야대장 상태값 확인
const getIssueStatus = (landRegisterId: string) => `${nestPath}/${LAND_REGISTER}/${landRegisterId}/status`;

// 토지•임야대장 발급 등록
const issue = `${nestPath}/${LAND_REGISTER}/issue`;

// 토지•임야대장 재발급
const reissue = (landRegisterId: string) => `${nestPath}/${LAND_REGISTER}/${landRegisterId}/reissue`;

// 토지•임야대장 pdf url 조회
const getPdfUrl = (landRegisterId: string) => `${nestPath}/${LAND_REGISTER}/${landRegisterId}/pdf`;

export const LAND_REGISTER_PATH = {
    searchAddress,
    issueHistory,
    changeIsRead,
    getIssueStatus,
    issue,
    reissue,
    getPdfUrl,
};
