import styled from "@emotion/styled";
import ico_remove_black from "@src/assets/ico_remove_black.svg";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useNewFilterMapCheckStore, useNewFilterStore } from "@src/store/newFilterStore";
import React from "react";
import { useNavigate } from "react-router-dom";

const FilterHeader = ({ children }: any) => {
    const navigate = useNavigate();
    const filterParams = useNewFilterStore((state) => state.newFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);
    return (
        <Header>
            <h3>{children}</h3>
            <img
                onClick={() => {
                    setFilterCheck(false);
                    transferToNative(JSON.stringify(filterParams), "setFilterDone");
                    navigate(-1);
                }}
                src={ico_remove_black}
            />
        </Header>
    );
};

export default FilterHeader;

const Header = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    height: 56px;
    justify-content: center;
    background: white;
    z-index: 5000;

    .h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }

    img {
        position: absolute;
        right: 5px;
        padding: 15px;
    }
`;
