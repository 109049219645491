import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import checkIcon from "@src/assets/ico_check_white.svg";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import AUCTION_MASTER_ITEMS from "@src/constants/auctionMasterItemFilters";
import { IFilterParamsType } from "@src/store/newFilterStore";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

const AuctionMasterItemFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    return (
        <div css={rootBoxStyle}>
            <div
                css={titleBoxStyle(
                    filterParams.auction_master_item.some((val) => !val) || !filterParams.auction_master_item.length,
                )}
            >
                <p className="titleText">경매고수물건</p>
                {allFilter ? (
                    <button className="allButton" onClick={() => setFilterParams({ ...filterParams, auction_master_item: [] })}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {Object.entries(AUCTION_MASTER_ITEMS).map(
                    ([title, itemNumber]) =>
                        (!allFilter || !!itemNumber) && (
                            <button
                                key={itemNumber}
                                css={selectButtonStyle(
                                    itemNumber === 0
                                        ? filterParams.auction_master_item.some((val) => !val) ||
                                              !filterParams.auction_master_item.length
                                        : filterParams.auction_master_item?.some((val) => val === itemNumber),
                                )}
                                onClick={() => {
                                    if (itemNumber === 0) {
                                        setFilterParams({ ...filterParams, auction_master_item: [] });
                                        return;
                                    }
                                    const checkInclude = filterParams.auction_master_item?.some((val) => val === itemNumber);
                                    if (checkInclude) {
                                        const newAuctionMasterItemList = [...filterParams.auction_master_item].filter(
                                            (val) => val !== itemNumber,
                                        );
                                        setFilterParams({ ...filterParams, auction_master_item: newAuctionMasterItemList });
                                    } else {
                                        filterParams.auction_master_item.length === Object.keys(AUCTION_MASTER_ITEMS).length - 2
                                            ? setFilterParams({ ...filterParams, auction_master_item: [] })
                                            : setFilterParams({
                                                  ...filterParams,
                                                  auction_master_item: [...filterParams.auction_master_item, itemNumber].sort(),
                                              });
                                    }
                                }}
                            >
                                {title}
                            </button>
                        ),
                )}
            </div>
            <div css={excludeBoxStyle}>
                <div
                    css={checkBoxRootStyle}
                    onClick={() =>
                        setFilterParams({ ...filterParams, auction_master_except: !filterParams.auction_master_except })
                    }
                >
                    <div css={checkBoxStyle(filterParams.auction_master_except)}>
                        <img src={checkIcon} />
                    </div>
                </div>
                <div>
                    <h3>제외하기</h3>
                    <div>
                        <span className="example">선택한 필터에 해당하는 매물 제외하고 매물보기</span>
                        <br />
                        <span className="ex">ex. 지분매각 필터 선택 후 제외하기 체크시 지분매각 매물을 제외하고 보여줍니다</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuctionMasterItemFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${Common.colors.grey02};
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;

const excludeBoxStyle = css`
    display: flex;
    padding: 14px;
    margin-top: 24px;
    h3 {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
    }

    .example {
        font-size: 13px;
        line-height: 20px;
        color: #505050;
    }

    .ex {
        font-size: 12px;
        line-height: 18px;
        color: #a3a3a3;
    }
`;

const checkBoxStyle = (active: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px !important;
    height: 20px !important;
    background: ${active ? Common.colors.aucMarkerColor : "#A3A3A3"};
    border-radius: 100%;
    margin-right: 14px;
    img {
        width: 10px;
    }
`;

const checkBoxRootStyle = css`
    width: 32px;
    padding-top: 4px;
`;
