export const useMajorCategory = [
    { inputValue: [], value: "", name: "전체" },
    { inputValue: ["A01", "A02", "A03", "A05", "A06", "A04"], value: "A", name: "주거용" },
    {
        inputValue: [
            "B01",
            "B02",
            "B03",
            "B04",
            "B05",
            "B06",
            "B07",
            "B08",
            "B09",
            "B10",
            "B12",
            "B13",
            "B14",
            "B15",
            "B16",
            "B17",
            "B18",
            "B19",
            "B20",
            "B21",
            "B22",
            "B11",
        ],
        value: "B",
        name: "토지",
    },
    { inputValue: ["C01", "C02", "C04", "C03"], value: "C", name: "산업용" },
    {
        inputValue: [
            "D01",
            "D02",
            "D03",
            "D04",
            "D06",
            "D07",
            "D08",
            "D09",
            "D10",
            "D11",
            "D12",
            "D13",
            "D14",
            "D15",
            "D16",
            "D05",
        ],
        value: "D",
        name: "상업/업무용",
    },
    { inputValue: ["E"], value: "E", name: "기타" },
];

export const useFilterTextList = [
    {
        name: "주거용 > 전체",
        value: "A",
    },
    {
        name: "주거용 > 아파트",
        value: "A01",
    },
    {
        name: "주거용 > 다세대",
        value: "A02",
    },
    {
        name: "주거용 > 다가구",
        value: "A03",
    },
    {
        name: "주거용 > 단독주택",
        value: "A05",
    },
    {
        name: "주거용 > 상가주택",
        value: "A06",
    },
    {
        name: "주거용 > 기타",
        value: "A04",
    },
    {
        name: "토지 > 전체",
        value: "B",
    },
    {
        name: "토지 > 대지",
        value: "B01",
    },
    {
        name: "토지 > 전",
        value: "B02",
    },
    {
        name: "토지 > 답",
        value: "B03",
    },
    {
        name: "토지 > 임야",
        value: "B04",
    },
    {
        name: "토지 > 도로",
        value: "B05",
    },
    {
        name: "토지 > 잡종지",
        value: "B06",
    },
    {
        name: "토지 > 과수원",
        value: "B07",
    },
    {
        name: "토지 > 공장용지",
        value: "B08",
    },
    {
        name: "토지 > 창고용지",
        value: "B09",
    },
    {
        name: "토지 > 목장용지",
        value: "B10",
    },
    {
        name: "토지 > 주차장",
        value: "B12",
    },
    {
        name: "토지 > 묘지",
        value: "B13",
    },
    {
        name: "토지 > 학교용지",
        value: "B14",
    },
    {
        name: "토지 > 체육용지",
        value: "B15",
    },
    {
        name: "토지 > 종교용지",
        value: "B16",
    },
    {
        name: "토지 > 구거",
        value: "B17",
    },
    {
        name: "토지 > 유지",
        value: "B18",
    },
    {
        name: "토지 > 제방",
        value: "B19",
    },
    {
        name: "토지 > 하천",
        value: "B20",
    },
    {
        name: "토지 > 염전",
        value: "B21",
    },
    {
        name: "토지 > 주유소용지",
        value: "B22",
    },
    {
        name: "토지 > 기타",
        value: "B11",
    },
    {
        name: "산업용 > 전체",
        value: "C",
    },
    {
        name: "산업용 > 창고",
        value: "C01",
    },
    {
        name: "산업용 > 공장",
        value: "C02",
    },
    {
        name: "산업용 > 아파트형공장",
        value: "C04",
    },
    {
        name: "산업용 > 기타",
        value: "C03",
    },
    {
        name: "상업/업무용 > 전체",
        value: "D",
    },
    {
        name: "상업/업무용 > 오피스텔",
        value: "D01",
    },
    {
        name: "상업/업무용 > 근린상가",
        value: "D02",
    },
    {
        name: "상업/업무용 > 사무실",
        value: "D03",
    },
    {
        name: "상업/업무용 > 숙박시설",
        value: "D04",
    },
    {
        name: "상업/업무용 > 근린시설",
        value: "D06",
    },
    {
        name: "상업/업무용 > 숙박시설(콘도 등)",
        value: "D07",
    },
    {
        name: "상업/업무용 > 농가관련시설",
        value: "D08",
    },
    {
        name: "상업/업무용 > 노유자시설",
        value: "D09",
    },
    {
        name: "상업/업무용 > 종교시설",
        value: "D10",
    },
    {
        name: "상업/업무용 > 주유소",
        value: "D11",
    },
    {
        name: "상업/업무용 > 의료시설",
        value: "D12",
    },
    {
        name: "상업/업무용 > 자동차관련시설",
        value: "D13",
    },
    {
        name: "상업/업무용 > 장례,묘지시설",
        value: "D14",
    },
    {
        name: "상업/업무용 > 분뇨쓰레기처리",
        value: "D15",
    },
    {
        name: "상업/업무용 > 관광휴게시설",
        value: "D16",
    },
    {
        name: "상업/업무용 > 기타",
        value: "D05",
    },
    {
        name: "기타 > 전체",
        value: "E",
    },
    // {
    //     name: "기타 > 전체 ",
    //     value: "E01",
    // },
];

export const useFilterList: any = {
    전체: [{ name: "전체", value: "" }],
    주거용: [
        {
            name: "전체",
            value: "A",
        },
        {
            name: "아파트",
            value: "A01",
        },
        {
            name: "다세대",
            value: "A02",
        },
        {
            name: "다가구",
            value: "A03",
        },
        {
            name: "단독주택",
            value: "A05",
        },
        {
            name: "상가주택",
            value: "A06",
        },
        {
            name: "주거용기타",
            value: "A04",
        },
    ],
    토지: [
        {
            name: "전체",
            value: "B",
        },
        {
            name: "대지",
            value: "B01",
        },
        {
            name: "전",
            value: "B02",
        },
        {
            name: "답",
            value: "B03",
        },
        {
            name: "임야",
            value: "B04",
        },
        {
            name: "도로",
            value: "B05",
        },
        {
            name: "잡종지",
            value: "B06",
        },
        {
            name: "과수원",
            value: "B07",
        },
        {
            name: "공장용지",
            value: "B08",
        },
        {
            name: "창고용지",
            value: "B09",
        },
        {
            name: "목장용지",
            value: "B10",
        },
        {
            name: "주차장",
            value: "B12",
        },
        {
            name: "묘지",
            value: "B13",
        },
        {
            name: "학교용지",
            value: "B14",
        },
        {
            name: "체육용지",
            value: "B15",
        },
        {
            name: "종교용지",
            value: "B16",
        },
        {
            name: "구거",
            value: "B17",
        },
        {
            name: "유지",
            value: "B18",
        },
        {
            name: "제방",
            value: "B19",
        },
        {
            name: "하천",
            value: "B20",
        },
        {
            name: "염전",
            value: "B21",
        },
        {
            name: "주유소용지",
            value: "B22",
        },
        {
            name: "기타",
            value: "B11",
        },
    ],
    산업용: [
        {
            name: "전체",
            value: "C",
        },
        {
            name: "창고",
            value: "C01",
        },
        {
            name: "공장",
            value: "C02",
        },
        {
            name: "아파트형공장",
            value: "C04",
        },
        {
            name: "기타",
            value: "C03",
        },
    ],
    "상업/업무용": [
        {
            name: "전체",
            value: "D",
        },
        {
            name: "오피스텔",
            value: "D01",
        },
        {
            name: "근린상가",
            value: "D02",
        },
        {
            name: "사무실",
            value: "D03",
        },
        {
            name: "숙박시설",
            value: "D04",
        },
        {
            name: "근린시설",
            value: "D06",
        },
        {
            name: "숙박시설(콘도 등)",
            value: "D07",
        },
        {
            name: "농가관련시설",
            value: "D08",
        },
        {
            name: "노유자시설",
            value: "D09",
        },
        {
            name: "종교시설",
            value: "D10",
        },
        {
            name: "주유소",
            value: "D11",
        },
        {
            name: "의료시설",
            value: "D12",
        },
        {
            name: "자동차관련시설",
            value: "D13",
        },
        {
            name: "장례,묘지시설",
            value: "D14",
        },
        {
            name: "분뇨쓰레기처리",
            value: "D15",
        },
        {
            name: "관광휴게시설",
            value: "D16",
        },
        {
            name: "기타",
            value: "D05",
        },
    ],
    기타: [
        {
            name: "전체",
            value: "E",
        },
        {
            name: "기타",
            value: "E01",
        },
    ],
};

const removeDuplicateArray = (arrayParam: any[]) => {
    const uniqueArray = arrayParam.filter((item, index) => {
        return arrayParam.indexOf(item) === index;
    });

    return uniqueArray;
};

export const makeUseParam = (arrayParam: string[]) => {
    const result: string[] = [];
    arrayParam.forEach((item) => {
        if (item === "allA") {
            useFilterList["주거용"].map((item: any) => {
                if (item.name !== "전체") {
                    result.push(item.value);
                }
            });
        }

        if (item === "allB") {
            useFilterList["토지"].map((item: any) => {
                if (item.name !== "전체") {
                    result.push(item.value);
                }
            });
        }

        if (item === "allC") {
            useFilterList["산업용"].map((item: any) => {
                if (item.name !== "전체") {
                    result.push(item.value);
                }
            });
        }

        if (item === "allD") {
            useFilterList["상업/업무용"].map((item: any) => {
                if (item.name !== "전체") {
                    result.push(item.value);
                }
            });
        }

        if (item === "allE") {
            useFilterList["기타"].map((item: any) => {
                if (item.name !== "전체") {
                    result.push(item.value);
                }
            });
        }
    });

    const array = arrayParam.filter((item) => item !== "All");
    const array2 = array.filter((item) => !item.includes("all"));

    return removeDuplicateArray(result.concat(array2));
};

export const landUseCategoryListLength: any = {
    A: 6,
    B: 22,
    C: 4,
    D: 16,
    E: 1,
};

export const checkAllUseFilter = (val: string[]) => {
    const lengthCheck: any = {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
        E: 0,
    };
    const reducer = (q: any, v: any, idx: number) => {
        const newVal = v.slice(0, 1);
        if (q.filter((u: any) => u.includes(newVal))?.length < landUseCategoryListLength[newVal] - 1) {
            q.push(v);
        } else if (q.filter((u: any) => u.includes(newVal))?.length === landUseCategoryListLength[newVal] - 1) {
            const checkUse = q.filter((u: any) => !u.includes(newVal));
            q = [...checkUse, newVal];
        }
        lengthCheck[newVal] = lengthCheck[newVal] += 1;
        return q;
    };
    const result = val.reduce(reducer, []);
    return result;
};
