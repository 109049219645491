import { css } from "@emotion/react";
import { IStoreGoStopResListDto } from "@src/hooks/storeGoStop/dto/storeGoStop.dto";
import React, { useEffect, useState } from "react";

import { Common } from "@src/styles/Common";

import GoIcon from "@src/assets/goStop/goIcon.png";
import StopIcon from "@src/assets/goStop/stopIcon.png";
import WarningIcon from "@src/assets/goStop/warningIcon.png";
import LikeGrey from "@src/assets/ico_like_grey.png";
import LikeRed from "@src/assets/ico_like_red.png";
import ico_price_down from "@src/assets/ico_price_down_bold.svg";
import ico_price_up from "@src/assets/ico_price_up.svg";
import noImage from "@src/assets/noImg.png";

const StoreGoStopListCard = ({ item }: { item: IStoreGoStopResListDto }) => {
    const [viewRate, setViewRate] = useState("");
    const [change, setChange] = useState("");

    useEffect(() => {
        if (item?.rate === "(0%)") {
            setViewRate("0%");
            setChange("noramal");
        }
        if (item?.rate.includes("-")) {
            const rate2 = item?.rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("-", "");
            setViewRate(setResult);
            setChange("degradation");
        }
        if (item?.rate.includes("+")) {
            const rate2 = item?.rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("-", "");
            setViewRate(setResult);
            setChange("increase");
        }
    }, [item?.rate]);

    return (
        <div css={cardWrapper}>
            <div css={infoTitleBox}>
                <div className="titleContainer">
                    <p css={bidTypeTextStyle(item?.bid_type_str)}>{item?.bid_type_str}</p>
                    <b>
                        {item?.case_number}
                        {item.bid_type === 1 && (item.item_id.split("-")[1] === "1" ? "" : "")}
                    </b>
                </div>
                <div className="titleContainer">
                    <p>{item.bid_type === 1 && item?.due_date_str}</p>
                    <span>
                        {item?.d_day?.replaceAll(/입찰종료|입찰시작/g, "")
                            ? item?.d_day?.trim()
                            : item.status === "매각"
                            ? item?.bid_type === 1
                                ? "매각"
                                : "낙찰"
                            : item.status === "기타"
                            ? item?.d_day
                            : item.status_detail?.replaceAll("중", "") ?? item.status}
                    </span>
                </div>
            </div>
            <div css={infoDescBox}>
                <div css={imgBox}>
                    {item?.image ? (
                        <>
                            <img src={item?.image} alt="image" />
                            {item?.gostop === "GO" ? (
                                <img className="imgIcon" src={GoIcon} alt="GO Icon" />
                            ) : item?.gostop === "STOP" ? (
                                <img className="imgIcon" src={StopIcon} alt="STOP Icon" />
                            ) : item?.gostop === "주의" ? (
                                <img className="imgIcon" src={WarningIcon} alt="주의 Icon" />
                            ) : null}
                            {item?.skp_cnt_str === "신건" ? (
                                <div className="imgNewSkpCnt">{item?.skp_cnt_str}</div>
                            ) : (
                                <div className="imgSkpCnt">{item?.skp_cnt_str}</div>
                            )}
                        </>
                    ) : (
                        <>
                            <img src={noImage} alt="image" />
                            <div></div>
                        </>
                    )}
                </div>
                <div css={descContainer}>
                    <p className="descUse">{item?.use}</p>
                    <p className="descAdress">{item?.address}</p>
                    <p className="descEstPrice">감정가 &nbsp;{item?.est_price_str}</p>
                    <div className="descMinPriceBox">
                        최저가&nbsp;
                        <b>{item?.min_price_str === "비공개" ? "-" : item?.min_price_str} </b>&nbsp;
                        {item?.rate !== "(0%)" ? (
                            <span className="price_percent">
                                {change === "normal" && (
                                    <>
                                        <span style={{ color: "black", fontSize: "13px" }}>{viewRate}</span>
                                    </>
                                )}
                                {change === "degradation" && (
                                    <>
                                        <img src={ico_price_down} alt="상승률 하락률" />
                                        <span style={{ color: `${Common.colors.blue}` }}>{viewRate}</span>
                                    </>
                                )}
                                {change === "increase" && (
                                    <>
                                        <img src={ico_price_up} alt="상승률 하락률" />
                                        <span style={{ color: `${Common.colors.notiColor}` }}>{viewRate}</span>
                                    </>
                                )}
                            </span>
                        ) : null}
                    </div>
                    {item?.somethings.length !== 0 && (
                        <div className="descSomethingContainer">
                            {item?.somethings?.map((something: any, idx: number) => {
                                return `${something} `;
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div
                css={estProfitBox(
                    Number(item.profitRate.replace("%", "")) > 0 ? 1 : Number(item.profitRate.replace("%", "")) === 0 ? 0 : -1,
                )}
            >
                <div className="container">
                    {/* {storeGoStopType[0] !== "STOP" && item?.profit !== "0원" && item?.profit?.slice(0, 1) !== "-" && ( */}
                    <>
                        <span>예상수익률</span>
                        <p>
                            {Number(item.profitRate.replace("%", "")) > 0
                                ? "+" + item.profitRate
                                : Number(item.profitRate.replace("%", "")) === 0
                                ? "0%"
                                : "주의"}
                        </p>
                    </>
                    {/* )} */}
                </div>
                <img className="likeImg" src={item?.is_favorited ? LikeRed : LikeGrey} alt="like" />
            </div>
        </div>
    );
};

export default StoreGoStopListCard;

const cardWrapper = css`
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid#dadada;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const infoTitleBox = css`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .titleContainer {
        display: flex;
        font-size: 13px;
        align-items: center;
        gap: 4px;

        & > b {
            font-size: 16px;
        }

        & > span {
            color: #ff0000;
        }
    }
`;

const infoDescBox = css`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const descContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 9px;
    padding: 0px 8px;
    justify-content: center;
    font-size: 14px;
    color: #0c002c;

    .descAdress,
    .descEstPrice {
        color: #505050;
        font-size: 13px;
        max-width: 225px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .descMinPriceBox {
        color: #0047ff;
        font-weight: bold;
        & > b {
            color: #0c002c;
        }
    }

    .descSomethingContainer {
        font-size: 13px;
        color: #505050;
    }
`;

const estProfitBox = (plus: number) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .container {
        display: flex;
        color: #0c002c;
        align-items: center;
        gap: 8px;

        & > p {
            border-radius: 4px;
            color: ${plus > 0 ? "#ff0000" : plus === 0 ? "#0047FF" : "#FF4300"};
            font-size: 16px;
            background-color: ${plus > 0 ? "#ffebeb" : plus === 0 ? "#E3E6F5" : "#FFF7DE"};
            padding: 4px 6px;
        }
    }
`;

const imgBox = css`
    position: relative;
    & > img {
        width: 108px;
        height: 108px;
        border-radius: 4px;
    }

    .imgIcon {
        position: absolute;
        width: 75px;
        height: 75px;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
    }

    .imgSkpCnt,
    .imgNewSkpCnt {
        position: absolute;
        background-color: #ffe3ac;
        color: #0c002c;
        font-size: 12px;
        padding: 4px 10px;
        border-radius: 4px;
        left: 5px;
        top: 5px;
    }
`;

const bidTypeTextStyle = (text: string) => css`
    display: inline-block !important;
    border-radius: 4px !important;
    color: ${text === "경매" ? "white" : "#0c002c"} !important;
    background-color: ${text === "경매" ? "#2275ee" : "#fee500"} !important;
    padding: 1px 8px !important;
    line-height: 20px !important;
    white-space: nowrap !important;
`;
