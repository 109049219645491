import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import InputSearchingItems from "./InputSearchingItems";
import OutFilter from "../filter/OutFilter";
import { useFilterStore } from "@src/store/filterStore";
import { useNavigate } from "react-router-dom";
export interface IFilterState {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force_list: number[];
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    sort: number;
    auction_master_except: boolean;
}

export const initialFilterState: IFilterState = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force_list: [],
    auction_master_item: [],
    auction_master_except: false,
    status: [],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};

const check = {
    type: "경공매 구분(단일값)(0: 전체, 1: 경매, 2: 공매)",
    loc: "소재지(전국: [], 그 외: 소재지의 cortarNo 배열)[]",
    use: "용도(주거용: A~, 토지: B~, 산업용: C~, 상업용: D~, 기타: E01)[]",
    counter_force: "대항력(0: 전체, 1: 대항력 있음, 2: 대항력 없음)",
    auction_master_item:
        "경매고수물건(기존 경매고수물건과 제외물건 합쳐진 필터)(1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경, 12: 가등기, 13:가처분)[]",
    auction_master_except: "경매고수물건 제외 여부(true: 제외, false: 포함)",
    status: "진행상태(0: 전체, 1: 진행예정, 2: 진행중, 3: 매각, 4: 기타)[]",
    skp_type: "유찰구분(0: 전체, 1: 신건, 2: 유찰)",
    skp_cnt: "유찰횟수[]",
    due_date: "매각기일(날짜 하나만 선택한 경우, 둘다 같은 날짜로 전달)[]",
    est_price: "감정가[]",
    min_price: "최저입찰가[]",
    sales_method: "처분방식(0: 전체, 1: 매각, 2: 임대)",
    assets: "자산구분(0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산)[]",
    sort: "정렬(0: 업데이트순, 1: 감정가 높은순, 2: 감정가 낮은순, 3: 최저입찰가 높은순, 4: 최저입찰가 낮은순, 5: 매각기일 가까운순, 6: 매각기일 먼순, 7: 유찰횟수 많은순 8: 유찰횟수 적은순)",
};

interface Props {
    isShowFilter: boolean;
    setIsShowFilter: Dispatch<SetStateAction<boolean>>;
}

export type openOutFilterName =
    | "history"
    | "status"
    | "loc"
    | "type"
    | "use"
    | "counter_force_list"
    | "auction_master_item"
    | "skp_type"
    | "due_date"
    | "est_price"
    | "min_price";

const SearchInputAndFilter: React.FC<Props> = ({ isShowFilter, setIsShowFilter }) => {
    const navigate = useNavigate();
    const [isOpenRecentKeywordList, setIsOpenRecentKeywordList] = useState(false);

    const [openOutFilter, setOpenOutFilter] = useState<string>("");
    const filterStoreState = useFilterStore((state) => state.filterState);

    WebAppBridge.openFilter = (filterName: openOutFilterName) => {
        setOpenOutFilter(filterName);
    };

    const handleFilterSelectDone = () => {
        setOpenOutFilter("");
    };

    useEffect(() => {
        if (isOpenRecentKeywordList === true) {
            transferToNative("full", "resizeWebview");
        } else {
            transferToNative(openOutFilter ? "full" : "default", "resizeWebview");
        }
        if (openOutFilter === "") {
            transferToNative(JSON.stringify(filterStoreState), "changeSearchItemsFilter");
            navigate("/searchItems");
        }
    }, [openOutFilter, filterStoreState]);

    // 뒤로가기시에 홈으로
    WebAppBridge.onBackButtonPressed = () => {
        if (openOutFilter !== "") {
            transferToNative(true, "respondBackButtonPressed");
            setOpenOutFilter("");
        } else {
            transferToNative(false, "respondBackButtonPressed");
        }
    };

    return (
        <div css={rootStyle}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <InputSearchingItems
                    isShowFilter={isShowFilter}
                    setIsShowFilter={setIsShowFilter}
                    openOutFilter={openOutFilter}
                    onFilterOff={handleFilterSelectDone}
                    isOpenRecentKeywordList={isOpenRecentKeywordList}
                    setIsOpenRecentKeywordList={setIsOpenRecentKeywordList}
                />
            </div>
            <OutFilter openOutFilter={openOutFilter} setOpenOutFilter={setOpenOutFilter} />
        </div>
    );
};

export default SearchInputAndFilter;

const rootStyle = css`
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 14px 14px 0;
    height: 62px;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255, 255, 1)"};
`;
