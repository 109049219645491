import { css, jsx } from "@emotion/react";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import InternalTable from "@src/components/internalTable/InternalTable";

import Title from "@src/components/title/Title";
import {
    initRealPriceDetailDataDto,
    historyItemType,
    realPriceType,
    useGetRealPriceList,
} from "@src/hooks/map/useGetRealPriceDetail";
import { Common } from "@src/styles/Common";
import React, { useEffect, useState } from "react";
import { realPriceHeaderInRealPriceDetail, similarRealPriceHeaderInRealPriceDetail } from "./getColums";

import {
    usePyeongCardDetailListStore,
    usePyeongCardDetailListStoreType,
    usePyeongCardPopupStore,
    usePyeongCardPopupStoreType,
    useSelctedPyeongCardDetailStore,
    useSelctedPyeongCardDetailStoreType,
} from "@src/store/RealPriceFilterStore";
import { usePyeongPriceDetail } from "@src/hooks/map/useUserRealPriceFilter";

interface RealPriceDetailProps {
    data: realPriceType;
    apiParams: any;
    realPriceRef: any;
    useDetail: string;
}

const HISTORY_CONTENTS = [
    {
        title: "매매",
        type: "sale",
    },
    {
        title: "전세",
        type: "charter",
    },
    {
        title: "월세",
        type: "rent",
    },
];

function RealPriceDetail({ data, apiParams, realPriceRef, useDetail }: RealPriceDetailProps) {
    const [realSizeUnit, setRealSizeUnit] = useState<"pyeong" | "meter">("meter");
    const [similarSizeUnit, setSimilarSizeUnit] = useState<"pyeong" | "meter">("meter");
    const [price, setPrice] = useState<string>(""); // 배너 가격 표시 저장 state
    const [area, setArea] = useState<string>("0"); // 평형 저장 state
    const pyeongCardPopup = usePyeongCardPopupStore((state: usePyeongCardPopupStoreType) => state.pyeongCardPopupState); // 평형 선택 팝업
    const setPyeongCardPopup = usePyeongCardPopupStore((state: usePyeongCardPopupStoreType) => state.setPyeongCardPopupState); // 평형 선택 팝업
    const setPyeongCaseList = usePyeongCardDetailListStore(
        (state: usePyeongCardDetailListStoreType) => state.setPyeongCardDetailListState,
    ); // 평형 케이스 목록 저장
    const selectedPyeongCase = useSelctedPyeongCardDetailStore(
        (state: useSelctedPyeongCardDetailStoreType) => state.selctedPyeongCardDetailState,
    );
    const setSelectedPyeongCase = useSelctedPyeongCardDetailStore(
        (state: useSelctedPyeongCardDetailStoreType) => state.setSelctedPyeongCardDetailState,
    );

    const { data: pyeongList } = usePyeongPriceDetail(apiParams ? apiParams.pnu : "", useDetail ? useDetail : "");
    const { lately, history, similar, similarTag } = data;
    const [currentTab, setCurrentTab] = useState(0);

    const handleCovertReal = (type: "meter" | "pyeong") => {
        setRealSizeUnit(type);
    };
    const handleCovertSimilar = (type: "meter" | "pyeong") => {
        setSimilarSizeUnit(type);
    };
    const realPriceTableHeader = realPriceHeaderInRealPriceDetail();
    const similarPriceTableHeader = similarRealPriceHeaderInRealPriceDetail();

    const [realPriceAllData, setRealPriceAllData] = useState<
        | {
              pricePyeong: any;
              priceSquare: any;
              type: string;
              date: string;
              price: any;
              sizeSquare: string;
              sizePyeong: string;
              note: string;
              next_it: string;
          }[]
        | []
    >([]);
    const [realPriceViewingData, setRealPriceViewingData] = useState<
        | {
              pricePyeong: any;
              priceSquare: any;
              type: string;
              date: string;
              price: any;
              sizeSquare: string;
              sizePyeong: string;
              note: string;
              next_it: string;
          }[]
        | []
    >([]);

    const [similarRealPriceAllData, setSimilarRealPriceAllData] = useState<
        | {
              pricePyeong: any;
              priceSquare: any;
              type: string;
              date: string;
              price: any;
              sizeSquare: string;
              sizePyeong: string;
              note: string;
              next_it: string;
          }[]
        | []
    >([]);
    const [similarRealPriceViewingData, setSimilarRealPriceViewingData] = useState<
        | {
              pricePyeong: any;
              priceSquare: any;
              type: string;
              date: string;
              price: any;
              sizeSquare: string;
              sizePyeong: string;
              note: string;
              next_it: string;
          }[]
        | []
    >([]);
    const formattingTableItem = ({ price, priceSquare, pricePyeong, sizeUnit }: any) => {
        return (
            <>
                <div>{price}</div>
                {currentTab !== 2 && (
                    <div css={pricePyeongStyle}>
                        <div className="title">{sizeUnit === "meter" ? "평당가" : "㎡당"}</div>
                        <div>{sizeUnit === "meter" ? pricePyeong : priceSquare}</div>
                    </div>
                )}
            </>
        );
    };
    const formattingTableData = (data: historyItemType[], sizeUnit: "meter" | "pyeong") => {
        return data.map((item: historyItemType) => {
            const { pricePyeong, priceSquare, price, sizeSquare, sizePyeong } = item;
            return {
                ...item,
                sizeSquare: sizeUnit === "meter" ? sizePyeong : sizeSquare,
                price: formattingTableItem({ priceSquare, price, pricePyeong, sizeUnit }),
            };
        });
    };
    useEffect(() => {
        if (history?.length !== 0) {
            getList("", 1);
        }
        if (similar?.length !== 0) {
            const formatSimilar = formattingTableData(similar, similarSizeUnit);
            setSimilarRealPriceViewingData(formatSimilar.slice(0, 5));
            setSimilarRealPriceAllData(similar);
        }
    }, [data]);
    useEffect(() => {
        let sizeConvertReal = formattingTableData(realPriceAllData, realSizeUnit);
        setRealPriceViewingData(sizeConvertReal);
    }, [realSizeUnit]);
    useEffect(() => {
        let sizeConvertSimilar = formattingTableData(similarRealPriceAllData, similarSizeUnit);
        setSimilarRealPriceViewingData(sizeConvertSimilar);
    }, [similarSizeUnit]);
    const [isTabChange, setIsTabchange] = useState(false);

    // 매매와 만원을 제거하는 함수
    function slicePrice(str?: string) {
        let newStr = "";
        if (str) {
            newStr = str.replace("매매 ", "");
            newStr = newStr.replace("만", "");
            newStr = newStr.replace("원", "");
        } else newStr = "내역 없음";
        setPrice(newStr);
    }

    useEffect(() => {
        if (selectedPyeongCase) {
            let newArea = selectedPyeongCase.area.replace("평형", "");
            setArea(newArea);
            slicePrice(selectedPyeongCase.sale);
            getList(newArea, currentTab + 1);
        } else {
            // 초기 화면으로
            setArea("");
            getList("", currentTab + 1);
        }
    }, [selectedPyeongCase]);

    // 현재 선택한 탭에 맞춰서 첫번째 리스트를 생성해주는 함수
    async function getList(area: string, index: number) {
        if (apiParams) {
            let response = await useGetRealPriceList({
                ...apiParams,
                type: index,
                area,
            });
            if (response?.length !== 0) {
                let formattingRes = formattingTableData(response, "meter");
                setRealPriceViewingData(formattingRes.slice(0, 5));
                setRealPriceAllData(response);
            } else {
                setRealPriceViewingData([]);
                setRealPriceAllData([]);
            }
        }
    }

    // 선택한 평형 초기화
    useEffect(() => {
        setSelectedPyeongCase(null);
    }, []);

    useEffect(() => {
        setPyeongCardPopup(false);
        setPyeongCaseList([]);
        data && setCurrentTab(0);
    }, [data]);

    function pyeongPopup() {
        if (pyeongList) setPyeongCaseList(pyeongList);
        setPyeongCardPopup(!pyeongCardPopup);
    }

    return (
        <div css={realPriceWrap} id={"real"}>
            <div ref={realPriceRef}>
                {lately === null ? (
                    <div className={"nullboxTitle"}>
                        <Title title={"거래내역"} size={"large"} />

                        <div css={nullBox} style={{ margin: "14px" }}>
                            해당 위치의 실거래 정보를 수집 중입니다
                        </div>
                    </div>
                ) : (
                    <>
                        <section>
                            <div css={latelyWrap}>
                                <Title title={"최근 실거래가"} size={"small"} />
                                <div>
                                    <span className="badge">{lately?.type}</span>
                                    <span className="price">{lately?.price}</span>
                                    <p>{lately?.date || lately?.date !== "" ? `(${lately?.date})` : null}</p>
                                </div>
                            </div>
                        </section>
                        <section className="history">
                            <div style={{ marginTop: "38px" }}>
                                <ul css={historyTab}>
                                    {HISTORY_CONTENTS.map((content, index) => {
                                        const { type, title } = content;
                                        return (
                                            <li
                                                key={type}
                                                onClick={async () => {
                                                    setCurrentTab(index);
                                                    setIsTabchange(true);
                                                    getList(area, index + 1);
                                                }}
                                                className={index == currentTab ? "active" : ""}
                                            >
                                                {title}
                                            </li>
                                        );
                                    })}
                                </ul>
                                {selectedPyeongCase && (
                                    <div className="title_bannerBox">
                                        <div className="banner">
                                            <h3>{selectedPyeongCase.area}</h3>
                                            <div className="detail">
                                                <div className="latest">최근 매매 실거래가 가격</div>
                                                <div className="price">{price}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="titleBoxWithButton">
                                    <Title title={"거래내역"} size={"large"} />
                                    <div className="btns">
                                        <ConvertPyeongButton handleConvert={handleCovertReal} />
                                        {/* 평형 리스트 데이터가 있을 때만 버튼 활성화 */}
                                        {pyeongList && pyeongList.length > 0 && (
                                            <div className="pyeong_btnBox">
                                                <div className="pyeong_btn" onClick={pyeongPopup}>
                                                    평형선택
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div css={realPriceTable}>
                                    <InternalTable
                                        isTabChange={isTabChange}
                                        columns={realPriceTableHeader}
                                        tryGetData={async (next_it?: string) => {
                                            setIsTabchange(false);

                                            let response = await useGetRealPriceList({
                                                ...apiParams,
                                                type: currentTab + 1,
                                                next_it,
                                                area: area === "0" ? undefined : area,
                                            });
                                            return response;
                                        }}
                                        viewingData={realPriceViewingData}
                                        setViewingData={(data: any) => {
                                            let newArr = data.map((item: any) => {
                                                let newPrice =
                                                    typeof item.price === "string"
                                                        ? formattingTableItem({
                                                              price: item.price,
                                                              priceSquare: item.priceSquare,
                                                              pricePyeong: item.pricePyeong,
                                                              sizeUnit: realSizeUnit,
                                                          })
                                                        : item.price;
                                                return {
                                                    ...item,
                                                    sizeSquare: realSizeUnit === "meter" ? item.sizePyeong : item.sizeSquare,
                                                    price: newPrice,
                                                };
                                            });
                                            setRealPriceViewingData(newArr);
                                        }}
                                        allTableDataList={realPriceAllData}
                                        setAllTableList={(data: any[]) => {
                                            setRealPriceAllData(data);
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </div>
            <section>
                <div className="titleBoxWithButton">
                    <Title title="유사 거래내역" size={"large"} />
                    <ConvertPyeongButton handleConvert={handleCovertSimilar} />
                </div>
                {similar.length === 0 ? (
                    <div css={nullBox}>유사 거래내역 정보를 수집 중입니다</div>
                ) : (
                    <div css={tagWrap}>
                        {similarTag.length
                            ? similarTag.map(
                                  (tag: string, index: number) =>
                                      tag && (
                                          <span key={index} className={"tag"}>
                                              #{tag === "0평형" ? "10평형 이하" : tag}
                                          </span>
                                      ),
                              )
                            : ""}
                        <div css={similarTable}>
                            <InternalTable
                                isTabChange={false}
                                columns={similarPriceTableHeader}
                                tryGetData={async (next_it?: string) => {
                                    let response = await useGetRealPriceList({ ...apiParams, type: 4, next_it });
                                    return response;
                                }}
                                viewingData={similarRealPriceViewingData}
                                setViewingData={(data: any) => {
                                    let newArr = data.map((item: any) => {
                                        let newPrice =
                                            typeof item.price === "string"
                                                ? formattingTableItem({
                                                      price: item.price,
                                                      priceSquare: item.priceSquare,
                                                      pricePyeong: item.pricePyeong,
                                                      sizeUnit: similarSizeUnit,
                                                  })
                                                : item.price;
                                        return {
                                            ...item,
                                            sizeSquare: similarSizeUnit === "meter" ? item.sizePyeong : item.sizeSquare,
                                            price: newPrice,
                                        };
                                    });
                                    setSimilarRealPriceViewingData(newArr);
                                }}
                                allTableDataList={similarRealPriceAllData}
                                setAllTableList={(data: any[]) => {
                                    setSimilarRealPriceAllData(data);
                                }}
                            />
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
}
const pricePyeongStyle = css`
    display: flex;
    gap: 4px;
    justify-content: center;
    padding-right: 4px;
    margin-top: 8px;
    white-space: nowrap;
    text-align: right;
    font-size: 11px;

    & > .title {
        width: 40px;
        height: 20px;
        ${Common.textStyle.caption_R11};
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        background-color: #00ff00;
        border-radius: 24px;
        padding: 0px 4px 0px 4px;
        box-sizing: border-box;
    }
    & > div {
        text-align: left;
    }
`;
const realPriceWrap = css`
    width: 100%;
    height: 100%;
    position: relative;

    .title.large {
        margin: 24px 0;
    }
    .nullboxTitle .title.large {
        margin-left: 14px;
    }
    .title.small {
        margin: 10px 0 0;
    }
    section {
        padding: 0 14px 14px;
        box-sizing: border-box;
        border-bottom: 6px solid ${Common.colors.grey02};
    }
    .history .title.small {
        margin-top: 24px;
        margin-bottom: 10px;
    }
    .임시버튼 {
        display: none;
    }

    .history {
        .title_bannerBox {
            box-sizing: border-box;
            padding: 10px 0;
            width: 100%;
            height: 96px;

            .banner {
                background: #000;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                gap: 26px;

                h3 {
                    font-size: 36px;
                    line-height: 36px;
                    color: #fff;
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    .latest {
                        background: #fff;
                        font-size: 14px;
                        height: 18px;
                        padding: 0 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .price {
                        font-size: 24px;
                        line-height: 24px;
                        color: #00ff00;
                        font-weight: 700;
                    }
                }
            }
        }

        .titleBoxWithButton {
            .btns {
                display: flex;
                gap: 8px;
            }
            .pyeong_btnBox {
                .pyeong_btn {
                    font-size: 13px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 8px;
                    background: #0047ff;
                    color: #fff;
                    border-radius: 14px;
                    cursor: pointer;
                }
            }
        }

        @media screen and (max-width: 350px) {
            .title_bannerBox {
                .banner {
                    gap: 10px;

                    h3 {
                        font-size: 25px;
                        line-height: 25px;
                    }

                    .detail {
                        gap: 5px;

                        .latest {
                            font-size: 12px;
                        }
                        .price {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
`;
const tagWrap = css`
    width: 100%;
    .tag {
        display: inline-block;
        padding: 0 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: ${Common.colors.blue};
        background-color: ${Common.colors.grey01};
        border-radius: 4px;
        margin-right: 8px;
    }
`;
const historyTab = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    li {
        cursor: pointer;
        flex: 1;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 4px 0;
        text-align: center;
        color: ${Common.colors.darkGrey04};
        background: ${Common.colors.grey01};
        &.active {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
            background: #0047ff;
        }
    }
`;
const latelyWrap = css`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    & > div {
        text-align: right;
    }
    span.badge {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        color: ${Common.colors.normalTextColor};
        border-radius: 24px;
        background-color: ${Common.colors.grey02};
        display: inline-block;
        padding: 0 4px;
        margin-right: 8px;
    }
    span.price {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: ${Common.colors.blue};
    }
    & > div > p {
        margin-top: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: ${Common.colors.darkGrey04};
    }
`;

const nullBox = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${Common.colors.greyPrimary};
    background-color: ${Common.colors.grey02};
    border-radius: 14px;
    text-align: center;
    padding: 24px 0;
    margin: 14px 0;
`;
const realPriceTable = css`
    & > div {
        padding-left: 0;
        padding-right: 0;
    }
    table {
        width: 100%;
        thead {
            padding: 0;
        }
        thead > tr > th {
            text-align: center;

            &:nth-of-type(1) {
                width: 25%;
            }
            &:nth-of-type(2) {
                width: 35%;
            }
            &:nth-of-type(3) {
                width: 20%;
            }
            &:nth-of-type(4) {
                width: 20%;
            }
        }
        tbody > tr {
            padding-left: 0;
            padding-right: 0;
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: keep-all;
            }
            &:nth-of-type(1) {
                width: 25%;
            }
            &:nth-of-type(2) {
                width: 35%;
            }
            &:nth-of-type(3) {
                width: 20%;
            }
            &:nth-of-type(4) {
                width: 20%;
            }
        }
    }
`;
const similarTable = css`
    & > div {
        padding-left: 0;
        padding-right: 0;
    }
    table {
        width: 100%;

        thead {
            padding: 0;
        }
        thead > tr > th {
            text-align: center;

            &:nth-of-type(1) {
                width: 25%;
            }
            &:nth-of-type(2) {
                width: 35%;
            }
            &:nth-of-type(3) {
                width: 20%;
            }
            &:nth-of-type(4) {
                width: 20%;
            }
        }
        tbody > tr {
            padding-left: 0;
            padding-right: 0;
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: keep-all;
            }
            &:nth-of-type(1) {
                width: 25%;
            }
            &:nth-of-type(2) {
                width: 35%;
            }
            &:nth-of-type(3) {
                width: 20%;
            }
            &:nth-of-type(4) {
                width: 20%;
            }
        }
    }
`;

export default RealPriceDetail;
