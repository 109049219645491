import CardDataSheet from "@src/components/Card/CardDataSheet";
import useTestBottomSheet from "@src/hooks/bottomSheet/useBottomSheet";
import { useFindOneAreaSize } from "@src/hooks/map/useFindRoadWidth";
import useGetFieldContents from "@src/hooks/map/useGetFieldContents";
import { useFindFieldAddress } from "@src/hooks/map/useGetMapPolyCoordInternalization";
import useGetRoadAddressFromCoords from "@src/hooks/map/useMapCoordToRoadAddr";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useMapBottomSheetStore } from "@src/store/MapBottomSheetStore";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import { css } from "@emotion/react";

interface Props {}

type CardType = "land" | "building";

const FieldDetail: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const pnu = searchParams.get("pnu") ?? "";
    const TYPE_LAND = "land";
    const TYPE_BUILDING = "building";
    const [cardType, setCardType] = useState<CardType>(TYPE_LAND);

    const { sheet, titleRef, content, data, wrapperRef, handleSwipDown, checked, onChangeChecked } = useTestBottomSheet(true);

    //* areaSize
    const { data: areaSize, refetch: refetchAreaSize } = useFindOneAreaSize(pnu);

    const isEmptyBuildingData = (data: any) => {
        if (
            data?.buildingNm === "-" &&
            data?.mainUse === "-" &&
            data?.platArea === "-" &&
            data?.totArea === "-" &&
            data?.ugrndFlrCnt === "B0" &&
            data?.grndFlrCnt === "0F" &&
            data?.useAprDay === "-"
        )
            return true;

        return false;
    };

    const wrapperHeight = useMapBottomSheetStore((state) => state.wrapperHeight);
    const setWrapperHeight = useMapBottomSheetStore((state) => state.setWrapperHeight);
    useEffect(() => {
        // setWrapperHeight(sheet.current.offsetHeight);
        onChangeChecked();
        sheet.current?.style.setProperty("transform", `translateY(${wrapperHeight - window.innerHeight}px)`);
        content.current?.style.setProperty("border-radius", "0");
        content.current?.style.setProperty("opacity", "1");
    }, [wrapperHeight]);

    const [landCardContents, setLandCardContents] = useState<any>();
    const [buildingCardContents, setBuildingCardContents] = useState<any>();

    const [allFieldData, setAllFieldData] = useState({
        land: undefined,
        building: undefined,
        pnu,
        totalBuildingTable: [],
    });

    const { data: address, refetch: refetchAddress } = useFindFieldAddress(
        Number(searchParams.get("lat")) ?? 0,
        Number(searchParams.get("lng")) ?? 0,
    );

    useEffect(() => {
        if (searchParams.get("lat")) {
            refetchAddress();
        }
    }, [searchParams.get("lat")]);

    const matchCardType = (lndcgrCodeNm: string): CardType => {
        const landTypes = ["전", "답", "목장용지", "임야", "하천", "유지", "공원", "사적지", "도로"];
        const buildingTypes = [
            "대",
            "대지",
            "공장용지",
            "학교용지",
            "창고용지",
            "종교용지",
            "유원지",
            "체육용지",
            "과수원",
            "광천지",
            "주차장",
            "주유소용지",
            "철도용지",
            "제방",
            "구거",
            "양어장",
            "수도용지",
            "묘지",
            "잡종지",
        ];
        if (landTypes.includes(lndcgrCodeNm)) return TYPE_LAND;
        if (buildingTypes.includes(lndcgrCodeNm)) return TYPE_BUILDING;

        return TYPE_LAND;
    };
    const getType = (lndcgrCodeNm: string) => {
        if (matchCardType(lndcgrCodeNm) === TYPE_BUILDING)
            return isEmptyBuildingData(buildingCardContents) ? TYPE_LAND : TYPE_BUILDING;
        if (matchCardType(lndcgrCodeNm) === TYPE_LAND) return TYPE_LAND;

        return TYPE_LAND;
    };

    const fetchFieldContentsData = async () => {
        const res = await Promise.all([
            useGetFieldContents({ address: address?.address ?? "", pnu }),
            useGetRoadAddressFromCoords(
                new naver.maps.LatLng(Number(searchParams.get("lat")) ?? 0, Number(searchParams.get("lng")) ?? 0),
            ),
        ]);
        const { land, building, totalBuildingTable, eum_map_obj } = res[0];
        const roadAddr = res[1];
        setLandCardContents({
            ...land,
            position: new naver.maps.LatLng(Number(searchParams.get("lat")) ?? 0, Number(searchParams.get("lng")) ?? 0),
            address: address?.address ?? "",
            eum_map_obj,
        });
        setBuildingCardContents({
            ...building,
            roadAddr,
            totalBuildingTable,
            position: new naver.maps.LatLng(Number(searchParams.get("lat")) ?? 0, Number(searchParams.get("lng")) ?? 0),
        });
    };

    useEffect(() => {
        //1. 필지 클릭시 address를 카드 컴포넌트로 전달하고 토지카드 api 호출
        if ((address?.address ?? "") !== "" && pnu !== "") {
            refetchAreaSize();
            Promise.all([fetchFieldContentsData()]);
        }
    }, [address, pnu]);
    useEffect(() => {
        //2. 토지카드 호출 후 landCardContents 지목명 변경시 카드 타입 정의
        if (landCardContents?.lndcgrCodeNm) {
            const GET_TYPE = getType(landCardContents?.lndcgrCodeNm);
            setCardType(GET_TYPE);
        }
    }, [landCardContents?.lndcgrCodeNm]);

    // useEffect(() => {
    //     if (!roadAddr) return;
    //     setBuildingCardContents((prev: any) => {
    //         return { ...prev, roadAddr };
    //     });
    // }, [roadAddr]);

    const testRef = useRef<any>();

    useEffect(() => {
        setAllFieldData((prev: any) => {
            return {
                ...prev,
                land: landCardContents,
                building: buildingCardContents,
                pnu,
            };
        });
        setBuildingCardProps({
            titleRef: testRef,
            isBackToAppShow: false,
            handleClickBackToApp: () => {},
            handleClickRoadViewButton: () => {},
            hideLandCard: () => {},
            landCardContents,
            buildingCardContents,
            address,
        });
    }, [landCardContents, buildingCardContents]);
    const [buildingCardProps, setBuildingCardProps] = useState({
        titleRef,
        isBackToAppShow: false,
        handleClickBackToApp: () => {},
        handleClickRoadViewButton: () => {},
        hideLandCard: () => {},
        landCardContents,
        buildingCardContents,
        address,
    });

    const rootRef = useRef<any>();
    const touchValues = useRef<any>({ startTouchY: null, currentTouchY: null, touchDirection: "down" });
    const [touchStart, setTouchStart] = useState<boolean>(false);

    return (
        <>
            {allFieldData?.land === undefined && allFieldData?.building === undefined ? (
                <>
                    <div css={loadingBox}>
                        <Lottie animationData={lottie_loading} />
                    </div>
                </>
            ) : (
                <div
                    ref={rootRef}
                    onTouchStart={(evt) => {
                        touchValues.current.startTouchY = evt.touches[0].clientY;
                    }}
                    onTouchMove={(evt) => {
                        const heightChange = evt.touches[0].clientY - touchValues.current.startTouchY;
                        if (touchValues.current.currentTouchY >= evt.touches[0].clientY) {
                            touchValues.current.touchDirection = "up";
                        } else {
                            touchValues.current.touchDirection = "down";
                        }
                        touchValues.current.currentTouchY = evt.touches[0].clientY;
                        if (data.current?.scrollTop === 0 && heightChange > 0) {
                            setTouchStart(true);
                            rootRef.current?.style.setProperty("transform", `translateY(${heightChange}px)`);
                            rootRef.current?.style.setProperty("transition", "all 0.1s");
                        }
                    }}
                    onTouchEnd={(evt) => {
                        if (touchStart) {
                            const heightChange = evt.changedTouches[0].clientY - touchValues.current.startTouchY;
                            if ((window.innerHeight / 3) * 1 <= heightChange && touchValues.current.touchDirection === "down") {
                                rootRef.current?.style.setProperty("transition", "all 0.5s");
                                rootRef.current?.style.setProperty("transform", `translateY(${window.innerHeight}px)`);
                                setTouchStart(false);
                                transferToNative("done", "closeWebView");
                            } else {
                                rootRef.current?.style.removeProperty("transform");
                                rootRef.current?.style.removeProperty("transition");
                                setTouchStart(false);
                            }
                        }
                    }}
                >
                    <CardDataSheet
                        data={data}
                        content={content}
                        checked={checked}
                        type={cardType}
                        location={
                            new naver.maps.LatLng(Number(searchParams.get("lat")) ?? 0, Number(searchParams.get("lng")) ?? 0)
                        }
                        cardData={allFieldData}
                        handleSwipDown={handleSwipDown}
                        buildingCardProps={buildingCardProps}
                        buildingSize={0}
                        from="app"
                        fieldJimok={address?.jimok ?? ""}
                        address={address?.address ?? ""}
                        areaSize={areaSize}
                        eumMapLinks={landCardContents.eum_map_obj}
                    />
                </div>
            )}
        </>
    );
};

export default FieldDetail;

const loadingBox = css`
    width: 100vw;
    height: 100dvh;
    background-color: white;
    svg {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        width: 100%;
        height: 100px !important;
    }
`;
