import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { initRealPriceDetailData } from "@src/hooks/map/useGetRealPriceDetail";
import { InternalTableColumns } from "./InternalTableColumns";

interface InternalTableContentsProps {
    data: any;
    columns: any;
}

function InternalTableContents({ data, columns }: InternalTableContentsProps) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <>
            <table {...getTableProps()} css={tableContentsStyle}>
                <thead css={[tableHeaderStyle, colors["white"]]}>
                    {headerGroups.map((header, idx) => (
                        <tr {...header.getHeaderGroupProps()} key={idx} css={tableHeaderRowStyle}>
                            {header.headers.map((col, idx) => (
                                <th {...col.getHeaderProps()} key={idx}>
                                    {col.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} css={tableBodyStyle}>
                    {data.length === 0 ? (
                        <tr>
                            <div className="noData">조회된 데이터가 없습니다</div>
                        </tr>
                    ) : (
                        <>
                            {rows.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={idx} css={tableBodyRowStyle}>
                                        {row.cells.map((cell, idx) => (
                                            <td {...cell.getCellProps()} key={idx}>
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </>
                    )}
                </tbody>
            </table>
        </>
    );
}

const colors: { [index: string]: any } = {
    white: css`
        background-color: ${Common.colors.white};
        border-bottom: 0.6px solid ${Common.colors.bgGrey03};
        box-sizing: border-box;
    `,
    gray: css`
        background-color: #fbf8f8;
        border: none;
        box-sizing: border-box;
    `,
};

const tableContentsStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    & > tbody {
        box-sizing: border-box;
    }
`;
const tableHeaderStyle = css`
    display: flex;
    padding: 0px 14px;
    width: 100%;
    height: 32px;
    align-items: center;
`;

const tableHeaderRowStyle = css`
    display: flex;
    width: 100%;
    gap: 8px;
    & > th {
        color: ${Common.colors.darkGrey04};
        ${Common.textStyle.text1_R13};
        box-sizing: border-box;
    }
`;

const tableBodyStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    .noData {
        text-align: center;
        padding-top: 10px;
        font-size: 13px;
    }
`;

const tableBodyRowStyle = css`
    display: flex;
    width: 100%;
    gap: 8px;
    border-bottom: 0.6px solid ${Common.colors.bgGrey03};
    padding: 6px 14px;
    & > td {
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.text1_R13}
        display:flex;
        text-align: center;
        box-sizing: border-box;
        div {
            word-break: keep-all;
        }
    }
`;

export default InternalTableContents;
