import React from "react";
import { css } from "@emotion/react";
import { LAND_REGISTER_ISSUE_STATE } from "@src/store/landReigster";
import ico_refresh_white from "@src/assets/ico_refresh_white.png";

import { transferToNative } from "@src/lib/WebAppBridge";
import { getLandRegisterStatusText } from "@src/pages/landRegister/LandRegisterIssueStatusWatch";

interface IssuedItemErrorStatusProps {
    landRegisterId: string;
    status: LAND_REGISTER_ISSUE_STATE;
    onRetry: (landRegisterId: string) => void;
}

const IssuedItemErrorStatus = ({ landRegisterId, status, onRetry }: IssuedItemErrorStatusProps) => {
    const handleRetry = () => {
        onRetry(landRegisterId);
    };
    const handleInquiry = () => {
        transferToNative("", "showInquireView");
    };

    const canRetry = status === LAND_REGISTER_ISSUE_STATE.에러;
    const canInquiry = status === LAND_REGISTER_ISSUE_STATE.주소검색에러;

    return (
        <div css={coverStyle}>
            <div className="loadingBox">
                {canRetry && <img src={ico_refresh_white} onClick={handleRetry} />}
                {canInquiry && <input type="button" value="문의하기" onClick={handleInquiry} />}

                <label>{getLandRegisterStatusText(status)}</label>
            </div>
        </div>
    );
};

export default IssuedItemErrorStatus;

const coverStyle = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);

    .loadingBox {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;

        .lottie {
            width: 60px;
            margin: 0 auto;
        }
        & > img {
            width: 32px;
            height: 32px;
            margin: 14px auto 0;
        }
        & > label {
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            color: #fff;
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.4;
        }
        & > input {
            width: 100px;
            height: 35px;
            background: #2275ee;
            border: none;
            border-radius: 8px;
            color: #fff;
            font-size: 13px;
            margin: 15px auto;
            padding: 0 10px;
            transition: all 0.3s ease;

            &.gray {
                background: #ebebeb;
                color: #0c002c;
            }
        }
    }
`;
