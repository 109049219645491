import React, { useEffect } from "react";
import smallRentTableImg from "@src/assets/smallRentTable.png";
import { css } from "@emotion/react";

export default function SmallRentTablePage() {
    useEffect(() => {
        const viewportMeta = document.querySelector("meta[name=viewport]");
        if (!viewportMeta) return;

        viewportMeta.setAttribute(
            "content",
            "width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, user-scalable=yes",
        );

        return () => {
            viewportMeta.setAttribute("content", "width=device-width, initial-scale=1, user-scalable=no");
        };
    }, []);

    return (
        <div css={rootBoxStyle}>
            <span className="moreInfo">
                자세히 보기:{" "}
                <a href="https://m.iros.go.kr" target="_blank" rel="noopener noreferrer">
                    대법원 인터넷등기소
                </a>{" "}
                &gt; 고객센터 &gt; 지원 안내 &gt; 소액임차인의 범위 등 안내
            </span>
            <img src={smallRentTableImg} alt="소액임차금표" />
        </div>
    );
}

const rootBoxStyle = css`
    height: 100dvh;
    overflow-y: auto;

    .moreInfo {
        display: inline-block;
        margin-bottom: 20px;

        a {
            text-decoration: underline;
            color: blue;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
`;
