import { IRegion } from "@src/hooks/newFilter/useGetLocation";
import create from "zustand";
import { persist } from "zustand/middleware";

export type IFilterParamsType = {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force?: number;
    counter_force_list: number[];
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    sort: number;
    case_number?: string;
    auction_master_except: boolean;
    userLocation?: any;
};

export const initialFilterParams: IFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force_list: [],
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2, 5, 11, 12],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};
export interface IMapBidItemsParams {
    limit: number;
    cursor?: {
        sortType: number;
        cursorValue?: string;
        updatedAt?: string;
    };
    afterKey?: any;
    ne?: {
        lntd: number;
        latd: number;
    };
    sw?: {
        lntd: number;
        latd: number;
    };
    itemFilter: IFilterParamsType;
    itemId?: string;
    groupKey?: string;
}

export type NameAndValue = {
    name: string;
    value: string;
};

export interface LocationFilterParams {
    zoom: number;
    lat: number;
    lng: number;
}

type useFilterStoreType = {
    newFilterState: IFilterParamsType;
    setStoreNewFilterState: (filterState: IFilterParamsType) => void;
};

type listFilterStoreType = {
    listFilterState: IMapBidItemsParams;
    setStoreListFilterState: (filterState: IMapBidItemsParams) => void;
};

type useFilterSearchTextType = {
    newFilterSearchText: string;
    setNewFilterSearchText: (text: string) => void;
};

type useNewFilterMapCheckStoreType = {
    newFilterMapCheckState: boolean;
    setNewFilterMapCheckState: (status: boolean) => void;
};

type useFilterLocationStoreType = {
    filterLocationState: NameAndValue | null;
    setFilterLocationState: (filterState: NameAndValue | null) => void;
};

type useTempNameAndNoListStoreType = {
    tempNameAndNoListState: IRegion[];
    setTempNameAndNoListState: (filterState: IRegion[]) => void;
};

type useFilterUseStoreType = {
    filterUseState: NameAndValue[];
    setFilterUseState: (filterState: NameAndValue[]) => void;
};

type useLocationFilterParamStoreType = {
    filterLocationParamState: LocationFilterParams | null;
    setFilterLocationParamState: (filterState: LocationFilterParams | null) => void;
};

export const useNewFilterStore = create<useFilterStoreType>(
    // persist(
    //     {
    //         key: "newFilterState",
    //         allowlist: ["newFilterState"],
    //         denylist: [],
    //     },
    (set: any) =>
        ({
            newFilterState: initialFilterParams,
            setStoreNewFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        newFilterState: {
                            ...filterState,
                        },
                    };
                });
            },
        } as any),
    // ) as any,
);

export const useNewFilterMapCheckStore = create<useNewFilterMapCheckStoreType>(
    (set: any) =>
        ({
            newFilterMapCheckState: false,
            setNewFilterMapCheckState: async (status: boolean) => {
                set(() => {
                    return {
                        newFilterMapCheckState: status,
                    };
                });
            },
        } as any),
);

export const useNewFilterSearchText = create<useFilterSearchTextType>(
    (set: any) =>
        ({
            newFilterSearchText: "",
            setNewFilterSearchText: async (text: string) => {
                set(() => {
                    return {
                        newFilterSearchText: text,
                    };
                });
            },
        } as any),
);

export const useListFilterFilterStore = create<listFilterStoreType>(
    (set: any) =>
        ({
            listFilterState: "",
            setStoreListFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        ...filterState,
                    };
                });
            },
        } as any),
);

export const useSavedLocationFilter = create<useFilterLocationStoreType>((set) => ({
    filterLocationState: null,
    setFilterLocationState: (state: NameAndValue | null) => set({ filterLocationState: state }),
}));

export const useTempNameAndNoList = create<useTempNameAndNoListStoreType>((set) => ({
    tempNameAndNoListState: [],
    setTempNameAndNoListState: (state: IRegion[]) => set({ tempNameAndNoListState: state }),
}));

export const useSavedUseFilter = create<useFilterUseStoreType>((set) => ({
    filterUseState: [],
    setFilterUseState: (state: NameAndValue[]) => set({ filterUseState: state }),
}));

export const useSearchSavedLocationFilter = create<useFilterLocationStoreType>((set) => ({
    filterLocationState: null,
    setFilterLocationState: (state: NameAndValue | null) => set({ filterLocationState: state }),
}));

export const useSearchedTempNameAndNoList = create<useTempNameAndNoListStoreType>((set) => ({
    tempNameAndNoListState: [],
    setTempNameAndNoListState: (state: IRegion[]) => set({ tempNameAndNoListState: state }),
}));

export const useSearchSavedUseFilter = create<useFilterUseStoreType>((set) => ({
    filterUseState: [],
    setFilterUseState: (state: NameAndValue[]) => set({ filterUseState: state }),
}));

export const useLocationParamSavedUseFilter = create<useLocationFilterParamStoreType>((set) => ({
    filterLocationParamState: null,
    setFilterLocationParamState: (state: LocationFilterParams | null) => set({ filterLocationParamState: state }),
}));
