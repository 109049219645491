"use client";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Header from "@src/components/Header";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import dayjs from "dayjs";
import ico_empty from "@src/assets/ico_deungi_empty.png";
import LandRegisterTab from "@src/components/landRegister/LandRegisterTab";
import { Common } from "@src/styles/Common";
import {
    IssueHistoryItemDto,
    landRegisterQueryKeys,
    useLandRegisterChangeIsRead,
    useLandRegisterIssueHistory,
    useLandRegisterReissue,
} from "@src/hooks/landRegister/useLandRegister";
import IssuedItemStatusWatch from "@src/components/landRegister/IssuedItemStatusWatch";
import { LAND_REGISTER_ISSUE_STATE } from "@src/store/landReigster";
import IssuedItemErrorStatus from "@src/components/landRegister/IssuedItemErrorStatus";
import { useQueryClient } from "react-query";

const LandRegisterIssuedList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data, hasNextPage, fetchNextPage, isLoading, refetch: refetchIssueHistory } = useLandRegisterIssueHistory();
    const { mutate: changeIsRead } = useLandRegisterChangeIsRead();
    const { mutate: reissueLandRegister } = useLandRegisterReissue();
    const [loadingRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        delayInMs: 100,
    });

    const totalCount = data?.pages?.[0].totalCount;

    const handleSelectAddress = (selectedAddress: IssueHistoryItemDto) => {
        selectedAddress.isNew && changeIsRead(selectedAddress._id);

        navigate({
            pathname: "/landRegister/viewer",
            search: `?id=${selectedAddress._id}&address=${selectedAddress.address}`,
        });
    };

    const handleRetry = (landRegisterId: string) => {
        reissueLandRegister(landRegisterId, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: landRegisterQueryKeys.issueHistory });
            },
        });
    };

    useEffect(() => {
        refetchIssueHistory();
    }, []);

    return (
        <Container>
            <Header title="실시간 토지•임야대장 열람" />
            <LandRegisterTab />
            <h3>
                총 <span>{totalCount}</span>건
            </h3>
            <ListBox>
                {totalCount !== 0 &&
                    data?.pages.map((page) =>
                        page.list.map((item) => {
                            const isErrorStatus =
                                item.status === LAND_REGISTER_ISSUE_STATE.에러 ||
                                item.status === LAND_REGISTER_ISSUE_STATE.주소검색에러;
                            const isLoadingStatus =
                                item.status === LAND_REGISTER_ISSUE_STATE.등록완료 ||
                                item.status === LAND_REGISTER_ISSUE_STATE.발급중;

                            return (
                                <div className={"listItem"} key={item._id}>
                                    <div onClick={() => handleSelectAddress(item)}>
                                        <label>
                                            {dayjs(item.issuedAt).format("YYYY.MM.DD HH:mm:ss")}{" "}
                                            {/* 토지•임야대장의 경우 발급일 기준 1일으로 new처리 하지 않음  - 등기열람과 다름*/}
                                            {item.isNew && <small>NEW</small>}
                                        </label>
                                        <div className="flexBox">
                                            <span>{item.address}</span>
                                            <span className="buttonBox">
                                                <span className="buttonLike">열람</span>
                                            </span>
                                        </div>
                                    </div>
                                    {isErrorStatus && (
                                        <IssuedItemErrorStatus
                                            landRegisterId={item._id}
                                            onRetry={handleRetry}
                                            status={item.status}
                                        />
                                    )}
                                    {isLoadingStatus && <IssuedItemStatusWatch landRegisterId={item._id} />}
                                </div>
                            );
                        }),
                    )}

                <div style={{ padding: "20px" }} ref={loadingRef} />
                {totalCount === 0 && (
                    <EmptyBox>
                        <div>
                            <img src={ico_empty} />
                            <span>실시간 토지•임야대장 열람 내역이 없어요</span>
                        </div>
                    </EmptyBox>
                )}
            </ListBox>
        </Container>
    );
};

const EmptyBox = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    & > div {
        display: flex;
        flex-direction: column;
        animation: fadeIn 0.2s;
        align-items: center;

        & > img {
            width: 140px;
            height: 115px;
            margin-bottom: 24px;
        }
        & > span {
            font-size: 17px;
            text-align: center;
        }
    }
`;

const ListBox = styled.div`
    position: relative;
    height: calc(100% - 180px);
    overflow: scroll;
    padding: 0 14px;

    .listItem {
        position: relative;
        padding: 12px 0px;
        border-bottom: 1px solid #c9c9c9;
        animation: fadeIn 0.5s ease;
        transition: all 0.3s ease;

        .flexBox {
            display: flex;
            justify-content: space-between;
            .buttonBox {
                min-width: 56px;
                display: flex;
                flex-direction: column;
                align-items: end;
            }
            .buttonLike {
                display: flex;
                max-height: 32px;
                justify-content: center;
                align-items: center;
                line-height: 1;
                color: #ffffff;
                background-color: ${Common.colors.blue};
                border-radius: 20px;
                padding: 8px;
            }
        }

        &:active {
            background: #dff0ff;
        }
        & > label {
            font-size: 14px;
            color: #0047ff;
        }

        small {
            display: inline-block;
            color: #fff;
            font-size: 12px;
            background: #f00;
            border-radius: 100px;
            line-height: 20px;
            padding: 0 4px;
            animation: gelatine 0.5s ease;
        }
        & > div {
            padding: 8px 0;
            font-size: 16px;
            line-height: 1.6;
        }
        & > p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            & > small {
                padding: 4px 6px;
                border-radius: 24px;
                margin-right: 4px;
            }
            /* small:nth-of-type(1) {
                background: #fff9e4;
            }
            small:nth-of-type(2) {
                background: #eefff5;
                margin-left: 20px;
            } */

            & > span {
                padding: 4px 6px;
                border-radius: 24px;
                margin-right: 8px;
                color: #fff;

                &.recapTitle {
                    background: #2275ee;
                }
                &.rgstgnrl {
                    background: #f6494b;
                }
                &.title {
                    background: #00b8be;
                }
                &.expos {
                    background: #ff9500;
                }
            }
        }
    }
    .loadingListBox {
        margin: 20px 0;
        border: none;
        background: #fff !important;

        .lottie {
            width: 50px;
            margin: auto;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    user-select: none;
    background-color: #fff;

    & > h3 {
        padding: 14px;
        font-weight: 500;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

        & > span {
            font-weight: 700;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes gelatine {
        from,
        to {
            transform: scale(1, 1);
        }
        25% {
            transform: scale(0.9, 1.1);
        }
        50% {
            transform: scale(1.1, 0.9);
        }
        75% {
            transform: scale(0.95, 1.05);
        }
    }
`;

export default LandRegisterIssuedList;
