/* eslint-disable react/no-unknown-property */

import React, { memo } from "react";
import { Canvas } from "@react-three/fiber";
import { Compass, CompassRotation } from "./Compass";
import { useLocation } from "react-router-dom";
import { transferToNative } from "@src/lib/WebAppBridge";
import SceneConfig from "./SceneConfig";
import { BuildingObject, GroundObject, LandObject } from "./Objects";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            // R3F의 box와 MUI의 Box 타입이 충돌하는 이슈가 있습니다.
            // MUI의 타입을 우선하기 위해 추가하였습니다.
            // 관련 이슈: https://github.com/pmndrs/react-three-fiber/discussions/1752
            box?: never;
        }
    }
}

// 해당 컴포넌트는 리렌더링을 최소화해야 합니다.
// 3D Scene을 자주 그리면 앱이 크래시될 수 있습니다.
function BuildingSimulation() {
    const location = useLocation();
    const shouldTransfer = location.pathname === "/three";

    return (
        <>
            <Canvas
                shadows
                onPointerDown={() => {
                    if (!shouldTransfer) return;
                    transferToNative("true", "disableNativeScroll");
                }}
                onPointerUp={() => {
                    if (!shouldTransfer) return;
                    transferToNative("false", "disableNativeScroll");
                }}
            >
                {/* Scene 설정 */}
                <SceneConfig />
                {/* 나침반 각도 계산 */}
                <CompassRotation />

                <GroundObject />
                <LandObject />
                <BuildingObject />
            </Canvas>

            <Compass />
        </>
    );
}

export default memo(BuildingSimulation);
