import { css } from "@emotion/react";
import {
    FIRST_INTERVAL_TIME,
    getLandRegisterStatusText,
    SECOND_INTERVAL_TIME,
    STATUS_WATCH_FIRST_TIMER_MAX_COUNT,
} from "@src/pages/landRegister/LandRegisterIssueStatusWatch";
import { LAND_REGISTER_ISSUE_STATE } from "@src/store/landReigster";

import Lottie from "lottie-react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";

import React, { useEffect, useState } from "react";
import { landRegisterQueryKeys, useLandRegisterIssueStatus } from "@src/hooks/landRegister/useLandRegister";
import { useQueryClient } from "react-query";

interface IssuedItemStatusWatchProps {
    landRegisterId: string;
}

const IssuedItemStatusWatch = ({ landRegisterId }: IssuedItemStatusWatchProps) => {
    const [isStartSecondTimer, setIsStartSecondTimer] = useState(false);
    let statusWatchCount = 1;
    const { data, refetch: refetchIssueStatus } = useLandRegisterIssueStatus(landRegisterId);
    const queryClient = useQueryClient();

    useEffect(() => {
        const timer = setInterval(() => {
            if (statusWatchCount < STATUS_WATCH_FIRST_TIMER_MAX_COUNT) {
                statusWatchCount++;
            } else {
                clearInterval(timer);
                setIsStartSecondTimer(true);
                return;
            }

            refetchIssueStatus().then((data) => {
                const canRead = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.발급중;
                if (canRead) {
                    clearInterval(timer);
                    queryClient.invalidateQueries({ queryKey: landRegisterQueryKeys.issueHistory });
                }
            });
        }, FIRST_INTERVAL_TIME);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (!isStartSecondTimer) return;
        const after15SecondsTimer = setInterval(() => {
            refetchIssueStatus().then((data) => {
                const canRead = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.발급중;
                if (canRead) {
                    clearInterval(after15SecondsTimer);
                    queryClient.invalidateQueries({ queryKey: landRegisterQueryKeys.issueHistory });
                }
            });
        }, SECOND_INTERVAL_TIME);
        return () => clearInterval(after15SecondsTimer);
    }, [isStartSecondTimer]);

    return (
        <div css={coverStyle}>
            <div className="loadingBox">
                <Lottie className="lottie" animationData={lottie_loading} />

                <label>{data?.issueStatus && getLandRegisterStatusText(data?.issueStatus)}</label>
            </div>
        </div>
    );
};

export default IssuedItemStatusWatch;

const coverStyle = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);

    .loadingBox {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;

        .lottie {
            width: 60px;
            margin: 0 auto;
        }
        & > img {
            width: 32px;
            height: 32px;
            margin: 14px auto 0;
        }
        & > label {
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            color: #fff;
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.4;
        }
        & > input {
            width: 100px;
            height: 35px;
            background: #2275ee;
            border: none;
            border-radius: 8px;
            color: #fff;
            font-size: 13px;
            margin: 15px auto;
            padding: 0 10px;
            transition: all 0.3s ease;

            &.gray {
                background: #ebebeb;
                color: #0c002c;
            }
        }
    }
`;
