import { css } from "@emotion/react";
import React, { useRef } from "react";
import noMoreImage from "@src/assets/goStop/noMore.png";
import { Common } from "@src/styles/Common";
import { SearchAddressItemDto } from "@src/hooks/landRegister/useLandRegister";
import { InfiniteData } from "react-query";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Lottie from "lottie-react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";

interface LandRegisterSearchListProps {
    addressList: InfiniteData<SearchAddressItemDto[]>;
    onSelectAddress: (address: SearchAddressItemDto) => void;
    onLoadMore: () => void;
}

const SEARCH_ADDRESS_LIMIT = 20;

const LandRegisterSearchAddressList = ({ addressList, onSelectAddress, onLoadMore }: LandRegisterSearchListProps) => {
    const searchInfoRef = useRef(null as any);

    const handleClickAddress = (address: SearchAddressItemDto) => {
        onSelectAddress(address);
    };

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: true,
        onLoadMore,
        disabled: false,
    });

    const noAddressList = addressList.pages[0].length === 0;
    const noMorePage = addressList.pages[addressList.pages.length - 1].length < SEARCH_ADDRESS_LIMIT;

    return (
        <div ref={searchInfoRef} css={searchInfoStyle}>
            {addressList.pages[0].length === 0 && (
                <div className={"notFoundInfo"}>
                    <img src={noMoreImage} alt="no more icon" className="noMore" width={78} height={78} />
                    <div>
                        해당 소재지에 대장 정보가 존재하지 않습니다 <br />
                        검색어를 다시 확인해주세요
                        <br />
                        <br />※ 토지•임야대장은 지번 주소로만 검색이 가능합니다.
                        <br />
                        지번 주소는 (읍,면,동,리)로 검색해주세요.
                    </div>
                </div>
            )}

            <div className="addressListBox">
                {addressList.pages.map((page) =>
                    page.map((address) => {
                        return (
                            <div
                                key={address.pnu}
                                className="addressItem"
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => handleClickAddress(address)}
                            >
                                <span className="addressText">{address.addressName}</span>
                                <span className="buttonLike">열람</span>
                            </div>
                        );
                    }),
                )}
                {!(noAddressList || noMorePage) && (
                    <div className="loadingListBox" ref={loadingRef}>
                        <Lottie className="lottie" animationData={lottie_loading} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LandRegisterSearchAddressList;

const searchInfoStyle = css`
    position: absolute;
    top: 195px;
    bottom: 0;
    width: 100%;
    background: #fff;
    overflow: scroll;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    transition: all 0.2s ease;
    animation: fadeIn 0.2s ease;
    z-index: 3;

    .addressListBox {
        .addressItem {
            cursor: pointer;
            padding: 8px 14px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            border-bottom: 1px solid ${Common.colors.grey03};

            .addressText {
                display: inline-flex;
                align-items: center;
            }

            .buttonLike {
                color: #ffffff;
                background-color: ${Common.colors.blue};
                border-radius: 20px;
                padding: 8px;
            }
        }

        .loadingListBox {
            width: 100%;
            margin: 20px 0;
            border: none;
            background: #fff !important;

            .lottie {
                width: 50px;
                margin: auto;
            }
        }
    }

    .notFoundInfo {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 14px;
        line-height: 1.8;
        text-align: center;
        padding-top: 120px;

        img {
            display: block;
        }

        div {
            width: 100%;
        }
        &:hover {
            background: #fff;
        }
    }
`;
