import convertNumberToKorean from "@src/util/convertNumToKorean";
import { getLocationCortar } from "@src/hooks/newFilter/useGetLocation";
import { useFilterTextList } from "./useFilterList";

// 경공매
export const makeTypeText = (type: number) => {
    let str = "";
    switch (type) {
        case 0:
            str = "전체";
            break;
        case 1:
            str = "경매";
            break;
        case 2:
            str = "공매";
            break;
    }

    return str;
};

// 처분방식
export const makeMethodText = (method: number) => {
    let str = "전체";

    switch (method) {
        case 1:
            str = "매각";
            break;
        case 2:
            str = "임대";
            break;
    }

    return str;
};
// 자산구분
export const makeAssetsText = (assetArr: number[]) => {
    let arr: string[] = [];

    assetArr?.map((aa) => {
        switch (aa) {
            case 1:
                arr.push("압류재산");
                break;
            case 2:
                arr.push("국공유재산");
                break;
            case 3:
                arr.push("신탁재산");
                break;
            case 4:
                arr.push("기타재산");
                break;
        }
    });

    if (arr.length === 0) arr.push("전체");

    return arr;
};
// 소재지
export const makeLocText = async (loc: string) => {
    let sidoText = "";
    let sigunguText = "";
    let emdText = "";
    sidoText = await getLocationCortar("0000000000").then((res) => {
        const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === loc.slice(0, 2));
        return selectedSido?.[0]?.cortarName ?? "";
    });
    if (loc.slice(2) !== "00000000") {
        sigunguText = await getLocationCortar(`${loc.slice(0, 2)}00000000`).then((res) => {
            const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === loc.slice(0, 5));
            return selectedSigungu?.[0]?.cortarName;
        });
    } else {
        return { name: `${sidoText} > 전체`, value: loc };
    }
    if (loc.slice(5) !== "00000") {
        emdText = await getLocationCortar(`${loc.slice(0, 5)}00000`).then((res) => {
            const selectedEmd = res.filter((val: any) => val?.cortarNo === loc);
            return selectedEmd?.[0]?.cortarName;
        });
    } else {
        emdText = "전체";
    }
    return { name: `${sidoText} > ${sigunguText} > ${emdText}`, value: loc };
};

// 용도
export const makeUseText = (useArr: string[]) => {
    let arr: string[] = [];
    let arr2: string[] = [];

    // 원본 배열의 길이
    const AlenOri = useFilterTextList.filter((aa) => aa.value.includes("A")).length - 1;
    const BlenOri = useFilterTextList.filter((aa) => aa.value.includes("B")).length - 1;
    const ClenOri = useFilterTextList.filter((aa) => aa.value.includes("C")).length - 1;
    const DlenOri = useFilterTextList.filter((aa) => aa.value.includes("D")).length - 1;

    useArr?.map((aa) => {
        const useStr = useFilterTextList.find((text) => text.value === aa);
        if (useStr) arr.push(useStr.name);
    });

    // 인자로 받은 배열의 길이
    const Alen = arr.filter((aa) => aa.includes("주거용"));
    const Blen = arr.filter((aa) => aa.includes("토지"));
    const Clen = arr.filter((aa) => aa.includes("산업용"));
    const Dlen = arr.filter((aa) => aa.includes("상업/업무용"));

    // 비교 후 같으면 전체로 변경해서 반환
    arr2 = AlenOri === Alen.length ? [...arr2, "주거용 > 전체"] : [...arr2, ...Alen];
    arr2 = BlenOri === Blen.length ? [...arr2, "토지 > 전체"] : [...arr2, ...Blen];
    arr2 = ClenOri === Clen.length ? [...arr2, "산업용 > 전체"] : [...arr2, ...Clen];
    arr2 = DlenOri === Dlen.length ? [...arr2, "상업/업무용 > 전체"] : [...arr2, ...Dlen];

    if (arr2.length === 0) arr2.push("전체");

    return arr2;
};
// 대항력(이전)
export const makeConterForceTextBefore = (counterArr: number) => {
    let str = "전체";

    switch (counterArr) {
        case 1:
            str = "없음";
            break;
        case 2:
            str = "있음";
            break;
        case 3:
            str = "미상";
            break;
    }

    return str;
};

// 대항력
export const makeConterForceText = (counterArr: number[]) => {
    let arr: string[] = [];

    counterArr?.map((aa) => {
        switch (aa) {
            case 1:
                arr.push("대항력 없음");
                break;
            case 2:
                arr.push("대항력 있음");
                break;
            case 3:
                arr.push("미상");
                break;
        }
    });

    if (arr.length === 0) arr.push("전체");

    return arr;
};

// 경매고수물건
export const makeMasterItemText = (masterArr: number[]) => {
    let arr: string[] = [];

    masterArr?.map((aa) => {
        switch (aa) {
            case 1:
                arr.push("유치권");
                break;
            case 2:
                arr.push("분묘기지권");
                break;
            case 3:
                arr.push("법정지상권");
                break;
            case 4:
                arr.push("위반건축물");
                break;
            case 5:
                arr.push("농취증필요");
                break;
            case 6:
                arr.push("토지만입찰");
                break;
            case 7:
                arr.push("건물만입찰");
                break;
            case 8:
                arr.push("토지별도등기");
                break;
            case 9:
                arr.push("지분매각");
                break;
            case 10:
                arr.push("대지권미등기");
                break;
            case 11:
                arr.push("HUG 임차권 인수조건 변경");
                break;
            case 12:
                arr.push("가등기");
                break;
            case 13:
                arr.push("가처분");
                break;
        }
    });

    if (arr.length === 0) arr.push("전체");

    return arr;
};

// 진행상태
export const makeStatusText = (statusArr: number[]) => {
    let arr: string[] = [];

    statusArr?.map((aa) => {
        switch (aa) {
            case 2:
                arr.push("진행중");
                break;
            case 3:
                arr.push("매각");
                break;
            case 4:
                arr.push("기타");
                break;
            case 5:
                arr.push("변경");
                break;
            case 6:
                arr.push("유찰(정지)");
                break;
            case 7:
                arr.push("기각");
                break;
            case 8:
                arr.push("취하");
                break;
            case 9:
                arr.push("취소");
                break;
            case 10:
                arr.push("입찰마감");
                break;
            case 11:
                arr.push("입찰중");
                break;
            case 12:
                arr.push("입찰준비중");
                break;
        }
    });

    if (arr.length === 0) arr.push("전체");

    return arr;
};

// 유찰횟수
export const makeSkpText = (type: number, cnt: string[]) => {
    let arr: string[] = [];

    switch (type) {
        case 0:
            arr.push("전체");
            break;
        case 1:
            arr.push("신건");
            break;
        case 2:
            arr.push("유찰");
            break;
    }

    // 유찰일경우 유찰횟수 추가
    if (type === 2) {
        const max = cnt[1] === "" ? "최대" : `${cnt[1]}회`;
        let str = `${cnt[0]}회` + " ~ " + `${max}`;

        arr.push(str);
    }

    return arr;
};

// 매각기한
export const makeDueDateText = (dateArr: string[]) => {
    let str = "전체";

    // 지정한 날짜가 있다면
    if (dateArr[0] !== "") str = dateArr[0] + " ~ " + dateArr[1];

    // null이 포함된 경우
    if (str.includes("null")) str = "전체";

    return str;
};

// 감정가
export const makePriceText = (priceArr: string[]) => {
    let str = "전체";

    // 지정한 최대값이 있을 때만
    if (priceArr[1] !== "") {
        let min = priceArr[0] === "" ? "0" : convertNumberToKorean(Number(priceArr[0]));
        let max = convertNumberToKorean(Number(priceArr[1]));

        str = min + " ~ " + max;
    }

    if (priceArr[0] == null) {
        if (priceArr[1] == null) str = "전체";
        else str.replace("null", "0");
    }

    return str;
};
