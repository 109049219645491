import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { IFilterParamsType } from "@src/store/newFilterStore";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

interface StatusMap {
    [key: string]: number | number[];
}

// export const auctionStatusList = ["전체", "진행중", "변경", "매각", "기타"];
// export const publicAuctionStatusList = ["전체", "진행중", "매각", "기타"];

export const allStatusTextList = [
    "전체",
    "진행중",
    "변경",
    "입찰준비중",
    "입찰중",
    "매각",
    "유찰(정지)",
    "취소",
    "기각",
    "취하",
    "기타",
    "입찰마감",
];

export const auctionStatusTextList = ["전체", "진행중", "변경", "매각", "유찰(정지)", "취소", "기각", "취하", "기타"];
export const publicAuctionStatusTextList = ["전체", "입찰준비중", "입찰중", "매각", "유찰(정지)", "취소", "입찰마감"];

export const allStatusMap: StatusMap = {
    전체: 0,
    진행중: [1, 2],
    변경: 5,
    입찰중: 11,
    입찰준비중: 12,
    매각: 3,
    "유찰(정지)": 6,
    기각: 7,
    취하: 8,
    취소: 9,
    기타: 4,
    입찰마감: 10,
};

const DEFAULT_ALL_ACTIVE_STATUS = [1, 2, 5, 11, 12];
const DEFAULT_AUCTION_ACTIVE_STATUS = [1, 2, 5];
const DEFAULT_PUBLIC_AUCTION_ACTIVE_STATUS = [11, 12];

export const defaultActiveStatus = [
    DEFAULT_ALL_ACTIVE_STATUS,
    DEFAULT_AUCTION_ACTIVE_STATUS,
    DEFAULT_PUBLIC_AUCTION_ACTIVE_STATUS,
];

const { 전체, ...auctionStatusMap }: StatusMap = createStatusMap(auctionStatusTextList);
const { 전체: 전체2, ...publciAuctionStatusMap }: StatusMap = createStatusMap(publicAuctionStatusTextList);

export const allStatusValue = Object.values(allStatusMap);
export const auctionStatusValue = Object.values(auctionStatusMap);
export const publicAuctionStatusValue = Object.values(publciAuctionStatusMap);

export const statusListByAuctionType = [allStatusValue, auctionStatusValue, publicAuctionStatusValue];
// 진행상태 텍스트 추출
export function findKeyByValueFromStatus(targetValue: number) {
    for (const key in allStatusMap) {
        if (Array.isArray(allStatusMap[key])) {
            const value = allStatusMap[key] as number[];
            if (value.includes(targetValue)) {
                return key;
            }
        }
        if (allStatusMap[key] === targetValue) {
            return key;
        }
    }
    return null;
}

// [진행중, 변경] => {진행중 : [1,2], 변경: 5}
export function createStatusMap(list: string[]): StatusMap {
    return list.reduce((acc, status) => {
        acc[status] = allStatusMap[status];
        return acc;
    }, {} as StatusMap);
}

export enum AUCTION_TYPE {
    ALL = 0,
    "AUCTION" = 1,
    "PUBLIC_AUCTION" = 2,
}

const AuctionStatusFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    // const statusList = filterParams.type === 2 ? publicAuctionStatusList : auctionStatusList;
    const statusList =
        filterParams.type === AUCTION_TYPE.ALL
            ? allStatusTextList
            : filterParams.type === AUCTION_TYPE.PUBLIC_AUCTION
            ? publicAuctionStatusTextList
            : auctionStatusTextList;

    const statusMap = createStatusMap(statusList);

    const onClickStatus = (newStatus: number | number[]) => {
        const statusTotalCount = Object.values(statusMap)
            .flat()
            .reduce((acc, cur) => acc + (Array.isArray(cur) ? cur.length : 1), 0);
        const combinedStatus = Array.isArray(newStatus)
            ? [...filterParams.status, ...newStatus]
            : [...filterParams.status, newStatus];

        const uniqueStatus = Array.from(new Set(combinedStatus));
        const isStatusAll = newStatus === 0 || uniqueStatus.length === statusTotalCount - 1;

        if (isStatusAll) {
            setFilterParams({ ...filterParams, status: [] });
            return;
        }

        let updatedStatus = [...filterParams.status];

        if (Array.isArray(newStatus)) {
            updatedStatus =
                updatedStatus.filter((status) => !newStatus.includes(status)).length === updatedStatus.length
                    ? Array.from(new Set([...updatedStatus, ...newStatus]))
                    : updatedStatus.filter((status) => !newStatus.includes(status));
        } else {
            const isDuplicate = updatedStatus.includes(newStatus);
            if (isDuplicate) {
                updatedStatus = [...updatedStatus.filter((status) => status !== newStatus)];
            } else {
                updatedStatus.push(newStatus);
            }
        }

        setFilterParams({ ...filterParams, status: updatedStatus.sort() });
    };

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(filterParams.status.some((val) => !val) || !filterParams.status.length)}>
                <p className="titleText">진행상태</p>
                {allFilter ? (
                    <button className="allButton" onClick={() => setFilterParams({ ...filterParams, status: [] })}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {statusList.map((item, idx) => {
                    const index = allStatusMap[item];

                    const checkActive = index
                        ? filterParams.status?.some((val) => (Array.isArray(index) ? index.includes(val) : val === index))
                        : filterParams.status.some((val) => !val) || !filterParams.status.length;
                    return (
                        (!allFilter || !!idx) && (
                            <button
                                key={item}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    onClickStatus(index);
                                }}
                            >
                                {item}
                            </button>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default AuctionStatusFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${Common.colors.grey02};
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;
