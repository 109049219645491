import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useSearchParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { DeungiListResponseDto } from "@src/hooks/deungi/dto/DeungiList.dto";
import useWindowSize from "@src/hooks/deungi/useWindowSize";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import DeungiPopup from "@src/components/deungi/DeungiPopup";
import { DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import { transferToNative } from "@src/lib/WebAppBridge";
import { checkDevice } from "@src/components/deungi/common/utils";
import { axiosPath } from "@src/api/axiosPath";
import { useDeungiInfo } from "@src/hooks/deungi/useDeungiInfo";
import { DeungiInfoResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { pdf } from "@src/hooks/buildingRegister/axiosPath";
import dayjs from "dayjs";
import Header from "@src/components/Header";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BuildingRegisterPdfViewer = () => {
    const windowSize = useWindowSize();
    const [searchParams] = useSearchParams();

    const [numPages, setNumPages] = useState(0);
    const [loadPageNum, setLoadPageNum] = useState(0);
    const [isShow, setIsShow] = useState(false);

    const downloadFile = () => {
        transferToNative(
            JSON.stringify({
                pdfUrl: `${pdf}/${searchParams.get("id")}`,
                fileName: `${decodeURIComponent(searchParams.get("address")?.toString() || "")}_${dayjs().format("YYMMDDHHmm")}`,
            }),
            "downloadPdf",
        );
    };

    const onPageLoadSuccess = () => {
        setLoadPageNum((prev) => prev + 1);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        if (numPages > 0 && loadPageNum === numPages) setIsShow(true);
    }, [loadPageNum, numPages]);

    return (
        <>
            <Container>
                <Header title="실시간 건축물대장 열람" hasCloseBtn={true} />
                <div className="loadingBox">
                    <Lottie className="lottie" animationData={lottie_loading}>
                        <div>
                            {loadPageNum}/{numPages}
                        </div>
                    </Lottie>
                </div>
                <TransformWrapper alignmentAnimation={{ sizeX: 0, sizeY: 0 }}>
                    <TransformComponent
                        wrapperStyle={{
                            position: "absolute",
                            top: "50px",
                            bottom: "80px",
                            height: "initial",
                            margin: "auto",
                        }}
                        contentStyle={{}}
                    >
                        <Document
                            className={isShow ? "document show" : "document"}
                            file={`${pdf}/${searchParams.get("id")}`}
                            loading=""
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (_, index) => (
                                <div key={index}>
                                    <Page
                                        width={1024}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        loading=""
                                        onRenderSuccess={onPageLoadSuccess}
                                    />
                                    <hr />
                                </div>
                            ))}
                        </Document>
                    </TransformComponent>
                </TransformWrapper>
                <div className="inputBox">
                    <input type="button" value="다운로드" onClick={() => downloadFile()} />
                </div>
                <label>손가락으로 확대 및 축소가 가능합니다</label>
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: #fff;
    animation: fadeIn 1s forwards;

    .exitBtn {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;

        & > img {
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }
    h3 {
        font-weight: 700;
        font-size: 18px;
        color: #0c0c0c;
        margin: 16px 0;
    }
    .document {
        opacity: 0;
        &.show {
            animation: fadeIn 0.5s forwards;
        }
        hr {
            border: 2px solid #ccc;
        }
        .page {
            width: 1024px;
            height: auto !important;
            transition: all 0.3s ease;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            margin: auto;
            margin-bottom: 20px;
        }
        canvas {
            width: 100% !important;
            height: auto !important;
            margin: auto;
        }
    }
    .loadingBox {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .lottie {
            position: relative;
            width: 75px;
            margin: auto;

            & > div {
                position: absolute;
                width: 100%;
                bottom: 0;
                font-size: 13px;
                color: #0c0c0c;
                text-align: center;
            }
        }
    }
    .inputBox {
        position: absolute;
        width: 100%;
        bottom: 14px;
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding: 0 14px;

        input {
            width: 100%;
            height: 55px;
            background: #2275ee;
            border: none;
            border-radius: 14px;
            color: #fff;
            font-size: 18px;
            margin-top: 15px;
            padding: 0 10px;
            transition: all 0.5s ease;

            &.hidden {
                display: none;
            }
        }
    }
    & > label {
        position: fixed;
        bottom: 90px;
        left: 14px;
        right: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        padding: 8px 14px;
        border-radius: 14px;
        animation: fadeOut 0.5s 2s forwards;
        text-align: center;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

export default BuildingRegisterPdfViewer;
