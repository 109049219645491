import styled from "@emotion/styled";
import React from "react";
import { useNavigate } from "react-router-dom";

enum tabType {
    search = "SEARCH",
    list = "LIST",
}

const LandRegisterTab = () => {
    const navigate = useNavigate();

    const onTabHandler = (category: tabType) => {
        navigate({
            pathname: `/landRegister${category === tabType.list ? "/list" : ""}`,
        });
    };

    return (
        <Container>
            <div className={location.pathname.match(/list/gi) ? "" : "active"} onClick={() => onTabHandler(tabType.search)}>
                토지•임야대장
            </div>
            <div className={location.pathname.match(/list/gi) ? "active" : ""} onClick={() => onTabHandler(tabType.list)}>
                열람내역
            </div>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    padding: 14px;
    gap: 5px;

    & > div {
        flex: 1;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        background: #fbf8f8;
        color: #8d8d8d;

        cursor: pointer;

        &.active {
            background: #2275ee;
            color: #fff;
            font-weight: 700;
        }
    }
`;

export default LandRegisterTab;
