/* eslint-disable react/no-unknown-property */

import React, { useEffect, useMemo } from "react";
import * as THREE from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";
import { useBuildingHeightStore, useLandPolygonStore } from "./store";
import { useBuildingPolygonStore } from "./store";
import { getMaxAbsCoord, SCALE_FACTOR } from "./utils";

// 바닥 Object
export function GroundObject() {
    const { landPolygon } = useLandPolygonStore();
    const { buildingHeight } = useBuildingHeightStore();
    if (!landPolygon || buildingHeight === null) return null;

    const [maxX, maxY] = getMaxAbsCoord(landPolygon);

    return (
        <mesh position={[0, -20, 0]} receiveShadow>
            <boxGeometry
                args={[Math.max(maxX * 2 + 10, buildingHeight * 2 + 10), 40, Math.max(maxY * 2 + 10, buildingHeight * 2 + 10)]}
            />
            <meshStandardMaterial color="#e0e0e0" toneMapped={false} />
        </mesh>
    );
}

// 토지 Object
export function LandObject() {
    const { landPolygon } = useLandPolygonStore();
    if (!landPolygon) return null;

    // 좌표 기준 다각형 그리기
    const shape = new THREE.Shape();
    shape.moveTo(landPolygon[0][0], landPolygon[0][1]);
    for (let i = 1; i < landPolygon.length; i++) {
        shape.lineTo(landPolygon[i][0], landPolygon[i][1]);
    }
    shape.closePath();

    const extrudeSettings = {
        depth: 0.05,
        bevelEnabled: false,
    };

    return (
        <>
            <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} receiveShadow>
                <extrudeGeometry args={[shape, extrudeSettings]} />
                <meshStandardMaterial color="#818181" toneMapped={false} />
            </mesh>
        </>
    );
}

// 빌딩 Object
export function BuildingObject() {
    const { buildingPolygon } = useBuildingPolygonStore();
    if (!buildingPolygon) return null;

    let currentHeight = 0;

    return (
        <>
            {buildingPolygon.map((polygon, idx) => {
                if (idx !== 0) {
                    currentHeight += polygon.height / SCALE_FACTOR;
                }

                return (
                    <BuildingFloor
                        key={idx}
                        coord={polygon.coordinates}
                        height={polygon.height / SCALE_FACTOR}
                        opacity={idx === 0 ? 0.3 : 1}
                        floorPosition={currentHeight}
                    />
                );
            })}
        </>
    );
}

function BuildingFloor({
    coord,
    height,
    opacity = 1,
    floorPosition,
}: {
    coord: number[][];
    height: number;
    opacity?: number;
    floorPosition: number;
}) {
    // 좌표 기준 다각형 그리기
    const shape = useMemo(() => {
        const s = new THREE.Shape();
        s.moveTo(coord[0][0], coord[0][1]);
        for (let i = 1; i < coord.length; i++) {
            s.lineTo(coord[i][0], coord[i][1]);
        }
        s.closePath();
        return s;
    }, [coord]);

    const extrudeSettings = useMemo(
        () => ({
            depth: height,
            bevelEnabled: false,
        }),
        [height],
    );
    const extrudeGeo = useMemo(() => new THREE.ExtrudeGeometry(shape, extrudeSettings), [shape, extrudeSettings]);
    const edgesGeo = useMemo(() => new THREE.EdgesGeometry(extrudeGeo), [extrudeGeo]);
    const lineMat = useMemo(
        () =>
            new LineMaterial({
                color: "black",
                linewidth: 0.5,
            }),
        [],
    );

    useEffect(() => {
        return () => {
            extrudeGeo.dispose();
            edgesGeo.dispose();
            lineMat.dispose();
        };
    }, [extrudeGeo, edgesGeo, lineMat]);

    return (
        <>
            <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, floorPosition, 0]} castShadow>
                <extrudeGeometry args={[shape, extrudeSettings]} />
                <meshStandardMaterial color={"#67D1F7"} opacity={opacity} transparent={opacity !== 1} toneMapped={false} />
            </mesh>

            {/* 외곽선 */}
            <primitive
                object={new LineSegments2(new LineSegmentsGeometry().fromEdgesGeometry(edgesGeo), lineMat)}
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, floorPosition, 0]}
            />
        </>
    );
}
