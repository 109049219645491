import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import gif_loading_deungi from "@src/assets/gif_loading_deungi.gif";
import ico_close_white from "@src/assets/ico_close_white.png";
import landRegisterBackgroundImageList from "@src/assets/landRegister/index";
import { useNavigate } from "react-router-dom";
import { LAND_REGISTER_ISSUE_STATE } from "@src/store/landReigster";
import { useLandRegisterChangeIsRead, useLandRegisterIssueStatus } from "@src/hooks/landRegister/useLandRegister";
import useLandRegisterPdf from "@src/hooks/landRegister/useLandRegisterPdf";

export const STATUS_WATCH_FIRST_TIMER_MAX_COUNT = 3;
export const FIRST_INTERVAL_TIME = 5000;
export const SECOND_INTERVAL_TIME = 1000;

const LandRegisterIssueStatusWatch = () => {
    const [isStartSecondTimer, setIsStartSecondTimer] = useState(false);
    let statusWatchCount = 1;

    const navigate = useNavigate();
    const { id, address } = useLandRegisterPdf();
    const { data: issueStatus, refetch: refetchIssueStatus } = useLandRegisterIssueStatus(id);
    const { mutate: changeIsRead } = useLandRegisterChangeIsRead();

    useEffect(() => {
        const timer = setInterval(() => {
            if (statusWatchCount < STATUS_WATCH_FIRST_TIMER_MAX_COUNT) {
                statusWatchCount++;
            } else {
                clearInterval(timer);
                setIsStartSecondTimer(true);
            }

            refetchIssueStatus().then((data) => {
                const isDone = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.발급중;
                const canNotRead = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.완료;
                const canRead = data.data && data.data.issueStatus === LAND_REGISTER_ISSUE_STATE.완료;
                if (isDone) {
                    clearInterval(timer);
                    if (canRead) {
                        goToPdfViewer();
                    }
                    if (canNotRead) {
                        goToList();
                    }
                }
            });
        }, FIRST_INTERVAL_TIME);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (!isStartSecondTimer) return;

        const after15SecondsTimer = setInterval(() => {
            refetchIssueStatus().then((data) => {
                const isDone = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.발급중;
                const canNotRead = data.data && data.data.issueStatus > LAND_REGISTER_ISSUE_STATE.완료;
                const canRead = data.data && data.data.issueStatus === LAND_REGISTER_ISSUE_STATE.완료;
                if (isDone) {
                    clearInterval(after15SecondsTimer);
                    if (canRead) {
                        goToPdfViewer();
                    }
                    if (canNotRead) {
                        goToList();
                    }
                }
            });
        }, SECOND_INTERVAL_TIME);

        return () => clearInterval(after15SecondsTimer);
    }, [isStartSecondTimer]);

    const titleRef = useRef(null);
    const bgRef_1 = useRef(null);
    const bgRef_2 = useRef(null);

    const getLandRegisterStatusClassname = (state: LAND_REGISTER_ISSUE_STATE) => {
        switch (state) {
            case LAND_REGISTER_ISSUE_STATE.등록완료:
                return "load-1";
            case LAND_REGISTER_ISSUE_STATE.발급중:
                return "load-2";
            case LAND_REGISTER_ISSUE_STATE.완료:
                return "load-3";
            default:
                return "";
        }
    };

    const goToPdfViewer = () => {
        changeIsRead(id);
        navigate({
            pathname: "/landRegister/viewer",
            search: `?id=${id}&address=${address}`,
        });
    };

    const goToList = () => {
        navigate({
            pathname: "/landRegister/list",
        });
    };
    useEffect(() => {
        const bgEl_1 = bgRef_1.current as any;
        const bgEl_2 = bgRef_2.current as any;
        let imgIdx = 1;

        if (!bgEl_1) return;

        const bgAnimate = setInterval(() => {
            if (imgIdx++ % 2 === 1) {
                bgEl_1.classList.remove("active");
                bgEl_1.src = landRegisterBackgroundImageList[imgIdx];
                bgEl_2.classList.add("active");
            } else {
                bgEl_2.classList.remove("active");
                bgEl_2.src = landRegisterBackgroundImageList[imgIdx];
                bgEl_1.classList.add("active");
            }

            if (imgIdx == landRegisterBackgroundImageList.length - 1) imgIdx = 1;
        }, 5000);

        return () => clearInterval(bgAnimate);
    }, [bgRef_1.current]);

    useEffect(() => {
        const titleEl = titleRef.current as any;
        if (!titleEl) return;

        titleEl.classList.add("active");
        setTimeout(() => titleEl.classList.remove("active"), 1000);
    }, [titleRef.current]);

    return (
        <Container>
            <div className="background">
                <img ref={bgRef_1} className="active" src={landRegisterBackgroundImageList[0]} />
                <img ref={bgRef_2} src={landRegisterBackgroundImageList[1]} />
            </div>
            <div className="cover">
                <div onClick={goToList}>
                    <img src={ico_close_white} />
                </div>
            </div>
            <div className="contents">
                <img
                    className={getLandRegisterStatusClassname(issueStatus?.issueStatus as LAND_REGISTER_ISSUE_STATE)}
                    src={gif_loading_deungi}
                />
                <h2
                    ref={titleRef}
                    dangerouslySetInnerHTML={{
                        __html: getLandRegisterStatusHtml(issueStatus?.issueStatus as LAND_REGISTER_ISSUE_STATE),
                    }}
                ></h2>
                <span>
                    열람 준비는 20초 정도 소요돼요
                    <br />
                    완료되면 알림을 보내드릴게요
                </span>
            </div>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 100;
    animation: fadeIn 1s forwards;

    &.hidden {
        animation: fadeOut 1s forwards;
    }
    .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;

        & > div {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;

            & > img {
                width: 14px;
                height: 14px;
            }
        }
    }
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                z-index: 1;
                animation: scaleUp 5s forwards;
            }
        }
        @keyframes scaleUp {
            from {
                scale: 1;
                opacity: 1;
            }
            90% {
                scale: 1.1;
            }
            100% {
                opacity: 0;
            }
        }
    }
    .contents {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 150px;
        left: 0;
        width: 100%;
        z-index: 2;

        & > img {
            width: 100px;
            height: 100px;
            margin: 0 auto 60px;
            box-sizing: border-box;
            border: 3px solid rgba(0, 0, 0, 0);
            border-radius: 100px;
            transition: all 2s ease;

            &.load-1 {
                border-top-color: #37dcd0;
            }
            &.load-2 {
                border-color: #37dcd0 #37dcd0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
            }
            &.load-3 {
                border-color: #37dcd0 #37dcd0 #37dcd0 rgba(0, 0, 0, 0);
            }
            &.load-4 {
                border-color: #37dcd0;
            }
        }
        & > h2 {
            width: 100%;
            font-size: 34px;
            font-weight: 500;
            line-height: 1.4;
            color: #fff;
            margin-bottom: 15px;
            padding-left: 24px;
            text-align: left;

            &.active {
                animation: gelatine 1s;
            }
            & > span {
                color: #5b95ff;
                font-weight: 700;

                &.danger {
                    color: #f00;
                }
            }

            @keyframes gelatine {
                from,
                to {
                    transform: scale(1, 1);
                }
                25% {
                    transform: scale(0.9, 1.1);
                }
                50% {
                    transform: scale(1.1, 0.9);
                }
                75% {
                    transform: scale(0.95, 1.05);
                }
            }
        }
        & > span {
            width: 100%;
            font-size: 13px;
            font-weight: 400;
            padding-left: 24px;
            color: #fff;
            line-height: 1.6;
        }
    }
    & > input {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        padding: 8px 14px;
        width: 110px;
        height: 40px;
        background: #2275ee;
        color: #fff;
        border: none;
        border-radius: 24px;
        z-index: 2;
    }

    @keyframes fadeIn {
        from {
            scale: 1;
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

export default LandRegisterIssueStatusWatch;

export const getLandRegisterStatusText = (state: LAND_REGISTER_ISSUE_STATE) => {
    switch (state) {
        case LAND_REGISTER_ISSUE_STATE.등록완료:
            return "열람을 시작할게요";
        case LAND_REGISTER_ISSUE_STATE.발급중:
            return "발급 중이에요";
        case LAND_REGISTER_ISSUE_STATE.완료:
            return "열람 준비가 완료되었어요";
        case LAND_REGISTER_ISSUE_STATE.취소:
            return "열람이 취소되었습니다";
        case LAND_REGISTER_ISSUE_STATE.에러:
            return "열람이 실패하였습니다";
        case LAND_REGISTER_ISSUE_STATE.주소검색에러:
            return "열람이 실패하였습니다";
        default:
            return "";
    }
};

export const getLandRegisterStatusHtml = (state: LAND_REGISTER_ISSUE_STATE) => {
    switch (state) {
        case LAND_REGISTER_ISSUE_STATE.등록완료:
            return "토지•임야대장 열람을<br/>시작할게요";
        case LAND_REGISTER_ISSUE_STATE.발급중:
            return "토지•임야대장을<br/>발급 중이에요";
        case LAND_REGISTER_ISSUE_STATE.완료:
            return "토지•임야대장 열람 준비가<br/>완료되었어요";
        case LAND_REGISTER_ISSUE_STATE.취소:
            return "토지•임야대장 열람이<br/>취소되었습니다";
        case LAND_REGISTER_ISSUE_STATE.에러:
            return "토지•임야대장 열람이<br/>실패하였습니다";
        case LAND_REGISTER_ISSUE_STATE.주소검색에러:
            return "토지•임야대장 열람이<br/>실패하였습니다";
        default:
            return "";
    }
};
