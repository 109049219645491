import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";

import { useUserAiSettingSotre } from "@src/store/AiRecommendFilterStore";
import {
    initialUserAiSetting,
    useGetAIRecommendSetting,
    useSendAiRecommendPush,
    useUpdateUserAiSetting,
} from "@src/hooks/aiRecommend/useAiRecommend";
import useCallToken from "@src/hooks/useCallToken";
import ToastMessage from "@src/components/filter/ToastMessage";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import { useFindOneUserAiPushAgreement, useUpdateUserPushAgreement } from "@src/hooks/user/userApi";
import AiSortBox from "./AiSortBox";
import CheckPushModal from "./CheckPushModal";
import AiRecommendTitle from "./AiRecommendTitle";
import AiRecommendFirstStep from "./AiRecommendFirstStep";
import AiRecommendThirdStep from "./AiRecommendThirdStep";
import AiRecommendSecondStep from "./AiRecommendSecondStep";
import AiRecommendFourthStep from "./AiRecommendFourthStep";
import AiRecommendBottomSheet from "./AiRecommendBottomSheet";
import AiResetConfirmModal from "../aiFilter/AiResetConfirmModal";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { useSearchParams } from "react-router-dom";
import AiRecommendAuctionStep from "./AiRecommendAuctionStep";

interface Props {
    preStep?: number;
}

const AiRecommend = ({ preStep }: Props) => {
    const { token } = useCallToken();
    const {
        sheetRef,
        content,
        buttonWrapper,
        sort,
        isTouched,
        buttonRef1,
        buttonRef2,
        buttonRef3,
        onScrollDown,
        checkShow,
        onScrollUp,
    } = useAiBottomSheet(90);

    const userAiSettingFilterState = useUserAiSettingSotre((state) => state.userAiSettingState);
    const setUserAiSettingFilterState = useUserAiSettingSotre((state) => state.setUserAiSettingState);
    const resetUserAiSettingFilterState = useUserAiSettingSotre((state) => state.resetUserAiSettingState);
    const { data: pushAgreement, refetch: refetchPushAgreement } = useFindOneUserAiPushAgreement(true);
    const { data: userAiSetting, refetch: refetchUserAiSetting } = useGetAIRecommendSetting(true);

    const [flag, setFlag] = useState(false);
    const [step, setStep] = useState<number>(preStep ?? 1);
    const [price, setPrice] = useState<any>("");
    const [showSortBox, setShowSortBox] = useState<boolean>(false);
    const [showResetModal, setShowResetModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [checkPushModal, setCheckPushModal] = useState<boolean>(false);
    const [isExitModal, setIsExitModal] = useState<boolean>(false);
    const [showDisablePopup, setShowDisablePopup] = useState<boolean>(false);

    const filterList = [
        {
            title: "1",
            id: 1,
        },
        {
            title: "2",
            id: 2,
        },
        {
            title: "3",
            id: 3,
        },
    ];
    const [entranceModal, setEntranceModal] = useState(false);
    const [checkedFilter, setCheckedFilter] = useState(0);
    const [searchParams] = useSearchParams();
    const params = searchParams.get("isall");
    useEffect(() => {
        if (localStorage.getItem("AiRecommendFilter") !== "true") {
            setEntranceModal(true);
        } else return;
    }, []);
    //! here

    //!
    const [alarmList, setAlarmList] = useState<number[]>([14]);

    WebAppBridge.showPushSettingInfo = () => {
        updatePushAgreement.mutate([
            {
                type: `agree_push_recommend_item${checkedFilter == 1 ? "" : "_" + checkedFilter}`,
                agreement: !!pushAgreement.filter(
                    (data: any) =>
                        data.type ==
                        (checkedFilter == 1 ? "agree_push_recommend_item" : `agree_push_recommend_item_${checkedFilter}`),
                )[0]?.agreement,
                dueDate: [],
            },
        ]);
        setStep(4);
        onScrollUp();
    };

    const handleSubmit = (val?: any) => {
        setUserAiSettingFilterState({
            ...userAiSettingFilterState,
            investmentPrice: val ?? Number(price?.replaceAll(",", "")) * 10000,
        });
    };

    const sendAiPush = useSendAiRecommendPush();
    const updateUserAiSettingWithoutRefetch = useUpdateUserAiSetting();
    const updateUserAiSetting = useUpdateUserAiSetting(() => {
        refetchUserAiSetting();
    });
    const updatePushAgreement = useUpdateUserPushAgreement(() => {
        refetchPushAgreement();
    });
    const updatePushAgreementForCheckSystem = useUpdateUserPushAgreement(() => {
        transferToNative("", "checkPushSetting");
        refetchPushAgreement();
        setStep(5);
    });

    const onClickGoBack = () => {
        if (!userAiSettingFilterState.isFinish) {
            setIsExitModal(true);
        } else {
            transferToNative("done", "closeWebView");
        }
    };

    useEffect(() => {
        let checkPageShow = false;
        window.onpageshow = function () {
            checkPageShow = true;
        };
        window.onpagehide = function () {
            if ((window.performance.navigation.type == 0 || window.performance.navigation.type == 2) && checkPageShow) {
                if (!userAiSettingFilterState.isFinish) {
                    setIsExitModal(true);
                }
            }
        };
    }, []);

    const BottomSheetArea = useMemo(
        () => (
            <AiSortBox
                showSortBox={showSortBox}
                sortType={userAiSettingFilterState.sortType}
                onChangeSortType={(val: number) => {
                    setUserAiSettingFilterState({
                        ...userAiSettingFilterState,
                        sortType: val,
                    });
                    updateUserAiSetting.mutate({
                        ...userAiSettingFilterState,
                        sortType: val,
                    });
                    setShowSortBox(false);
                }}
                onCloseSortBox={() => setShowSortBox(false)}
            />
        ),
        [showSortBox, userAiSettingFilterState.sortType],
    );

    const handleBeforeUnload = () => {
        history.pushState(null, "", location.href);
        setIsExitModal(true);
    };

    useEffect(() => {
        if (userAiSetting?.list[findSettingIndex(checkedFilter)]?.isFinish == false) {
            history.pushState(null, "", location.href);
            window.addEventListener("popstate", handleBeforeUnload);
        }
        return () => {
            window.removeEventListener("popstate", handleBeforeUnload);
        };
    }, [checkedFilter]);
    useEffect(() => {
        if (token?.length) {
            setFlag(true);
            refetchUserAiSetting().then((res) => {
                if (res.isFetched) {
                    refetchPushAgreement();
                }
            });
        }
    }, [token]);

    useEffect(() => {
        return () => {
            setFlag(false);
        };
    }, []);

    const findSettingIndex = (order: number) => {
        return userAiSetting?.list.findIndex((item) => item.order === order) ?? 0;
    };

    useEffect(() => {
        if (params !== "1") {
            if (flag) {
                if (userAiSetting?.list.some((data) => data.isFinish === true)) {
                    const result = userAiSetting.list
                        .filter((data) => data.isFinish !== true)
                        .sort((a, b) => a.settingOrder - b.settingOrder);
                    if (result.length > 0) {
                        setCheckedFilter(result.slice(0, 1)[0].order);
                    } else {
                        const filterList = userAiSetting?.list.filter((data) => data.isFinish === true);
                        const filterListLastIndex = filterList[filterList.length - 1].order;
                        if (filterList.length == 1) {
                            switch (filterListLastIndex) {
                                case 1:
                                    setCheckedFilter(2);
                                    break;
                                case 2:
                                    setCheckedFilter(1);
                                    break;
                                case 3:
                                    setCheckedFilter(1);
                                    break;
                            }
                        } else if (filterList.length == 2) {
                            switch (filterListLastIndex) {
                                case 3:
                                    if (filterList.some((data) => data.order === 1)) {
                                        setCheckedFilter(2);
                                    } else setCheckedFilter(1);
                                    break;
                                case 2:
                                    if (filterList.some((data) => data.order === 3)) {
                                        setCheckedFilter(1);
                                    } else setCheckedFilter(3);
                            }
                        } else if (filterList.length === 3) {
                            setCheckedFilter(3);
                        }
                    }
                } else {
                    setUserAiSettingFilterState({
                        ...userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter,
                        settingOrder: 1,
                        isFinish: false,
                    });
                    setCheckedFilter(1);
                }
            }
        } else {
            if (flag) {
                if (userAiSetting?.list.some((data) => data.isFinish === true)) {
                    const result = userAiSetting.list
                        .filter((data) => data.isFinish === true)
                        .sort((a, b) => a.settingOrder - b.settingOrder);
                    if (result.length > 0) {
                        setCheckedFilter(result.slice(-1)[0].order);
                        setStep(4);
                        onScrollUp();
                    }
                } else return;
            } else return;
        }
    }, [flag]);

    useEffect(() => {
        refetchUserAiSetting();
        refetchPushAgreement();
        if (checkedFilter !== 0 && userAiSetting) {
            if (
                (userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter.saleMethod !== 2 &&
                    userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter.investmentPrice) ||
                userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter.saleMethod === 2
            ) {
                setUserAiSettingFilterState({
                    ...userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter,
                    settingOrder: checkedFilter,
                    isFinish: userAiSetting?.list[findSettingIndex(checkedFilter)]?.isFinish,
                });
                setStep(4);
            } else if (userAiSetting?.list[findSettingIndex(checkedFilter)] === undefined) {
                setUserAiSettingFilterState({
                    ...initialUserAiSetting,
                    settingOrder: checkedFilter,
                });
                setStep(1);
            } else {
                setUserAiSettingFilterState({
                    ...initialUserAiSetting,
                    settingOrder: checkedFilter,
                });
                setStep(1);
            }
        }
    }, [checkedFilter, userAiSetting]);

    useEffect(() => {
        setAlarmList([14]);
        if (params === "1" && userAiSetting?.list.some((data) => data.isFinish === true)) {
            const result = userAiSetting.list
                .filter((data) => data.isFinish === true)
                .sort((a, b) => a.settingOrder - b.settingOrder);
            if (result.length > 0 && checkedFilter !== result.slice(-1)[0].order) {
                onScrollDown();
            }
        } else {
            onScrollDown();
        }
    }, [checkedFilter]);

    useEffect(() => {
        if (userAiSettingFilterState?.investmentPrice) {
            if (userAiSettingFilterState.investmentPrice / 10000 != price?.replaceAll(",", "")) {
                setPrice((userAiSettingFilterState.investmentPrice / 10000)?.toLocaleString());
                return;
            }
        }
    }, [userAiSettingFilterState.investmentPrice]);

    useEffect(() => {
        if (userAiSetting === null && pushAgreement && pushAgreement.agreement) {
            const newPushAgreement = { ...pushAgreement };
            newPushAgreement.agreement = false;
            updatePushAgreement.mutate([newPushAgreement]);
        }
    }, [pushAgreement]);

    const onChangeValueWithRefetch = (key: string, val: any) => {
        setUserAiSettingFilterState({
            ...userAiSettingFilterState,
            [key]: val,
        });
        if (userAiSettingFilterState.isFinish === true && userAiSetting) {
            if (key === "categories" && val !== userAiSetting?.list[findSettingIndex(checkedFilter)].itemFilter.categories) {
                updateUserAiSetting.mutate({
                    ...userAiSettingFilterState,
                    isFinish: false,
                    [key]: val,
                });
                updatePushAgreement.mutate([
                    {
                        type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                        agreement: false,
                        dueDate: [],
                    },
                ]);
            }
        } else {
            updateUserAiSetting.mutate({
                ...userAiSettingFilterState,
                [key]: val,
            });
        }
    };

    const onChangeValue = (key: string, val: any) => {
        setUserAiSettingFilterState({
            ...userAiSettingFilterState,
            [key]: val,
        });
    };

    return (
        <>
            {isExitModal && (
                <ConfirmModal
                    resultMessage={
                        <p style={{ lineHeight: "26px" }}>
                            {"지금 페이지를 벗어나면 AI추천 매물의\n 입찰일 알림을 받을 수 없어요.\n 그래도 나가시겠어요?"}
                        </p>
                    }
                    cancelText="나가기"
                    buttonText="계속하기"
                    onConfirm={() => {
                        setIsExitModal(false);
                        window.removeEventListener("popstate", handleBeforeUnload);
                    }}
                    onCancel={() => {
                        transferToNative("done", "closeWebView");
                    }}
                />
            )}
            <div css={rootStyle}>
                <div css={rootBoxStyle}>
                    <AiRecommendTitle onClickBack={onClickGoBack} />
                    {entranceModal && (
                        <ConfirmModal
                            resultMessage={
                                <p style={{ lineHeight: "26px" }}>
                                    {"AI추천 경공매 입찰일 알림은\n최대 3개까지 설정할 수 있어요"}
                                </p>
                            }
                            onConfirm={() => {
                                localStorage.setItem("AiRecommendFilter", "true");
                                setEntranceModal(false);
                            }}
                        />
                    )}
                    <div css={disabledpopup}>알림 설정까지 완료하면 입찰일 알림을 받을 수 있어요</div>
                    <div css={filterListContainer}>
                        {filterList.map((ele) => {
                            return (
                                <button
                                    css={settingList(
                                        userAiSetting?.list[findSettingIndex(ele.id)]?.isFinish ||
                                            (userAiSettingFilterState.isFinish === true &&
                                                userAiSettingFilterState.settingOrder === ele.id),
                                        checkedFilter == ele.id,
                                    )}
                                    onClick={() => {
                                        setCheckedFilter(ele.id);
                                    }}
                                    id={`filterButton${ele.id}`}
                                    key={ele.id}
                                >
                                    AI 설정{ele.title}{" "}
                                    {userAiSetting?.list[findSettingIndex(ele.id)]?.isFinish ||
                                    (userAiSettingFilterState.isFinish === true &&
                                        userAiSettingFilterState.settingOrder === ele.id)
                                        ? "완료"
                                        : "추가"}
                                </button>
                            );
                        })}
                    </div>
                    {step === 1 && (
                        <AiRecommendAuctionStep
                            bidType={userAiSettingFilterState.bidType}
                            onChangeBidTye={onChangeValue}
                            onSubmitShowToast={() => setShowToast(true)}
                        />
                    )}
                    {step === 2 && checkedFilter !== 0 && (
                        <AiRecommendFirstStep
                            price={userAiSettingFilterState.investmentPrice}
                            onChangePrice={onChangeValue}
                            handleSubmit={handleSubmit}
                        />
                    )}
                    {step === 3 && checkedFilter !== 0 && (
                        <AiRecommendSecondStep
                            locationList={userAiSettingFilterState.regions}
                            onChangeLocation={(val: string[]) =>
                                setUserAiSettingFilterState({ ...userAiSettingFilterState, regions: val })
                            }
                            onSubmitShowToast={() => setShowToast(true)}
                        />
                    )}
                    {step === 4 && checkedFilter !== 0 && (
                        <AiRecommendThirdStep
                            categories={userAiSettingFilterState.categories}
                            onChangeCategories={onChangeValueWithRefetch}
                            showToast={() => setShowToast(true)}
                        />
                    )}
                    {step === 5 && (
                        <AiRecommendFourthStep
                            checkedFilter={checkedFilter}
                            alarmList={alarmList}
                            setAlarmList={setAlarmList}
                            updatePushAgreement={updatePushAgreement}
                            pushAgreement={pushAgreement}
                        />
                    )}
                    <div style={{ padding: "0px 14px" }}>
                        <AiRecommendBottomSheet
                            checkedFilter={checkedFilter}
                            sheetRef={sheetRef}
                            setPrice={setPrice}
                            price={price}
                            content={content}
                            buttonWrapper={buttonWrapper}
                            sort={sort}
                            isTouched={isTouched}
                            buttonRef1={buttonRef1}
                            buttonRef2={buttonRef2}
                            buttonRef3={buttonRef3}
                            checkShow={checkShow}
                            onScrollUp={onScrollUp}
                            showSortBox={showSortBox}
                            onChangeShowSortBox={() => setShowSortBox((pre) => !pre)}
                            step={step}
                            flag={flag}
                            setStep={setStep}
                            onClickReset={() => setShowResetModal(true)}
                            handleOpenCheckPushModal={() => setCheckPushModal(true)}
                            pushAgreement={pushAgreement}
                            sendAiPush={sendAiPush}
                        />
                    </div>
                </div>
                {BottomSheetArea}
            </div>
            {showResetModal && (
                <AiResetConfirmModal
                    text="필터를 초기화할까요?"
                    onClickCancel={() => setShowResetModal(false)}
                    onClickConfirm={() => {
                        setUserAiSettingFilterState({
                            ...initialUserAiSetting,
                        });
                        updateUserAiSetting.mutate({
                            ...initialUserAiSetting,
                            settingOrder: checkedFilter,
                        });
                        updatePushAgreement.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                agreement: false,
                                dueDate: [],
                            },
                        ]);
                        setShowResetModal(false);
                        setStep(1);
                    }}
                />
            )}
            {checkPushModal && (
                <CheckPushModal
                    onCloseView={() => {
                        onScrollUp();
                        setCheckPushModal(false);
                        updateUserAiSettingWithoutRefetch.mutate({
                            ...userAiSettingFilterState,
                            isFinish: true,
                        });
                        setUserAiSettingFilterState({
                            ...userAiSettingFilterState,
                            isFinish: true,
                        });
                    }}
                    onClickConfirm={() => {
                        setCheckPushModal(false);
                        setStep((prev) => prev + 1);

                        updatePushAgreementForCheckSystem.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter == 1 ? "" : "_" + checkedFilter}`,
                                agreement: true,
                                dueDate: [14],
                            },
                        ]);
                        updateUserAiSettingWithoutRefetch.mutate({
                            ...userAiSettingFilterState,
                            isFinish: true,
                        });
                        setUserAiSettingFilterState({
                            ...userAiSettingFilterState,
                            isFinish: true,
                        });
                    }}
                />
            )}
            <ToastMessage visible={showToast} setToastVisible={setShowToast}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
        </>
    );
};

export default AiRecommend;

const disabledpopup = css`
    background-color: #fff500;
    font-size: 13px;
    width: fit-content;
    border-radius: 4px;
    padding: 6px 6px;
    color: #0c002c;
    margin-bottom: 8px;
    margin-left: 14px;
    position: absolute;
    top: 42px;
`;

const filterListContainer = css`
    display: grid;
    width: 100%;
    padding: 0px 14px;
    font-size: 14px;
    gap: 4%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 25px;
`;

const settingList = (finish: boolean, checked: boolean) => css`
    padding: 6px 2px;
    text-align: center;
    font-size: 14px;
    border-radius: 50px;
    border: ${(finish && checked) || checked ? "1px solid #0047ff" : finish ? "1px solid #c5ffe9" : "1px solid #dadada"};
    color: ${(finish && checked) || checked ? "#0037ff" : finish ? "#0047ff" : "#dadada"};
    background-color: ${finish && checked ? "#c5ffe9" : finish ? "#c5ffe9" : ""};
`;

const rootBoxStyle = css`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .lottie {
        width: 100%;
        margin-top: 20px;
    }
`;

const rootStyle = css`
    overflow: hidden;
    height: 100%;
`;

// const PriceToKorean = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     padding-right: 14px;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 24px;
//     margin-top: 8px;
//     color: ${Common.colors.navyPrimary};
// `

const inputBoxStyle = (checkInput: boolean) => css`
    position: relative;
    margin-top: 40px;
    input {
        width: 100%;
        background: ${checkInput ? "#FDFAFA" : "#FFFFFF"};
        padding: 14px 60px 14px 14px;
        border: ${checkInput ? "1px solid #0C002C" : "1px solid #B1AEB8"};
        border-radius: 14px;
        color: ${Common.colors.normalTextColor};
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: right;
    }
    input:focus {
        outline: none;
    }

    span {
        position: absolute;
        right: 14px;
        font-weight: 600;
        top: calc(50% - 8px);
        font-size: 18px;
    }
`;
