import React from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { mq } from "../../util/media";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useTicketCount } from "@src/hooks/deungi/useTicketCount";
import { TICKET_TYPE } from "@src/components/deungi/common/enum";

interface Props {
    address?: string;
    size?: "small" | "large";
}

const DeungiOpenButton: React.FC<Props> = ({ address, size = "small" }) => {
    const navigate = useNavigate();
    const { self, small, large, liveButton, boldText } = DeungiOpenButtonCss;

    const onClick = async () => {
        await useTicketCount().then((res) => {
            const data = res.data;
            navigate(`/deungi?type=search&searchText=${address}`);
        });
    };

    return (
        <button onClick={onClick} css={[self, size === "small" ? small : large]}>
            <div css={liveButton}>
                실시간<span>Live</span>
            </div>
            <span css={boldText}>부동산등기</span> 열람
        </button>
    );
};

export default DeungiOpenButton;

const DeungiOpenButtonCss = {
    self: css({
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        background: "linear-gradient(220deg, #1672ff 6.5%, #0030ff 76%, #0042ff 100%, #001eaa 100%)",
        borderRadius: "4px",
        color: "#fff",

        gap: "4px",
        padding: "8px 14px",

        fontWeight: "400",
    }),

    small: css(
        mq({
            width: "60%",
            fontSize: ["16px", "10px", "14px"],
            lineHeight: "26px",

            div: {
                fontSize: ["14px", "8px", "12px"],
                lineHeight: "24px",
                whiteSpace: "nowrap",
            },
        }),
    ),

    large: css({
        width: "100%",
        fontSize: "18px",
        lineHeight: "28px",

        div: {
            fontSize: "16px",
            lineHeight: "26px",
        },
    }),

    liveButton: css({
        backgroundColor: "#f6494b",
        borderRadius: "100px",
        padding: "0 8px",

        span: {
            fontWeight: 700,
        },
    }),

    boldText: css({
        fontWeight: 700,
    }),
};
