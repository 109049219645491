import * as turf from "@turf/turf";
import proj4 from "proj4";

// 중심 좌표 계산
export const getCenter = (coords: [number, number][]) => {
    const polygon = turf.polygon([coords]);
    const center = turf.centerOfMass(polygon);
    return center.geometry.coordinates;
};

// 축소 비율
export const SCALE_FACTOR = 4;

// 상대 좌표 계산
export const getRelativeCoords = (coords: [number, number][], center: number[]): [number, number][] => {
    return coords.map(([lng, lat]) => [(lng - center[0]) / SCALE_FACTOR, (lat - center[1]) / SCALE_FACTOR]);
};

// 좌표계 변환
export const lngLatToXY = (coords: [number, number]): [number, number] => {
    const wgs84 = "EPSG:4326";
    const webMercator = "EPSG:3857";
    const [x, y] = proj4(wgs84, webMercator, coords);

    return [x, y];
};

// x, y 절댓값 좌표의 최대값
export const getMaxAbsCoord = (coords: [number, number][]) => {
    let maxX = 0;
    let maxY = 0;

    coords.forEach(([x, y]) => {
        maxX = Math.max(maxX, Math.abs(x));
        maxY = Math.max(maxY, Math.abs(y));
    });

    return [maxX, maxY];
};

// 고층 빌딩 기준: 80m
export const SKYSCRAPER_HEIGHT = 80 / SCALE_FACTOR;
