import { axiosInstance } from "@src/api/axiosConfig";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { LAND_REGISTER_PATH } from "./axoisPath";
import { LAND_REGISTER_ISSUE_STATE } from "@src/store/landReigster";
import axios from "axios";

export type SearchAddressItemDto = {
    addressDong: string;
    addressName: string;
    lng: string;
    lat: string;
    pnu: string;
};

export type IssueHistoryItemDto = {
    _id: string;
    address: string;
    issuedAt: string; // "2025-01-17T00:19:05.319Z"
    isNew: boolean;
    fileUrl: string;
    status: LAND_REGISTER_ISSUE_STATE;
};

export type FindIssueHistoryResponseDto = {
    totalCount: number;
    cursor?: string;
    list: IssueHistoryItemDto[];
};

export type IssueLandRegisterRequestDto = {
    address?: string;
    dong?: string;
    beonji?: string;
    ho?: string;
    pnu: string;
};

export type IssueLandRegisterResponseDto = {
    address: string;
    _id: string;
};

export type FindLandRegisterStatusResponseDto = {
    id: string;
    issueStatus: LAND_REGISTER_ISSUE_STATE;
};

interface LandRegisterErrorResponseDto {
    statusCode: number;
    message: string;
    error: string;
}

const baseKey = ["landRegister"];

export const landRegisterQueryKeys = {
    default: baseKey,
    searchAddress: (searchText: string) => [...baseKey, "searchAddress", searchText],
    issueHistory: [...baseKey, "issueHistory"],
    issueStatus: (landRegisterId: string) => [...baseKey, "status", landRegisterId],
    pdfUrl: (landRegisterId: string) => [...baseKey, "pdf", landRegisterId],
};

const searchAddress = (searchText: string, limit: number, page: number) =>
    axiosInstance
        .get<SearchAddressItemDto[]>(LAND_REGISTER_PATH.searchAddress, { params: { searchText, limit, page } })
        .then((data) => data.data);

export const useLandRegisterSearchAddress = (searchText: string, enabled: boolean) => {
    const LIMIT = 20;
    return useInfiniteQuery({
        queryKey: landRegisterQueryKeys.searchAddress(searchText),
        queryFn: ({ pageParam = 1 }) => searchAddress(searchText, LIMIT, pageParam),
        getNextPageParam: (lastPage, pages) => {
            return pages.length + 1;
        },
        enabled,
    });
};

const getIssueHistory = ({ limit, cursor }: { limit?: number; cursor: string }) =>
    axiosInstance
        .get<FindIssueHistoryResponseDto>(LAND_REGISTER_PATH.issueHistory, { params: { limit, cursor } })
        .then((data) => data.data);

export const useLandRegisterIssueHistory = () => {
    const LIMIT = 20;
    return useInfiniteQuery({
        queryKey: landRegisterQueryKeys.issueHistory,
        queryFn: ({ pageParam }) => getIssueHistory({ limit: LIMIT, cursor: pageParam }),
        getNextPageParam: (lastPage, pages: any) => {
            return pages[0]?.totalCount > pages?.length * LIMIT ? lastPage.cursor : undefined;
        },
    });
};

const postIssue = (issueRequestBody: IssueLandRegisterRequestDto) => {
    return axiosInstance
        .post<IssueLandRegisterResponseDto>(LAND_REGISTER_PATH.issue, issueRequestBody)
        .then((data) => data.data)
        .catch((error) => {
            if (axios.isAxiosError(error)) {
                throw error.response?.data as LandRegisterErrorResponseDto;
            }
            throw error;
        });
};

export const useIssueLandRegister = () => {
    return useMutation<IssueLandRegisterResponseDto, LandRegisterErrorResponseDto, IssueLandRegisterRequestDto>({
        mutationFn: (selectedLand: IssueLandRegisterRequestDto) => postIssue(selectedLand),
    });
};

export const changeIsRead = (landRegisterId: string) => axiosInstance.put(LAND_REGISTER_PATH.changeIsRead(landRegisterId));

export const useLandRegisterChangeIsRead = () => {
    return useMutation({ mutationFn: (landRegisterId: string) => changeIsRead(landRegisterId) });
};

const getIssueStatus = (landRegisterId: string) =>
    axiosInstance
        .get<FindLandRegisterStatusResponseDto>(LAND_REGISTER_PATH.getIssueStatus(landRegisterId))
        .then((data) => data.data);

export const useLandRegisterIssueStatus = (landRegisterId: string) => {
    return useQuery({
        queryKey: landRegisterQueryKeys.issueStatus(landRegisterId),
        queryFn: () => getIssueStatus(landRegisterId),
    });
};

const reissue = (landRegisterId: string) =>
    axiosInstance.put<IssueLandRegisterResponseDto>(LAND_REGISTER_PATH.reissue(landRegisterId)).then((data) => data.data);

export const useLandRegisterReissue = () => {
    return useMutation({ mutationFn: (landRegisterId: string) => reissue(landRegisterId) });
};

const getPdfUrl = (landRegisterId: string) =>
    axiosInstance.get<{ pdfUrl: string }>(LAND_REGISTER_PATH.getPdfUrl(landRegisterId)).then((data) => data.data);

export const useLandRegisterPdfUrl = (landRegisterId: string, enabled = false) => {
    return useQuery({
        queryKey: landRegisterQueryKeys.pdfUrl(landRegisterId),
        queryFn: () => getPdfUrl(landRegisterId),
        enabled,
        select: (data) => data.pdfUrl,
    });
};
