import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { createJWTToken } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";

const ENV_URL: any = process.env.REACT_APP_REGION_API;

export interface IRegion {
    centerLat: number;
    centerLon: number;
    cortarName: string;
    cortarNo: string;
    cortarType: string;
}

export interface IRegionParent {
    cortarNo: string;
    parentNo: string;
    centerLat: number;
    centerLon: number;
    cortarName: string;
    parentName: string;
    cortarType: string;
}

export const useFindLocation = async (param: string) => {
    const response = await axiosAsync
        .get(`${ENV_URL}/regions/list?cortarNo=${param}`)
        .then((res) => {
            return res?.data?.regionList;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};

export const getLocationCortar = async (param: string) => {
    const tokenValue = localStorage.getItem("accessToken");
    const response = await fetch(`${ENV_URL}/regions/list?cortarNo=${param}`, {
        method: "get",
        headers: { "Content-Type": "application/json", Authorization: createJWTToken(tokenValue) },
        cache: "force-cache",
        keepalive: true,
    });
    const newData = await response.json();
    return newData?.regionList;
};

export const useGetRegionList = (cortar: string) => {
    const tokenValue = localStorage.getItem("accessToken");
    const getRegions = () =>
        fetch(`${axiosPath.regionsPath}?cortarNo=${cortar}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: createJWTToken(tokenValue),
            },
            cache: "no-cache",
            keepalive: true,
        }).then((data) => data.json());

    return useQuery<{ regionList: IRegion[] }, unknown, IRegion[]>({
        queryKey: ["regions", cortar],
        queryFn: getRegions,
        select: (data) => data?.regionList,
    });
};

//* 특정 지역 정보 가져오기
export const useFindOneLocation = async (param: string) => {
    const tokenValue = localStorage.getItem("accessToken");
    const response = await fetch(`${axiosPath.regionsPath}?cortarNo=${param}`, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: createJWTToken(tokenValue),
        },
        cache: "no-cache",
        keepalive: true,
    });
    const newData = await response.json();
    const result: {
        region: IRegion;
        parent: IRegionParent;
    } = newData;
    return result;
};
