import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React, { useEffect } from "react";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { defaultActiveStatus, statusListByAuctionType } from "./AuctionStatusFilter";
import { IFilterParamsType } from "@src/store/newFilterStore";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const typeList = ["전체", "경매", "공매"];
export const saleMethodList = ["전체", "매각", "임대(대부)"];
export const assetList = ["전체", "압류재산", "국공유재산", "신탁재산", "기타재산"];

const AuctionTypeFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    return (
        <div css={rootStyle}>
            {!allFilter && (
                <div css={titleBoxStyle(!filterParams.sales_method)}>
                    <p className="titleText">경공매</p>
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                </div>
            )}
            <div css={rootBoxStyle}>
                <div css={typeButtonBoxStyle}>
                    {typeList.map((auctionType, idx) => (
                        <button
                            key={auctionType}
                            css={typeButtonStyle(filterParams.type === idx)}
                            onClick={() => {
                                setFilterParams({
                                    ...filterParams,
                                    status: defaultActiveStatus[idx],
                                    type: idx,
                                    sales_method: idx !== 2 ? 0 : filterParams.sales_method,
                                    assets: idx !== 2 ? [] : filterParams.assets,
                                });
                            }}
                        >
                            {auctionType}
                        </button>
                    ))}
                </div>
            </div>
            {filterParams.type === 2 && (
                <>
                    <div>
                        <div css={titleBoxStyle(!filterParams.sales_method)}>
                            <p className="titleText">처분방식</p>
                            <button className="allButton" onClick={() => setFilterParams({ ...filterParams, sales_method: 0 })}>
                                전체
                            </button>
                        </div>
                        <div css={selectButtonBoxStyle}>
                            {saleMethodList.map(
                                (sale, idx) =>
                                    !!idx && (
                                        <button
                                            key={sale}
                                            css={selectButtonStyle(idx === filterParams.sales_method)}
                                            onClick={() => setFilterParams({ ...filterParams, sales_method: idx })}
                                        >
                                            {sale}
                                        </button>
                                    ),
                            )}
                        </div>
                    </div>
                    <div css={lineStyle} />
                    <div>
                        <div css={titleBoxStyle(filterParams.assets.some((val) => !val) || !filterParams.assets.length)}>
                            <p className="titleText">자산구분</p>
                            <button className="allButton" onClick={() => setFilterParams({ ...filterParams, assets: [] })}>
                                전체
                            </button>
                        </div>
                        <div css={selectButtonBoxStyle}>
                            {assetList.map(
                                (asset, idx) =>
                                    !!idx && (
                                        <button
                                            key={asset}
                                            css={selectButtonStyle(filterParams.assets?.some((val) => val === idx))}
                                            onClick={() => {
                                                const checkInclude = filterParams.assets?.some((val) => val === idx);
                                                if (checkInclude) {
                                                    const newAssets = [...filterParams.assets].filter((val) => val !== idx);
                                                    setFilterParams({ ...filterParams, assets: newAssets });
                                                } else {
                                                    filterParams.assets.length === 3
                                                        ? setFilterParams({ ...filterParams, assets: [] })
                                                        : setFilterParams({
                                                              ...filterParams,
                                                              assets: [...filterParams.assets, idx].sort(),
                                                          });
                                                }
                                            }}
                                        >
                                            {asset}
                                        </button>
                                    ),
                            )}
                        </div>
                    </div>
                    <div css={lineStyle} />
                </>
            )}
        </div>
    );
};

export default AuctionTypeFilter;

const rootStyle = css`
    background-color: #ffffff;
`;

const rootBoxStyle = css`
    width: 100%;
    padding: 0 20px;
    display: flex;
    background-color: #ffffff;
`;

const typeButtonBoxStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 6px;
    margin-bottom: 16px;
`;

const typeButtonStyle = (active: boolean) => css`
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding: 8px;
    width: 33.3%;
`;

const expectedAuctionDescStyle = css`
    width: 100%;
    padding: 14px;
    .text {
        height: 38px;
        border-radius: 24px;
        background-color: ${Common.colors.blue01};
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Common.colors.normalTextColor};
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
    }
    span {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #fdfafa;
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;

const lineStyle = css`
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
`;
