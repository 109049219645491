import { css } from "@emotion/react";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { useFilterStore } from "@src/store/filterStore";
import React, { useEffect, useState } from "react";
import BottomSheetHeader from "./BottomSheetHeader";
import Content from "./Content";
import { useLocation, useNavigate } from "react-router-dom";

const initialContents = [
    { buttonText: "최신순", id: 0, isFocus: false },
    { buttonText: "높은순", id: 1, isFocus: false },
    { buttonText: "낮은순", id: 2, isFocus: false },
    { buttonText: "높은순", id: 3, isFocus: false },
    { buttonText: "낮은순", id: 4, isFocus: false },
    { buttonText: "많은순", id: 7, isFocus: false },
    { buttonText: "적은순", id: 8, isFocus: false },
    { buttonText: "먼순", id: 6, isFocus: false },
    { buttonText: "가까운순", id: 5, isFocus: false },
    { buttonText: "오래된순", id: 21, isFocus: false },
];

const checkedInitialContents = [
    { buttonText: "최신순", id: 0, isFocus: false },
    { buttonText: "높은순", id: 1, isFocus: false },
    { buttonText: "낮은순", id: 2, isFocus: false },
    { buttonText: "높은순", id: 3, isFocus: false },
    { buttonText: "낮은순", id: 4, isFocus: false },
    { buttonText: "많은순", id: 7, isFocus: false },
    { buttonText: "적은순", id: 8, isFocus: false },
    { buttonText: "먼순", id: 6, isFocus: false },
    { buttonText: "가까운순", id: 5, isFocus: true },
    { buttonText: "오래된순", id: 21, isFocus: false },
];

type BottomSheetContentsPropsTypes = {
    setSelectedSortId: (selected: number | undefined) => void;
    setSortStatus: (selectedSort: string | undefined) => void;
    type: string;
    currentValue?: string;
    currentValueId?: number;
};

const BottomSheetContents = ({
    setSelectedSortId,
    setSortStatus,
    type,
    currentValue,
    currentValueId,
}: BottomSheetContentsPropsTypes) => {
    const [contents, setContents] = useState(initialContents);
    const navigate = useNavigate();
    const location = useLocation();

    const sortId = useAiFilterStore((state) => state.selectedSortId); //
    const setSortId = useAiFilterStore((state) => state.setSortId); //
    const setSelectedSortText = useAiFilterStore((state) => state.setSelectedSortText);
    const sortText = useAiFilterStore((state) => state.selectedSortText);
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);

    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const filterState = useFilterStore((state) => state.filterState);

    const handleSelected = (id: number | undefined, buttonText: string | undefined) => {
        if (type === "Ai") {
            if (id === sortId) {
                setSelectedSortId(5);
                setSortStatus("매각기일 가까운순");
                setSelectedSortText("매각기일 가까운순");
                setContents(checkedInitialContents);
                setSortId(5);
            } else {
                const selectButton = contents.map((item) => {
                    return { ...item, isFocus: item.id === id };
                });
                setAiFilterState(aiFilterState, id);
                setSelectedSortId(id);
                setSortStatus(buttonText);
                setSelectedSortText(buttonText);
                setContents(selectButton);
                setSortId(id);
            }
        } else {
            if (id == filterState.sort) {
                setSelectedSortId(5);
                setSortStatus("매각기일 가까운순");
                setSelectedSortText("매각기일 가까운순");
                setContents(checkedInitialContents);
                setStoreFilterState(
                    {
                        ...filterState,
                        sort: 5,
                    },
                    true,
                );
            } else {
                navigate(`${location.pathname}/?sort=${id}`);
                const selectButton = contents.map((item) => {
                    return { ...item, isFocus: item.id === id };
                });
                setSelectedSortId(id);
                if (buttonText === "업데이트 최신순") {
                    setSortStatus("업데이트순");
                } else {
                    setSortStatus(buttonText);
                }
                setContents(selectButton);
                setStoreFilterState(
                    {
                        ...filterState,
                        sort: id ? id : 5,
                    },
                    true,
                );
            }
        }
    };

    useEffect(() => {
        if (type === "Ai") {
            handleSelected(sortId, sortText);
        } else if (type === "list" && currentValue) {
            const currentSelected = initialContents.filter((q) => q.id === (Number(currentValue) ?? 5));
            const newText =
                currentSelected[0]?.id === 0
                    ? "업데이트순"
                    : currentSelected[0]?.id === 1
                    ? "감정가 높은순"
                    : currentSelected[0]?.id === 2
                    ? "감정가 낮은순"
                    : currentSelected[0]?.id === 3
                    ? "최저가 높은순"
                    : currentSelected[0]?.id === 4
                    ? "최저가 낮은순"
                    : currentSelected[0]?.id === 5
                    ? "매각기일 가까운순"
                    : currentSelected[0]?.id === 7
                    ? "유찰회수 많은순"
                    : currentSelected[0]?.id === 8
                    ? "유찰회수 적은순"
                    : "매각기일 가까운순";
            setSelectedSortId(currentSelected[0]?.id ?? 5);
            setSortStatus(newText);
            setSelectedSortText(newText);
            const newSelectButton = contents.map((item) => {
                return { ...item, isFocus: item.id === currentSelected[0]?.id };
            });
            setContents(newSelectButton);
        } else {
            handleSelected(5, "매각기일 가까운순");
        }
    }, []);

    useEffect(() => {
        if (type !== "list") {
            const selectButton = contents.map((item) => {
                return { ...item, isFocus: item.id === sortId };
            });
            setContents(selectButton);
        }
    }, [sortId]);

    if (type === "Ai") {
        return (
            <div css={Container}>
                <BottomSheetHeader />
                <div css={contentDiv}>
                    <Content title="매각기일" onSelect={handleSelected} button1={contents[8]} button2={contents[7]} />
                    <Content title="업데이트" onSelect={handleSelected} button1={contents[0]} button2={contents[9]} />
                    <Content title="감정가" onSelect={handleSelected} button1={contents[1]} button2={contents[2]} />
                    <Content title="최저가" onSelect={handleSelected} button1={contents[3]} button2={contents[4]} />
                    <Content title="유찰횟수" onSelect={handleSelected} button1={contents[5]} button2={contents[6]} />
                </div>
            </div>
        );
    }

    return (
        <div css={Container}>
            <BottomSheetHeader />
            <div css={contentDiv}>
                <Content title="매각기일" onSelect={handleSelected} button1={contents[8]} />
                <Content title="업데이트" onSelect={handleSelected} button1={contents[0]} />
                <Content title="감정가" onSelect={handleSelected} button1={contents[1]} button2={contents[2]} />
                <Content title="최저가" onSelect={handleSelected} button1={contents[3]} button2={contents[4]} />
                <Content title="유찰횟수" onSelect={handleSelected} button1={contents[5]} button2={contents[6]} />
            </div>
        </div>
    );
};

export default BottomSheetContents;

const Container = css`
    width: 100%;
    padding: 14px 14px 0px 14px;
`;

const contentDiv = css`
    overflow-y: scroll;
    height: 90%;
`;

const TitleWrapper = css`
    display: flex;
    align-items: center;
    height: 48px;
    margin-bottom: 8px;

    h3 {
        font-size: 18px;
    }
`;
