import img1 from "@src/assets/landRegister/land_register_1.png";
import img2 from "@src/assets/landRegister/land_register_2.png";
import img3 from "@src/assets/landRegister/land_register_3.png";
import img4 from "@src/assets/landRegister/land_register_4.png";
import img5 from "@src/assets/landRegister/land_register_5.png";
import img6 from "@src/assets/landRegister/land_register_6.png";
import img7 from "@src/assets/landRegister/land_register_7.png";
import img8 from "@src/assets/landRegister/land_register_8.png";
import img9 from "@src/assets/landRegister/land_register_9.png";
import img10 from "@src/assets/landRegister/land_register_10.png";

const landRegisterBackgroundImages = [img1, img2, img3,img4,img5, img6,img7, img8, img9, img10]

export default landRegisterBackgroundImages