import dayjs from "dayjs";
import { DEUNGI_OPEN_STATE } from "./enum";

export const checkDevice = () => {
    const ua = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if (ua.indexOf("android") > -1) return "android";
    else if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1 || ua.indexOf("ipod") > -1) return "ios";
    else return "other";
};

export const getDeungiOpenState = (state: DEUNGI_OPEN_STATE, type: "watch" | "list", createAt?: Date) => {
    switch (state) {
        case DEUNGI_OPEN_STATE.WAITING:
        case DEUNGI_OPEN_STATE.REOPEN:
            if (type === "watch") return "등기열람을<br/><span>시작</span>할게요";
            else return "등기열람을 시작할게요";
        case DEUNGI_OPEN_STATE.REGISTER:
            if (type === "watch") return "대기열에 등기열람을<br/><span>등록</span>하고 있어요";
            else return "대기열에 등기열람을 등록하고 있어요";
        case DEUNGI_OPEN_STATE.PAYMENT_ING:
        case DEUNGI_OPEN_STATE.PAID:
            if (type === "watch") return "인터넷 등기소에서<br/><span>결제진행</span>하고 있어요";
            else return "인터넷 등기소에서 결제진행하고 있어요";
        case DEUNGI_OPEN_STATE.UPLOAD_ING:
            if (type === "watch") return "등기를<br/><span>업로드 중</span>이에요";
            else return "등기를 업로드 중이에요";
        case DEUNGI_OPEN_STATE.UPLOADED:
            if (type === "watch") return "등기열람 준비가<br/><span>완료</span>되었습니다";
            else return "등기 열람 준비가 완료되었습니다";
        case DEUNGI_OPEN_STATE.PAYMENT_FAIL:
            if (type === "watch")
                return '등기열람이<br/><span class="danger">실패</span>되었습니다<br/>다시 시도해주시기<br/>바랍니다';
            else return "등기열람이 실패되었습니다<br/>다시 시도해주시기 바랍니다";
        case DEUNGI_OPEN_STATE.UPLOAD_FAIL:
        case DEUNGI_OPEN_STATE.REOPEN_FAIL:
            if (type === "watch")
                return '등기열람이<br/><span class="danger">실패</span>되었습니다<br/>고객센터로<br/><span>문의</span>주시기 바랍니다';
            else return "등기열람이 실패되었습니다<br/>고객센터로 문의주시기 바랍니다";
        case DEUNGI_OPEN_STATE.UPLOAD_CANCEL:
            if (type === "watch") return "회원님의 요청으로<br/>취소된 등기열람입니다";
            else return "회원님의 요청으로 취소된 등기열람입니다";
        default:
            return "";
    }
};

export const validSearchText = (searchText: string) => {
    const sidoArr = [
        "서울특별시",
        "서울시",
        "서울",
        "광주광역시",
        "광주시",
        "광주",
        "대구광역시",
        "대구시",
        "대구",
        "대전광역시",
        "대전시",
        "대전",
        "부산광역시",
        "부산시",
        "부산",
        "울산광역시",
        "울산시",
        "울산",
        "인천광역시",
        "인천시",
        "인천",
        "세종특별자치시",
        "세종시",
        "세종",
        "강원특별자치도",
        "강원도",
        "강원",
        "경기도",
        "경기",
        "경상도",
        "경상",
        "경상북도",
        "경북",
        "경상남도",
        "경남",
        "전라도",
        "전라",
        "전라북도",
        "전북",
        "전라남도",
        "전남",
        "제주특별자치도",
        "제주도",
        "제주",
        "충청도",
        "충청",
        "충청북도",
        "충북",
        "충청남도",
        "충남",
        "특별시",
        "광역시",
    ];

    return sidoArr.indexOf(searchText) === -1;
};

export const dateTimeFormat = (date: Date) => {
    const targetDate = dayjs().diff(date);

    if (targetDate / 1000 / 60 <= 60) return `접수 ${Math.floor(targetDate / 1000 / 60)}분 전`;
    else if (targetDate / 1000 / 60 / 60 <= 24) return `접수 ${Math.floor(targetDate / 1000 / 60 / 60)}시간 전`;
    else return dayjs(date).format("접수 YY.MM.DD");
};
