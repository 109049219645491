import React, { useEffect, useState } from "react";
import icoConvertMeter from "@src/assets/ico_convert_meter.png";
import icoConvertPyeong from "@src/assets/ico_convert_pyeong.png";
import { css } from "@emotion/react";

interface ConvertPyeongButtonProps {
    handleConvert: (type: "pyeong" | "meter") => void;
}
function ConvertPyeongButton({ handleConvert }: ConvertPyeongButtonProps) {
    const [type, setType] = useState<"pyeong" | "meter">("meter");
    const handleClick = () => {
        setType((prev) => (prev === "meter" ? "pyeong" : "meter"));
    };
    useEffect(() => {
        handleConvert(type);
    }, [type]);
    return (
        <div className="convertPyeongButton" css={convertPyeongButtonWrap}>
            <img src={type === "pyeong" ? icoConvertPyeong : icoConvertMeter} alt="단위변환버튼" onClick={handleClick} />
        </div>
    );
}

const convertPyeongButtonWrap = css`
    display: inline;
    width: 56px;
    height: 28px;
    img {
        width: 100%;
        height: 100%;
    }
`;

export default ConvertPyeongButton;
