import { css } from "@emotion/react";
import React from "react";
import { Common } from "@src/styles/Common";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { IFilterParamsType } from "@src/store/newFilterStore";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

const EstPriceFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    const getClassName = (price: string, title: string) => {
        const numberStartValue = Number(filterParams.est_price[0]);
        const numberEndValue = Number(filterParams.est_price[1]);
        const numberPrice = Number(price);
        if (title === "전체" && filterParams.est_price.every((val) => !val)) return "selected start";
        if (filterParams.est_price[0] === "30000000000" && price === "higher") return "selected end";
        if (numberPrice === numberEndValue && filterParams.est_price[1]) return "selected end";
        if (
            filterParams.est_price.every((val) => val) &&
            filterParams.est_price[0] !== "30000000000" &&
            numberPrice > numberStartValue &&
            numberPrice < numberEndValue
        )
            return "range";
        if (filterParams.est_price[0] !== "" && filterParams.est_price[0] !== "30000000000" && numberStartValue === numberPrice)
            return "selected start";
        return "";
    };

    const onClickValue = (price: string, title: string) => {
        if (title === "전체") {
            return setFilterParams({
                ...filterParams,
                est_price: ["", ""],
            });
        }
        if (price === "higher") {
            return setFilterParams({
                ...filterParams,
                est_price: ["30000000000", ""],
            });
        }
        if (filterParams.est_price.every((val) => !val) || filterParams.est_price[0] === "30000000000") {
            return setFilterParams({
                ...filterParams,
                est_price: ["", price],
            });
        }
        if (!filterParams.est_price[0] && Number(filterParams.est_price[1]) > Number(price)) {
            return setFilterParams({
                ...filterParams,
                est_price: [price, filterParams.est_price[1]],
            });
        }
        if (!filterParams.est_price[0] && Number(filterParams.est_price[1]) < Number(price)) {
            return setFilterParams({
                ...filterParams,
                est_price: [filterParams.est_price[1], price],
            });
        }
        if (filterParams.est_price.every((val) => val)) {
            setFilterParams({
                ...filterParams,
                est_price: ["", price],
            });
        }
    };

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>
                <p className="titleText">감정가</p>
                {!allFilter && (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={rangeSelectRootBox(filterParams.est_price.every((val) => val))}>
                {itemForAppraisedPrice.map((price) => (
                    <div key={price.value} className="wrapper" onClick={() => onClickValue(price.value, price.title)}>
                        <div className={getClassName(price.value, price.title)}></div>
                        <span>{price.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EstPriceFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const rangeSelectRootBox = (isRange: boolean) => css`
    padding: 0px 14px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        span {
            font-size: 14px;
            white-space: nowrap;
        }
        .selected.start,
        .selected.end {
            z-index: 1;
            height: 100%;
            width: 100%;
            border-radius: 24px;
            & + span {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                border-radius: 24px;
                background: ${Common.colors.aucMarkerColor};
                color: white;
                font-size: 14px;
            }
        }
        ${isRange &&
        css`
            .selected.start::before {
                content: "";
                position: absolute;
                right: 0;
                width: 50%;
                height: 100%;
                z-index: -99;
                background: transparent;
                background-color: #dff0ff;
            }
            .selected.end::before {
                content: "";
                position: absolute;
                left: 0;
                width: 50%;
                height: 100%;
                z-index: -99;
                background: transparent;
                background-color: #dff0ff;
            }
        `}
        .range {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: #dff0ff;
        }
        span {
            z-index: 2;
        }
    }
`;
