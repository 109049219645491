import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import DueDateFilter from "./DueDateFilter";
import EstPriceFilter from "./EstPriceFilter";
import MinPriceFilter from "./MinPriceFilter";
import AiResetConfirmModal from "../aiFilter/AiResetConfirmModal";
import AuctionTypeFilter from "./AuctionTypeFilter";
import AuctionMasterItemFilter from "./AuctionMasterItemFilter";
import AuctionStatusFilter, { findKeyByValueFromStatus } from "./AuctionStatusFilter";
import AuctionSkipFilter, { auctionSkipList } from "./AuctionSkipFilter";
import RecentFilterHistory from "./RecentFilterHistory";
import hideFilter from "@src/assets/hideFilter.png";
import showFilter from "@src/assets/showFilter.png";
import searchBlackIcon from "@src/assets/ico_search_black_bold.svg";
import ico_filter_white from "@src/assets/ico_filter_white.png";
import { Link, useSearchParams } from "react-router-dom";
import { Common } from "@src/styles/Common";
import ResetFilterButton from "@src/components/filter/ResetFilterButton";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import {
    initialFilterParams,
    useLocationParamSavedUseFilter,
    useNewFilterMapCheckStore,
    useNewFilterStore,
    useSavedLocationFilter,
    useSavedUseFilter,
    useTempNameAndNoList,
} from "@src/store/newFilterStore";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import styled from "@emotion/styled";
import AuctionCounterForceFilter, { getAuctionCounterForceListReversed } from "./AuctionCounterForceFilter";
import micIcon from "@src/assets/basicIcon/micIcon.svg";
import { useFilterStore } from "@src/store/filterStore";
import { usePositionStore } from "@src/store/positionStore";
import { getAuctionMasterItemsReversed } from "@src/constants/auctionMasterItemFilters";
import LocationFilterV3Detail from "./LocationFilterV3Detail";
import UseFilterV3Detail, { getMinorCategories } from "./UseFilterV3Detail";

interface Props {}

const MapFilter: React.FC<Props> = () => {
    const [searchParam, setSearchParam] = useSearchParams();
    const filterParams = useNewFilterStore((state) => state.newFilterState);
    const setFilterParams = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);
    const [searchTextFromApp, setSearchTextFromApp] = useState("");
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const outSelectedSido = useFilterStore((state) => state.selectedOutSido);
    const setOutSelectedSido = useFilterStore((state) => state.setSelectedOutSido);
    const setPositionStore = usePositionStore((state) => state.setPosition);
    const savedLocation = useSavedLocationFilter((state) => state.filterLocationState);
    const setSavedLocation = useSavedLocationFilter((state) => state.setFilterLocationState);
    const savedUseList = useSavedUseFilter((state) => state.filterUseState);
    const setSavedUseList = useSavedUseFilter((state) => state.setFilterUseState);
    const setTempNameAndNo = useTempNameAndNoList((state) => state.setTempNameAndNoListState);

    // 소재지 좌표 저장
    const savedLocationParam = useLocationParamSavedUseFilter((state) => state.filterLocationParamState);
    const setSavedLocationParam = useLocationParamSavedUseFilter((state) => state.setFilterLocationParamState);

    const tabButtons = [
        {
            title: "최근검색조건",
            key: "history",
            selected: false,
            selectedChild: "",
        },
        {
            title: "진행상태",
            key: "status",
            selected: !!filterParams.status.length,
            selectedChild:
                filterParams.status.filter((status) => status !== 2).length > 1
                    ? `${findKeyByValueFromStatus(filterParams.status[0])} 외 ${
                          filterParams.status.filter((status) => status !== 2).length - 1
                      }`
                    : `${findKeyByValueFromStatus(filterParams.status[0])}`,
        },
        {
            title: "소재지",
            key: "loc",
            selected: outSelectedSido !== "all" || filterParams?.loc?.length > 0,
            selectedChild: "소재지",
        },
        {
            title: "경공매",
            key: "type",
            selected: !!filterParams.type,
            selectedChild: filterParams.type === 1 ? "경매" : "공매",
        },
        {
            title: "용도",
            key: "use",
            selected: !!filterParams.use.length,
            selectedChild: "용도",
        },
        {
            title: "대항력",
            key: "counter_force_list",
            selected: filterParams.counter_force_list.length !== 0,
            selectedChild:
                filterParams.counter_force_list.length > 1
                    ? `${getAuctionCounterForceListReversed()[filterParams.counter_force_list[0]]} 외 ${
                          filterParams.counter_force_list.length - 1
                      }`
                    : `${getAuctionCounterForceListReversed()[filterParams.counter_force_list[0]]}`,
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: !!filterParams.auction_master_item.length,
            selectedChild:
                filterParams.auction_master_item.length > 1
                    ? `${getAuctionMasterItemsReversed()[filterParams.auction_master_item[0]]} 외 ${
                          filterParams.auction_master_item.length - 1
                      }${filterParams.auction_master_except ? " 제외" : ""}`
                    : `${getAuctionMasterItemsReversed()[filterParams.auction_master_item[0]]}${
                          filterParams.auction_master_except ? " 제외" : ""
                      }`,
        },
        {
            title: "유찰횟수",
            key: "skp_type",
            selected: !!filterParams.skp_type,
            selectedChild:
                `${auctionSkipList[filterParams.skp_type]}` +
                `${filterParams.skp_type === 2 ? ` ${filterParams.skp_cnt[0]}` : ""}` +
                `${filterParams.skp_cnt.some((val) => val) && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1] ? " ~" : ""}` +
                `${
                    filterParams.skp_type === 2 && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1]
                        ? ` ${filterParams.skp_cnt[1]}`
                        : ""
                }`,
        },
        {
            title: "매각기일",
            key: "due_date",
            selected: !!filterParams.due_date.every((val) => val),
            selectedChild:
                filterParams.due_date[0] !== filterParams.due_date[1]
                    ? `${filterParams.due_date[0]?.slice(2)} ~ ${filterParams.due_date[1]?.slice(2)}`
                    : `${filterParams.due_date[0]?.slice(2)}`,
        },
        {
            title: "감정가",
            key: "est_price",
            selected: !!filterParams.est_price.some((val) => val),
            selectedChild:
                `감정가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value === (filterParams.est_price[0] === "30000000000" ? "higher" : filterParams.est_price[0]),
                    )?.[0]?.showText
                } ~ ` +
                `${
                    filterParams.est_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterParams.est_price[1])?.[0]?.title
                }`,
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: !!filterParams.min_price.some((val) => val),
            selectedChild:
                `최저입찰가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value === (filterParams.min_price[0] === "30000000000" ? "higher" : filterParams.min_price[0]),
                    )?.[0]?.showText
                } ~ ` +
                `${
                    filterParams.min_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterParams.min_price[1])?.[0]?.title
                }`,
        },
    ];
    const [openOutFilter, setOpenOutFilter] = useState<string>("");

    const onClickDone = () => {
        setFilterCheck(false);
        transferToNative(JSON.stringify(filterParams), "setFilterDone");
        // loc 값이 있으면서 소재지 필터를 닫았을 때만 보냄
        if (savedLocationParam && openOutFilter === "loc") transferToNative(JSON.stringify(savedLocationParam), "mapCenterPost");

        setOpenOutFilter("");
    };

    useEffect(() => {
        WebAppBridge.setUserLocationInMap = (lat: string, lng: string) => {
            setPositionStore({
                lat,
                lng,
            });
        };
    }, []);

    useEffect(() => {
        if (searchParam.get("close")) {
            onClickDone();
            const currentParams = Object.fromEntries(searchParam);
            delete currentParams?.close;
            setSearchParam(currentParams);
        }
    }, [searchParam.get("close")]);

    useEffect(() => {
        setSearchTextFromApp(searchParam.get("searchText") ?? "");
    }, [searchParam.get("searchText")]);

    WebAppBridge.filterDone = () => {
        setOpenOutFilter("");
    };

    useEffect(() => {
        let newLoc = {
            ...filterParams,
            loc: savedLocation ? [savedLocation.value] : [],
        };
        setFilterParams(newLoc);
    }, [savedLocation]);

    useEffect(() => {
        const updated = savedUseList
            .map((use) => {
                // 해당 용도의 전체일 경우
                if (use.value.length === 1) {
                    // 용도가 기타일 경우 예외 처리
                    if (use.value === "E") {
                        return ["E01"];
                    } else {
                        return getMinorCategories(use.value)
                            .map((item) => item.value)
                            .filter((item) => item.length !== 1);
                    }
                } else {
                    return use.value;
                }
            })
            .flat();
        const copy = { ...filterParams, use: updated };
        setFilterParams(copy);
    }, [savedUseList]);

    // 뒤로가기시에 홈으로
    WebAppBridge.onBackButtonPressed = () => {
        if (openOutFilter !== "") {
            transferToNative(true, "respondBackButtonPressed");
            setOpenOutFilter("");
        } else {
            transferToNative(false, "respondBackButtonPressed");
        }
    };

    return (
        <div css={rootStyle(!!openOutFilter)}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <div css={inputBoxStyle}>
                    <img
                        src={isShowFilter ? hideFilter : showFilter}
                        alt=""
                        width="38px"
                        height="38px"
                        onClick={() => {
                            if (openOutFilter) {
                                onClickDone();
                            }
                            setIsShowFilter((pre) => !pre);
                        }}
                    />
                    <input
                        placeholder="소재지, 사건번호 검색"
                        value={searchTextFromApp ?? ""}
                        readOnly
                        css={inputStyle}
                        onClick={() => {
                            const param = { search_text: searchTextFromApp };
                            transferToNative(JSON.stringify(param), "showSearchView");
                        }}
                    />
                    <img src={searchBlackIcon} alt="" css={searchImageStyle} />
                    {navigator.userAgent.toLowerCase().includes("iphone") && (
                        <img
                            src={micIcon}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                transferToNative("appSearch", "onClickSearchMic");
                            }}
                            css={micIconStyle}
                        />
                    )}
                </div>
                <div css={tabListBoxStyle(isShowFilter)}>
                    <Link
                        to="/allFilter/map"
                        css={allFilterButtonStyle}
                        onClick={() => {
                            setFilterCheck(true);
                            transferToNative("", "setFilterStart");
                        }}
                    >
                        <img src={ico_filter_white} />
                        <span>지도종합검색설정</span>
                    </Link>
                    <div className="filter_button_scroll_wrapper">
                        {tabButtons.map((item, index) => (
                            <TabButton
                                isSelected={openOutFilter === item.key || item.selected}
                                disabled={false}
                                key={`${item}_${index}`}
                                onClick={() => {
                                    transferToNative("", "setFilterStart");
                                    if (openOutFilter === item.key) {
                                        onClickDone();
                                    } else {
                                        setFilterCheck(true);
                                        setOpenOutFilter(item.key);
                                    }
                                }}
                            >
                                <span>{item.selected ? item.selectedChild : item.title}</span>
                            </TabButton>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ background: "#FFF" }}>
                {openOutFilter && (
                    <>
                        <div css={dividerStyle} />
                        <div css={whiteDividerStyle} />
                    </>
                )}
                {openOutFilter === "history" && (
                    <RecentFilterHistory
                        onClose={() => setOpenOutFilter("")}
                        setFilter={setFilterParams}
                        setSavedLocation={setSavedLocation}
                        setSavedUseList={setSavedUseList}
                        setTempNameAndNo={setTempNameAndNo}
                    />
                )}
                {openOutFilter === "type" && (
                    <AuctionTypeFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "loc" && (
                    <LocationFilterV3Detail
                        isNotParfOfMainFilter={false}
                        backFn={onClickDone}
                        setSavedLocation={setSavedLocation}
                        isMap={true}
                    />
                )}
                {openOutFilter === "use" && (
                    <UseFilterV3Detail
                        isNotParfOfMainFilter={false}
                        backFn={onClickDone}
                        savedUseList={savedUseList}
                        setSavedUseList={setSavedUseList}
                    />
                )}
                {openOutFilter === "counter_force_list" && (
                    <AuctionCounterForceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "auction_master_item" && (
                    <AuctionMasterItemFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "status" && (
                    <AuctionStatusFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "skp_type" && (
                    <AuctionSkipFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "due_date" && (
                    <DueDateFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "est_price" && (
                    <EstPriceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "min_price" && (
                    <MinPriceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter && openOutFilter !== "history" && (
                    <ResetFilterButton onResetClick={() => setResetModalVisible(true)} onConfirmClick={onClickDone} />
                )}
            </div>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={() => {
                        setFilterParams(initialFilterParams);
                        setResetModalVisible(false);
                        setOutSelectedSido("all");
                        setSavedLocation(null);
                        setSavedUseList([]);
                        setOpenOutFilter("");
                        setTempNameAndNo([]);
                        setSavedLocationParam(null);
                        transferToNative(JSON.stringify(initialFilterParams), "setFilterDone");
                    }}
                />
            )}
            {openOutFilter && <div css={{ height: "100%", background: "rgba(0,0,0,1)", opacity: 0.3 }} onClick={onClickDone} />}
        </div>
    );
};

export default MapFilter;

const rootStyle = (isShowFilterList: boolean) => css`
    width: 100vw;
    height: ${isShowFilterList ? "calc(var(--vh, 1vh) * 100)" : "fit-content"};
    height: ${isShowFilterList ? "100dvh" : "fit-content"};
    height: ${isShowFilterList ? "100vh" : "fit-content"};
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 14px;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255,2 55, 0"};
`;

const inputBoxStyle = css`
    position: relative;
    width: 100%;
    height: 44px;
    padding: 4px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const inputStyle = css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    opacity: 1;
    height: 40px;
    border: 1px solid #e3e3e6;
    border-radius: 8px;
    padding: 0px 48px;
    font-size: 16px;
    background: #ffffff;
    &:focus {
        outline: none;
    }
`;

const searchImageStyle = css`
    position: absolute;
    left: 58px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
`;

const micIconStyle = css`
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
`;

const tabListBoxStyle = (isShowFilter: boolean) => css`
    display: flex;
    align-items: center;
    height: ${isShowFilter ? "56px" : "0px"};
    opacity: ${isShowFilter ? 1 : 0};
    visibility: ${isShowFilter ? "visible" : "hidden"};
    transition: all 0.2s ease;
    z-index: 3;
    button + button {
        margin-left: 8px;
    }
    & > a {
        min-width: fit-content;
        padding: 8px 16px;
        & > button {
            opacity: ${isShowFilter ? 1 : 0};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${isShowFilter ? "visible" : "hidden"};
            height: ${isShowFilter ? "30px !important" : "0px"};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${isShowFilter ? "32px" : "0px"};
        opacity: ${isShowFilter ? 1 : 0};
        visibility: ${isShowFilter ? "visible" : "hidden"};
        transition: all 0.2s ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const allFilterButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }
    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const dividerStyle = css`
    height: 12px;
    background: rgb(236, 239, 244);
`;

const whiteDividerStyle = css`
    height: 10px;
    background-color: #ffffff;
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled }) => (disabled ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled }) =>
        disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled }) =>
            disabled ? `${Common.colors.grey04}` : isSelected ? ` ${Common.colors.white}` : `${Common.colors.normalTextColor}`};
    }
`;
