import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";

import { useFilterStore, useSidoFilterStore } from "@src/store/filterStore";
import SearchInputAndFilter, { IFilterState, initialFilterState } from "@src/components/searchItems/SearchInputAndFilter";
import { useSearchParams } from "react-router-dom";

export const SearchItems: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const filterStoreState = useFilterStore((state) => state.filterState);
    const setFilterStoreState = useFilterStore((state) => state.setStoreFilterState);

    useEffect(() => {
        transferToNative("done", "onLoad");
    }, []);

    useEffect(() => {
        const type = searchParams.get("type");
        if (type === "auction") {
            setFilterStoreState({
                ...filterStoreState,
                type: 1,
            });
        } else if (type === "publicAuction") {
            setFilterStoreState({
                ...filterStoreState,
                type: 2,
            });
        } else return;
    }, [searchParams.get("type")]);

    useEffect(() => {
        transferToNative(JSON.stringify(filterStoreState), "changeSearchItemsFilter");
    }, [isShowFilter]);

    return (
        <StyledItems>
            <div css={FilterArea}>
                <SearchInputAndFilter isShowFilter={isShowFilter} setIsShowFilter={setIsShowFilter} />
            </div>
        </StyledItems>
    );
};

const StyledItems = styled.div`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    overflow: hidden;
`;

const FilterArea = css`
    position: fixed;
    z-index: 9998;
    width: 100%;
`;
