import { css } from "@emotion/react";
import React from "react";

import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import { Common } from "@src/styles/Common";
import { NameAndValue } from "@src/store/newFilterStore";
import { IRegion } from "@src/hooks/newFilter/useGetLocation";

interface LocationFilterV3Props {
    changeSubFilter: (str: string) => void;
    savedLocation: NameAndValue | null;
    setSavedLocation: (filterState: NameAndValue | null) => void;
    setTempNameAndNo: (filter: IRegion[]) => void;
}

const LocationFilterV3Preview = ({
    changeSubFilter,
    savedLocation,
    setSavedLocation,
    setTempNameAndNo,
}: LocationFilterV3Props) => {
    return (
        <div css={titleBoxStyle}>
            <p className="titleText">소재지</p>
            <div
                className="btns"
                onClick={() => {
                    changeSubFilter("mainFilter-loc");
                }}
            >
                <button>시/도</button>
                <button>시/군/구</button>
                <button>읍/면/동</button>
            </div>
            {savedLocation && (
                <div className="locations">
                    <ShowSelectedUseLocValueBox
                        key={savedLocation.value}
                        value={savedLocation.name}
                        onDeleteValue={() => {
                            setSavedLocation(null);
                            setTempNameAndNo([]);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default LocationFilterV3Preview;

const titleBoxStyle = css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
    padding: 0 14px;
    min-height: 72px;
    .titleText {
        color: #0c002c;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;

        span {
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.darkGrey04};
        }
    }

    .btns {
        display: flex;
        gap: 6px;

        button {
            color: ${Common.colors.white};
            background: ${Common.colors.aucMarkerColor};
            border-radius: 24px;
            padding: 8px 10px;
            line-height: 14px;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .locations {
        margin: 16px 0 0;
        display: flex;
        flex-wrap: wrap;
        max-height: 160px;
        gap: 12px;
        width: 100%;
    }
`;
