import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import input_close_icon from "@src/assets/buildingRegister/input_close_icon.svg";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import micIcon from "@src/assets/basicIcon/micIcon.svg";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";

interface LandReigsterSearchBarProps {
    onSearchDone: (searchText: string) => void;
}

const LandReigsterSearchBar = ({ onSearchDone }: LandReigsterSearchBarProps) => {
    const [searchParams] = useSearchParams();
    const searchTextAreaRef = useRef(null as any);
    const [searchText, setSearchText] = useState("");

    WebAppBridge.setSpeechResult = async (val: string) => {
        setSearchText(val);
        onSearchDone(val);
    };

    const handleSearchDone = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.nativeEvent.isComposing) return;
        if (event.key.match(/Enter/g)) event.preventDefault();

        switch (event.key) {
            case "Enter":
                onSearchDone(searchText);
                break;
        }
    };

    const onClearSearchTextHandler = () => {
        setSearchText("");
    };

    return (
        <BuildingRegisterSearchBar>
            <div className="searchBox">
                <textarea
                    rows={1}
                    ref={searchTextAreaRef}
                    placeholder="지번 주소(읍,면,동,리)와 번지 입력"
                    onKeyDown={handleSearchDone}
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                ></textarea>
                <SearchIcon />
                {searchText.length > 0 && (
                    <div className={"closeBtn"} onClick={onClearSearchTextHandler}>
                        <img src={input_close_icon} />
                    </div>
                )}
                {navigator.userAgent.toLowerCase().includes("iphone") && (
                    <div
                        className={"micBtn"}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            transferToNative("webSearch", "onClickSearchMic");
                        }}
                    >
                        <img src={micIcon} />
                    </div>
                )}
            </div>
        </BuildingRegisterSearchBar>
    );
};

export default LandReigsterSearchBar;

const BuildingRegisterSearchBar = styled.div`
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin: 0 14px 14px;

    .searchBox {
        position: absolute;
        display: flex;
        align-items: center;
        width: calc(100% - 28px);
        border: 2px solid #2275ee !important;
        border-radius: 8px;
        z-index: 4;

        & > textarea {
            width: 100%;
            height: 44px;
            max-height: 65px;
            line-height: 1.6;
            font-weight: 400;
            font-size: 16px;
            padding: 6px 48px 12px 45px;
            border: 4px solid #fff !important;
            border-radius: 8px;
            background: #fff;
            box-sizing: border-box;
            text-align: left;
            resize: none;
            overflow: hidden;
            outline: none;
            z-index: 2;
            transition: all 0.2s ease;

            &::placeholder {
                color: #c9c9c9;
            }
            &.hidden {
                padding: 0 15px;
            }
        }
        & > svg {
            position: absolute;
            top: 50%;
            transform: translate(0%, -50%);
            left: 14px;
            font-size: 27.45px;
            color: #2275ee;
            z-index: 2;
        }
        .closeBtn {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 36px;
            z-index: 2;
            cursor: pointer;

            & > img {
                height: 15px;
            }
        }
        .micBtn {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 14px;
            z-index: 2;
            cursor: pointer;
        }
    }
`;
