import create from "zustand";

export interface goStopIFilterParamsType {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force: number;
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    gostop_profit: any[];
    sort: number;
    auction_master_except: boolean;
}

export const goStopInitialFilterParams: goStopIFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    gostop_profit: [0, null],
    sort: 5,
    auction_master: [],
    except_item: [],
};

type useFilterStoreType = {
    goStopFilterState: goStopIFilterParamsType;
    setStoreGoStopFilterState: (filterState: goStopIFilterParamsType) => void;
};

type useGoStopFilterMapCheckStoreType = {
    goStopFilterMapCheckState: boolean;
    setGoStopFilterMapCheckState: (status: boolean) => void;
};

export const useGoStopFilterStore = create<useFilterStoreType>(
    (set: any) =>
        ({
            goStopFilterState: goStopInitialFilterParams,
            setStoreGoStopFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        goStopFilterState: {
                            ...filterState,
                        },
                    };
                });
            },
        } as any),
);

export const useGoStopFilterMapCheckStore = create<useGoStopFilterMapCheckStoreType>(
    (set: any) =>
        ({
            goStopFilterMapCheckState: false,
            setGoStopFilterMapCheckState: async (status: boolean) => {
                set(() => {
                    return {
                        goStopFilterMapCheckState: status,
                    };
                });
            },
        } as any),
);
