import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Global } from "@emotion/react";
import reset from "@src/styles/Reset";
import Error from "./pages/Error";
import Map from "@src/pages/map/Map";
import Login from "./components/Login";
import TestPage from "./components/table/TestPage";
import IntrenalTablePage from "./components/internalTable/InternalTablePage";
import TestAiPage from "./components/aiFilter/TestAiPage";
import AiFilterMainV2 from "./pages/aiFilter/AiFilterMainV2";
import RealPriceFilter from "./pages/map/RealPrice/RealPriceFilter";
import RegisterCouponIOS from "./pages/coupon/RegisterCouponIOS";
import CouponRegistrationCompleted from "./pages/coupon/CouponRegistrationCompleted";
import DeungiMain from "./pages/deungi/DeungiMain";
import DeungiPayment from "./pages/deungi/DeungiPayment";
import PdfViewer from "./pages/deungi/PdfViewer";
import DeungiList from "./pages/deungi/DeungiList";
import CharginUseList from "./pages/deungi/CharginUseList";
import DeungiTicketCancelList from "./pages/deungi/DeungiTicketCancelList";
import { useTokenStore } from "./store/TokenStore";
import loading from "./assets/Loading.json";
import Lottie from "lottie-react";
import styled from "@emotion/styled";
import Campus from "./pages/campus/Campus";
import CampusDetail from "./pages/campus/campusDetail/CampusDetail";
import Sugang from "./pages/campus/sugang/Sugang";
import WriteGrade from "./pages/campus/sugang/WriteGrade";
import PanoramaView from "./pages/panorama";
import UnSubscribe from "./pages/unSubscription";
import UnsubsribeRefund from "./pages/unSubscription/UnsubsribeRefund";
import Inquiry from "./pages/expert/Inquiry/Inquiry";
import GoStop from "./pages/goStop";
import GoStopList from "./pages/goStop/GoStopList";
import ExpertAdList from "./pages/expert/AdItemList";
import AdvertisementCancelRefund from "./pages/advertisement/AdvertisementCancelRefund";
import BuildingRegisterMain from "./pages/buildingRegister/BuildingRegisterMain";
import BuildingRegisterPdfViewer from "./pages/buildingRegister/BuildingRegisterPdfViewer";
import BuildingRegisterList from "./pages/buildingRegister/BuildingRegisterList";
import FieldDetail from "./pages/fieldDetail";
import StateLandRefund from "./pages/stateLand/StateLandRefund";
import StateLandInfo from "./pages/stateLand/stateLandInfo";
import SubscriptionInfo from "./pages/subscription";
import StateLandUserFilter from "./pages/stateLandFilter/StateLandUserFilter";
import DeungiAlarmMain from "./pages/deungi/DeungiAlarmMain";
import DeungiAlarmSetting from "./pages/deungi/DeungiAlarmSetting";
import DeungiAlarmSearch from "./pages/deungi/DeungiAlarmSearch";
import DeungiAlarmDetail from "./pages/deungi/DeungiAlarmDetail";
import DeungiAlarmSubmitList from "./pages/deungi/DeungiAlarmSubmitList";
import SpeechShorts from "./pages/SpeechShorts";
import AiRecommend from "./pages/aiRecommend";
import StoreGoStop from "./pages/storeGoStop";
import StoreGoStopList from "./pages/storeGoStop/StoreGoStopList";
import StoreGoStopCalculate from "./pages/storeGoStop/StoreGoStopCalculate";
import IosSubscriptionInfo from "./pages/subscription/iosIndex";
import { ExpertLocalAdIntroductionsPopup } from "./pages/expert/RegionAd";
import RoadPlanRefund from "./pages/roadPlan/RoadPlanRefund";
import ServiceBannerPage from "./pages/storeGoStop/ServiceBannerPage";
import RegionAdsRefund from "./pages/expert/RegionAd/Refund";
import GoStopDialog from "./pages/goStop/GoStopDialog";
import StoreGoStopDialog from "./pages/goStop/StoreGoStopDialog";
import IosDatamap from "./pages/introduction/IosDatamap";
import DeungiTicketRefund from "./pages/deungi/deingiTicketRefund";
import CampuseRefundV2 from "./pages/campus/CampussRefundV2";
import CustomerServiceInquiry from "./components/customerService/inquiry";
import DailyNewAnnounce from "./pages/DailyNewAnnounce";
import DepositDetail from "./pages/DepositDetail";
import AiDividendEvent from "./pages/event/aiDividendEvent";
import { SearchItems } from "./pages/searchItems";
import AllFilter from "./pages/mapFilter/AllFilter";
import AllFilter_search from "./components/filter/AllFilter_search";
import AiFilterPurposeV2 from "./pages/aiFilter/AiFilterPurposeV2";
import AiFilterLocationV2 from "./pages/aiFilter/AiFilterLocationV2";
import ThreePage from "./pages/three";
import MapFilter from "./pages/mapFilter";
import LandRegisterMain from "./pages/landRegister/LandRegisterMain";
import LandRegisterIssuedList from "./pages/landRegister/LandRegisterIssuedList";
import LandRegisterPdfViewer from "./pages/landRegister/LandRegisterPdfViewer";
import LandRegisterIssueStatusWatch from "./pages/landRegister/LandRegisterIssueStatusWatch";
import LandRegisterIssueWaiting from "./pages/landRegister/LandRegisterIssueWaiting";
import DatamapNewFnEvent from "./pages/datamapNewFnEvent/DatamapNewFnEvent";
import SmallRentTablePage from "./pages/smallRentTable";

function Router() {
    const tokenStore = useTokenStore((state) => state.accessToken);

    useEffect(() => {
        if (
            !(window.navigator.userAgent.includes("Android") || window.navigator.userAgent.includes("iPhone")) &&
            !tokenStore.length &&
            (window.location.search.includes("pnu") || window.location.search.includes("itemId"))
        ) {
            const langingLink = document.createElement("a");
            document.body.appendChild(langingLink);
            langingLink.href = "https://www.missgoauction.com";
            langingLink.click();
            document.body.removeChild(langingLink);
        }
    }, []);

    return (
        <>
            <Global styles={reset} />
            <Routes>
                {/* <Route exact path="*" element={<Navigate replace to="/login" />} /> */}
                <Route path="/login" element={<Login />} />
                {tokenStore?.length > 0 ? (
                    <>
                        <Route exact path="/error" element={<Error />} />
                        <Route exact path="/map" element={<Map />} />
                        <Route exact path="/items/:id/deposit-detail" element={<DepositDetail />} />
                        <Route exact path="/searchItems" element={<SearchItems />} />
                        <Route exact path="/items/daily-new" element={<DailyNewAnnounce />} />
                        <Route exact path="/aiFilterV2" element={<AiRecommend />} />
                        <Route exact path="/aiFilterV2/locationV2" element={<AiRecommend preStep={2} />} />
                        <Route exact path="/aiFilterv2/locationv2/purposev2" element={<AiRecommend preStep={3} />} />
                        <Route exact path="/smallFilter" element={<AiFilterMainV2 />} />
                        <Route exact path="/smallFilter/locationV2" element={<AiFilterLocationV2 />} />
                        <Route exact path="/smallFilter/locationv2/purposev2" element={<AiFilterPurposeV2 />} />
                        <Route exact path="/table" element={<TestPage />} />
                        <Route exact path="/internaltablepage" element={<IntrenalTablePage />} />
                        <Route exact path="/aibottomsheet" element={<TestAiPage />} />
                        <Route exact path="/realPriceFilter" element={<RealPriceFilter />} />
                        <Route exact path="/successCoupon" element={<CouponRegistrationCompleted />} />
                        <Route exact path="/deungi" element={<DeungiMain />} />
                        <Route exact path="/deungi/payment" element={<DeungiPayment />} />
                        <Route exact path="/deungi/pdf" element={<PdfViewer />} />
                        <Route exact path="/deungi/list" element={<DeungiList />} />
                        <Route exact path="/deungi/charginguselist" element={<CharginUseList />} />
                        <Route exact path="/deungi/refund" element={<DeungiTicketCancelList />} />
                        <Route exact path="/deungi/readingticketcancel" element={<DeungiTicketRefund />} />
                        <Route exact path="/deungi/alarm" element={<DeungiAlarmMain />} />
                        <Route exact path="/deungi/alarm/setting" element={<DeungiAlarmSetting />} />
                        <Route exact path="/deungi/alarm/search" element={<DeungiAlarmSearch />} />
                        <Route exact path="/deungi/alarm/detail" element={<DeungiAlarmDetail />} />
                        <Route exact path="/deungi/alarm/list" element={<DeungiAlarmSubmitList />} />
                        <Route exact path="/campus" element={<Campus />} />
                        <Route exact path="/campus/:path" element={<Campus />} />
                        <Route exact path="/campus/detail/:campusNo" element={<CampusDetail />} />
                        <Route exact path="/campus/sugang/:campusNo" element={<Sugang />} />
                        <Route exact path="/campus/sugang/grade/:campusNo" element={<WriteGrade />} />
                        <Route exact path="/campus/refund" element={<CampuseRefundV2 />} />
                        <Route exact path="/mapFilter" element={<MapFilter />} />
                        <Route exact path="/allFilter/map" element={<AllFilter />} />
                        <Route exact path="/allFilter/search" element={<AllFilter_search />} />
                        <Route exact path="/panoramaView" element={<PanoramaView />} />
                        <Route exact path="/unSubscribe" element={<UnSubscribe />} />
                        <Route exact path="/unSubscribe/refund" element={<UnsubsribeRefund />} />
                        <Route exact path="/expert/inquiry" element={<Inquiry />} />
                        <Route exact path="/expert/localAd/introduction/popup" element={<ExpertLocalAdIntroductionsPopup />} />
                        <Route exact path="/regionAdsRefund" element={<RegionAdsRefund />} />
                        <Route exact path="/goStop" element={<GoStop />} />
                        <Route exact path="/storeGoStop" element={<StoreGoStop />} />
                        <Route exact path="/goStopList" element={<GoStopList />} />
                        <Route exact path="/storeGoStopList" element={<StoreGoStopList />} />
                        <Route exact path="/storeGoStop/calculate" element={<StoreGoStopCalculate />} />
                        <Route exact path="/expertAdList" element={<ExpertAdList />} />
                        <Route exact path="/advertisementCancelRefund" element={<AdvertisementCancelRefund />} />
                        <Route exact path="/buildingRegister" element={<BuildingRegisterMain />} />
                        <Route exact path="/buildingRegister/list" element={<BuildingRegisterList />} />
                        <Route exact path="/buildingRegister/viewer" element={<BuildingRegisterPdfViewer />} />
                        <Route exact path="/landRegister" element={<LandRegisterMain />} />
                        <Route exact path="/landRegister/list" element={<LandRegisterIssuedList />} />
                        <Route exact path="/landRegister/viewer" element={<LandRegisterPdfViewer />} />
                        <Route exact path="/landRegister/status" element={<LandRegisterIssueStatusWatch />} />
                        <Route exact path="/landRegister/issue" element={<LandRegisterIssueWaiting />} />
                        <Route exact path="/fieldDetail" element={<FieldDetail />} />∏
                        <Route exact path="/stateLandRefund" element={<StateLandRefund />} />
                        <Route exact path="/stateLandInfo" element={<StateLandInfo />} />
                        <Route exact path="/roadPlanRefund" element={<RoadPlanRefund />} />
                        <Route exact path="/goStopRefund" element={<GoStopDialog />} />
                        <Route exact path="/storeGoStopRefund" element={<StoreGoStopDialog />} />
                        <Route exact path="/subscriptionInfo" element={<SubscriptionInfo />} />
                        <Route exact path="/ios-subscriptionInfo" element={<IosSubscriptionInfo />} />
                        <Route exact path="/stateLandUserFilter" element={<StateLandUserFilter />} />
                        <Route exact path="/speechShorts" element={<SpeechShorts />} />
                        <Route exact path="/storeGoStop/introduction" element={<ServiceBannerPage />} />
                        <Route exact path="/introduction/IosDatamap" element={<IosDatamap />} />
                        <Route exact path="/customerService/inquiry" element={<CustomerServiceInquiry />} />
                        <Route exact path="/event/aiDividendEvent" element={<AiDividendEvent />} />
                        <Route exact path="/event/datamapNewFnEvent" element={<DatamapNewFnEvent />} />
                        <Route exact path="/three" element={<ThreePage />} />
                        <Route exact path="/smallRentTable" element={<SmallRentTablePage />} />
                    </>
                ) : (
                    <>
                        <Route exact path="/speechShorts" element={<SpeechShorts />} />
                        <Route exact path="/registerCoupon" element={<RegisterCouponIOS />} />
                        <Route
                            path="*"
                            element={
                                <StyledLottie>
                                    <Lottie className="lottie" animationData={loading} />
                                </StyledLottie>
                            }
                        />
                    </>
                )}
            </Routes>
        </>
    );
}

export default Router;

const StyledLottie = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* [3] */
    height: calc(var(--vh, 1vh) * 100); /* [4] */
    width: 100%;
    & > .lottie {
        width: 50%;
    }
`;
