import { css } from "@emotion/react";

import React, { useEffect, useState } from "react";

import { useLocationParamSavedUseFilter, useSavedLocationFilter, useTempNameAndNoList } from "@src/store/newFilterStore";
import { IRegion, useGetRegionList } from "@src/hooks/newFilter/useGetLocation";
import { useNavigate } from "react-router-dom";
import { Common } from "@src/styles/Common";
import AuctionFilterHeader from "./AuctionFilterHeader";
import CommonFilterHeader from "./CommonFilterHeader";

interface Props {
    backFn: () => void;
    isNotParfOfMainFilter: boolean;
    setSavedLocation: (filterState: NameAndValue | null) => void;
    isMap?: boolean; // 지도에서 사용하는 건지 확인. 맵에서만 소재지 이동을 위함
}

const ALL_SIDO = "0000000000";

export type NameAndValue = {
    name: string;
    value: string;
};

enum LOCATION_STEP {
    "SIDO",
    "SIGUNGU",
    "EMPMYEONDONG",
}

const LocationFilterV3Detail: React.FC<Props> = ({ backFn, isNotParfOfMainFilter, setSavedLocation, isMap = false }) => {
    const [cortarNo, setCortar] = useState(ALL_SIDO);
    const { data: regionList } = useGetRegionList(cortarNo);

    const [currentStep, setCurrentStep] = useState(LOCATION_STEP.SIDO); //0: 시도, 1:시구군, 2: 읍면동
    const tempNameAndNoList = useTempNameAndNoList((state) => state.tempNameAndNoListState);
    const setTempNameAndNo = useTempNameAndNoList((state) => state.setTempNameAndNoListState);

    // 소재지 좌표 저장. mapFilter에서만 사용
    const setSavedLocationParam = useLocationParamSavedUseFilter((state) => state.setFilterLocationParamState);

    // 설정 완료 당시 상태로 변경
    function getLoc() {
        switch (tempNameAndNoList.length) {
            case 1:
                setCortar(tempNameAndNoList[0].cortarNo);
                setCurrentStep(1);
                break;
            case 2:
            case 3:
                setCortar(tempNameAndNoList[1].cortarNo);
                setCurrentStep(2);
                break;
        }
    }

    useEffect(() => {
        getLoc();
    }, []);

    const handlePrev = () => {
        // 이전 단계로 가고 배열 마지막 요소 제거
        switch (currentStep) {
            case 1:
                setCortar(ALL_SIDO);
                break;
            case 2:
                setCortar(tempNameAndNoList[0].cortarNo);
                break;
        }
        setCurrentStep((prev) => prev - 1);
        let copy = [...tempNameAndNoList];
        if (tempNameAndNoList.length === 3) copy.splice(1, 2);
        else copy.pop();
        setTempNameAndNo(copy);
    };

    // 지역 이름 활용하여 breadcrumb 과 유사한 디스플레이 텍스트 제공
    const getDisplayLocationText = (locations: IRegion[]) => {
        return locations.map((loc) => loc.cortarName).join(" > ");
    };

    // 마지막 단계에서 전체 클릭 시
    const handleClickAll = () => {
        let copy = [...tempNameAndNoList];
        if (tempNameAndNoList.length > 2) copy.pop();
        setTempNameAndNo(copy);
    };

    const handleClickRegionItem = (regionItem: IRegion) => {
        const regionNameAndValue = { name: regionItem.cortarName, value: regionItem.cortarNo };

        //선택된 지역 UI 활성화. 마지막 단계라면 제거하고 추가
        if (currentStep !== LOCATION_STEP["EMPMYEONDONG"]) {
            let copy = [...tempNameAndNoList, regionItem];
            setTempNameAndNo(copy);
            setCurrentStep((prevStep) => prevStep + 1);
            setCortar(regionNameAndValue.value);
        } else {
            let copy = [...tempNameAndNoList];
            if (tempNameAndNoList.length > 2) copy.pop();
            copy.push(regionItem);
            setTempNameAndNo(copy);
        }
    };

    function saveLocation() {
        if (tempNameAndNoList.length > 0) {
            const regValue = tempNameAndNoList[tempNameAndNoList.length - 1];
            const updated = {
                value: regValue.cortarNo,
                name: getDisplayLocationText(tempNameAndNoList),
            };
            setSavedLocation(updated);

            if (isMap) {
                let zoom = 15;

                switch (regValue.cortarType) {
                    case "city":
                        regValue.cortarName.includes("도") ? (zoom = 9) : (zoom = 11);
                        if (regValue.cortarName === "제주도") zoom = 11;
                        break;
                    case "dvsn":
                        zoom = 12;
                        break;
                    case "sec":
                        zoom = 15;
                        break;
                }

                const body = {
                    lat: regValue.centerLat,
                    lng: regValue.centerLon,
                    zoom,
                };

                setSavedLocationParam(body);
            }
        } else {
            setSavedLocationParam(null);
            setSavedLocation(null);
        }
    }

    useEffect(() => {
        saveLocation();
    }, [tempNameAndNoList]);

    return (
        <div css={rootBoxStyle}>
            {isNotParfOfMainFilter ? (
                <div className="header">
                    <AuctionFilterHeader title="소재지 선택" out={isNotParfOfMainFilter} backFn={backFn} />
                </div>
            ) : (
                <CommonFilterHeader
                    onGoback={() => {
                        backFn();
                    }}
                >
                    소재지 선택
                </CommonFilterHeader>
            )}

            <div css={locButtonBox(isNotParfOfMainFilter)}>
                <div css={selectBigButtonStyle(true)}>{tempNameAndNoList[0] ? tempNameAndNoList[0].cortarName : "시/도"}</div>
                <div css={selectBigButtonStyle(tempNameAndNoList.length > 0)}>
                    {tempNameAndNoList[1] ? tempNameAndNoList[1].cortarName : "시/군/구"}
                </div>
                <div css={selectBigButtonStyle(tempNameAndNoList.length > 1)}>읍/면/동</div>
            </div>

            <div css={lineStyle(isNotParfOfMainFilter)} />
            <div css={selectBoxRootStyle}>
                {
                    <div css={selectBoxStyle(isNotParfOfMainFilter)}>
                        {currentStep === LOCATION_STEP.EMPMYEONDONG ? (
                            <button css={selectButtonStyle(tempNameAndNoList.length === 2)} onClick={handleClickAll}>
                                전체
                            </button>
                        ) : (
                            <button css={selectButtonStyle(true)}>{currentStep === LOCATION_STEP.SIDO ? "전국" : "전체"}</button>
                        )}
                        {regionList?.map((item) => {
                            return (
                                <button
                                    key={item.cortarNo}
                                    css={selectButtonStyle(item.cortarNo === tempNameAndNoList[currentStep]?.cortarNo)}
                                    onClick={() => handleClickRegionItem(item)}
                                >
                                    {item.cortarName}
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
            <div css={footerRootStyle}>
                <div css={footerButtonBoxStyle(isNotParfOfMainFilter)}>
                    {currentStep > 0 && (
                        <button onClick={handlePrev}>
                            <span>이전으로</span>
                        </button>
                    )}
                    <button
                        className="active"
                        onClick={() => {
                            backFn();
                        }}
                    >
                        <span>적용하기</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LocationFilterV3Detail;

const rootBoxStyle = css`
    background-color: #ffffff;
    min-height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 5px;

    button {
        cursor: pointer;
    }
    .header {
        padding: 0 14px;
        display: flex;
        align-items: center;
    }
`;

const lineStyle = (isNotParfOfMainFilter: boolean) => css`
    ${!isNotParfOfMainFilter ? { margin: "0px" } : { margin: "14px 0 24px 0" }}
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
`;

const locButtonBox = (isNotParfOfMainFilter: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 4px;
    ${!isNotParfOfMainFilter ? { margin: "16px" } : { margin: "0 0 16px 0" }}

    button:disabled {
        background-color: #f0f0f0;
        color: ${Common.colors.navyPrimary};
        font-weight: 500;
        border: none;
        cursor: not-allowed;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: stretch;
    flex: 1;
    max-height: calc(100dvh - 285px);
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 10px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }
`;

const selectBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    ${isNotParfOfMainFilter ? { padding: "0" } : { margin: "24px 16px 0" }}
    ${isNotParfOfMainFilter && { marginBottom: "16px" }};
    gap: 8px;
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 14px;
    max-height: 48px;
    box-sizing: border-box;
    color: ${Common.colors.aucMarkerColor};
    border: 1px solid ${Common.colors.aucMarkerColor};
    background: ${Common.colors.white};
    font-size: 16px;
    cursor: pointer;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: Common.colors.white,
    }}
`;

const selectBigButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 16px 6px;
    font-size: 16px;
    box-sizing: border-box;
    max-height: 48px;
    background: #f0f0f0;
    cursor: default;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: Common.colors.white,
    }}
`;

const footerRootStyle = css`
    /* margin-top: 24px; */
    width: 100%;
    background-color: #ffffff;
    margin-top: auto;
`;

const footerButtonBoxStyle = (isNotParfOfMainFilter: boolean) => css`
    display: flex;
    ${isNotParfOfMainFilter ? { padding: "0 14px" } : { padding: " 14px" }}
    button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #c3c3c3;
        span {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
        }
    }
    button.active {
        background: ${Common.colors.aucMarkerColor};
    }
    button + button {
        margin-left: 12px;
    }
`;
