import { css } from "@emotion/react";
import React from "react";
import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import { NameAndValue, useSavedUseFilter } from "@src/store/newFilterStore";
import { Common } from "@src/styles/Common";

interface UseFilterV3Props {
    changeSubFilter: (str: string) => void;
    savedUseList: NameAndValue[];
    setSavedUseList: (filterState: NameAndValue[]) => void;
}

const UseFilterV3Preview = ({ changeSubFilter, savedUseList, setSavedUseList }: UseFilterV3Props) => {
    return (
        <div css={titleBoxStyle}>
            <p className="titleText">
                용도<span>(중복 선택 가능)</span>
            </p>
            <button
                css={smallButton}
                onClick={() => {
                    changeSubFilter("mainFilter-use");
                }}
            >
                용도 선택
            </button>
            {savedUseList.length > 0 && (
                <div className="useListBox">
                    {savedUseList.map((use) => (
                        <ShowSelectedUseLocValueBox
                            key={use.value}
                            value={use.name}
                            onDeleteValue={() => {
                                const updated = savedUseList.filter((item) => item.value !== use.value);
                                setSavedUseList(updated);
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default UseFilterV3Preview;

const titleBoxStyle = css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
    padding: 0 14px;
    min-height: 56px;
    .titleText {
        color: #0c002c;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;

        span {
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.darkGrey04};
        }
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }

    .useListBox {
        width: 100%;
        max-height: 160px;
        margin: 16px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        overflow-y: scroll;
        box-sizing: border-box;
    }
`;

const smallButton = css`
    color: ${Common.colors.white};
    background: ${Common.colors.aucMarkerColor};
    border-radius: 24px;
    padding: 8px 10px;
    line-height: 14px;
    cursor: pointer;
`;
