import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useLandRegisterPdfUrl } from "./useLandRegister";

const useLandRegisterPdf = () => {
    const [searchParams] = useSearchParams();

    const id = searchParams.get("id") || "";
    const address = decodeURIComponent(searchParams.get("address")?.toString() || "");
    const fileName = `${address}_${dayjs().format("YYMMDDHHmm")}`;

    return { fileName, id, address };
};

export default useLandRegisterPdf;
