import styled from "@emotion/styled";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ico_remove_grey from "@src/assets/ico_remove_grey.svg";
import ico_arrow_left_black from "@src/assets/ico_arrow_left_black.svg";
import { Common } from "@src/styles/Common";
import { PurposeDataTypes } from "@src/hooks/filter/useGetPurpose";
import { LocationDataType, LocationsDataType } from "@src/hooks/filter/useGetLocations";
import { SelectedItemFilter } from "@src/hooks/filter/useSelectedFilter";
import { format } from "date-fns";
import { css } from "@emotion/react";
import { useFilterStore } from "@src/store/filterStore";
import produce from "immer";
import { isEmpty, result } from "lodash";

type FilterFooterPropsType = {
    purposeState?: PurposeDataTypes;
    locationState?: LocationsDataType;
    opposingPowerState?: SelectedItemFilter[];
    itemForFilterMasterState?: SelectedItemFilter[];
    dateState?: any;
    style?: any;
    appraisedPrice?: {
        appraisedPriceStartValue: {
            title: string;
            value: string;
        };
        appraisedPriceEndValue: {
            title: string;
            value: string;
        };
    };

    lowBidPrice?: {
        lowBidPriceStartValue: {
            title: string;
            value: string;
        };
        lowBidPriceEndValue: {
            title: string;
            value: string;
        };
    };
    itemForFilterAssetState?: SelectedItemFilter[];
    itemForFilterProgressState?: SelectedItemFilter[];
    itemForFilterBidCountState?: SelectedItemFilter[];
    itemForFilterDispositionState?: SelectedItemFilter[];
    history?: boolean;
    withoutState?: boolean;
    setLocationSelectedParams?: (pnu: string[]) => void;
    onClickAppraisedReset?: () => void;

    onClickLowBidPriceReset?: () => void;
    onClickRemove?: (location: string) => void;
    onClickRemovePurpose?: (selectedString: string) => void;
    onClickResetDate?: () => void;
    onClickResetMaster?: () => void;
    onClickResetOpposingPower?: () => void;
    onClickResetAsset?: () => void;
    onClickResetProgress?: () => void;
    onClickResetBidCount?: () => void;
    onClickResetDisposition?: () => void;
    onSubmit: (params: any) => void;
    onClickReset?: () => void;
    setAiSelectedLocation?: (selected: string[]) => void;
    setAiSelectedPurpose?: (selected: string[]) => void;
    setSubmitPNUData?: (selected: string[]) => void;
    type?: string;
    setSubmitPurposeData?: (selected: string[]) => void;
    bidCount?: {
        minCount: string;
        maxCount: string;
    };
    recentSearch?: boolean;
};

type ResultStringType = {
    title: string;
    id: string;
};

const FilterFooter = ({
    onClickRemove,
    onClickRemovePurpose,
    onClickReset,
    onSubmit,
    onClickAppraisedReset,
    onClickLowBidPriceReset,
    onClickResetDate,
    onClickResetMaster,
    onClickResetOpposingPower,
    onClickResetAsset,
    onClickResetProgress,
    onClickResetBidCount,
    onClickResetDisposition,
    setLocationSelectedParams,
    setAiSelectedLocation,
    setAiSelectedPurpose,
    purposeState,
    locationState,
    opposingPowerState,
    itemForFilterMasterState,
    dateState,
    appraisedPrice,
    lowBidPrice,
    itemForFilterAssetState,
    itemForFilterProgressState,
    itemForFilterBidCountState,
    itemForFilterDispositionState,
    type,
    style,
    history,
    withoutState,
    setSubmitPNUData,
    setSubmitPurposeData,
    bidCount,
    recentSearch,
}: FilterFooterPropsType) => {
    const [selectedPurpose, setSelectedPurpose] = useState<ResultStringType[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<ResultStringType[]>([]);
    const [selectedOpposingPower, setSelectedOpposingPower] = useState<ResultStringType[]>([]);
    const [selectedMaster, setSelectedMaster] = useState<ResultStringType[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [selectedAppraisedPrice, setSelectedAppraisedPrice] = useState<string>();
    const [selectedLowBidPrice, setSelectedLowBidPrice] = useState<string>();
    const [selectedProgress, setSelectedProgress] = useState<ResultStringType[]>([]);
    const [selectedBidCount, setSelectedBidCount] = useState<ResultStringType[]>([]);
    const [selectedDisposition, setSelectedDisposition] = useState<ResultStringType[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<ResultStringType[]>([]);
    const [visible, setVisible] = useState(false);
    const [subVisible, setSubVisible] = useState(false);
    const [testElement, setTestElement] = useState<any>([]);
    const keys = purposeState && Object.keys(purposeState);

    const isAll = (price: string) => {
        return price.includes("전체");
    };

    const getType = (id: string) => {
        if (id === "All") return "전체";
        if (id.includes("A")) return "주거용";
        if (id.includes("B")) return "토지";
        if (id.includes("C")) return "산업용";
        if (id.includes("D")) return "상업/업무용";
        if (id.includes("E")) return "기타";

        return "";
    };

    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const filterState = useFilterStore((state) => state.filterState);

    // 소재지용
    useEffect(() => {
        const selectedLocationId = selectedLocation.map((item) => item.id);
        const locationStateChildren = locationState?.city.map((item) => item.children);
        const isNotHaveChildren = locationStateChildren?.every((children) => isEmpty(children));

        if (!isNotHaveChildren) {
            locationState?.city.forEach((city) => {
                city.children?.map((dvsn: LocationDataType) => {
                    if (dvsn.isActive === false && dvsn.cortarNo.includes("All")) {
                        setSelectedLocation((prevState) => prevState.filter((item) => item.id !== dvsn.cortarNo));
                        return;
                    }

                    if (dvsn.isActive === true && !selectedLocationId.includes(dvsn.cortarNo) && dvsn.cortarNo.includes("All")) {
                        setSelectedLocation((prev) =>
                            prev.concat({
                                title: `${city.cortarName}>${dvsn.cortarName}`,
                                id: dvsn.cortarNo,
                            }),
                        );
                        return;
                    }
                    dvsn.children.forEach((sec: LocationDataType) => {
                        if (sec.isActive === false) {
                            setSelectedLocation((prevState) => prevState.filter((item) => item.id !== sec.cortarNo));
                            return;
                        }

                        if (sec.isActive === true && !selectedLocationId.includes(sec.cortarNo)) {
                            setSelectedLocation((prev) =>
                                prev.concat({
                                    title: `${city.cortarName}>${dvsn.cortarName}>${sec.cortarName}`,
                                    id: sec.cortarNo,
                                }),
                            );
                            return;
                        }
                    });
                });
            });
        }
    }, [locationState]);

    // 소재지
    useEffect(() => {
        if (type !== "AI" && type === "부분필터" && !history && locationState) {
            const nextState = produce(filterState, (draft) => {
                draft.loc = selectedLocation.map((item) => item.id);
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedLocation]);

    // 용도용
    useEffect(() => {
        const selectedPurposeId = selectedPurpose.map((selectedPurpose) => selectedPurpose.id);

        if (purposeState) {
            keys?.forEach((key) => {
                purposeState[key].children.forEach((child) => {
                    if (!child.isActive) {
                        setSelectedPurpose((prevState) => prevState.filter((item) => item.id !== child.id));
                    }

                    if (child.isActive && !selectedPurposeId.includes(child.id)) {
                        setSelectedPurpose((prev) => prev.concat({ title: `${getType(child.id)}>${child.title}`, id: child.id }));
                    }
                });
            });
        }
    }, [purposeState]);

    // 용도
    useEffect(() => {
        const selectedItemId = selectedPurpose.map((item) => item.id);
        const isSame = JSON.stringify(selectedItemId) === JSON.stringify(filterState.use);

        if (!isSame) {
            if (type === "부분필터" && purposeState) {
                const nextState = produce(filterState, (draft) => {
                    draft.use = selectedPurpose.map((item) => item.id);
                });

                setStoreFilterState(nextState, true);
            }
        }
    }, [selectedPurpose]);

    // 대항력
    useEffect(() => {
        if (opposingPowerState) {
            opposingPowerState.forEach((item) => {
                if (item.isActive) {
                    setSelectedOpposingPower([item]);
                }
            });
        }
    }, [opposingPowerState]);

    // 대항력
    useEffect(() => {
        if (type === "부분필터" && opposingPowerState && !isEmpty(selectedOpposingPower)) {
            const nextState = produce(filterState, (draft) => {
                draft.counter_force_list = [Number(selectedOpposingPower[0]?.id)];
            });
            setStoreFilterState(nextState, true);
        }
    }, [selectedOpposingPower]);

    // 경매고수물건
    useEffect(() => {
        if (itemForFilterMasterState) {
            const result: ResultStringType[] = [];

            itemForFilterMasterState.forEach((item) => {
                if (item.isActive) {
                    result.push(item);
                }
            });

            setSelectedMaster(result);
        }
    }, [itemForFilterMasterState]);

    // 경매고수물건
    useEffect(() => {
        if (type === "부분필터" && itemForFilterMasterState && !isEmpty(selectedMaster)) {
            const nextState = produce(filterState, (draft) => {
                draft.auction_master_item = selectedMaster.map((item) => Number(item.id));
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedMaster]);

    // 진행상태
    useEffect(() => {
        if (itemForFilterProgressState) {
            const result: ResultStringType[] = [];
            itemForFilterProgressState.forEach((item) => {
                if (item.isActive) {
                    result.push(item);
                }
            });

            setSelectedProgress(result);
        }
    }, [itemForFilterProgressState]);

    // 진행상태
    useEffect(() => {
        if (type === "부분필터" && itemForFilterProgressState && !isEmpty(selectedProgress)) {
            const nextState = produce(filterState, (draft) => {
                const result = selectedProgress.map((item) => Number(item.id));
                draft.status = result.length === 0 ? [] : result;
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedProgress]);

    // 자산구분
    useEffect(() => {
        if (itemForFilterAssetState) {
            const result: ResultStringType[] = [];
            itemForFilterAssetState.forEach((item) => {
                if (item.isActive) {
                    result.push(item);
                }
            });
            setSelectedAsset(result);
        }
    }, [itemForFilterAssetState]);

    // 처분방식
    useEffect(() => {
        if (itemForFilterDispositionState) {
            itemForFilterDispositionState.forEach((item) => {
                if (item.isActive) {
                    setSelectedDisposition([item]);
                }
            });
        }
    }, [itemForFilterDispositionState]);

    // 자산구분 처분방식
    useEffect(() => {
        const selectedAssetsId = selectedAsset.map((item) => Number(item.id));
        if (
            (type === "부분필터" && !isEmpty(selectedDisposition) && itemForFilterAssetState) ||
            (type === "부분필터" && itemForFilterDispositionState)
        ) {
            const nextState = produce(filterState, (draft) => {
                draft.assets = selectedAssetsId[0] === 0 ? [] : selectedAssetsId;
                draft.sales_method = selectedDisposition.map((item) => Number(item.id))[0];
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedDisposition, selectedAsset]);

    // 유찰횟수
    useEffect(() => {
        if (itemForFilterBidCountState) {
            itemForFilterBidCountState.forEach((item) => {
                if (item.isActive) {
                    setSelectedBidCount([item]);
                }
            });
        }
    }, [itemForFilterBidCountState]);

    // 유찰횟수
    useEffect(() => {
        if (type === "부분필터" && itemForFilterBidCountState && !isEmpty(selectedBidCount)) {
            const nextState = produce(filterState, (draft) => {
                draft.skp_type = Number(itemForFilterBidCountState.filter((item) => item.isActive)[0].id);
            });
            setStoreFilterState(nextState, true);
        }
    }, [selectedBidCount]);

    // 매각기일
    useEffect(() => {
        if (dateState) {
            const startDate = dateState[0].startDate && format(dateState[0].startDate, "yyyy-MM-dd").substring(2);
            const endDate = dateState[0].endDate && format(dateState[0].endDate, "yyyy-MM-dd").substring(2);
            if (startDate === null) {
                setSelectedDate("");
            } else {
                if (startDate === endDate) {
                    setSelectedDate(`매각기일 ${startDate}`);
                } else {
                    setSelectedDate(`매각기일 ${startDate} ~ ${endDate}`);
                }
            }
        }
    }, [dateState]);

    // 감정가
    useEffect(() => {
        if (appraisedPrice) {
            if (appraisedPrice.appraisedPriceStartValue.value.includes("higher")) {
                setSelectedAppraisedPrice(`${appraisedPrice.appraisedPriceEndValue.title}`);
                return;
            }

            if (appraisedPrice.appraisedPriceEndValue !== undefined && appraisedPrice.appraisedPriceStartValue !== undefined) {
                if (appraisedPrice.appraisedPriceStartValue.title === appraisedPrice.appraisedPriceEndValue.title) {
                    setSelectedAppraisedPrice(
                        `~ ${appraisedPrice?.appraisedPriceStartValue?.title}
                         `,
                    );
                    return;
                }
                if (appraisedPrice.appraisedPriceStartValue.title && appraisedPrice.appraisedPriceEndValue.value === "") {
                    setSelectedAppraisedPrice(
                        `~ ${appraisedPrice?.appraisedPriceStartValue?.title}
                         `,
                    );
                    return;
                }
                if (appraisedPrice.appraisedPriceStartValue.value === "" && appraisedPrice.appraisedPriceEndValue.value) {
                    setSelectedAppraisedPrice(
                        `~ ${appraisedPrice?.appraisedPriceEndValue?.title}
                         `,
                    );
                    return;
                }
                setSelectedAppraisedPrice(
                    `${appraisedPrice?.appraisedPriceStartValue?.title}
                     ~ ${appraisedPrice?.appraisedPriceEndValue?.title}`,
                );
            }
        }
    }, [appraisedPrice]);

    // 감정가
    useEffect(() => {
        if (appraisedPrice && !isEmpty(selectedAppraisedPrice)) {
            const nextState = produce(filterState, (draft) => {
                const startValue = appraisedPrice.appraisedPriceStartValue.value;
                const endValue = appraisedPrice.appraisedPriceEndValue?.value;

                if (!endValue) {
                    draft.est_price = ["", startValue];
                    return;
                }

                draft.est_price = [startValue, endValue];
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedAppraisedPrice]);

    // 최저입찰가
    useEffect(() => {
        if (lowBidPrice) {
            if (lowBidPrice.lowBidPriceStartValue.value.includes("higher")) {
                setSelectedLowBidPrice(`${lowBidPrice.lowBidPriceEndValue.title}`);
                return;
            }
            if (lowBidPrice.lowBidPriceEndValue !== undefined && lowBidPrice.lowBidPriceStartValue !== undefined) {
                if (lowBidPrice.lowBidPriceStartValue.title === lowBidPrice.lowBidPriceEndValue.title) {
                    setSelectedLowBidPrice(
                        `~ ${lowBidPrice?.lowBidPriceStartValue?.title}
                         `,
                    );
                    return;
                }
                if (lowBidPrice.lowBidPriceStartValue.title && lowBidPrice.lowBidPriceEndValue.value === "") {
                    setSelectedLowBidPrice(
                        `~ ${lowBidPrice?.lowBidPriceStartValue?.title}
                         `,
                    );
                    return;
                }
                if (lowBidPrice.lowBidPriceStartValue.value === "" && lowBidPrice.lowBidPriceEndValue.value) {
                    setSelectedLowBidPrice(
                        `~ ${lowBidPrice?.lowBidPriceEndValue?.title}
                         `,
                    );
                    return;
                }
                setSelectedLowBidPrice(
                    `${lowBidPrice?.lowBidPriceStartValue?.title}
                 ~ ${lowBidPrice?.lowBidPriceEndValue?.title}`,
                );
            }
        }
    }, [lowBidPrice]);

    // 최저입찰가
    useEffect(() => {
        if (lowBidPrice && !isEmpty(selectedLowBidPrice)) {
            const nextState = produce(filterState, (draft) => {
                const startValue = lowBidPrice.lowBidPriceStartValue.value;
                const endValue = lowBidPrice.lowBidPriceEndValue?.value;

                if (!endValue) {
                    draft.min_price = ["", startValue];
                    return;
                }

                draft.min_price = [startValue, endValue];
            });

            setStoreFilterState(nextState, true);
        }
    }, [selectedLowBidPrice]);

    const container: any = useRef();
    const subContainer: any = useRef();

    useEffect(() => {
        if (container?.current) {
            const result = container?.current?.childNodes;
            setTestElement(result);
            setVisible(result.length);
        }

        if (subContainer?.current) {
            const result = subContainer?.current?.childNodes;
            setSubVisible(result.length);
        }
    }, [
        selectedPurpose,
        selectedLocation,
        selectedOpposingPower,
        selectedMaster,
        selectedDate,
        selectedAppraisedPrice,
        selectedLowBidPrice,
        selectedProgress,
        selectedBidCount,
        selectedDisposition,
        selectedAsset,
    ]);

    useEffect(() => {
        if (setAiSelectedLocation) {
            setAiSelectedLocation(selectedLocation.map((item) => item.id));
        }
        setSubmitPNUData && setSubmitPNUData(selectedLocation.map((item) => item.id));
    }, [selectedLocation]);

    useEffect(() => {
        if (setAiSelectedPurpose) {
            setAiSelectedPurpose(selectedPurpose.map((item) => item.id));
        }

        setSubmitPurposeData && setSubmitPurposeData(selectedPurpose.map((item) => item.id));
    }, [selectedPurpose, purposeState]);

    return (
        <Container type={type || ""} style={style}>
            <LocationArea ref={container} visible={visible} history={history}>
                {selectedLocation &&
                    onClickRemove &&
                    selectedLocation.map((data, index) => {
                        const splitLocationData = data.title.split(">");
                        return (
                            <LocationItem key={index}>
                                <span>
                                    {splitLocationData.map((item, index) => (
                                        <Fragment key={`${item}_${index}`}>
                                            {item}{" "}
                                            {index + 1 !== splitLocationData.length && (
                                                <img className="ico_arrow" src={ico_arrow_left_black} />
                                            )}
                                        </Fragment>
                                    ))}
                                </span>
                                {!recentSearch && (
                                    <img
                                        src={ico_remove_grey}
                                        onClick={() => {
                                            onClickRemove(data.id);
                                        }}
                                    />
                                )}
                            </LocationItem>
                        );
                    })}

                {selectedPurpose &&
                    selectedPurpose[0]?.id !== "All" &&
                    onClickRemovePurpose &&
                    selectedPurpose.map((data, index) => {
                        const splitPurposeData = data.title.split(">");
                        return (
                            <LocationItem key={index}>
                                <span>
                                    {splitPurposeData.map((item, index) => (
                                        <Fragment key={`${item}_${index}`}>
                                            {item}{" "}
                                            {index + 1 !== splitPurposeData.length && (
                                                <img className="ico_arrow" src={ico_arrow_left_black} />
                                            )}
                                        </Fragment>
                                    ))}
                                </span>
                                <img
                                    src={ico_remove_grey}
                                    onClick={() => {
                                        onClickRemovePurpose(data.title);
                                    }}
                                />
                            </LocationItem>
                        );
                    })}
            </LocationArea>

            <LocationArea ref={subContainer} visible={subVisible}>
                {/* 대항력 */}
                {selectedOpposingPower &&
                    onClickResetOpposingPower &&
                    selectedOpposingPower[0]?.id !== "0" &&
                    selectedOpposingPower.map((data, index) => (
                        <LocationItem key={index} type={"selected"}>
                            <span>{data.title}</span>
                            <img
                                src={ico_remove_grey}
                                onClick={() => {
                                    onClickResetOpposingPower();
                                }}
                            />
                        </LocationItem>
                    ))}

                {/* 경매고수 물건 */}
                {selectedMaster.length && onClickResetMaster && !selectedMaster[0].title.includes("전체") ? (
                    <LocationItem type={"selected"}>
                        <span>{`${selectedMaster[0].title} ${
                            withoutState
                                ? selectedMaster.length > 1
                                    ? `외 ${selectedMaster.length - 1} 제외`
                                    : "제외"
                                : selectedMaster.length > 1
                                ? `외 ${selectedMaster.length - 1}`
                                : ""
                        }`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickResetMaster();
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}

                {/* 진행상태 */}
                {selectedProgress.length && onClickResetProgress && !selectedProgress[0].title.includes("전체") ? (
                    <LocationItem type={"selected"}>
                        <span>{`${selectedProgress[0].title} ${
                            selectedProgress.length > 1 ? `외 ${selectedProgress.length - 1}` : ""
                        }`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickResetProgress();
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}

                {/* 유찰횟수 */}
                {selectedBidCount[0]?.id !== "0" &&
                    onClickResetBidCount &&
                    selectedBidCount.map((data, index) => {
                        return (
                            <LocationItem key={index} type={"selected"}>
                                <span>
                                    {data.title}{" "}
                                    {data.id === "2" &&
                                        (bidCount?.minCount || bidCount?.maxCount) &&
                                        `${bidCount?.minCount} ~ ${bidCount?.maxCount}`}
                                </span>
                                <img
                                    src={ico_remove_grey}
                                    onClick={() => {
                                        onClickResetBidCount();
                                    }}
                                />
                            </LocationItem>
                        );
                    })}

                {/* 매각기일 */}
                {selectedDate?.length && onClickResetDate && selectedDate !== "매각기일 null ~ null" ? (
                    <LocationItem type={"selected"}>
                        <span>{`${selectedDate}`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickResetDate();
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}
                {/* 감정가 */}
                {selectedAppraisedPrice?.length && onClickAppraisedReset && !isAll(selectedAppraisedPrice) ? (
                    <LocationItem type={"selected"}>
                        <span>{`감정가 ${selectedAppraisedPrice}`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickAppraisedReset();
                                setSelectedAppraisedPrice("");
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}

                {/* 최저입찰가 */}
                {selectedLowBidPrice?.length && onClickLowBidPriceReset && !isAll(selectedLowBidPrice) ? (
                    <LocationItem type={"selected"}>
                        <span>{`최저입찰가 ${selectedLowBidPrice}`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickLowBidPriceReset();
                                setSelectedLowBidPrice("");
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}

                {/* 처분방식 */}
                {selectedDisposition[0]?.id !== "0" &&
                    onClickResetDisposition &&
                    selectedDisposition.map((data, index) => (
                        <LocationItem key={index} type={"selected"}>
                            <span>{data.title}</span>
                            <img
                                src={ico_remove_grey}
                                onClick={() => {
                                    onClickResetDisposition();
                                }}
                            />
                        </LocationItem>
                    ))}

                {/* 자산구분 */}
                {selectedAsset.length && onClickResetAsset && !selectedAsset[0].title.includes("전체") ? (
                    <LocationItem type={"selected"}>
                        <span>{`${selectedAsset[0].title} ${
                            selectedAsset.length > 1 ? `외 ${selectedAsset.length - 1}` : ""
                        }`}</span>
                        <img
                            src={ico_remove_grey}
                            onClick={() => {
                                onClickResetAsset();
                            }}
                        />
                    </LocationItem>
                ) : (
                    <></>
                )}
            </LocationArea>

            {type !== "부분필터" && type !== "AI" ? (
                <ButtonArea>
                    <button onClick={() => onClickReset && onClickReset()}>
                        <span>초기화</span>
                    </button>
                    <button
                        className="active"
                        onClick={() => {
                            onSubmit({
                                loc: selectedLocation.map((item) => item.id),
                                use: selectedPurpose.map((item) => item.id),
                                counter_force: selectedOpposingPower.map((item) => item.id),
                                auction_master_item: selectedMaster.map((item) => item.id),
                                status: selectedProgress.map((item) => item.id),
                                skp_type: selectedBidCount.map((item) => item.id),
                                due_date: [
                                    dateState[0].startDate ? format(dateState[0].startDate, "yyyy-MM-dd") : "",
                                    dateState[0].endDate ? format(dateState[0]?.endDate, "yyyy-MM-dd") : "",
                                ],
                                est_price: [
                                    appraisedPrice?.appraisedPriceStartValue.value || "",
                                    appraisedPrice?.appraisedPriceEndValue.value || "",
                                ],
                                min_price: [
                                    lowBidPrice?.lowBidPriceStartValue.value || "",
                                    lowBidPrice?.lowBidPriceEndValue.value || "",
                                ],
                                sales_method: selectedDisposition.map((item) => item.id),
                                assets: selectedAsset.map((item) => item.id),
                            });
                        }}
                    >
                        <span>적용하기</span>
                    </button>
                </ButtonArea>
            ) : (
                <></>
            )}
        </Container>
    );
};

export default FilterFooter;

const Container = styled.div<{ type: string }>`
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 9999;
    background: white;
    ${({ type }) => {
        if (type === "부분필터" || type === "AI") {
            return css`
                position: initial;
            `;
        }
    }}
`;

const ButtonArea = styled.div`
    display: flex;
    height: 94px;
    padding: 14px 14px 24px 14px;

    button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #c3c3c3;

        span {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
        }
    }

    button.active {
        background: ${Common.colors.aucMarkerColor};
    }
    button + button {
        margin-left: 12px;
    }
`;

export const LocationArea = styled.div<{ visible?: boolean; history?: boolean }>`
    display: flex;
    align-items: center;
    height: ${({ visible }) => (visible ? "52px" : "0px")};
    overflow-x: ${({ history }) => (history ? "inherit" : "scroll")};
    padding: 0px 14px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

export const LocationItem = styled.div<{ type?: string }>`
    display: flex;
    height: 36px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    background: #f6faff;
    background: ${({ type }) => (type === "selected" ? "#F7F8FA" : "#f6faff")};
    padding: 0px 14px;
    min-width: fit-content;

    span {
        display: flex;
        font-size: 13px;
        letter-spacing: 0px;
        color: #0c002c;
    }

    .ico_arrow {
        width: 11px;
        height: 12px;
        margin: 0 4px;
        transform: rotate(180deg);
    }
    img {
        width: 16px;
        height: 16px;
        margin-left: 9px;
        cursor: pointer;
    }

    & + & {
        margin-left: 8px;
    }
`;
