import { css } from "@emotion/react";
import { IFilterParamsType } from "@src/store/newFilterStore";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
    makeAssetsText,
    makeConterForceText,
    makeConterForceTextBefore,
    makeDueDateText,
    makeLocText,
    makeMasterItemText,
    makeMethodText,
    makePriceText,
    makeSkpText,
    makeStatusText,
    makeTypeText,
    makeUseText,
} from "./makeFilterToStr";

interface Props {
    value: IFilterParamsType;
    hash: string;
    more: boolean;
}

const FilterHistotyList: React.FC<Props> = ({ value, hash, more }) => {
    const [typeText, setTypeText] = useState(""); // 경공매
    const [assetsList, setAssetsList] = useState<string[]>([]);
    const [methodText, setMethodText] = useState("");
    const [showLocText, setShowLocText] = useState("전체"); // 소재지
    const [useList, setUseList] = useState<string[]>([]); // 용도
    const [counterText, setCounterText] = useState(""); // 대항력(이전)
    const [counterList, setCounterList] = useState<string[]>([]); // 대항력
    const [masterItemList, setMasterItemList] = useState<string[]>([]); // 경매고수물건
    const [statusList, setStatusList] = useState<string[]>([]); // 진행상태
    const [skpList, setSkpList] = useState<string[]>([]); // 유찰횟수
    const [dueDate, setDueDate] = useState("전체"); // 매각기일
    const [estPrice, setEstPrice] = useState("전체"); // 감정가
    const [minPrice, setMinPrice] = useState("전체"); // 최저가

    // 줄바꿈이 일어날때마다 border를 생성하기 위한 변수
    const useListRef = useRef<HTMLDivElement>(null); //
    const [useBorderList, setUseBorderList] = useState<string[]>([]);
    const expertListRef = useRef<HTMLDivElement>(null); //
    const [expertBorderList, setExpertBorderList] = useState<string[]>([]);
    const statusListRef = useRef<HTMLDivElement>(null); //
    const [statusBorderList, setStatusBorderList] = useState<string[]>([]);

    // 요소의 높이에 맞춰서 borderlist를 생성해주는 함수
    function makeBorderLine(el: HTMLDivElement, setState: Dispatch<SetStateAction<string[]>>) {
        let arr = [];
        const height = el.clientHeight / 32;
        for (let i = 0; i < height; i++) {
            let num = 32 * (i + 1);
            arr.push(`${num - 6}px`);
        }
        setState(arr);
    }

    useEffect(() => {
        if (useListRef.current) makeBorderLine(useListRef.current, setUseBorderList);
        if (expertListRef.current) makeBorderLine(expertListRef.current, setExpertBorderList);
        if (statusListRef.current) makeBorderLine(statusListRef.current, setStatusBorderList);
    }, [useListRef, more]);

    const settingLocText = async () => {
        if (value.loc[0]) {
            const result = await makeLocText(value.loc[0]);
            setShowLocText(result.name);
        }
    };
    // 최근검색 기록 변환 및 저장
    function initSetState(data: IFilterParamsType) {
        let typeStr = makeTypeText(data.type);
        let methodStr = makeMethodText(data.sales_method);
        let assetArr = makeAssetsText(data.assets);
        let useArr = makeUseText(data.use);
        let masterArr = makeMasterItemText(data.auction_master_item);
        let statusArr = makeStatusText(data.status);
        let skpArr = makeSkpText(data.skp_type, data.skp_cnt);
        let dueDateStr = makeDueDateText(data.due_date);
        let estStr = makePriceText(data.est_price);
        let minStr = makePriceText(data.min_price);

        setTypeText(typeStr);
        setMethodText(methodStr);
        setAssetsList(assetArr);
        settingLocText();
        setUseList(useArr);
        setMasterItemList(masterArr);
        setStatusList(statusArr);
        setSkpList(skpArr);
        setDueDate(dueDateStr);
        setEstPrice(estStr);
        setMinPrice(minStr);

        // 이전 필터에서 사용하던 대항력 필터 예외처리
        if (data.counter_force) {
            let counterStr = makeConterForceTextBefore(data.counter_force);
            setCounterText(counterStr);
        } else {
            let counterArr = makeConterForceText(data.counter_force_list);
            setCounterList(counterArr);
            1;
        }
    }

    useEffect(() => {
        if (value) {
            initSetState(value);
        }
    }, [value]);

    // 문자열 배열을 span으로 감싸주는 함수
    function listToSpan(arr: string[]) {
        const spans = arr?.map((aa, index) => {
            return <span key={`${index}${hash}`}>{aa}</span>;
        });

        return spans;
    }

    const useBorders = useBorderList?.map((aa, index) => {
        return <div className="border" key={`useBorder${index}`} style={{ top: aa }}></div>;
    });
    const expertBorders = expertBorderList?.map((aa, index) => {
        return <div className="border" key={`expertBorder${index}`} style={{ top: aa }}></div>;
    });
    const statusBorders = statusBorderList?.map((aa, index) => {
        return <div className="border" key={`statusBorder${index}`} style={{ top: aa }}></div>;
    });

    return (
        <div css={summaryBoxStyle}>
            <div className="row">
                <p className="title">경공매</p>
                <p className="content">
                    <span>{typeText}</span>
                </p>
            </div>
            {typeText === "공매" && (
                <div className="row">
                    <p className="title">처분방식</p>
                    <p className="content">
                        <span>{methodText}</span>
                    </p>
                </div>
            )}
            {typeText === "공매" && (
                <div className="row">
                    <p className="title">자산구분</p>
                    <p className="content">{listToSpan(assetsList)}</p>
                </div>
            )}
            <div className="row">
                <p className="title">소재지</p>
                <p className="content">
                    <span>{showLocText}</span>
                </p>
            </div>
            <div className="row" ref={useListRef}>
                <p className="title">용도</p>
                <p className="content">{listToSpan(useList)}</p>
                <div className="borderBox">{useBorders}</div>
            </div>
            <div className="row">
                <p className="title">대항력</p>
                <p className="content">{counterText !== "" ? <span>{counterText}</span> : <>{listToSpan(counterList)}</>}</p>
            </div>
            <div className="row" ref={expertListRef}>
                <p className="title">경매고수물건</p>
                <p className="content">{listToSpan(masterItemList)}</p>
                <div className="borderBox">{expertBorders}</div>
            </div>
            <div className="row" ref={statusListRef}>
                <p className="title">진행상태</p>
                <p className="content">{listToSpan(statusList)}</p>
                <div className="borderBox">{statusBorders}</div>
            </div>
            <div className="row">
                <p className="title">유찰횟수</p>
                <p className="content">{listToSpan(skpList)}</p>
            </div>
            <div className="row">
                <p className="title">매각기일</p>
                <p className="content">
                    <span>{dueDate}</span>
                </p>
            </div>
            <div className="row">
                <p className="title">감정가</p>
                <p className="content">
                    <span>{estPrice}</span>
                </p>
            </div>
            <div className="row">
                <p className="title">최저입찰가</p>
                <p className="content">
                    <span>{minPrice}</span>
                </p>
            </div>
        </div>
    );
};

export default FilterHistotyList;

const summaryBoxStyle = css`
    background-color: #ffffff;
    padding: 8px 14px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: max-content;
    border-radius: 25px;
    padding-bottom: 42px;

    .row {
        display: flex;
        align-items: flex-start;
        padding: 6px 0;
        border-bottom: 1px solid rgba(215, 215, 215);
        position: relative;
        gap: 6px;

        p {
            font-size: 12px;
            display: flex;
            align-items: center;
        }

        .borderBox {
            width: 100%;
            height: 100%;
            position: absolute;
            .border {
                width: 100%;
                height: 1px;
                background: rgba(215, 215, 215);
                position: absolute;
            }
        }

        .title {
            width: 70px;
            flex-shrink: 0;
            font-weight: 700;
            height: 20px;
        }
        .content {
            flex: 1;
            font-weight: 500;
            flex-wrap: wrap;
            gap: 6px;
            row-gap: 12px;

            span {
                flex-shrink: 0;
                display: inline-flex;
                border-radius: 100px;
                height: 20px;
                align-items: center;
                padding: 0 8px;
                background: #f6faff;
                color: #0c002c;
            }
        }
    }
`;
