import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useFindAllFilterHistory, useRemoverFilterHistoryAll } from "@src/hooks/item/useGetItem";
import { IFilterParamsType, NameAndValue } from "@src/store/newFilterStore";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import clearIcon from "@src/assets/clearIcon.svg";
import trashCan_white from "@src/assets/trashCan_white.svg";
import RecentFilterHistoryItem from "./RecentFilterHistoryItem";
import { IRegion } from "@src/hooks/newFilter/useGetLocation";
import { Common } from "@src/styles/Common";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
    onClose: () => void;
    setFilter: (filter: IFilterParamsType) => void;
    setSavedLocation: (filterState: NameAndValue | null) => void;
    setSavedUseList: (filterState: NameAndValue[]) => void;
    setTempNameAndNo: (filterState: IRegion[]) => void;
}

export interface historyType {
    hash: string;
    no: number;
    updated_at: string;
    value: IFilterParamsType;
}

const RecentFilterHistory: React.FC<Props> = ({ onClose, setFilter, setSavedLocation, setSavedUseList, setTempNameAndNo }) => {
    const { pathname } = useLocation();
    const filterName = pathname.includes("mapFilter") ? "지도종합" : "매물상세";
    const { data, refetch } = useFindAllFilterHistory(false);

    const removeFilterHistory = useRemoverFilterHistoryAll(
        () => {
            refetch();
        },
        (err) => {
            console.log("err>", err);
        },
    );

    useEffect(() => {
        refetch();
    }, []);

    const historyContent = data?.map(
        (history: historyType) =>
            history?.value?.type !== undefined && (
                <RecentFilterHistoryItem
                    key={history.hash}
                    history={history}
                    refetch={refetch}
                    onClose={onClose}
                    open={data[0].hash === history.hash}
                    setFilter={setFilter}
                    setSavedLocation={setSavedLocation}
                    setSavedUseList={setSavedUseList}
                    setTempNameAndNo={setTempNameAndNo}
                />
            ),
    );

    return (
        <div css={rootBoxStyle}>
            <div css={headerStyle}>
                <h3>최근 검색 조건</h3>
                <div className="btns">
                    <div
                        className="delete"
                        onClick={() => {
                            removeFilterHistory.mutate();
                        }}
                    >
                        <p>전체삭제</p>
                        <div className="img_box">
                            <img src={trashCan_white} alt="delete" />
                        </div>
                    </div>
                    <div className="close img_box" onClick={onClose}>
                        <img src={clearIcon} alt="close" />
                    </div>
                </div>
            </div>
            <p className="notice">※ {filterName}검색설정 변경 후 &#34;적용하기&#34; 클릭 시 저장됩니다.</p>
            {data && data?.length ? (
                <ContainerWrap>{historyContent}</ContainerWrap>
            ) : (
                <div css={emptyBoxStyle}>
                    <p className="noDataText">최근 검색한 조건 목록이 없어요</p>
                    <p className="noticeText"> 필터를 이용해 물건을 검색해 보세요!</p>
                </div>
            )}
        </div>
    );
};

export default RecentFilterHistory;

const rootBoxStyle = css`
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100dvh - 116px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 40px;

    .notice {
        color: ${Common.colors.darkGrey04};
        font-size: 12px;
        padding-left: 14px;
    }
`;

const emptyBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 120px 0px;
    background-color: #ffffff;
    color: #3c3c3c;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid ${Common.colors.darkGrey04};
    margin: 14px;
    border-radius: 14px;

    .noDataText {
        font-size: 14px;
        color: ${Common.colors.normalTextColor};
    }

    .noticeText {
        font-size: 12px;
        color: ${Common.colors.darkGrey04};
    }
`;

const headerStyle = css`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
        font-size: 18px;
        line-height: 28px;
        padding: 0 14px;
    }

    .img_box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .btns {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 0 24px;
        .delete {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-size: 14px;
            line-height: 24px;
            background-color: ${Common.colors.blue};
            border-radius: 24px;
            color: #ffffff;
            padding: 0px 8px;
            white-space: nowrap;

            .img_box {
                width: 16px;
                height: 16px;
            }
        }
        .close {
            width: 14px;
            height: 14px;
        }
    }
`;

const ContainerWrap = styled.div`
    overflow-y: auto;
    padding: 14px;
`;
