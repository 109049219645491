import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { IGoStopResListDto } from "@src/hooks/goStop/dto/goStop.dto";
import GoIcon from "@src/assets/goStop/goIcon.png";
import StopIcon from "@src/assets/goStop/stopIcon.png";
import WarningIcon from "@src/assets/goStop/warningIcon.png";
import LikeGrey from "@src/assets/ico_like_grey.png";
import LikeRed from "@src/assets/ico_like_red.png";
import ico_price_down from "@src/assets/ico_price_down_bold.svg";
import ico_price_up from "@src/assets/ico_price_up.svg";
import noImage from "@src/assets/noImg.png";
import { Common } from "@src/styles/Common";

const GoStopListCard = ({ item, goStopType }: { item: IGoStopResListDto; goStopType: string[] }) => {
    const [viewRate, setViewRate] = useState("");
    const [change, setChange] = useState("");

    useEffect(() => {
        if (item?.rate === "(0%)") {
            setViewRate("0%");
            setChange("normal");
        }
        if (item?.rate.includes("-")) {
            const rate2 = item?.rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("-", "");
            setViewRate(setResult);
            setChange("degradation");
        }
        if (item?.rate.includes("+")) {
            const rate2 = item?.rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("+", "");
            setViewRate(setResult);
            setChange("increase");
        }
    }, [item?.rate]);

    return (
        <div css={cardWrapper}>
            <div className="cardContainer">
                <div className="titleWrap">
                    <div className="titleContainer">
                        <p css={bidTypeTextStyle(item?.bid_type_str)}>{item?.bid_type_str}</p>
                        <b>{item?.case_number}</b>
                    </div>
                    <div className="titleContainer">
                        <p>{item?.due_date_str}</p>
                        <span>{item?.d_day}</span>
                    </div>
                </div>
                <div className="descWrap">
                    <div className="imgWrap">
                        {item?.image ? (
                            <img className="imgContainer" src={item?.image} alt="image" />
                        ) : (
                            <>
                                <img className="imgContainer" src={noImage} alt="image" />
                                <div className="imgBack"> </div>
                            </>
                        )}

                        {item?.gostop === "GO" ? (
                            <img className="imgIcon" src={GoIcon} alt="GO Icon" />
                        ) : item?.gostop === "STOP" ? (
                            <img className="imgIcon" src={StopIcon} alt="STOP Icon" />
                        ) : item?.gostop === "주의" ? (
                            <img className="imgIcon" src={WarningIcon} alt="주의 Icon" />
                        ) : null}
                        {item?.skp_cnt_str === "신건" ? (
                            <div className="imgNewSkpCnt">{item?.skp_cnt_str}</div>
                        ) : (
                            <div className="imgSkpCnt">{item?.skp_cnt_str}</div>
                        )}
                    </div>
                    <div className="descMainWrap">
                        <div className="descUse">{item?.use}</div>
                        <div className="descAddress">{item?.address}</div>
                        <div className="descEstPriceWrap">
                            <span>감정가 </span>
                            <p> {item?.est_price_str}</p>
                        </div>
                        <div className="descMinPriceWrap">
                            <span>최저가</span>
                            <b>
                                {item?.min_price_str === "비공개" ? "-" : item?.min_price_str}{" "}
                                {item?.rate !== "(0%)" ? (
                                    <span className="price_percent">
                                        {change === "normal" && (
                                            <>
                                                <span style={{ color: "black", fontSize: "13px" }}>{viewRate}</span>
                                            </>
                                        )}
                                        {change === "degradation" && (
                                            <>
                                                <img src={ico_price_down} alt="상승률 하락률" />
                                                <span style={{ color: `${Common.colors.blue}` }}>{viewRate}</span>
                                            </>
                                        )}
                                        {change === "increase" && (
                                            <>
                                                <img src={ico_price_up} alt="상승률 하락률" />
                                                <span style={{ color: `${Common.colors.notiColor}` }}>{viewRate}</span>
                                            </>
                                        )}
                                    </span>
                                ) : null}
                            </b>
                        </div>
                        {item?.somethings.length !== 0 && (
                            <div className="descSomethingWrap">
                                {item?.somethings?.map((something: any, idx: number) => {
                                    return (
                                        <div className="descSomethingContainer" key={idx}>
                                            {something}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className="descProfitWrap">
                            <div className="container">
                                {goStopType[0] !== "STOP" && item?.profit !== "0원" && item?.profit?.slice(0, 1) !== "-" && (
                                    <>
                                        <span>수익예상</span>
                                        <p>{item?.profit}</p>
                                    </>
                                )}
                            </div>
                            <img className="likeImg" src={item?.is_favorited ? LikeRed : LikeGrey} alt="like" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoStopListCard;

const cardWrapper = css`
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid#dadada;
    .cardContainer {
        display: flex;
        flex-direction: column;
    }
    .titleWrap {
        display: flex;
        width: 100vw;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        .titleContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }
        p {
            color: #3c3c3c;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            text-align: center;
        }
        span {
            color: #f00;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: max-content;
        }
        b {
            color: #282828;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
        }
    }
    .descWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: fit-content;
    }
    .imgWrap {
        position: relative;
        padding: 0 14px;
        height: 140px;
        .imgBack {
            position: absolute;
            top: 0;
            left: 14px;
            width: 140px;
            height: 140px;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.5);
        }
        .imgContainer {
            width: 140px;
            height: 140px;
            border-radius: 5px;
        }
        .imgIcon {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 85px;
            width: 70px;
            height: 70px;
        }
        .imgDesc {
            position: absolute;
            top: 6px;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 22px;
            border-radius: 4px;
            padding: 0 8px;
            background: #ffe3ac;
            color: #0c002c;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
        .imgSkpCnt {
            position: absolute;
            top: 6px;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 22px;
            border-radius: 4px;
            padding: 0 8px;
            background: #ffe3ac;
            color: #0c002c;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
        .imgNewSkpCnt {
            position: absolute;
            top: 6px;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 22px;
            border-radius: 4px;
            padding: 0 8px;
            background: #9365ff;
            color: #fff;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
    }
    .descMainWrap {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: 14px;
        width: 100%;
        height: 136px;
        justify-content: space-between;
        margin-top: 3px;
        .descUse {
            color: #0c002c;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;
        }
        .descAddress {
            height: 20px !important;
            color: #0c002c;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .descEstPriceWrap {
            display: flex;
            color: #0c002c;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            gap: 4px;
        }
        .descMinPriceWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #0047ff;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            gap: 4px;
            span {
                width: 41px !important;
            }
        }
        .descSomethingWrap {
            display: flex;
            flex-direction: row;
            width: 50vw;
            height: 20px;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: #0c002c;
            gap: 6px;
            flex-wrap: wrap;
            .descSomethingContainer {
                font-weight: 400;
                font-size: 13px;
                padding: 0px 6px;
                line-height: 20px;
                color: rgb(255, 0, 0);
                border: 1px solid rgb(255, 0, 0);
                border-radius: 100px;
            }
        }
        .descProfitWrap {
            display: flex;
            justify-content: space-between;
            .container {
                display: flex;
                align-items: center;
                gap: 6px;
                span {
                    display: flex;
                    width: fit-content;
                    padding: 0 4px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 24px;
                    background: #f00;
                    color: #fff;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    width: 60px !important;
                }
                p {
                    color: #0c002c;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
            .likeImg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .price_percent {
        display: inline-flex;
        align-items: center;
        font-size: 13px;
        line-height: 20px;
        img {
            width: 10px;
            margin: 0px 4px 0px 2px;
        }
        & > span {
            display: inline-flex;
            align-items: center;
            font-size: 13px;
            line-height: 20px;
        }
    }
`;

const bidTypeTextStyle = (text: string) => css`
    display: inline-block !important;
    border-radius: 4px !important;
    color: ${text === "경매" ? "white" : "#0c002c"} !important;
    background-color: ${text === "경매" ? "#2275ee" : "#fee500"} !important;
    padding: 1px 8px !important;
    line-height: 20px !important;
    white-space: nowrap !important;
`;
