import { initYearFilter, initPriceFilter, initUseFilters } from "@src/constants/mapRealPriceFilters";
import create from "zustand";
import { persist } from "zustand/middleware";

export interface pyeongCaseType {
    area: string;
    supply: string;
    exclusive: string;
    sale?: string;
    rent?: string;
    month?: string;
}

export type RealPriceFilterType = {
    use: any[];
    price: any[];
    year: any[];
};

export const initialState: RealPriceFilterType = {
    use: [],
    price: [0, initPriceFilter.length - 1],
    year: [0, initYearFilter.length - 1],
};

export interface usePyeongCardPopupStoreType {
    pyeongCardPopupState: boolean;
    setPyeongCardPopupState: (pyeongCardPopupState: boolean) => void;
}
export interface usePyeongCardDetailListStoreType {
    pyeongCardDetailListState: pyeongCaseType[];
    setPyeongCardDetailListState: (pyeongCardDetailListState: pyeongCaseType[]) => void;
}
export interface useSelctedPyeongCardDetailStoreType {
    selctedPyeongCardDetailState: pyeongCaseType | null;
    setSelctedPyeongCardDetailState: (selctedPyeongCardDetailState: pyeongCaseType | null) => void;
}

export const usePyeongCardPopupStore = create(
    persist<usePyeongCardPopupStoreType>(
        (set) => ({
            pyeongCardPopupState: false,
            setPyeongCardPopupState: (state: any) => set({ pyeongCardPopupState: state }),
        }),
        {
            name: "pyeongCardPopupStorage",
        },
    ),
);

export const usePyeongCardDetailListStore = create(
    persist<usePyeongCardDetailListStoreType>(
        (set) => ({
            pyeongCardDetailListState: [],
            setPyeongCardDetailListState: (state: pyeongCaseType[]) => set({ pyeongCardDetailListState: state }),
        }),
        {
            name: "pyeongCardPopupStorage",
        },
    ),
);

export const useSelctedPyeongCardDetailStore = create(
    persist<useSelctedPyeongCardDetailStoreType>(
        (set) => ({
            selctedPyeongCardDetailState: null,
            setSelctedPyeongCardDetailState: (state: pyeongCaseType | null) => set({ selctedPyeongCardDetailState: state }),
        }),
        {
            name: "pyeongCardPopupStorage",
        },
    ),
);
