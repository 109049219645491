/**
 * 등기 기록 상태
 * 0: 전체, 1: 현행, 2: 폐쇄
 */
export enum DEUNGI_STATE_ENUM {
    ALL = 0,
    ING = 1,
    CLOSE = 2,
}
/**
 * 소모성 상품 타입
 * 1: 실시간 등기 열람권, 2: 기존 등기 열람권, 3: 매물 광고권, 4: 지역 광고권
 */
export enum TICKET_TYPE {
    DEUNGI_TICKET = 1,
    EXIST_DEUNGI_TICKET = 2,
    ITEMS_ADS_TICKET = 3,
    REGION_ADS_TICKET = 4,
}
/**
 * 소모성 상품 타입 스트링
 * deungi_ticket: 실시간 등기 열람권, exist_deungi_ticket: 기존 등기 열람권, items_ads_ticket: 매물 광고권, region_ads_ticket: 지역 광고권
 */
export enum TICKET_TYPE_STRING {
    DEUNGI_TICKET = "deungi_ticket",
    EXIST_DEUNGI_TICKET = "exist_deungi_ticket",
    ITEMS_ADS_TICKET = "items_ads_ticket",
    REGION_ADS_TICKET = "region_ads_ticket",
}

/**
 * 부동산 구분
 * 0: 전체, 1: 집합건물, 2: 토지, 3: 건물
 */
export enum DEUNGI_TYPE_ENUM {
    ALL = 0,
    COLLECTIVE_BUILDING = 1,
    GROUND = 2,
    BUILDING = 3,
}

export enum DEUNGI_FILTERS {
    TYPE = "구분",
    STATE = "상태",
}

export enum DEUNGI_FILTER_TYPE {
    ALL = "전체",
    COLLECTIVE_BUILDING = "집합건물",
    LAND = "토지",
    BUILDING = "건물",
}

export enum DEUNGI_FILTER_STATE {
    ALL = "전체",
    ACTIVE = "현행",
    INACTIVE = "폐쇄",
}

export enum DEUNGI_POPUP_TYPE {
    LIMIT_BUILDING_REGISTER = -2,
    ERROR = -1,
    INFO,
    SERVICE_DENY,
    OPEN,
    EXIST_OPEN,
    REOPEN,
    NO_DEUNGI,
    IS_IOS,
    HAS_DEUNGI,
    PAY_EXIST_DEUNGI,
    SELECT_NEW_OR_EXIST,
    SELECT_NEW_OR_EXIST_YEAR_SUBS,
    PACKAGE_INFO_DEUNGI_TICKET,
    PACKAGE_INFO_EXIST_DEUNGI_TICKET,
    PACKAGE_INFO_ITEMS_ADS_TICKET,
    PACKAGE_INFO_REGION_ADS_TICKET,

    ERROR_BUILDING_REGISTER,
    WAITING,

    ALARM_LIST,
    ALARM_EXPIRED,
    ALARM_OVER_3,
    OPEN_WITH_ADDRESS,
    INPUT_OWNER_INFO,
    ALARM_PERIOD_INFO,
}

export enum DEUNGI_OPEN_STATE {
    WAITING = "대기중",
    REGISTER = "등록중",
    PAID = "결제완료",
    PAYMENT_ING = "결제진행",
    PAYMENT_FAIL = "결제실패",
    UPLOADED = "열람완료",
    UPLOAD_ING = "열람진행",
    UPLOAD_FAIL = "열람실패",
    UPLOAD_CANCEL = "열람취소",
    REOPEN = "재열람",
    REOPEN_FAIL = "재열람실패",
    EXISTS = "기존등기",
}

/**
 * 결제 상품 상태
 * 0 = 일반판매 미노출, 1 = 일반판매 노출, 2 = 파트너스 제휴판매 노출, 3 = 직원용, 4 = 무료 쿠폰용, 5 = 유료 쿠폰용, 6 = AOS, 7 = IOS, 8 = PG
 */
export enum PRODUCT_SALE_TYPE {
    NOT_DISPLAYED = 0,
    DISPLAYED = 1,
    PARTNER_DISPLAYED = 2,
    EMPLOYEE = 3,
    FREE_COUPON = 4,
    PAID_COUPON = 5,
    AOS = 6,
    IOS = 7,
    PG = 8,
}

export enum DEUNGI_CHANGE_STATE {
    FAIL = "확인실패",
    WAITING = "변동확인전",
    NONE = "변동없음",
    CHANGE = "변동있음",
    INVALID_OWNER = "소유자불일치",
    EXPIRED = "기간만료",
}
