"use client";
import React, { useEffect, useState, KeyboardEvent, useRef } from "react";
import styled from "@emotion/styled";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";
import Header from "@src/components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
    BuildingRegisterListRequestDto,
    BuildingRegisterListResponseDto,
} from "@src/hooks/buildingRegister/dto/BuildingRegister.dto";
import { useBuildingRegisterList } from "@src/hooks/buildingRegister/useBuildingRegister";
import dayjs from "dayjs";
import BuildingRegisterTab from "@src/components/buildingRegister/BuildingRegisterTab";
import ico_empty from "@src/assets/ico_deungi_empty.png";

const BuildingRegisterList = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [list, setList] = useState([] as BuildingRegisterListResponseDto[]);
    const [totalCnt, setTotalCnt] = useState(0);
    const [filterPage, setFilterPage] = useState(1);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loadingToggle, setLoadingToggle] = useState(false);

    const getBuildingRegisterList = async (page?: number) => {
        const params = {} as BuildingRegisterListRequestDto;
        params.page = page ? page : filterPage;
        params.limit = 20;

        if (loadingToggle) return;
        setLoadingToggle(true);

        if (page === 1) {
            setFilterPage(1);
            setList([]);
        }

        await useBuildingRegisterList(params)
            .then((res) => {
                const data = res.data;

                if (data?.totalCnt === 0) {
                    setTotalCnt(0);
                    setList([]);
                } else {
                    setTotalCnt(data?.totalCnt);
                    setFilterPage((prev: number) => prev + 1);
                    setList((prev: any) => prev.concat(data?.list));
                }
            })
            .finally(() => {
                setFirstLoad(true);
                setLoadingToggle(false);
            });
    };

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: totalCnt > (filterPage - 1) * 20,
        onLoadMore: getBuildingRegisterList,
        disabled: false,
        delayInMs: 100,
    });

    const onPdfClickHandler = (el: BuildingRegisterListResponseDto) => {
        navigate({
            pathname: "/buildingRegister/viewer",
            search: `?id=${el._id}&address=${encodeURIComponent(el.detailAddress + "_" + el.regstrKindCdNm)}`,
        });
    };

    useEffect(() => {
        getBuildingRegisterList(1);
    }, []);

    return (
        <Container>
            <Header title="실시간 건축물대장 열람" />
            <BuildingRegisterTab />
            <h3>
                총 <span>{totalCnt}</span>건
            </h3>
            <ListBox>
                {list &&
                    list.map((el: BuildingRegisterListResponseDto) => {
                        return (
                            <div className={"list"} key={el._id} onClick={() => onPdfClickHandler(el)}>
                                <label>
                                    {dayjs(el.created_at).format("YYYY.MM.DD HH:mm:ss")}{" "}
                                    {dayjs(el.created_at).add(1, "day").diff() > 0 && <small>NEW</small>}
                                </label>
                                <div>{el.detailAddress}</div>
                                <p>
                                    <small>종류</small>
                                    <span
                                        className={
                                            el.regstrKindCd === "1"
                                                ? "recapTitle"
                                                : el.regstrKindCd === "2"
                                                ? "rgstgnrl"
                                                : el.regstrKindCd === "3"
                                                ? "title"
                                                : el.regstrKindCd === "4"
                                                ? "expos"
                                                : ""
                                        }
                                    >
                                        {el.regstrKindCdNm}
                                    </span>
                                </p>
                            </div>
                        );
                    })}
                <div className="loadingListBox" ref={loadingRef}>
                    {totalCnt > list.length ? <Lottie className="lottie" animationData={lottie_loading} /> : <></>}
                </div>
                {totalCnt === 0 && firstLoad && (
                    <EmptyBox>
                        <div>
                            <img src={ico_empty} />
                            <span>열람내역이 없어요</span>
                        </div>
                    </EmptyBox>
                )}
            </ListBox>
        </Container>
    );
};

const EmptyBox = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    & > div {
        display: flex;
        flex-direction: column;
        animation: fadeIn 0.2s;

        & > img {
            width: 140px;
            height: 115px;
            margin-bottom: 24px;
        }
        & > span {
            font-size: 17px;
            text-align: center;
        }
    }
`;

const ListBox = styled.div`
    position: relative;
    height: calc(100% - 180px);
    overflow: scroll;
    padding: 0 14px;

    .list {
        padding: 16px 8px;
        border-bottom: 1px solid #c9c9c9;
        animation: fadeIn 0.5s ease;
        transition: all 0.3s ease;

        &:active {
            background: #dff0ff;
        }
        & > label {
            font-size: 14px;
            color: #0047ff;

            & > small {
                display: inline-block;
                color: #fff;
                font-size: 12px;
                background: #f00;
                border-radius: 100px;
                line-height: 20px;
                padding: 0 4px;
                animation: gelatine 0.5s ease;
            }
        }
        & > div {
            margin: 8px 0;
            font-size: 16px;
            line-height: 1.6;
        }
        & > p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            & > small {
                padding: 4px 6px;
                border-radius: 24px;
                margin-right: 4px;
            }
            /* small:nth-of-type(1) {
                background: #fff9e4;
            }
            small:nth-of-type(2) {
                background: #eefff5;
                margin-left: 20px;
            } */

            & > span {
                padding: 4px 6px;
                border-radius: 24px;
                margin-right: 8px;
                color: #fff;

                &.recapTitle {
                    background: #2275ee;
                }
                &.rgstgnrl {
                    background: #f6494b;
                }
                &.title {
                    background: #00b8be;
                }
                &.expos {
                    background: #ff9500;
                }
            }
        }
    }
    .loadingListBox {
        margin: 20px 0;
        border: none;
        background: #fff !important;

        .lottie {
            width: 50px;
            margin: auto;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    & > h3 {
        padding: 14px;
        font-weight: 500;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

        & > span {
            font-weight: 700;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes gelatine {
        from,
        to {
            transform: scale(1, 1);
        }
        25% {
            transform: scale(0.9, 1.1);
        }
        50% {
            transform: scale(1.1, 0.9);
        }
        75% {
            transform: scale(0.95, 1.05);
        }
    }
`;

export default BuildingRegisterList;
