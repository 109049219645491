import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { css, keyframes } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { mapOptions } from "@src/constants/mapOptions";
import PopUp from "@src/components/popup/PopUp";
import zIndexOfMarker from "@src/constants/zIndexOfMarker";
import Marker from "./Marker";
import { NaverMap } from "@src/lib/NaverMap";
import { useGetMapAggregation, initParams } from "@src/hooks/map/useMapAggregation";
import { useGetNewMapList } from "@src/hooks/map/useGetMapList";
import useGetAddressFromCoords from "@src/hooks/map/useMapCoordToAddr";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import closeIcon from "@src/assets/ico_close.png";
import mapIcon from "@src/assets/ico_map.png";
import backIcon from "@src/assets/ico_arrow_back.png";
import roadViewIcon from "@src/assets/ico_roadview.png";
import useCallToken from "@src/hooks/useCallToken";
import useGetMapPolyCoord from "@src/hooks/map/useGetMapPolyCoord";
import MobileSearch from "@src/pages/MobileSearch";
import { mapApiParams } from "@src/pages/map/types";
import { removeAll, useFilterStore } from "@src/store/filterStore";
import { useGetUserLastPosition, usePostUserLastPosition } from "@src/hooks/map/useUserLastPosition";
import { useGetUserLastMapType, usePostUserLastMapType } from "@src/hooks/map/useUserLastMapType";
import Dim from "@src/components/DIM/Dim";
import Card, { landTypes } from "@src/components/Card/Card";
import { useMapStore } from "@src/store/MapStore";

import SalePage from "../SalePage";
import PopulationStatusCard from "../PopulationStatusCard";
import selectedFieldIcon from "@src/assets/ico_location.png";
import myPostionBearingIcon from "@src/assets/myPostionBearingIcon.svg";
import PanoramaIcon from "@src/assets/panorama_img2.png";
import pinMarkerIcon from "@src/assets/pinMarker/pinMarkerIcon.svg";
import pinMiddleMarkerIcon from "@src/assets/pinMarker/pinMiddleMarkerIcon.svg";
import pinSmallMarkerIcon from "@src/assets/pinMarker/pinSmallMarkerIcon.svg";
import deletePinIcon from "@src/assets/pinMarker/pinDeleteIcon.svg";
import TutorialLgImg from "@src/assets/mapTutorial_lg_img.png";
import TutorialSmImg from "@src/assets/mapTutorial_sm_img.png";
import pinMarkerTutorialLgImg from "@src/assets/pinMarkerTutorialLgImg.png";
import pinMarkerTutorialSMImg from "@src/assets/pinMarkerTutorialSMImg.png";
import { useMapBottomSheetStore } from "@src/store/MapBottomSheetStore";
import styled from "@emotion/styled";
import { useGetUserRealPriceFilter } from "@src/hooks/map/useUserRealPriceFilter";
import useGetRoadAddressFromCoords from "@src/hooks/map/useMapCoordToRoadAddr";
import useGetPNU from "@src/hooks/map/useGetPNU";
import { onApiCancelHandler } from "@src/api/abortableAxiosConfig";
import { onMSGAApiCancelHandler } from "@src/api/axiosConfig";

import ConvenienceBottomSheet from "./ConvenienceBottomSheet";
import DrawScreen from "./DrawScreen";
import MeasureCard from "./MeasureCard";
import { setPermissionFunc, checkPermissionStore } from "@src/store/TokenStore";

import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { useGetMapFieldInformation } from "@src/hooks/map/useGetMapPolyCoordInternalization";
import NewFilter from "../mapFilter";
import { useNewFilterMapCheckStore, useNewFilterStore } from "@src/store/newFilterStore";
import ToastMessage from "@src/components/filter/ToastMessage";
import useGetFieldContents from "@src/hooks/map/useGetFieldContents";
import { debounce } from "@mui/material";
import {
    IPolygonApiParams,
    useGetAdemdDongPolyCoord,
    useGetPolygonByCenter,
    useGetPolygonByClick,
    useGetSidoPolyCoord,
    useGetSigunguPolyCoord,
} from "@src/hooks/map/useGetFieldPolycoord";
import NewFloatingMenu from "@src/pages/map/NewFloatingMenu";
import { IPinInformation, useFindPinMarker, useSaverPinMarker } from "@src/hooks/map/useUserPinMarker";
import { useOpenStateLandFilterStore, useStateLandFilterStore } from "@src/store/stateLandFilter";
import StateLandFilter from "../stateLandFilter";
import { useFindAllStateLandV2, useFindDimPolygon } from "@src/hooks/stateLand/useGetStateLand";
import { useFindOneStateLandFilter, useSaverStateLandFilter } from "@src/hooks/inventory/useInventory";
import InfoCard from "../stateLandFilter/InfoCard";
import LoadingInfoCard from "../stateLandFilter/LoadingInfoCard";
import { useFindOneStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import NewMeasureCard from "./NewMeasureCard";
import { ISimpleLandData, fetchAreaCardData } from "@src/hooks/map/useSimpleLandData";
import MeasureLandCardList from "./MeasureLandCardList";

// 용도지역 관련 임시 주석처리_22.11.16

function Map() {
    //* 브이월드 이슈 팝업
    const [vworldIssueModalOpen, setVworldIssueModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let search = Boolean(Number(searchParams.get("search")));
    const { naver } = window;
    const { token } = useCallToken();
    const location = useLocation();
    const { state } = useLocation() as {
        state: {
            geometryCoordinates: any;
            center: { lat: number; lng: number };
            searchKeyword: string;
        };
    };

    // const filterState = useFilterStore((state) => state.filterState);
    const filterState = useNewFilterStore((state) => state.newFilterState);
    const dimVisible = useFilterStore((state) => state.dimVisible);
    const setFilterVisible = useFilterStore((state) => state.setFilterVisible);
    const setMap = useMapStore((state) => state.setMap);
    const setDimVisible = useFilterStore((state) => state.setDimVisible);
    const filterCheck = useNewFilterMapCheckStore((state) => state.newFilterMapCheckState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);

    useEffect(() => {
        //부분 필터 활성화시 (dimVisible) 매물카드, 필지카드 숨김처리
        if (dimVisible) {
            setIsSaleCardShow(false);
            hideLandCard();
            setIsBackToAppShow(false); //매물상세로 돌아가기 버튼 삭제
            setIsFieldCardShow(false); //필지카드 삭제
            setIsPopulationStatusCardShow(false); //인구현황
            setFromCheck("");
        }
    }, [dimVisible]);

    useEffect(() => {
        //부분 필터 활성화시 (dimVisible) 매물카드, 필지카드 숨김처리
        if (filterCheck) {
            hideLandCard();
            setIsSaleCardShow(false);
            setIsBackToAppShow(false); //매물상세로 돌아가기 버튼 삭제
            setIsFieldCardShow(false); //필지카드 삭제
            setFromCheck("");
        }
    }, [filterCheck]);

    //* 지도, 마커, 거리뷰, 지적도, 항공뷰
    const naverMaps = naver.maps;
    const { addListener: addListener, once } = naverMaps.Event;

    const mapRef = useRef<HTMLDivElement | null>(null);
    const markerRef = useRef<any | null>(null);
    const expectedMarkerRef = useRef<any | null>(null);
    const panoViewRef = useRef<any>(null);
    const panoMarkerRef = useRef<any | null>(null);
    const roadMapInPanoView = useRef<any>();
    const roadMarkerInPanoView = useRef<any | null>(null);
    const searchMarkerRef = useRef<any | null>(null); //사건번호 검색 결과 마커
    const currentZoomRef = useRef(20);
    const cadastalLayerRef = useRef<any>(null);

    let initLatLng = new naverMaps.LatLng(37.49094871675334, 127.05557683885974); //최초 좌표(도곡역)
    let noLatLng = new naverMaps.LatLng(0, 0); //최초 좌표(도곡역)
    const [initLocation, setInitLocation] = useState<naver.maps.LatLng>(noLatLng);
    const [currentMap, setCurrentMap] = useState<NaverMap | null>(null);
    const [currentSearchMarker, setCurrentSearchMarker] = useState<naver.maps.Marker>(); //사건번호 검색 매물마커

    const [isStreetActive, setIsStreetActive] = useState(false);
    const [isFlightActive, setIsFlightActive] = useState(false);
    const [isCadastralShow, setIsCadastralShow] = useState(false);
    const [isPanoViewActive, setIsPanoViewActive] = useState(false);
    const [isRoadMapInPanoView, setIsRoadMapInPanoView] = useState(false);
    const [isMarkerClicked, setIsMarkerClicked] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isFieldCardShow, setIsFieldCardShow] = useState(false);
    const [isSaleCardShow, setIsSaleCardShow] = useState(false);
    const [isBackToAppShow, setIsBackToAppShow] = useState(false);
    const [fromCheck, setFromCheck] = useState<string>("");

    //인구현황
    const [isPopulationStatusCardShow, setIsPopulationStatusCardShow] = useState<boolean>(false);
    const [populationStatusItem, setPopulationStatusItem] = useState<any>([]);

    const [markerContents, setMarkerContents] = useState<any[]>([]);
    const [renderMarkerList, setRenderMarkerList] = useState<any[]>([]);
    const [renderExpectedMarkerList, setExpectedRenderMarkerList] = useState<any[]>([]);
    const [renderRealMarkerList, setRenderRealMarkerList] = useState<any[]>([]);
    const [renderRectMarkerList, setRenderRectMarkerList] = useState<any[]>([]);

    const [roadLayerMarker, setRoadLayerMarker] = useState<naver.maps.Marker>(); //로드뷰 레이어 마커

    const [mapApiParams, setMapApiParams] = useState<mapApiParams>(initParams);
    const [mapState, setMapState] = useState<any>(); //쿼리스트링으로 전달받는 state
    const [panoAddress, setPanoAddress] = useState<string>("");

    //매물카드
    const setCurrentIndex = useMapBottomSheetStore((state) => state.setCurrentIndex);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [isBackgroundVisible, setIsBackgroundVisible] = useState<boolean>(false);

    const cadastralLayer = useRef<naver.maps.CadastralLayer | null>(null);
    const streetLayer = useRef<naver.maps.StreetLayer | null>(null);
    const flightSpot = useRef<naver.maps.FlightSpot | null>(null);
    const isStreetActiveRef = useRef<boolean>(false);

    const [parsedSearchParams, setParsedSearchParams] = useState({
        itemId: "",
        ScrollY: "",
        bidType: "",
        bidTypeDetail: 0,
    });
    const [pnuFromApp, setPnuFromApp] = useState<string>("");
    const [latLngFromApp, setLatLngFromApp] = useState({ lat: 0, lng: 0 });
    const [searchTextFromApp, setSearchTextFromApp] = useState("");
    const [popupContents, setPopupContents] = useState({
        text: "",
        isShow: false,
        isShowCancelButton: true,
        confirmButtonTitle: "확인",
        handleConfirmClick: () => {},
        handleCancelClick: () => {},
    });
    const fetchData = (params: mapApiParams) => {
        useGetMapAggregation(params).then((res) => setMarkerContents(res));
    };
    const currentLocationRef = useRef<any>({ zoom: 18, lat: 37.49094871675334, lng: 127.05557683885974 });
    const currentCheckRef = useRef<any>("false");
    const [positionHeading, setPositionHeading] = useState<number>(0);
    const positionHeadingRef = useRef<number>(0);

    WebAppBridge.setUserLocationInMap = (param1: string, param2: string) => {
        const naverLocation = new naver.maps.LatLng({ lat: Number(param1), lng: Number(param2) });
        setInitLocation(naverLocation);
        positionHeadingRef.current = Number(0);
        setPositionHeading(Number(0));
        const bearingDiv: any = document.querySelector('div[title="useLocation"]');
        if (bearingDiv) {
            bearingDiv.style.transform = `rotate(${Number(0)}deg)`;
        }
        currentLocationRef.current = { zoom: 18, lat: Number(param1), lng: Number(param2) };
    };

    useEffect(() => {
        if (!mapRef.current || !naver) return;
        //매물상세로 돌아가기 버튼 숨기는 메서드 정의 (앱 호출)
        WebAppBridge.hideBackToAppButtonInMap = () => {
            setIsBackToAppShow(false);
            setFromCheck("");
        };
        //앱 -> 사용자 위치 좌표 설정하는 메서드 정의 (앱 호출)

        WebAppBridge.setUserLocationInMap = (param1: string, param2: string) => {
            const naverLocation = new naver.maps.LatLng({ lat: Number(param1), lng: Number(param2) });
            setInitLocation(naverLocation);
            setPositionHeading(Number(0));
            const bearingDiv: any = document.querySelector('div[title="useLocation"]');
            if (bearingDiv) {
                bearingDiv.style.transform = `rotate(${Number(0)}deg)`;
            }
            currentLocationRef.current = { zoom: 18, lat: Number(param1), lng: Number(param2) };
        };
        WebAppBridge.checkCallMap = (c: boolean) => {
            currentCheckRef.current = c.toString();
        };
        transferToNative("", "callCheckMapStatus");
        transferToNative("", "onClickUserLoButton");
        return () => {
            setIsSaleCardShow(false);
        };
    }, []);

    //* 지적도 끄고 키기
    useEffect(() => {
        if (currentMap?.map && cadastralLayer.current) {
            if (isCadastralShow) {
                cadastralLayer.current.setMap(currentMap?.map);
            } else {
                cadastralLayer.current.setMap(null);
            }
        }
    }, [isCadastralShow]);

    //* 거리뷰 끄고 켜기
    useEffect(() => {
        if (currentMap?.map && streetLayer.current) {
            if (isStreetActive) {
                isStreetActiveRef.current = true;
                streetLayer.current.setMap(currentMap?.map);
            } else {
                isStreetActiveRef.current = false;
                streetLayer.current.setMap(null);
            }
        }
    }, [isStreetActive]);

    //* 항공뷰 끄고 켜기
    useEffect(() => {
        if (currentMap?.map && flightSpot.current) {
            if (isFlightActive) {
                flightSpot.current.setMap(currentMap?.map);
            } else {
                flightSpot.current.setMap(null);
            }
        }
    }, [isFlightActive]);

    const userPatchData = async () => {
        return await Promise.all([useGetUserLastPosition(), useGetUserRealPriceFilter(), useGetUserLastMapType()]);
    };
    const locationStore = checkPermissionStore((state) => state.permission);
    const [checkMyPosition, setCheckMyPosition] = useState<boolean>(false);
    useEffect(() => {
        if (currentMap && locationStore === "true" && currentCheckRef.current === "true") {
            setCheckMyPosition(true);
            checkLocationEvent.current = setInterval(() => {
                // setInitLocation(new naverMaps.LatLng(37.49094871675334, 127.05557683885974));
                // setPositionHeading((pre) => pre - 2);
                transferToNative("", "onClickUserLoButton");
            }, 1500);
        }
    }, [locationStore]);
    useEffect(() => {
        if (currentMap && locationStore === "true" && currentCheckRef.current === "true") {
            setPermissionFunc("");
        }
    }, [initLocation]);
    useEffect(() => {
        if (currentMap?.map) {
            if (checkMyPosition) {
                if (!firstCurrentPositionMarkerRef.current) {
                    firstCurrentPositionMarkerRef.current = new naver.maps.Marker({
                        position: new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng),
                        map: currentMap?.map,
                        clickable: true,
                        zIndex: 17,
                        icon: {
                            url: myPostionBearingIcon,
                            size: new naver.maps.Size(44, 44),
                        },
                        title: "useLocation",
                        visible: true,
                    });
                } else if (!firstCurrentPositionMarkerRef.current?.getVisible()) {
                    firstCurrentPositionMarkerRef.current?.setOptions({
                        position: new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng),
                        map: currentMap?.map,
                        clickable: true,
                        zIndex: 17,
                        icon: {
                            url: myPostionBearingIcon,
                            size: new naver.maps.Size(44, 44),
                        },
                        title: "useLocation",
                        visible: true,
                    });
                } else {
                    firstCurrentPositionMarkerRef.current?.setOptions({
                        position: new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng),
                        icon: {
                            url: myPostionBearingIcon,
                            size: new naver.maps.Size(44, 44),
                        },
                        visible: true,
                    });
                }
                firstCurrentPositionMarkerRef.current?.setMap(currentMap.map);
            } else {
                firstCurrentPositionMarkerRef.current?.setVisible(false);
            }
        }
    }, [initLocation]);

    const [checkLoad, setCheckLoad] = useState<boolean>(false);

    useEffect(() => {
        if (currentMap?.map) {
            const bearingDiv: any = document.querySelector('div[title="useLocation"]');
            if (bearingDiv) {
                bearingDiv.style.transform = `rotate(${Number(positionHeadingRef.current ?? 0)}deg)`;
            }
        }
    }, [positionHeading]);

    const checkLocationEvent = useRef<any>();
    useEffect(() => {
        if (checkMyPosition && currentMap) {
            if (locationStore === "true") {
                checkLocationEvent.current = setInterval(() => {
                    setCheckLoad(true);
                    transferToNative("", "onClickUserLoButton");
                }, 1500);
            } else if (checkLoad) {
                checkLocationEvent.current = setInterval(() => {
                    transferToNative("", "onClickUserLoButton");
                }, 1500);
            } else {
                transferToNative("", "onClickUserLoButton");
            }
            currentMap.map.morph(new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng));
        } else {
            checkLocationEvent.current && clearInterval(checkLocationEvent.current);
            firstCurrentPositionMarkerRef.current?.setVisible(false);
        }
        return () => {
            if (!checkMyPosition) {
                checkLocationEvent.current && clearInterval(checkLocationEvent.current);
                firstCurrentPositionMarkerRef.current?.setVisible(false);
            }
        };
    }, [checkMyPosition]);

    useEffect(() => {
        //토큰 변경시 ㅎ
        if (token.length !== 0) {
            searchParams.get("search") === "1" && localStorage.removeItem("isDataMapLoadFirst");
            userPatchData()
                .then((res) => {
                    const position = currentCheckRef.current === "true" ? currentLocationRef.current : res[0];
                    const realPriceFilter = res[1].apiParams;
                    const newMapType = res[2]?.type ? res[2]?.type : "hybrid";

                    if (position !== undefined) {
                        const { zoom, lat, lng } = position;
                        let initMap = new NaverMap(mapRef.current, { lat, lng });
                        cadastalLayerRef.current = new naver.maps.CadastralLayer();
                        initMap.map.setZoom(zoom);
                        currentZoomRef.current = zoom;
                        initMap.map.setMapTypeId(newMapType);
                        let bounds = initMap.map.getBounds() as naver.maps.LatLngBounds;
                        let NE = bounds.getNE();
                        let SW = bounds.getSW();
                        let ne = { latd: NE.lat(), lntd: NE.lng() };
                        let sw = { latd: SW.lat(), lntd: SW.lng() };
                        // 최적화 관련 작업
                        const device_width = window.innerWidth;
                        mapApiParamsRef.current = {
                            zoom,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter },
                        };
                        setMapApiParams({
                            zoom,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter, device_width: device_width },
                        });
                        fetchData({
                            zoom,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter, device_width: device_width },
                        });
                        usePostUserLastPosition(position);
                        setCurrentMap(initMap);
                    } else {
                        let initMap = new NaverMap(mapRef.current, { lat: initLocation.lat(), lng: initLocation.lng() });
                        let bounds = initMap.map.getBounds() as naver.maps.LatLngBounds;
                        initMap.map.setMapTypeId(newMapType);
                        let NE = bounds.getNE();
                        let SW = bounds.getSW();
                        let ne = { latd: NE.lat(), lntd: NE.lng() };
                        let sw = { latd: SW.lat(), lntd: SW.lng() };
                        mapApiParamsRef.current = {
                            zoom: 18,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter },
                        };
                        const device_width = window.innerWidth;
                        setMapApiParams({
                            zoom: 18,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter, device_width: device_width },
                        });
                        fetchData({
                            zoom: 18,
                            ne,
                            sw,
                            filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                            real_filter: { ...realPriceFilter, device_width: device_width },
                        });
                        setCurrentMap(initMap);
                    }
                })
                .finally(() => {
                    // transferToNative("", "onClickUserLoButton");
                    let lat = Number(searchParams.get("lat")) ?? 0;
                    let lng = Number(searchParams.get("lng")) ?? 0;
                    let itemId = searchParams.get("itemId") ?? "";
                    let ScrollY = searchParams.get("ScrollY") ?? "";
                    let bidType = searchParams.get("bidType") ?? "";
                    let bidTypeDetail = Number(searchParams.get("bidTypeDetail") ?? "");
                    let pnu = searchParams.get("pnu") ?? "";
                    let searchText = searchParams.get("searchText") ?? "";
                    setFromCheck(searchParams.get("from") ?? "");
                    setPnuFromApp(pnu);
                    setParsedSearchParams({ itemId, ScrollY, bidType, bidTypeDetail });
                    setSearchTextFromApp(searchText);
                    setLatLngFromApp({ lat, lng });
                    refetchStateLandSubs().then((res) => {
                        if (res.isFetched) {
                            stateLandSubsRef.current = res?.data;
                        }
                    });
                });
        }
    }, [token]);

    useEffect(() => {
        if (currentMap && token.length !== 0 && !filterCheck) {
            setMapApiParams((prev: any) => {
                let newParams = {
                    ...prev,
                    filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                };
                fetchData(newParams);
                return newParams;
            });
        }
    }, [filterCheck]);

    useEffect(() => {
        if (currentMap && token.length !== 0 && !dimVisible) {
            setMapApiParams((prev: any) => {
                let newParams = {
                    ...prev,
                    filter: { ...filterState, loc: removeAll(filterState.loc), use: makeUseParam(filterState.use) },
                };
                fetchData(newParams);
                return newParams;
            });
        }
    }, [dimVisible, token]);

    useEffect(() => {
        //앱 매물상세 페이지에서 진입시 실행
        if (latLngFromApp.lat !== 0) {
            let position = new naverMaps.LatLng(latLngFromApp.lat, latLngFromApp.lng);
            drawPolylineWithLandCard(position, true, pnuFromApp);
            currentMap?.map.setCenter(position);
            currentMap?.map.setZoom(18);
            if (fromCheck && searchTextFromApp === "") {
                setIsBackToAppShow(true);
            }
        }
    }, [latLngFromApp.lat]);

    const handleClickBackToApp = () => {
        const { itemId, ScrollY, bidType, bidTypeDetail } = parsedSearchParams;
        let deepLink =
            bidTypeDetail !== 6
                ? `msgaapp://deeplink/itemDetail?itemId=${itemId}&ScrollY=${ScrollY}&bidType=${bidType}`
                : `msgaapp://deeplink/itemDetail?itemId=${itemId}&ScrollY=${ScrollY}&bidType=${bidType}&bidTypeDetail=${bidTypeDetail}`;
        transferToNative(deepLink, "onClickBottomButton");
        setPnuFromApp("");
        setLatLngFromApp({ lat: 0, lng: 0 });
        setFromCheck("");
        setIsBackToAppShow(false);
        navigate("/map?search=0");
    };

    const handleClickBackToAiChat = () => {
        let deepLink = "msgaapp://deeplink/home/speechRecognition";
        transferToNative(deepLink, "onClickBottomButton");
        setPnuFromApp("");
        setLatLngFromApp({ lat: 0, lng: 0 });
        setFromCheck("");
        setIsBackToAppShow(false);
        navigate("/map?search=0");
    };

    useEffect(() => {
        setFilterVisible(false);
        setIsSaleCardShow(false);
        setIsPopulationStatusCardShow(false);
        setIsSearch(search);
        state && setMapState(state);
    }, [search]);

    const firstCurrentPositionMarkerRef = useRef<any>();
    // new naver.maps.Marker({
    //     position: new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng),
    //     map: currentMap?.map,
    //     clickable: true,
    //     zIndex: 17,
    //     icon: {
    //         url: myPostionBearingIcon,
    //         size: new naver.maps.Size(44, 44),
    //     },
    //     title: "useLocation",
    //     visible: false,
    // }),
    useEffect(() => {
        if (currentMap) {
            panoMarkerRef.current = new naverMaps.Marker({
                position: currentMap.map.getCenter(),
                map: undefined,
                clickable: true,
            });
            setRoadLayerMarker(panoMarkerRef.current);
            setMap(currentMap.map);
            flightSpot.current = new naver.maps.FlightSpot();
            streetLayer.current = new naver.maps.StreetLayer();
            cadastralLayer.current = new naver.maps.CadastralLayer();
            naver.maps.Event.addListener(flightSpot.current, "poi_clicked", (panoId) => {
                currentMap.map && roadViewActive(currentMap.map.getCenter(), panoId);
            });
            if (initLocation.lat()) {
                firstCurrentPositionMarkerRef.current = new naver.maps.Marker({
                    position: new naver.maps.LatLng(currentLocationRef.current.lat, currentLocationRef.current.lng),
                    map: currentMap?.map,
                    clickable: true,
                    zIndex: 17,
                    icon: {
                        url: myPostionBearingIcon,
                        size: new naver.maps.Size(44, 44),
                    },
                    title: "useLocation",
                    visible: true,
                });
                firstCurrentPositionMarkerRef.current?.setMap(currentMap.map);
                setCheckMyPosition(true);
                setCheckLoad(true);
            }
            findPinMarker();
            if (location.state?.hideMarker) {
                setIsMarkerHide(location.state?.hideMarker);
                navigate("/map?search=0", { replace: true });
            }
        }
    }, [currentMap]);

    ////////////////////
    //검색(소재지, 사건번호) 결과 지도에 표시
    useEffect(() => {
        if (mapState) {
            hideLandCard();
            const { geometryCoordinates, center, item } = mapState;
            const oriItem = { ...item };
            if (!item) {
                //소재지 검색시
                let searchPosition = new naverMaps.LatLng(center);
                drawPolylineWithLandCard(searchPosition);
            }
            currentMap?.setPosition(center);
            currentMap?.map.setZoom(17);
            let bounds = currentMap?.map.getBounds() as naver.maps.LatLngBounds;
            if (bounds) {
                let ne = bounds.getNE();
                let sw = bounds.getSW();
                let params = {
                    ne: {
                        lntd: ne.lng(),
                        latd: ne.lat(),
                    },
                    sw: {
                        lntd: sw.lng(),
                        latd: sw.lat(),
                    },
                    filter: {},
                    real_filter: { ...mapApiParams.real_filter, device_width: window.innerWidth },
                    zoom: 17,
                };

                fetchData(params);
                if (item) {
                    delete item.bid_type_str;
                    delete item.view_count;
                    handleItemMarkerClick(item);
                    item.bid_type_detail = oriItem.bid_type;
                    const markerElement = renderToStaticMarkup(
                        Marker({
                            type: "item",
                            displayName: "",
                            count: 0,
                            item,
                            isSearch: true,
                            real: null,
                            markerType,
                        }),
                    );

                    const position = {
                        lat: center.lat,
                        lng: center.lng,
                    };
                    let zIndex = 11;
                    if (currentMap) {
                        searchMarkerRef.current = currentMap.marker(markerElement, position, zIndex, true);
                        setCurrentSearchMarker(searchMarkerRef.current);
                        addListener(searchMarkerRef.current, "click", () => {
                            delete item.bid_type_detail;
                            let passResponse = parseString([item]);
                            transferToNative(passResponse, "showItemCard");
                        });
                    }
                }
            }
        }
    }, [mapState, mapApiParams.real_filter]);

    const parseString = (data: any) => {
        return JSON.stringify(data);
    };

    //* 핀마커 추가 3개가능
    const pinMarkerRef = useRef<naver.maps.Marker[]>([]);
    const pinMarkerInformationRef = useRef<IPinInformation[]>([]);
    const pinEventCheck = useRef<any>();
    const [isShowToast, setIsShowToast] = useState<boolean>(false);
    const [pinDimShow, setPinDimShow] = useState<boolean>(false);
    const [clickedPinMarker, setClickedPinMarker] = useState<IPinInformation | null>(null);
    const largePinSize = {
        url: pinMarkerIcon,
        size: new naver.maps.Size(80, 105),
    };
    const middlePinSize = {
        url: pinMiddleMarkerIcon,
        size: new naver.maps.Size(60, 79),
    };
    const smallPinSize = {
        url: pinSmallMarkerIcon,
        size: new naver.maps.Size(40, 52.5),
    };

    const findPinMarker = async () => {
        const pinList = await useFindPinMarker();
        if (pinList.length) {
            pinList.forEach((pin) => {
                const newLatLng = new naver.maps.LatLng(pin?.lat, pin?.lng);
                makePinMarker(newLatLng, false);
            });
        }
    };

    const longTapMap = (evt: any) => {
        const newCoord = evt.coord;
        makePinMarker(newCoord, true);
    };

    const makePinMarker = async (latlng: naver.maps.LatLng, savePin: boolean) => {
        if (currentMap?.map) {
            if (pinMarkerRef.current?.length > 2) {
                setIsShowToast(true);
                return;
            }
            const currentZoom = currentMap.map.getZoom();
            const currentIndex = pinMarkerRef.current.length + 1;
            const newMarker = new naverMaps.Marker({
                position: latlng,
                map: currentMap.map,
                clickable: true,
                zIndex: 13,
                title: `pinMarker${currentIndex}`,
                icon: currentZoom > 15 ? largePinSize : middlePinSize,
            });
            const pinMarkDiv = document.querySelector(`div[title = "pinMarker${currentIndex}"]`);
            pinMarkDiv?.setAttribute("id", `pinMarker${currentIndex}`);
            pinMarkerRef.current = pinMarkerRef.current?.concat(newMarker);
            const newRes = await getFieldInfoWithoutLandCard(latlng)
                .then((res) => {
                    const currentPinInfo = [...pinMarkerInformationRef.current];
                    currentPinInfo[currentIndex - 1] = { ...res, clickedLatLng: latlng };
                    pinMarkerInformationRef.current = currentPinInfo;
                    if (pinMarkDiv) {
                        pinMarkDiv.addEventListener("touchstart", (evt: any) => pinTouchStartEvent(evt), true);
                        pinMarkDiv.addEventListener("touchend", (evt: any) => pinTouchEndEvent(evt), true);
                    }
                    savePin &&
                        useSaverPinMarker(
                            pinMarkerInformationRef.current.map((pin) => ({ lat: pin?.latlng?.lat(), lng: pin?.latlng?.lng() })),
                        );
                })
                .catch(() => {
                    const currentPinInfo = [...pinMarkerInformationRef.current];
                    currentPinInfo[currentIndex - 1] = { clickedLatLng: latlng };
                    pinMarkerInformationRef.current = currentPinInfo;
                    if (pinMarkDiv) {
                        pinMarkDiv.addEventListener("touchstart", (evt: any) => pinTouchStartEvent(evt), true);
                        pinMarkDiv.addEventListener("touchend", (evt: any) => pinTouchEndEvent(evt), true);
                    }
                    savePin &&
                        useSaverPinMarker(
                            pinMarkerInformationRef.current.map((pin) => ({ lat: pin?.latlng?.lat(), lng: pin?.latlng?.lng() })),
                        );
                });
        }
    };

    const deletePinMarker = () => {
        const newPinMarkerInformation = pinMarkerInformationRef.current.filter(
            (fin) =>
                fin?.clickedLatLng?.lat() !== clickedPinMarker?.clickedLatLng?.lat() &&
                fin?.clickedLatLng?.lng() !== clickedPinMarker?.clickedLatLng?.lng(),
        );
        pinMarkerInformationRef.current = newPinMarkerInformation;
        pinMarkerRef.current?.map((pin) => pin?.setMap(null));
        pinMarkerRef.current?.map((pin) => pin?.setIcon(largePinSize));
        pinMarkerRef.current = [];
        newPinMarkerInformation.forEach((pin: any) => {
            makePinMarker(pin?.latlng, false);
        });
        useSaverPinMarker(pinMarkerInformationRef.current.map((pin) => ({ lat: pin?.latlng?.lat(), lng: pin?.latlng?.lng() })));
    };

    const pinTouchStartEvent = (evt: any) => {
        if (drawingTypeRef.current === 0) {
            const currentId = evt.target.parentElement.id;
            evt.stopPropagation();
            if (currentMap?.map) {
                currentMap?.map.setOptions({
                    draggable: false,
                    pinchZoom: false,
                });
            }
            const currentZoom = currentMap?.map?.getZoom() ?? 18;
            pinMarkerRef.current?.[currentId.slice(-1) - 1]?.setIcon(currentZoom > 15 ? largePinSize : middlePinSize);
            pinEventCheck.current = setTimeout(() => {
                setPinDimShow(true);
                setClickedPinMarker(pinMarkerInformationRef.current[currentId.slice(-1) - 1]);
                // setIsSaleCardShow(false);
                // setIsFieldCardShow(false);
                const pinMarkDiv: any = document.getElementById(currentId);
                const pinDim = document.getElementById("pinDim");
                const pinbutton = document.getElementById("pinbutton");
                const pinDimMarker: any = document.getElementById("pinDimMarker");
                const parentTransform = pinMarkDiv?.parentElement?.parentElement?.parentElement.style.transform;
                const currentInfo = pinMarkerInformationRef.current?.[currentId?.slice(-1) - 1];
                const buildingCheck = currentInfo?.buildingName?.replaceAll("-", "")
                    ? (currentInfo?.buildingName?.replaceAll("-", "")?.length ?? 0) > 13
                        ? "30px"
                        : "20px"
                    : "0px";
                const addrCheck = (currentInfo?.roadAddr?.length ?? 0) > 15 ? "10px" : "0px";
                if (pinDim) {
                    pinDim.style.top = `calc(${pinMarkDiv.style.top} - 55px - ${buildingCheck} - ${addrCheck} + ${
                        currentZoom > 15 ? "0px" : "5px"
                    })`;
                    pinDim.style.left = `calc(${pinMarkDiv.style.left} - 57px - ${currentZoom > 15 ? "0px" : "10px"})`;
                    pinDim.style.transform = parentTransform;
                }
                if (pinbutton) {
                    pinbutton.style.top = `calc(${pinMarkDiv.style.top} + 110px - ${currentZoom > 15 ? "0px" : "18px"})`;
                    pinbutton.style.left = `calc(${pinMarkDiv.style.left} - 10px - ${currentZoom > 15 ? "0px" : "10px"})`;
                    pinbutton.style.transform = parentTransform;
                }
                if (pinDimMarker) {
                    pinDimMarker.src = currentZoom > 15 ? pinMarkerIcon : pinMiddleMarkerIcon;
                    pinDimMarker.style.top = pinMarkDiv.style.top;
                    pinDimMarker.style.left = pinMarkDiv.style.left;
                    pinDimMarker.style.transform = parentTransform;
                }
                clearTimeout(pinEventCheck.current);
                pinEventCheck.current = null;
            }, 800);
        }
    };

    const pinTouchEndEvent = async (evt: any) => {
        if (drawingTypeRef.current === 0) {
            evt.stopPropagation();
            const currentId = evt.target.parentElement.id;
            if (pinEventCheck.current) {
                const currentInfo = pinMarkerInformationRef.current?.[currentId?.slice(-1) - 1];
                drawPolylineWithLandCard(currentInfo?.latlng ?? currentInfo?.clickedLatLng, true);
                clearTimeout(pinEventCheck.current);
                pinEventCheck.current = null;
            }
            if (currentMap?.map) {
                currentMap?.map.setOptions({
                    draggable: true,
                    pinchZoom: true,
                });
            }
        }
    };

    const [items, setItems] = useState<any[]>([]);

    ////////////////////
    //지도에서 마커 지우기
    const hideMarkers = () => {
        const resetMarkers = renderMarkerList.map((marker) => marker.setMap(null));
        setRenderMarkerList(resetMarkers);

        const resetRectMarkers = renderRectMarkerList.map((marker) => marker.setMap(null));
        setRenderRectMarkerList(resetRectMarkers);
        const resetRealMarkers = renderRealMarkerList.map((marker) => marker.setMap(null));
        setRenderRealMarkerList(resetRealMarkers);
        const resetExpectedMarkers = renderExpectedMarkerList.map((marker) => marker.setMap(null));
        setExpectedRenderMarkerList(resetExpectedMarkers);
    };
    //클러스터링 마커 클릭 이벤트
    const handleCluMarkerClick = (map: any, onclick: any) => {
        const nextZm = onclick.next_level ?? 0;
        const { latd, lntd }: any = onclick.center;

        const nextLatLng = new naverMaps.LatLng(latd, lntd);
        map?.morph(nextLatLng, nextZm, { duration: "0.2s", easing: "easeInCubic" });
    };
    const currentFisrtItemIdRef = useRef<string | null>(null);
    //bot, low , exp 마크 클릭 이벤트
    const handleBottomMarkerClick = (item: any, params: any, isMulti: boolean = false) => {
        // if (currentFisrtItemIdRef.current === item && !isMulti) {
        //     return;
        // }
        currentFisrtItemIdRef.current = item;
        setIsFieldCardShow(false);
        setIsPopulationStatusCardShow(false);
        useGetNewMapList(params)
            .then((res) => {
                let passResponse = parseString(res);
                setCurrentIndex(0);
                const firstItem = res.filter((q: any) => q.item_id === item);
                const restItem = res.filter((q: any) => q.item_id !== item);
                const newItems = item ? [...firstItem, ...restItem] : res;
                setItems(newItems);
                setIsSaleCardShow(true);
            })
            .catch((e) => console.log(e));
    };

    //item 마커 클릭 이벤트
    const handleItemMarkerClick = (item: any) => {
        hideLandCard();
        let passResponse = parseString([item]);
        setItems([item]);
        setCurrentIndex(0);
        setIsSaleCardShow(true);
        setIsPopulationStatusCardShow(false);
        transferToNative(passResponse, "showItemCard");
        setIsMarkerClicked(!isMarkerClicked);
    };

    const [markerType, setMarkerType] = useState<"product" | "real" | "expected">("product");
    // !임시로 빌딩 평형 정보 넣기(다른 사람 일정상 임의 작업 추후 수정 필요
    const [buildingSize, setBuildingSize] = useState<any>(0);
    const realMarkerListRef = useRef<any>(null);
    const cluItemListRef = useRef<any>(null);
    const realMarkerEventListenerListRef = useRef<any>([]);
    const prevSelectedRef = useRef<any>(null);

    const handleMultiMarkerConvert = (e: any, contents: any) => {
        const { displayName, count, item, real, markerType } = contents;
        const prevMarker = e.overlay;
        const convertMarker = renderToStaticMarkup(
            Marker({
                type: "multi",
                displayName,
                count,
                item,
                real,
                markerType,
            }),
        );
        let Anchor = markerType === "product" ? new naver.maps.Point(50, 70) : new naver.maps.Point(80, 100);
        prevMarker?.setIcon({
            content: convertMarker,
            anchor: Anchor,
            origin: new naver.maps.Point(50, 50),
        });
    };
    useEffect(() => {
        ///마커 생성
        if (!mapRef.current || !naver) return;
        realMarkerListRef.current = [];
        cluItemListRef.current = [];
        realMarkerEventListenerListRef.current = [];
        let newMarkerList: any[] = [];
        let newExpectedMarkerList: any[] = [];
        let newRecMarkerList: any[] = [];
        let newRealMarkerList: any[] = [];
        hideMarkers();
        markerContents.map((cluItem, index: number) => {
            let {
                type,
                count,
                latd: cluItemLatd,
                lntd: cluItemLntd,
                item,
                _id: displayName,
                onclick,
                sw,
                ne,
                real,
                use,
            } = cluItem;
            const markerElement = renderToStaticMarkup(
                Marker({
                    type,
                    displayName,
                    count,
                    item,
                    real,
                    markerType,
                    use,
                }),
            );
            const position = {
                lat: cluItemLatd,
                lng: cluItemLntd,
            };
            const zIndex = zIndexOfMarker[type];
            if (currentMap) {
                if (type === "real") {
                    //실거래 작은 마커
                    const isShowSmallRealMarker = cluItem.show_marker;
                    if (isShowSmallRealMarker) {
                        const newMarker = new naver.maps.Marker({
                            position: new naver.maps.LatLng(position.lat, position.lng),
                            map: currentMap.map,
                            clickable: true,
                            zIndex,
                            icon: {
                                content: markerElement,
                                anchor: new naver.maps.Point(50, 70),
                                origin: new naver.maps.Point(50, 50),
                            },
                            visible: !isMarkerHide.real,
                            title: displayName,
                        });
                        const prevIcon = newMarker.getIcon();
                        newMarker.setIcon({
                            ...prevIcon,
                            anchor: new naver.maps.Point(26, 55),
                        });
                        realMarkerListRef.current = realMarkerListRef.current
                            ? [...realMarkerListRef.current, newMarker]
                            : [newMarker];
                        cluItemListRef.current = cluItemListRef.current ? [...cluItemListRef.current, cluItem] : [cluItem];
                        newRealMarkerList.push(newMarker);
                        realMarkerEventListenerListRef.current = [
                            ...realMarkerEventListenerListRef.current,
                            newMarker.addListener("click", () => {
                                setIsBackToAppShow(false);
                                setFromCheck("");
                                const newClu = cluItem;
                                const checkVal = prevSelectedRef.current !== newClu._id;
                                if (checkVal) {
                                    if (prevSelectedRef.current !== null) {
                                        const prevCluItem = cluItemListRef.current.filter(
                                            (q: any) => q._id === prevSelectedRef.current,
                                        );
                                        if (prevCluItem.length) {
                                            const prevMarkerIndex = realMarkerListRef.current.findIndex(
                                                (q: any) => q.getTitle() === prevSelectedRef.current,
                                            );
                                            const markerElement = renderToStaticMarkup(
                                                Marker({
                                                    type: "real",
                                                    displayName: prevCluItem[0]._id,
                                                    count: prevCluItem[0].count,
                                                    item: prevCluItem[0].item,
                                                    real: prevCluItem[0].real,
                                                    markerType: "real",
                                                }),
                                            );
                                            realMarkerListRef.current[prevMarkerIndex]?.setMap(null);
                                            realMarkerListRef.current[prevMarkerIndex]?.setOptions({
                                                position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                                                map: currentMap.map,
                                                clickable: true,
                                                zIndex,
                                                icon: {
                                                    content: markerElement,
                                                    anchor: new naver.maps.Point(26, 55),
                                                    origin: new naver.maps.Point(50, 50),
                                                },
                                                visible: !isMarkerHide.real,
                                                title: prevCluItem[0]._id,
                                            });
                                            realMarkerListRef.current[prevMarkerIndex]?.setMap(currentMap.map);
                                        }
                                    }
                                }
                                prevSelectedRef.current = newClu._id;
                                const currentCluItem = cluItemListRef.current.filter(
                                    (q: any) => q._id === prevSelectedRef.current,
                                );
                                const currentIndex = realMarkerListRef.current.findIndex((q: any) => q.getTitle() === newClu._id);
                                const markerElement = renderToStaticMarkup(
                                    Marker({
                                        type: "realDetail",
                                        displayName: currentCluItem[0]._id,
                                        count: currentCluItem[0].count,
                                        item: currentCluItem[0].item,
                                        real: currentCluItem[0].real,
                                        markerType,
                                    }),
                                );
                                realMarkerListRef.current[currentIndex]?.setOptions({
                                    icon: {
                                        content: markerElement,
                                        anchor: new naver.maps.Point(80, 100),
                                    },
                                    position: new naverMaps.LatLng(currentCluItem[0].latd, currentCluItem[0].lntd),
                                    zIndex: 6,
                                    map: currentMap?.map,
                                    clickable: true,
                                    visible: true,
                                    title: currentCluItem[0]._id,
                                });
                                if (checkVal) {
                                    currentCluItem[0]?.real?.area &&
                                        setBuildingSize(
                                            Number(
                                                currentCluItem[0].real.area?.slice(2, currentCluItem[0].real.area.length - 1),
                                            ).toFixed(0),
                                        );
                                    setIsFieldCardShow(false);
                                    setIsPopulationStatusCardShow(false);
                                    const POSITION = new naverMaps.LatLng(position);
                                    drawPolylineWithLandCard(POSITION, true, currentCluItem[0].real?.pnu);
                                }
                            }),
                        ];
                    }
                    //예정매물
                } else if (item?.bid_type_detail === 6 || type === "expGroup" || type === "expBot") {
                    //예정매물 숨기기
                    expectedMarkerRef.current = currentMap.marker(markerElement, position, 4, !isMarkerHide.expected);
                    newExpectedMarkerList.push(expectedMarkerRef.current);
                    addListener(expectedMarkerRef?.current, "click", async (e) => {
                        setIsBackToAppShow(false);
                        setFromCheck("");
                        const clickType = onclick.type;
                        const copyItem = item && JSON.parse(JSON.stringify(item));
                        if (clickType === "zoom") {
                            handleCluMarkerClick(currentMap?.map, onclick);
                        } else if (clickType === "list") {
                            //! 폴리라인 그리기
                            drawPolylineWithoutLandCard(new naver.maps.LatLng({ lat: copyItem.latd, lng: copyItem.lntd }));
                            if (prevSelectedRef.current !== null) {
                                const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
                                const prevMarkerIndex = realMarkerListRef.current.findIndex(
                                    (q: any) => q.getTitle() === prevSelectedRef.current,
                                );
                                if (prevCluItem.length) {
                                    const markerElement = renderToStaticMarkup(
                                        Marker({
                                            type: "real",
                                            displayName: prevCluItem[0]._id,
                                            count: prevCluItem[0].count,
                                            item: prevCluItem[0].item,
                                            real: prevCluItem[0].real,
                                            markerType: "real",
                                        }),
                                    );
                                    realMarkerListRef.current[prevMarkerIndex].setMap(null);
                                    realMarkerListRef.current[prevMarkerIndex].setOptions({
                                        position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                                        map: currentMap?.map,
                                        clickable: true,
                                        zIndex: 3,
                                        icon: {
                                            content: markerElement,
                                            anchor: new naver.maps.Point(26, 55),
                                            origin: new naver.maps.Point(50, 50),
                                        },
                                        visible: !isMarkerHide.real,
                                        title: prevCluItem[0]._id,
                                    });
                                    realMarkerListRef.current[prevMarkerIndex]?.setMap(currentMap?.map);
                                    prevSelectedRef.current = null;
                                }
                            }
                            if (type === "low") {
                                handleBottomMarkerClick(copyItem?.item_id, {
                                    zoom: currentZoomRef.current,
                                    sw,
                                    ne,
                                    filter: {
                                        ...filterState,
                                        loc: removeAll(filterState.loc),
                                        use: makeUseParam(filterState.use),
                                    },
                                });
                            } else {
                                const ClassList = e.domEvent.target.classList;
                                if (type === "multi") {
                                    if (ClassList.contains("convertMarkerType")) {
                                        handleMultiMarkerConvert(e, {
                                            displayName,
                                            count,
                                            item,
                                            real,
                                            markerType: "real",
                                        });
                                        setIsFieldCardShow(false);
                                        setIsPopulationStatusCardShow(false);
                                        drawPolylineWithLandCard(new naverMaps.LatLng(position), true, copyItem?.pnu);
                                    } else if (ClassList.contains("convertMarkerType_real")) {
                                        handleMultiMarkerConvert(e, {
                                            displayName,
                                            count,
                                            item,
                                            real,
                                            markerType: "product",
                                        });
                                        copyItem &&
                                            handleBottomMarkerClick(
                                                copyItem?.item_id,
                                                {
                                                    zoom: currentZoomRef.current,
                                                    sw,
                                                    ne,
                                                    filter: {
                                                        ...filterState,
                                                        loc: removeAll(filterState.loc),
                                                        use: makeUseParam(filterState.use),
                                                    },
                                                    itemId: copyItem?.item_id,
                                                },
                                                true,
                                            );
                                    } else {
                                        ClassList.forEach((c: string) => {
                                            if (c.includes("real")) {
                                                //클릭한 마커가 멀티-실거래 마커일때
                                                setIsFieldCardShow(false);
                                                setIsPopulationStatusCardShow(false);
                                                drawPolylineWithLandCard(new naverMaps.LatLng(position), true, copyItem?.pnu);
                                            } else {
                                                //클릭한 마커가 멀티-경공매 마커일때
                                                copyItem &&
                                                    handleBottomMarkerClick(
                                                        copyItem?.item_id,
                                                        {
                                                            zoom: currentZoomRef.current,
                                                            sw,
                                                            ne,
                                                            filter: {
                                                                ...filterState,
                                                                loc: removeAll(filterState.loc),
                                                                use: makeUseParam(filterState.use),
                                                            },
                                                            itemId: copyItem?.item_id,
                                                        },
                                                        true,
                                                    );
                                            }
                                        });
                                    }
                                } else {
                                    copyItem &&
                                        handleBottomMarkerClick(copyItem?.item_id, {
                                            zoom: currentZoomRef.current,
                                            sw,
                                            ne,
                                            filter: {
                                                ...filterState,
                                                loc: removeAll(filterState.loc),
                                                use: makeUseParam(filterState.use),
                                            },
                                            itemId: copyItem?.item_id,
                                        });
                                }
                            }
                        }
                    });
                } else {
                    markerRef.current = currentMap.marker(markerElement, position, zIndex, !isMarkerHide.product);
                    newMarkerList.push(markerRef.current);
                    addListener(markerRef.current, "click", async (e) => {
                        setIsBackToAppShow(false);
                        setFromCheck("");
                        const clickType = onclick.type;
                        const copyItem = item && JSON.parse(JSON.stringify(item));
                        if (clickType === "zoom") {
                            handleCluMarkerClick(currentMap?.map, onclick);
                        } else if (clickType === "list") {
                            //! 폴리라인 그리기
                            drawPolylineWithoutLandCard(
                                new naver.maps.LatLng({ lat: copyItem.latd, lng: copyItem.lntd }),
                                item?.pnu,
                            );
                            if (prevSelectedRef.current !== null) {
                                const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
                                const prevMarkerIndex = realMarkerListRef.current.findIndex(
                                    (q: any) => q.getTitle() === prevSelectedRef.current,
                                );
                                if (prevCluItem.length) {
                                    const markerElement = renderToStaticMarkup(
                                        Marker({
                                            type: "real",
                                            displayName: prevCluItem[0]._id,
                                            count: prevCluItem[0].count,
                                            item: prevCluItem[0].item,
                                            real: prevCluItem[0].real,
                                            markerType: "real",
                                        }),
                                    );
                                    realMarkerListRef.current[prevMarkerIndex].setMap(null);
                                    realMarkerListRef.current[prevMarkerIndex].setOptions({
                                        position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                                        map: currentMap?.map,
                                        clickable: true,
                                        zIndex: 3,
                                        icon: {
                                            content: markerElement,
                                            anchor: new naver.maps.Point(26, 55),
                                            origin: new naver.maps.Point(50, 50),
                                        },
                                        visible: !isMarkerHide.real,
                                        title: prevCluItem[0]._id,
                                    });
                                    realMarkerListRef.current[prevMarkerIndex]?.setMap(currentMap?.map);
                                }
                                prevSelectedRef.current = null;
                            }
                            if (type === "bot" || type === "low") {
                                handleBottomMarkerClick(copyItem?.item_id, {
                                    zoom: currentZoomRef.current,
                                    isExpected: false,
                                    sw,
                                    ne,
                                    filter: {
                                        ...filterState,
                                        loc: removeAll(filterState.loc),
                                        use: makeUseParam(filterState.use),
                                    },
                                });
                            } else {
                                const ClassList = e.domEvent.target.classList;
                                if (type === "multi") {
                                    if (ClassList.contains("convertMarkerType")) {
                                        handleMultiMarkerConvert(e, {
                                            displayName,
                                            count,
                                            item,
                                            real,
                                            markerType: "real",
                                        });
                                        setIsFieldCardShow(false);
                                        setIsPopulationStatusCardShow(false);
                                        drawPolylineWithLandCard(new naverMaps.LatLng(position), true, copyItem?.pnu);
                                    } else if (ClassList.contains("convertMarkerType_real")) {
                                        handleMultiMarkerConvert(e, {
                                            displayName,
                                            count,
                                            item,
                                            real,
                                            markerType: "product",
                                        });
                                        copyItem &&
                                            handleBottomMarkerClick(
                                                copyItem?.item_id,
                                                {
                                                    zoom: currentZoomRef.current,
                                                    sw,
                                                    ne,
                                                    filter: {
                                                        ...filterState,
                                                        loc: removeAll(filterState.loc),
                                                        use: makeUseParam(filterState.use),
                                                    },
                                                    itemId: copyItem?.item_id,
                                                },
                                                true,
                                            );
                                    } else {
                                        ClassList.forEach((c: string) => {
                                            if (c.includes("real")) {
                                                //클릭한 마커가 멀티-실거래 마커일때
                                                setIsFieldCardShow(false);
                                                setIsPopulationStatusCardShow(false);
                                                drawPolylineWithLandCard(new naverMaps.LatLng(position), true, copyItem?.pnu);
                                            } else {
                                                //클릭한 마커가 멀티-경공매 마커일때
                                                copyItem &&
                                                    handleBottomMarkerClick(
                                                        copyItem?.item_id,
                                                        {
                                                            zoom: currentZoomRef.current,
                                                            sw,
                                                            ne,
                                                            filter: {
                                                                ...filterState,
                                                                loc: removeAll(filterState.loc),
                                                                use: makeUseParam(filterState.use),
                                                            },
                                                            itemId: copyItem?.item_id,
                                                        },
                                                        true,
                                                    );
                                            }
                                        });
                                    }
                                } else {
                                    copyItem &&
                                        handleBottomMarkerClick(copyItem?.item_id, {
                                            zoom: currentZoomRef.current,
                                            isExpected: false,
                                            sw,
                                            ne,
                                            filter: {
                                                ...filterState,
                                                loc: removeAll(filterState.loc),
                                                use: makeUseParam(filterState.use),
                                            },
                                            itemId: copyItem?.item_id,
                                        });
                                }
                            }
                        }
                    });
                }
            }
        });
        setRenderMarkerList(newMarkerList);
        setExpectedRenderMarkerList(newExpectedMarkerList);
        setRenderRectMarkerList(newRecMarkerList);
        setRenderRealMarkerList(newRealMarkerList);
    }, [markerContents, markerType]);

    const mapApiParamsRef = useRef<any>(mapApiParams);
    //새 마커 api 가져오기
    const fetchNewMarker = (bounds: any, zoom: any) => {
        const device_width = window.innerWidth;
        const { _ne: ne, _sw: sw } = bounds;
        setMapApiParams((prev: any) => {
            const noDeviceWidthParam = {
                ...prev,
                sw: {
                    lntd: sw._lng,
                    latd: sw._lat,
                },
                ne: {
                    lntd: ne._lng,
                    latd: ne._lat,
                },
                zoom,
            };
            let newParams = {
                ...prev,
                sw: {
                    lntd: sw._lng,
                    latd: sw._lat,
                },
                ne: {
                    lntd: ne._lng,
                    latd: ne._lat,
                },
                zoom,
                real_filter: { ...prev.real_filter, device_width },
                hidden: {
                    hideExpected: isMarkerHideRef.current.expected,
                    hideBid: isMarkerHideRef.current.product,
                    hideReal: isMarkerHideRef.current.real,
                },
            };
            useGetMapAggregation(newParams)
                .then((res) => {
                    realMarkerListRef.current = [];
                    setMarkerContents(res);
                })
                .then(() => {
                    mapApiParamsRef.current = noDeviceWidthParam;
                    if (startStateLandRef.current && currentMap) {
                        if (currentMap.map.getZoom() >= 16) {
                            if (stateLandSubsRef.current && stateLandSubsRef.current?.allPnus) {
                                refetchDimStateLand().then((res) => {
                                    if (res.isFetched) {
                                        !res.data?.unpurchasedRegions && refetchStateLand();
                                    }
                                });
                            } else {
                                refetchStateLand();
                            }
                        } else {
                            setShowStateLandZoomInfo(true);
                        }
                    }
                });
            return noDeviceWidthParam;
        });
    };

    const zoomChangeEventRef = useRef<any>();
    const dragEventRef = useRef<any>();
    const checkClickedMyLocation = useRef<boolean>(false);

    const isMoveMap = () => {
        if (currentMap?.map) {
            if (realMarkerListRef.current) {
                realMarkerListRef.current?.map((q: any) => q.setMap(null));
                realMarkerListRef.current = [];
            }
            currentCheckRef.current = "";
            prevSelectedRef.current = null;
            !searchParams.get("lat") && onMSGAApiCancelHandler();
            let currentBounds = currentMap.map.getBounds();
            let currentZoom = currentMap.map.getZoom();
            currentZoomRef.current = currentZoom;
            fetchNewMarker(currentBounds, currentZoom);
            let currentPosition = currentMap.map.getCenter() as naver.maps.LatLng;
            let params = { zoom: currentZoom, lat: currentPosition.lat(), lng: currentPosition.lng() };
            usePostUserLastPosition(params);
            usePostUserLastMapType({ type: currentMap.map.getMapTypeId() });
            currentSearchMarker?.setMap(null);
            const currentCenter = currentMap?.map?.getCenter();
            const newParam: IPolygonApiParams = {
                latitude: currentCenter.y,
                longitude: currentCenter.x,
                zoom: currentZoom,
            };
            drawingMoveFieldPolygon(newParam);
            if (currentZoom < 15) {
                hideLandCard();
                setIsSaleCardShow(false);
                setIsPopulationStatusCardShow(false);
                currentFisrtItemIdRef.current = null;
            } else {
                selectedFieldMarkerRef.current && selectedFieldMarkerRef.current?.setMap(null);
            }
            if (currentZoom < 16) {
                selectedFieldMarkerRef.current && selectedFieldMarkerRef.current?.setMap(currentMap.map);
                pinMarkerRef.current?.map((pin) => pin?.setIcon(middlePinSize));
            } else {
                selectedFieldMarkerRef.current && selectedFieldMarkerRef.current?.setMap(null);
                pinMarkerRef.current?.map((pin) => pin?.setIcon(largePinSize));
            }
            if (currentZoom > 13 || currentZoom < 7) {
                clickFieldPolygonRef.current?.setMap(null);
            }
        }
    };

    useEffect(() => {
        //지도 이벤트 추가(줌, 드래그, 클릭)
        if (currentMap?.map) {
            zoomChangeEventRef.current = addListener(currentMap.map, "zoom_changed", () => {
                if (!checkClickedMyLocation.current) {
                    // setCheckMyPosition(false);
                } else {
                    checkClickedMyLocation.current = false;
                }
                isMoveMap();
            });
            dragEventRef.current = addListener(currentMap.map, "dragend", () => {
                if (!checkClickedMyLocation.current) {
                    // setCheckMyPosition(false);
                } else {
                    checkClickedMyLocation.current = false;
                }
                isMoveMap();
            });
        }
    }, [currentMap, currentSearchMarker]);

    const addHideActiveClassName = (elem: any) => {
        const newClassName =
            elem.className === "hideCadastralButton active" ? "hideCadastralButton" : "hideCadastralButton active";
        elem.className = newClassName;
    };

    const addHideActiveClassNameV2 = (elem: any) => {
        const newClassName =
            drawingType !== 0
                ? "hideCadastralButton active"
                : elem.className === "hideCadastralButton active"
                ? "hideCadastralButton"
                : "hideCadastralButton active";
        elem.className = newClassName;
    };

    //! 편의 기능

    const [showConvenienceBar, setShowConvenienceBar] = useState<boolean>(false);
    const [prevMarkerHide, setPrevMarkerHide] = useState<any>(null);
    const [showCanvas, setShowCanvas] = useState<boolean>(false);
    const [drawingType, setDrawingType] = useState<number>(0);
    const prevCadastralMapRef = useRef<any>(null);
    const drawingTypeRef = useRef<any>();
    const mapClickEventRef = useRef<any>();
    const mapLongTapEventRef = useRef<any>();
    const drawPolylineRef = useRef<any>();
    const drawPolylineEventRef = useRef<any>();
    const drawPolygonEventRef = useRef<any>();
    const drawPolylinePointRef = useRef<any>();
    const drawPolylineMarkerRef = useRef<any>();
    const drawPolygonMarkerRef = useRef<any>();
    const [totalPolylineDistance, setTotalPolylineDistance] = useState<number>(-1);
    const [totalPolygonSize, setTotalPolygonSize] = useState<number>(-1);
    const drawPolygonRef = useRef<any>();
    const drawPolygonPointRef = useRef<any>();

    //* 면적 합산
    const drawPolygonSizeEventRef = useRef<any>();
    const drawPolygonSizeRef = useRef<any>();
    const [totalPolygonLandSize, setTotalPolygonLandSize] = useState<{ pyong: number; square: number }>({ pyong: 0, square: 0 });
    const [polygonLandList, setPolygonLandList] = useState<ISimpleLandData[]>([]);
    const polygonLandListRef = useRef<ISimpleLandData[]>([]);
    const landSizePolygonListRef = useRef<any[]>([]);
    const landSizeMarkerListRef = useRef<any[]>([]);
    const [isShowToastLandSize, setIsShowToastLandSize] = useState<boolean>(false);
    const [selectedLandSizePolygonPnu, setSelectedLandSizePolygonPnu] = useState<string>("");

    const drawLandSizePolygon = (index: number, landGeometryCoordinates: any, pnu: string) => {
        if (currentMap) {
            const newPolygon = new naver.maps.Polygon({
                map: currentMap.map,
                paths: landGeometryCoordinates,
                fillColor: "#008FF8",
                fillOpacity: 0.4,
                clickable: true,
                strokeStyle: "dash",
                strokeColor: "#5F0080",
                strokeWeight: 1,
                strokeLineCap: "square",
                id: pnu,
            } as any);
            const polygonBounds = newPolygon?.getBounds() as naver.maps.LatLngBounds;
            const maxPoint = polygonBounds.getMax();
            const minPoint = polygonBounds.getMin();
            const newPoint = {
                x: (maxPoint.x + minPoint.x) / 2,
                y: (maxPoint.y + minPoint.y) / 2,
            };
            const numberMarkerContents = renderToStaticMarkup(<div className="customAreaCard">{index}</div>);
            const numberMarker = new naver.maps.Marker({
                position: newPoint,
                map: currentMap.map,
                icon: {
                    content: numberMarkerContents,
                    size: new naver.maps.Size(22, 20),
                },
                zIndex: zIndexOfMarker.my,
                clickable: true,
                id: pnu,
            } as any);
            naver.maps.Event.addListener(newPolygon, "click", (evt) => {
                if (drawingTypeRef.current === 3) {
                    const pnu = evt.overlay?.id;
                    currentMap.map.morph(newPoint);
                    setSelectedLandSizePolygonPnu(pnu);
                }
            });
            naver.maps.Event.addListener(numberMarker, "click", (evt) => {
                if (drawingTypeRef.current === 3) {
                    const pnu = evt.overlay?.id;
                    currentMap.map.morph(newPoint);
                    setSelectedLandSizePolygonPnu(pnu);
                }
            });
            landSizePolygonListRef.current = [...landSizePolygonListRef.current, newPolygon];
            landSizeMarkerListRef.current = [...landSizeMarkerListRef.current, numberMarker];
        }
    };

    const onChangeShowConvenienceBar = () => {
        setShowConvenienceBar((pre) => !pre);
    };

    // 편의기능(면적, 거리측정) 종료시 그린 선들 없애주는 함수
    const onCloseConvenience = () => {
        onDeleteLandSizePolygon(-1);
        drawPolylineRef.current?.setMap(null);
        drawPolylineMarkerRef.current?.forEach((marker: any) => {
            marker?.setMap(null);
        });
        drawPolylinePointRef.current = null;
        drawPolygonRef.current?.setMap(null);
        drawPolygonMarkerRef.current?.forEach((marker: any) => {
            marker?.setMap(null);
        });
        if (currentMap && prevCadastralMapRef.current !== null) {
            if (prevCadastralMapRef.current !== isCadastralShow) {
                mapLayerHandler.activeCadastralMap(currentMap.map);
            }
            prevMarkerHide && setIsMarkerHide(prevMarkerHide);
            setPrevMarkerHide(null);
            prevCadastralMapRef.current = null;
        }
        if (cadastralRef.current) {
            cadastralRef.current.className = "hideCadastralButton hidden";
        }
        drawPolygonPointRef.current = null;
        setTotalPolylineDistance(-1);
        setTotalPolygonSize(-1);
        setDrawingType(0);
    };

    // 포인트 하나씩 지우는 함수
    const onClickDeletePoint = () => {
        if (drawingType === 1) {
            drawPolylineMarkerRef.current[drawPolylineMarkerRef.current.length - 1]?.setMap(null);
            drawPolylineMarkerRef.current = drawPolylineMarkerRef.current.splice(0, drawPolylineMarkerRef.current.length - 1);
            drawPolylinePointRef.current = drawPolylinePointRef.current.splice(0, drawPolylinePointRef.current.length - 1);
            drawPolylineRef.current?.setMap(null);
            drawPolylineRef.current?.setPath(drawPolylinePointRef.current);
            drawPolylineRef.current?.setMap(currentMap?.map);
            const newDistance =
                drawPolylinePointRef.current.length < 1
                    ? -1
                    : drawPolylinePointRef.current.length >= 1
                    ? drawPolylineRef.current?.getDistance()
                    : 0;
            setTotalPolylineDistance(newDistance);
        } else if (drawingType === 2) {
            drawPolygonMarkerRef.current[drawPolygonMarkerRef.current.length - 1]?.setMap(null);
            drawPolygonMarkerRef.current = drawPolygonMarkerRef.current.splice(0, drawPolygonMarkerRef.current.length - 1);
            drawPolygonPointRef.current = drawPolygonPointRef.current.splice(0, drawPolygonPointRef.current.length - 1);
            drawPolygonRef.current?.setMap(null);
            drawPolygonRef.current?.setPath(drawPolygonPointRef.current);
            drawPolygonRef.current?.setMap(currentMap?.map);
            const newSize =
                drawPolygonPointRef.current.length < 1
                    ? -1
                    : drawPolygonPointRef.current.length >= 1
                    ? drawPolygonRef.current?.getAreaSize()
                    : 0;
            setTotalPolygonSize(newSize);
        }
    };

    const onClickAllDeletePoint = () => {
        if (drawingType === 1) {
            drawPolylineMarkerRef.current.map((marker: any) => marker?.setMap(null));
            drawPolylineMarkerRef.current = null;
            drawPolylinePointRef.current = null;
            drawPolylineRef.current?.setMap(null);
            setTotalPolylineDistance(-1);
        } else if (drawingType === 2) {
            drawPolygonMarkerRef.current.map((marker: any) => marker?.setMap(null));
            drawPolygonMarkerRef.current = null;
            drawPolygonPointRef.current = null;
            drawPolygonRef.current?.setMap(null);
            setTotalPolygonSize(-1);
        }
    };

    const onClickDrawLine = (evt: any) => {
        if (currentMap) {
            if (drawPolylinePointRef.current?.length >= 100) {
                return;
            }
            const newCoord = evt.coord;
            drawPolylinePointRef.current
                ? (drawPolylinePointRef.current = [...drawPolylinePointRef.current, newCoord])
                : (drawPolylinePointRef.current = [newCoord]);
            if (!drawPolylineRef.current) {
                drawPolylineRef.current = new naver.maps.Polyline({
                    strokeWeight: 3,
                    strokeOpacity: 0.5,
                    strokeColor: "#0047FF",
                    zIndex: 28,
                    path: [newCoord],
                    map: currentMap.map,
                });
            } else {
                drawPolylineRef.current?.setMap(null);
                drawPolylineRef.current?.setPath(drawPolylinePointRef.current);
            }
            const newIdx = drawPolylinePointRef.current ? drawPolylinePointRef.current.length - 1 : 0;
            const tempPoly =
                drawPolylinePointRef.current[drawPolylinePointRef.current.length - 2] &&
                new naver.maps.Polyline({
                    strokeWeight: 1,
                    strokeOpacity: 0,
                    path: [drawPolylinePointRef.current[drawPolylinePointRef.current.length - 2], newCoord],
                    zIndex: 28,
                    map: currentMap.map,
                });
            const distanceName = tempPoly
                ? tempPoly.getDistance().toFixed(0).length > 3
                    ? `${(tempPoly?.getDistance() / 1000).toFixed(1)}km`
                    : `${tempPoly?.getDistance().toFixed(0)}m`
                : "시작";
            const newContent = renderToStaticMarkup(
                distanceName === "시작" ? (
                    <div>
                        <div className="convenienceBox">{newIdx}</div>
                    </div>
                ) : (
                    <div>
                        <div className="convenienceBox">{newIdx}</div>
                        <div className="distanceBox">{distanceName}</div>
                    </div>
                ),
            );
            const newMarker = new naverMaps.Marker({
                map: currentMap.map,
                position: newCoord,
                zIndex: 28,
                icon: {
                    content: newContent,
                    anchor: new naver.maps.Point(12, 12),
                },
                clickable: false,
            });
            newIdx === 0
                ? (drawPolylineMarkerRef.current = [newMarker])
                : (drawPolylineMarkerRef.current = [...drawPolylineMarkerRef.current, newMarker]);
            newMarker?.setMap(currentMap.map);
            drawPolylineRef.current?.setMap(currentMap.map);
            tempPoly?.setMap(null);
            setTotalPolylineDistance(drawPolylineRef.current?.getDistance() ?? 0);
        }
    };

    const onClickDrawSize = (evt: any) => {
        if (currentMap) {
            if (drawPolygonPointRef.current?.length >= 100) {
                return;
            }
            const newCoord = evt.coord;
            drawPolygonPointRef.current
                ? (drawPolygonPointRef.current = [...drawPolygonPointRef.current, newCoord])
                : (drawPolygonPointRef.current = [newCoord]);
            if (!drawPolygonRef.current) {
                drawPolygonRef.current = new naver.maps.Polygon({
                    strokeWeight: 3,
                    strokeOpacity: 0.7,
                    strokeColor: "#0047FF",
                    fillColor: "#2275EE",
                    fillOpacity: 0.5,
                    zIndex: 28,
                    paths: [newCoord],
                    map: currentMap?.map,
                });
            } else {
                drawPolygonRef.current?.setMap(null);
                drawPolygonRef.current?.setPath(drawPolygonPointRef.current);
            }
            const newIdx = drawPolygonPointRef.current ? drawPolygonPointRef.current.length - 1 : 0;
            const newContent = renderToStaticMarkup(
                <div>
                    <div className="convenienceBox">{newIdx}</div>
                </div>,
            );
            const newMarker = new naverMaps.Marker({
                map: currentMap?.map,
                position: newCoord,
                zIndex: 28,
                icon: {
                    content: newContent,
                    anchor: new naver.maps.Point(8, 8),
                },
                clickable: false,
            });
            newIdx === 0
                ? (drawPolygonMarkerRef.current = [newMarker])
                : (drawPolygonMarkerRef.current = [...drawPolygonMarkerRef.current, newMarker]);
            newMarker?.setMap(currentMap.map);
            drawPolygonRef.current?.setMap(currentMap.map);
            setTotalPolygonSize(drawPolygonRef.current?.getAreaSize() ?? 0);
        }
    };

    const onClickWithLandSizeAdd = debounce(async (lat: number, lng: number) => {
        const fieldInfoResponse = lat && (await useGetMapFieldInformation({ latitude: lat, longitude: lng }));
        const params = { lat, lng };
        let vworldLandResponse: any;
        if (!fieldInfoResponse) {
            vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
        }
        let landPolygon: any = fieldInfoResponse?.land?.geometry?.coordinates?.[0];
        if (!landPolygon) {
            try {
                vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
                const { geometryCoordinates: vworldLandPolygon, bbox } = vworldLandResponse;
                landPolygon = fieldInfoResponse?.land?.geometry?.coordinates?.[0] ?? vworldLandPolygon;
            } catch (error) {
                console.log(error);
            }
        }
        const newPnu = fieldInfoResponse?.pnu;
        const newAddress = fieldInfoResponse?.land?.address ?? vworldLandResponse?.address;
        const newFieldJimok = fieldInfoResponse?.land?.jimok ?? "";
        if (polygonLandListRef.current?.some((val) => val.pnu === newPnu)) {
            return;
        }
        if (polygonLandListRef.current?.length >= 5) {
            setIsShowToastLandSize(true);
            return;
        }
        const newData = await fetchAreaCardData({ pnu: newPnu, lat, lng, address: newAddress, jimok: newFieldJimok });
        polygonLandListRef.current = [...polygonLandListRef.current, newData];
        const newTotalSize = polygonLandListRef.current?.reduce(
            (q: any, val: any) => {
                q = {
                    pyong: q.pyong + Number(val?.size?.pyong?.replaceAll(/,|평|-/gi, "")),
                    square: q.square + Number(val?.size?.square?.replaceAll(/,|㎡|-/gi, "")),
                };
                return q;
            },
            { pyong: 0, square: 0 },
        );
        setTotalPolygonLandSize(newTotalSize);
        drawLandSizePolygon(polygonLandListRef.current.length, landPolygon, newPnu);
        setPolygonLandList(polygonLandListRef.current);
        setSelectedLandSizePolygonPnu("");
    }, 200);

    const onDeleteLandSizePolygon = (index: number) => {
        if (index === -1) {
            landSizePolygonListRef.current?.map((val: any) => val?.setMap(null));
            landSizeMarkerListRef.current?.map((val: any) => val?.setMap(null));
            landSizePolygonListRef.current = [];
            landSizeMarkerListRef.current = [];
            polygonLandListRef.current = [];
            setTotalPolygonLandSize({ pyong: 0, square: 0 });
            setPolygonLandList([]);
            setSelectedLandSizePolygonPnu("");
        } else {
            landSizePolygonListRef.current?.map((val: any, idx: number) => {
                if (idx === index) {
                    val?.setMap(null);
                }
            });
            landSizePolygonListRef.current = landSizePolygonListRef.current?.filter((_val: any, idx: number) => idx !== index);
            landSizeMarkerListRef.current?.map((val: any, idx: number) => {
                if (idx === index) {
                    val?.setMap(null);
                }
            });
            landSizeMarkerListRef.current = landSizeMarkerListRef.current?.filter((_val: any, idx: number) => idx !== index);
            landSizeMarkerListRef.current?.map((val: any, idx: number) => {
                const numberMarkerContent = renderToStaticMarkup(<div className="customAreaCard">{idx + 1}</div>);
                val?.setIcon({ content: numberMarkerContent, size: new naver.maps.Size(22, 20) });
            });
            polygonLandListRef.current = polygonLandListRef.current.filter((val: any, idx: number) => idx !== index);
            const newTotalSize = polygonLandListRef.current?.reduce(
                (q: any, val: any) => {
                    q = {
                        pyong: q.pyong + Number(val?.size?.pyong?.replaceAll(/,|평|-/gi, "")),
                        square: q.square + Number(val?.size?.square?.replaceAll(/,|㎡|-/gi, "")),
                    };
                    return q;
                },
                { pyong: 0, square: 0 },
            );
            setTotalPolygonLandSize(newTotalSize);
            setPolygonLandList(polygonLandListRef.current);
        }
    };
    // 기본 이벤트와 합쳐서 만듬
    useEffect(() => {
        if (drawingType === 0 && currentMap?.map) {
            const currentZoom = currentMap?.map?.getZoom();
            const currentCenter = currentMap?.map?.getCenter();
            const newParam: IPolygonApiParams = {
                latitude: currentCenter.y,
                longitude: currentCenter.x,
                zoom: currentZoom,
            };
            drawingMoveFieldPolygon(newParam);
            currentMap?.map.removeListener(drawPolylineEventRef.current);
            currentMap?.map.removeListener(drawPolygonEventRef.current);
            currentMap?.map.removeListener(drawPolygonSizeEventRef.current);
            // 기본 이벤트 설정
            drawingTypeRef.current && onCloseConvenience();
            drawingTypeRef.current = 0;
            if (pinMarkerInformationRef.current.length) {
                pinMarkerRef.current?.map((pin) => pin?.setClickable(true));
            }
            stateLandPolygonList.current.map((val) => val?.setClickable(true));
            const clickMap = (e: any) => {
                let position = e.latlng;
                let currentZoom = currentMap.map.getZoom();
                setIsFieldCardShow(false);
                setIsPopulationStatusCardShow(false);
                setShowStateLandZoomInfo(false);
                setPnuFromApp("");
                setLatLngFromApp({ lat: 0, lng: 0 });
                setFromCheck("");
                setIsBackToAppShow(false);
                if (isStreetActiveRef.current) {
                    roadViewActive(position);
                    return;
                }
                if (currentZoom > 13) {
                    if (prevSelectedRef.current !== null) {
                        const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
                        const prevMarkerIndex = realMarkerListRef.current.findIndex(
                            (q: any) => q.getTitle() === prevSelectedRef.current,
                        );
                        const markerElement = renderToStaticMarkup(
                            Marker({
                                type: "real",
                                displayName: prevCluItem[0]._id,
                                count: prevCluItem[0].count,
                                item: prevCluItem[0].item,
                                real: prevCluItem[0].real,
                                markerType: "real",
                            }),
                        );
                        realMarkerListRef.current[prevMarkerIndex].setOptions({
                            position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                            map: currentMap.map,
                            clickable: true,
                            zIndex: 2,
                            icon: {
                                content: markerElement,
                                anchor: new naver.maps.Point(50, 70),
                                origin: new naver.maps.Point(50, 50),
                            },
                            visible: !isMarkerHide.real,
                            title: prevCluItem[0]._id,
                        });
                        prevSelectedRef.current = null;
                    }
                    pinMarkerRef.current?.map((pin) => pin?.setIcon(smallPinSize));
                    drawPolylineWithLandCard(position);
                } else {
                    const currentZoom = currentMap?.map?.getZoom();
                    const newParam: IPolygonApiParams = {
                        latitude: position.lat(),
                        longitude: position.lng(),
                        zoom: currentZoom,
                    };
                    drawingClickFieldPolygon(newParam);
                }
            };
            mapClickEventRef.current = addListener(currentMap.map, "click", clickMap);
            //* longTap
            mapLongTapEventRef.current = addListener(currentMap.map, "longtap", longTapMap);
        }
        if (drawingType === 1 && currentMap) {
            setCheckMyPosition(false);
            currentMap?.map.removeListener(mapClickEventRef.current);
            currentMap?.map.removeListener(mapLongTapEventRef.current);
            currentMap?.map.removeListener(drawPolygonEventRef.current);
            currentMap?.map.removeListener(drawPolygonSizeEventRef.current);
            if (pinMarkerInformationRef.current.length) {
                pinMarkerRef.current?.map((pin) => pin?.setClickable(false));
            }
            if (landSizePolygonListRef.current?.length) {
                landSizePolygonListRef.current?.map((val) => val?.setClickable(false));
            }
            if (landSizeMarkerListRef.current?.length) {
                landSizeMarkerListRef.current?.map((val) => val?.setClickable(false));
            }
            stateLandPolygonList.current.map((val) => val?.setClickable(false));
            // 거리 측정시 이벤트 설정
            drawingTypeRef.current = 1;
            drawPolylineEventRef.current = addListener(currentMap.map, "click", (evt) => {
                onClickDrawLine(evt);
            });
        } else if (drawingType === 2 && currentMap) {
            setCheckMyPosition(false);
            currentMap?.map.removeListener(drawPolylineEventRef.current);
            currentMap?.map.removeListener(mapClickEventRef.current);
            currentMap?.map.removeListener(mapLongTapEventRef.current);
            currentMap?.map.removeListener(drawPolygonSizeEventRef.current);
            if (pinMarkerInformationRef.current.length) {
                pinMarkerRef.current?.map((pin) => pin?.setClickable(false));
            }
            if (landSizePolygonListRef.current?.length) {
                landSizePolygonListRef.current?.map((val) => val?.setClickable(false));
            }
            if (landSizeMarkerListRef.current?.length) {
                landSizeMarkerListRef.current?.map((val) => val?.setClickable(false));
            }
            stateLandPolygonList.current.map((val) => val?.setClickable(false));
            // 면적 측정시 이벤트 설정

            drawingTypeRef.current = 2;
            drawPolygonEventRef.current = addListener(currentMap.map, "click", (evt) => {
                onClickDrawSize(evt);
            });
        } else if (drawingType === 3 && currentMap) {
            setCheckMyPosition(false);
            currentMap?.map.removeListener(drawPolylineEventRef.current);
            currentMap?.map.removeListener(mapClickEventRef.current);
            currentMap?.map.removeListener(mapLongTapEventRef.current);
            currentMap?.map.removeListener(drawPolygonEventRef.current);
            if (pinMarkerInformationRef.current.length) {
                pinMarkerRef.current?.map((pin) => pin?.setClickable(false));
            }
            if (landSizePolygonListRef.current?.length) {
                landSizePolygonListRef.current?.map((val) => val?.setClickable(true));
            }
            if (landSizeMarkerListRef.current?.length) {
                landSizeMarkerListRef.current?.map((val) => val?.setClickable(true));
            }
            stateLandPolygonList.current.map((val) => val?.setClickable(false));
            // 면적 측정시 이벤트 설정
            drawingTypeRef.current = 3;
            drawPolygonSizeEventRef.current = addListener(currentMap.map, "click", (evt) => {
                const currentZoom = currentMap?.map?.getZoom();
                if (currentZoom > 13) {
                    const newCoord = evt.coord;
                    onClickWithLandSizeAdd(newCoord.y, newCoord.x);
                }
            });
        }
    }, [currentMap, drawingType]);

    useEffect(() => {
        if (currentMap) {
            const position = currentMap.map.getCenter() as naver.maps.LatLng;
            once(currentMap.map, "init", () => {
                transferToNative("", "onDone");
            });
        }
    }, [currentMap]);

    //* 읍면동, 시군구, 시도 폴리곤
    const moveFieldPolygonRef = useRef<naver.maps.Polygon | null>(null);
    const clickFieldPolygonRef = useRef<naver.maps.Polygon | null>(null);

    //* 기본 읍면동, 시군구, 시도 폴리곤 옵션
    const basicPolygonOption: any = {
        fillColor: "#008ff8",
        strokeOpacity: 1,
        clickable: false,
        strokeColor: "#0047FF",
        strokeLineCap: "round",
        strokeLineJoin: "round",
    };

    //* 지도 클릭으로 폴리곤 그리기
    const drawingClickFieldPolygon = debounce(async (param: IPolygonApiParams) => {
        const clickPolygonInfo = await useGetPolygonByClick(param);
        const { zoom, latitude, longitude } = param;
        if (currentMap) {
            clickFieldPolygonRef.current?.setMap(null);
            if (clickPolygonInfo) {
                clickFieldPolygonRef.current = new naver.maps.Polygon({
                    map: currentMap.map,
                    paths: clickPolygonInfo?.geometry?.coordinates?.map((val: any) => [...val]).flat(),
                    fillOpacity: 0.3,
                    ...basicPolygonOption,
                });
                //시군구 마커 클릭 시 줌레벨에 따른 인구현황페이지
                if (zoom > 7 && zoom < 13) {
                    setIsPopulationStatusCardShow(true);
                    setPopulationStatusItem([clickPolygonInfo]);
                    setIsSaleCardShow(false);
                    setShowStateLandZoomInfo(false);
                } else {
                    setIsPopulationStatusCardShow(false);
                    setPopulationStatusItem([]);
                }
            } else {
                //* 줌레벨에 따라 내재화된 정보를 못불러올때 브이월드로 처리
                if (zoom === 13) {
                    const vworldResult = await useGetAdemdDongPolyCoord({ lat: latitude, lng: longitude });
                    clickFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0.3,
                        ...basicPolygonOption,
                    });
                } else if (zoom > 9 && zoom < 13) {
                    const vworldResult = await useGetSigunguPolyCoord({ lat: latitude, lng: longitude });
                    clickFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0.3,
                        ...basicPolygonOption,
                    });
                } else if (zoom < 10 && zoom > 6) {
                    const vworldResult = await useGetSidoPolyCoord({ lat: latitude, lng: longitude });
                    clickFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0.3,
                        ...basicPolygonOption,
                    });
                }
            }
        }
    }, 500);

    //* 지도 이동으로 폴리곤 그리기
    const drawingMoveFieldPolygon = debounce(async (param: IPolygonApiParams) => {
        const movePolygonInfo = await useGetPolygonByCenter(param);
        const { zoom, latitude, longitude } = param;
        const reg = /^(00|11|41|28|26)/;
        if (currentMap) {
            moveFieldPolygonRef.current?.setMap(null);
            if (movePolygonInfo?.geometry?.coordinates) {
                moveFieldPolygonRef.current = new naver.maps.Polygon({
                    map: currentMap.map,
                    paths: movePolygonInfo?.geometry?.coordinates?.map((val: any) => [...val]).flat(),
                    fillOpacity: 0,
                    strokeWeight: 3,
                    ...basicPolygonOption,
                });
            } else {
                //* 줌레벨에 따라 내재화된 정보를 못불러올때 브이월드로 처리
                if (zoom > 13) {
                    const vworldResult = await useGetAdemdDongPolyCoord({ lat: latitude, lng: longitude });
                    moveFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0,
                        strokeWeight: 3,
                        ...basicPolygonOption,
                    });
                } else if (zoom === 13) {
                    const vworldResult = await useGetAdemdDongPolyCoord({ lat: latitude, lng: longitude });
                    const vworldSigunguResult = await useGetSigunguPolyCoord({ lat: latitude, lng: longitude });
                    moveFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: reg.test(vworldResult?.code)
                            ? vworldResult?.geometryCoordinates
                            : vworldSigunguResult?.geometryCoordinates,
                        fillOpacity: 0,
                        strokeWeight: 3,
                        ...basicPolygonOption,
                    });
                } else if (zoom > 10) {
                    const vworldResult = await useGetSigunguPolyCoord({ lat: latitude, lng: longitude });
                    moveFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0,
                        strokeWeight: 3,
                        ...basicPolygonOption,
                    });
                } else if (zoom === 10) {
                    const vworldResult = await useGetSigunguPolyCoord({ lat: latitude, lng: longitude });
                    const vworldSidoResult = await useGetSidoPolyCoord({ lat: latitude, lng: longitude });
                    moveFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: reg.test(vworldResult?.code)
                            ? vworldResult?.geometryCoordinates
                            : vworldSidoResult?.geometryCoordinates,
                        fillOpacity: 0,
                        strokeWeight: 3,
                        ...basicPolygonOption,
                    });
                } else if (zoom < 10 && zoom > 6) {
                    const vworldResult = await useGetSidoPolyCoord({ lat: latitude, lng: longitude });
                    moveFieldPolygonRef.current = new naver.maps.Polygon({
                        map: currentMap.map,
                        paths: vworldResult?.geometryCoordinates,
                        fillOpacity: 0,
                        strokeWeight: 3,
                        ...basicPolygonOption,
                    });
                }
            }
        }
    }, 500);

    //* 토지, 건물 폴리곤
    const buildingPolygonRef = useRef<naver.maps.Polygon | null>(null);
    const landPolygonRef = useRef<naver.maps.Polygon | null>(null);
    const fieldDetailDrawPolyline = (buildingGeometryCoordinates: any[], landGeometryCoordinates: any[]) => {
        if (currentMap) {
            buildingPolygonRef.current?.setMap(null);
            landPolygonRef.current?.setMap(null);
            buildingPolygonRef.current = new naver.maps.Polygon({
                map: currentMap.map,
                paths: buildingGeometryCoordinates,
                fillColor: "#008FF8",
                fillOpacity: 0.4,
                strokeOpacity: 1,
                clickable: false,
                strokeColor: "#008FF8",
                strokeWeight: 1,
                strokeLineCap: "square",
            });
            landPolygonRef.current = new naver.maps.Polygon({
                map: currentMap.map,
                paths: landGeometryCoordinates,
                fillColor: "#008FF8",
                fillOpacity: 0.4,
                clickable: false,
                strokeStyle: "dash",
                strokeColor: "#5F0080",
                strokeWeight: 1,
                strokeLineCap: "square",
            });
        }
    };
    const hidePolyline = () => {
        buildingPolygonRef.current?.setMap(null);
        landPolygonRef.current?.setMap(null);
    };
    const [fieldPNU, setFieldPNU] = useState<string>("");
    const [fieldLocation, setFieldLocation] = useState<naver.maps.LatLng>(initLocation);
    const [fieldAddress, setFieldAddress] = useState<string>("");
    const [fieldJimok, setFieldJimok] = useState<string>("");
    const [fieldRoadAddress, setFieldRoadAddress] = useState<string>("");

    //* 선택필지에 대한 마커
    const selectedFieldMarkerRef = useRef<naver.maps.Marker | null>(null);

    //필지카드와 폴리라인 노출
    const drawPolylineWithLandCard = async (latlng: naver.maps.LatLng, type?: boolean, givedPnu?: string) => {
        setIsSaleCardShow(false);
        setIsFieldCardShow(false);
        setIsPopulationStatusCardShow(false);
        setShowStateLandZoomInfo(false);
        currentFisrtItemIdRef.current = null;
        let position = latlng;
        let lat = position.lat();
        let lng = position.lng();
        let params = { lat, lng };
        panoMarkerRef.current && panoMarkerRef.current.setPosition(position);
        //* 내재화된 정보 호출
        const fieldInfoResponse =
            lat && (await useGetMapFieldInformation({ latitude: lat, longitude: lng, pnu: givedPnu ?? "" }));

        //* 선택필지 마커
        if (pinMarkerInformationRef.current?.every((pin) => pin.pnu !== fieldInfoResponse?.pnu)) {
            selectedFieldMarkerRef.current?.setMap(null);
            selectedFieldMarkerRef.current = new naver.maps.Marker({
                position: new naver.maps.LatLng(lat, lng),
                map: undefined,
                clickable: true,
                zIndex: 4,
                icon: {
                    url: selectedFieldIcon,
                },
                title: "selectedLocation",
            });
            if (currentMap) {
                const currentZoom = currentMap?.map.getZoom();
                currentZoom < 16
                    ? selectedFieldMarkerRef.current?.setMap(currentMap.map)
                    : selectedFieldMarkerRef.current?.setMap(null);
            }
        }
        //2. v-world 토지 정보 호출
        let vworldLandResponse: any;
        if (!fieldInfoResponse) {
            vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
        }
        let buildingGeometryCoordinates: any;
        let landPolygon: any = fieldInfoResponse?.land?.geometry?.coordinates?.[0];
        if (!fieldInfoResponse?.buildings?.length && !landTypes.some((val) => val === fieldInfoResponse?.land.jimok)) {
            try {
                vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
                const { geometryCoordinates: vworldLandPolygon, bbox } = vworldLandResponse;
                const vworldBuildingResponse = await useGetMapPolyCoord(
                    {
                        position: params,
                        bbox,
                        pnu: fieldInfoResponse?.pnu,
                    },
                    true,
                );
                const { geometryCoordinates } = vworldBuildingResponse;
                buildingGeometryCoordinates = geometryCoordinates;
                landPolygon = fieldInfoResponse?.land?.geometry?.coordinates?.[0] ?? vworldLandPolygon;
            } catch (error) {
                console.log(error);
            }
        } else {
            const newBuildingPolygon = fieldInfoResponse?.buildings?.map((data: any) => data?.geometry?.coordinates?.[0]?.[0]);
            buildingGeometryCoordinates = newBuildingPolygon;
        }
        if (!vworldLandResponse?.pnu && !pnuFromApp && !fieldInfoResponse?.pnu) throw new Error("pnu 없음");

        const newPnu = fieldInfoResponse?.pnu ? fieldInfoResponse?.pnu : givedPnu ? givedPnu : pnuFromApp.toString();

        setFieldAddress(fieldInfoResponse?.land?.address ?? vworldLandResponse?.address);
        !latLngFromApp?.lat && setSearchTextFromApp(fieldInfoResponse?.land?.address ?? vworldLandResponse?.address);
        setFieldPNU(newPnu);
        setFieldLocation(position);

        const isBuilding = buildingGeometryCoordinates?.length !== 0;
        setFieldType(isBuilding ? "building" : "land");
        setFieldJimok(fieldInfoResponse?.land?.jimok ?? "");
        setIsFieldCardShow(true);
        fieldDetailDrawPolyline(buildingGeometryCoordinates, landPolygon);
        !type && pinMarkerRef.current?.map((pin) => pin?.setIcon(smallPinSize));
    };

    const drawPolylineWithoutLandCard = async (latlng: naver.maps.LatLng, pnu?: string) => {
        setIsSaleCardShow(false);
        setIsFieldCardShow(false);
        setIsPopulationStatusCardShow(false);
        setShowStateLandZoomInfo(false);
        let position = latlng;
        let lat = position.lat();
        let lng = position.lng();
        let params = { lat, lng };
        panoMarkerRef.current && panoMarkerRef.current.setPosition(position);
        selectedFieldMarkerRef.current?.setMap(null);
        selectedFieldMarkerRef.current = new naver.maps.Marker({
            position: new naver.maps.LatLng(lat, lng),
            map: undefined,
            clickable: true,
            zIndex: 4,
            icon: {
                url: selectedFieldIcon,
            },
            title: "selectedLocation",
        });
        if (currentMap) {
            const currentZoom = currentMap?.map.getZoom();
            currentZoom < 16
                ? selectedFieldMarkerRef.current?.setMap(currentMap.map)
                : selectedFieldMarkerRef.current?.setMap(null);
        }

        //* 내재화된 정보 호출
        const fieldInfoResponse = lat && (await useGetMapFieldInformation({ latitude: lat, longitude: lng, pnu: pnu }));
        //2. v-world 토지 정보 호출
        let vworldLandResponse: any;
        if (!fieldInfoResponse) {
            vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
        }
        let buildingGeometryCoordinates: any;
        let landPolygon: any = fieldInfoResponse?.land?.geometry?.coordinates?.[0];
        if (!fieldInfoResponse?.buildings?.length && !landTypes.some((val) => val === fieldInfoResponse?.land.jimok)) {
            try {
                const vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
                const { geometryCoordinates: vworldLandPolygon, bbox } = vworldLandResponse;
                const vworldBuildingResponse = await useGetMapPolyCoord(
                    {
                        position: params,
                        bbox,
                        pnu: fieldInfoResponse?.pnu,
                    },
                    true,
                );
                const { geometryCoordinates } = vworldBuildingResponse;
                buildingGeometryCoordinates = geometryCoordinates;
            } catch (error) {
                console.log(error);
            }
        } else {
            const newBuildingPolygon = fieldInfoResponse?.buildings?.map((data: any) => data?.geometry?.coordinates?.[0]?.[0]);
            buildingGeometryCoordinates = newBuildingPolygon;
        }
        if (!vworldLandResponse?.pnu && !pnuFromApp && !fieldInfoResponse?.pnu) throw new Error("pnu 없음");

        !latLngFromApp?.lat && setSearchTextFromApp(fieldInfoResponse?.land?.address ?? vworldLandResponse?.address);
        fieldDetailDrawPolyline(buildingGeometryCoordinates, landPolygon);
        pinMarkerRef.current?.map((pin) => pin?.setIcon(smallPinSize));
    };

    const getFieldInfoWithoutLandCard = async (latlng: naver.maps.LatLng) => {
        setIsSaleCardShow(false);
        setIsPopulationStatusCardShow(false);
        setShowStateLandZoomInfo(false);
        currentFisrtItemIdRef.current = null;
        const position = latlng;
        const lat = position.lat();
        const lng = position.lng();
        const params = { lat, lng };
        //* 내재화된 정보 호출
        const fieldInfoResponse = lat && (await useGetMapFieldInformation({ latitude: lat, longitude: lng }));
        let vworldLandResponse: any;
        if (!fieldInfoResponse) {
            vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
        }
        let buildingGeometryCoordinates: any;
        let landPolygon: any = fieldInfoResponse?.land?.geometry?.coordinates?.[0];
        if (!fieldInfoResponse?.buildings?.length) {
            try {
                const vworldLandResponse = await useGetMapPolyCoord({ position: params }, false);
                const { geometryCoordinates: vworldLandPolygon, bbox } = vworldLandResponse;
                const vworldBuildingResponse = await useGetMapPolyCoord(
                    {
                        position: params,
                        bbox,
                        pnu: fieldInfoResponse?.pnu,
                    },
                    true,
                );
                const { geometryCoordinates } = vworldBuildingResponse;
                buildingGeometryCoordinates = geometryCoordinates;
                landPolygon = fieldInfoResponse?.land?.geometry?.coordinates?.[0] ?? vworldLandPolygon;
            } catch (error) {
                console.log(error);
            }
        } else {
            const newBuildingPolygon = fieldInfoResponse?.buildings?.map((data: any) => data?.geometry?.coordinates?.[0]?.[0]);
            buildingGeometryCoordinates = newBuildingPolygon;
        }
        if (!vworldLandResponse?.pnu && !pnuFromApp && !fieldInfoResponse?.pnu) throw new Error("pnu 없음");

        const newPnu = fieldInfoResponse?.pnu
            ? fieldInfoResponse?.pnu
            : vworldLandResponse?.pnu
            ? vworldLandResponse?.pnu
            : pnuFromApp.toString();
        const newAddress = fieldInfoResponse?.land?.address ?? vworldLandResponse?.address;
        const res = await Promise.all([
            useGetFieldContents({ address: newAddress, pnu: newPnu }),
            useGetRoadAddressFromCoords(latlng),
        ]);
        const { land, building, totalBuildingTable } = res[0];
        const roadAddr = res[1];
        return { pnu: newPnu, address: newAddress, roadAddr: roadAddr, buildingName: building?.buildingNm, latlng };
    };

    const drawLandCardwithoutPolygon = async (latlng: naver.maps.LatLng) => {
        // 처리되지 않는 이전 api 호출 취소하고 새 api 호출 실행
        onApiCancelHandler();

        //0. 매물카드 삭제
        // transferToNative("", "hideItemCard");
        //1. 클릭한 필지 위경도 구하기
        let position = latlng;
        let lat = position.lat();
        let lng = position.lng();

        const addrParams = { _lat: lat, _lng: lng };

        const res = await Promise.all([useGetAddressFromCoords(addrParams), useGetRoadAddressFromCoords(addrParams)]);
        const { si, gu, dong, etc, jibun, address, isMountain } = res[0];
        const roadAddr = res[1];
        setFieldAddress(address);
        const isLand = roadAddr === "일치하는 도로명 주소가 없습니다";
        const { pnu } = await useGetPNU({ si, gu, dong, jibun, etc, isMountain });
        setFieldPNU(pnu);
        setFieldRoadAddress(roadAddr);
        setFieldLocation(position);
        setFieldType(isLand ? "land" : "building");
        setIsFieldCardShow(true);

        panoMarkerRef.current && panoMarkerRef.current.setPosition(position);
    };

    const mapLayerHandler = {
        activeRoadMap: (map: naver.maps.Map) => {
            //거리뷰
            if (streetLayer.current) {
                if (isStreetActive) {
                    streetLayer.current.setMap(map);
                } else {
                    streetLayer.current.setMap(null);
                    setIsPanoViewActive(false);
                }
            }
            setIsStreetActive(!isStreetActive);
        },
        activeCadastralMap: (map: naver.maps.Map) => {
            //지적도
            if (cadastralLayer.current) {
                if (isCadastralShow) {
                    cadastralLayer.current.setMap(map);
                } else {
                    cadastralLayer.current.setMap(null);
                }
            }
            setIsCadastralShow(!isCadastralShow);
        },
        activeSatelliteMap: (map: naver.maps.Map, mapType: "HYBRID" | "NORMAL") => {
            //위성도
            if (mapType !== "NORMAL") {
                map.setMapTypeId(naverMaps.MapTypeId.HYBRID);
            } else {
                map.setMapTypeId(naverMaps.MapTypeId.NORMAL);
            }
        },
    };

    //거리뷰 화면 활성화
    const roadViewActive = async (position: naver.maps.LatLng | naver.maps.Coord, panoId?: string) => {
        setIsRoadMapInPanoView(true);
        const roadMap = document.getElementById("roadMapForPano");
        const roadPano = document.getElementById("roadPano");
        let addr = await useGetAddressFromCoords(position);
        const { si, gu, dong, etc, jibun } = addr;
        setPanoAddress(`${si} ${gu} ${dong} ${etc} ${jibun}`);
        if (roadPano && roadMap) {
            roadMapInPanoView.current = new naver.maps.Map(roadMap, {
                center: position,
                ...mapOptions,
            });
            const panoStreet = new naver.maps.StreetLayer();
            const flightSpot = new naver.maps.FlightSpot();
            panoStreet?.setMap(roadMapInPanoView.current);
            flightSpot?.setMap(roadMapInPanoView.current);
            if (!panoViewRef.current) {
                panoViewRef.current = new naver.maps.Panorama("roadPano", {
                    position,
                    pov: {
                        pan: -135,
                        tilt: -20,
                        fov: 100,
                    },
                    logoControl: false,
                    flightSpot: true,
                    aroundControl: true,
                    aroundControlOptions: {
                        position: 7,
                    },
                    panoId: panoId,
                });
                roadMarkerInPanoView.current = new naverMaps.Marker({
                    position,
                    map: roadMapInPanoView.current,
                    clickable: true,
                    visible: true,
                    title: "useRoadMarker",
                    icon: {
                        url: PanoramaIcon,
                        anchor: new naverMaps.Point(27, 31),
                    },
                });
                once(panoViewRef.current, "init", () => {
                    const streetImg: any = document.querySelector(".street");
                    const airImg: any = document.querySelector(".air");
                    if (airImg) {
                        airImg.setAttribute("src", "https://image.missgoauction.com/image/pcImage/flightViewChangeIcon.svg");
                        airImg.setAttribute("width", "44");
                        airImg.setAttribute("height", "44");
                        airImg.style.setProperty("background", "#ffffff");
                        airImg.style.setProperty("border-radius", "8px");
                        airImg.parentElement?.style.setProperty("width", "44px");
                        airImg.parentElement?.style.setProperty("height", "44px");
                        airImg.parentElement?.style.setProperty("margin", "0");
                        airImg.parentElement?.style.setProperty("border", "none");
                        airImg.addEventListener("touchstart", () => {
                            airImg.setAttribute(
                                "src",
                                "https://image.missgoauction.com/image/pcImage/activeFlightViewChangeIcon.svg",
                            );
                        });
                        airImg.addEventListener("touchend", () => {
                            airImg.setAttribute("src", "https://image.missgoauction.com/image/pcImage/flightViewChangeIcon.svg");
                        });
                        airImg.parentElement?.parentElement?.parentElement?.style.setProperty("top", "28px");
                        airImg.parentElement?.parentElement?.parentElement?.style.setProperty("right", "13px");
                    }
                    if (streetImg) {
                        streetImg.setAttribute("src", "https://image.missgoauction.com/image/pcImage/streetViewChangeIcon.svg");
                        streetImg.addEventListener("touchstart", () => {
                            streetImg.setAttribute(
                                "src",
                                "https://image.missgoauction.com/image/pcImage/activeStreetViewChangeIcon.svg",
                            );
                        });
                        streetImg.addEventListener("touchend", () => {
                            streetImg.setAttribute(
                                "src",
                                "https://image.missgoauction.com/image/pcImage/streetViewChangeIcon.svg",
                            );
                        });
                        streetImg.setAttribute("width", "44");
                        streetImg.setAttribute("height", "44");
                        streetImg.style.setProperty("background", "#ffffff");
                        streetImg.style.setProperty("border-radius", "8px");
                    }
                });
                once(panoViewRef.current, "pano_status", (status: "OK" | "ERROR") => {
                    if (status === "OK") {
                        setIsPanoViewActive(true);
                        const newPov = panoViewRef.current.getPov().pan % 360;
                        const drawingPov = Math.round(newPov / 22.5);
                        const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
                        if (roadImg)
                            roadImg.style.top = `${
                                -600 + 75 * drawingPov <= -1200
                                    ? 75 * drawingPov + 600
                                    : 75 * drawingPov - 600 > 0
                                    ? 75 * drawingPov - 1800
                                    : 75 * drawingPov - 600
                            }px`;
                    } else if (status === "ERROR") {
                        transferToNative("해당 위치에 대한 거리뷰가 없습니다.", "alertMessage");
                    }
                });
                addListener(panoViewRef.current, "pano_status", (status: "OK" | "ERROR") => {
                    if (status === "OK") {
                        const changePosition = panoViewRef.current?.getPosition();
                        roadMapInPanoView.current?.panTo(changePosition);
                        roadMarkerInPanoView.current?.setPosition(changePosition);
                    } else if (status === "ERROR") {
                        transferToNative("해당 위치에 대한 거리뷰가 없습니다.", "alertMessage");
                    }
                });
                addListener(panoViewRef.current, "init", function () {
                    panoViewRef.current.setVisible(true);
                });
                addListener(panoViewRef.current, "pov_changed", function () {
                    const newPov = panoViewRef.current.getPov().pan % 360;
                    const drawingPov = Math.round(newPov / 22.5);
                    const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
                    if (roadImg)
                        roadImg.style.top = `${
                            -600 + 75 * drawingPov <= -1200
                                ? 75 * drawingPov + 600
                                : 75 * drawingPov - 600 > 0
                                ? 75 * drawingPov - 1800
                                : 75 * drawingPov - 600
                        }px`;
                });
            } else {
                panoViewRef.current.setPov({
                    pan: -135,
                    tilt: -20,
                    fov: 100,
                });
                panoViewRef.current.setZoom(0);
                panoViewRef.current.setPosition(position);
                panoViewRef.current.setVisible(true);
                const newPov = panoViewRef.current.getPov().pan % 360;
                const drawingPov = Math.round(newPov / 22.5);
                const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
                if (roadImg)
                    roadImg.style.top = `${
                        -600 + 75 * drawingPov <= -1200
                            ? 75 * drawingPov + 600
                            : 75 * drawingPov - 600 > 0
                            ? 75 * drawingPov - 1800
                            : 75 * drawingPov - 600
                    }px`;
            }
            addListener(roadMapInPanoView.current, "click", (e) => {
                let position = e.latlng;
                roadMarkerInPanoView.current?.setPosition(position);
                panoViewRef.current?.setPosition(position);
            });
            naver.maps.Event.addListener(flightSpot, "poi_clicked", (panoId) => {
                panoViewRef.current.setPanoId(panoId);
            });
            // if (panoId) {
            //     panoViewRef.current.setPanoId(panoId);
            // }
        }
    };

    //필지카드, 매물카드 상단 로드뷰 버튼 클릭시
    const handleClickRoadViewButton = (position: naver.maps.LatLng) => {
        roadViewActive(position);
    };

    //필지 카드 숨기기
    const hideLandCard = () => {
        setIsFieldCardShow(false);
        setItems([]);
    };

    const hideFieldCard = () => {
        setIsFieldCardShow(false);
        setItems([]);
        if (prevSelectedRef.current !== null) {
            const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
            const prevMarkerIndex = realMarkerListRef.current.findIndex((q: any) => q.getTitle() === prevSelectedRef.current);
            if (prevCluItem.length) {
                const markerElement = renderToStaticMarkup(
                    Marker({
                        type: "real",
                        displayName: prevCluItem[0]._id,
                        count: prevCluItem[0].count,
                        item: prevCluItem[0].item,
                        real: prevCluItem[0].real,
                        markerType: "real",
                    }),
                );
                realMarkerListRef.current[prevMarkerIndex].setMap(null);
                realMarkerListRef.current[prevMarkerIndex].setOptions({
                    position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                    map: currentMap?.map,
                    clickable: true,
                    zIndex: 3,
                    icon: {
                        content: markerElement,
                        anchor: new naver.maps.Point(26, 55),
                        origin: new naver.maps.Point(50, 50),
                    },
                    visible: !isMarkerHide.real,
                    title: prevCluItem[0]._id,
                });
                realMarkerListRef.current[prevMarkerIndex]?.setMap(currentMap?.map);
                prevSelectedRef.current = null;
            }
        }
    };

    const [fieldType, setFieldType] = useState<"land" | "building">("land");
    const widthRef = useRef() as any;

    //* 머커 숨김관련
    const [isMarkerHide, setIsMarkerHide] = useState<{
        product: boolean;
        real: boolean;
        expected: boolean;
    }>({
        product: false,
        real: false,
        expected: false,
    });
    const isMarkerHideRef = useRef<{
        product: boolean;
        real: boolean;
        expected: boolean;
    }>({ product: false, real: false, expected: false });
    const cadastralRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (currentMap) {
            isMarkerHideRef.current = isMarkerHide;
            if (isMarkerHide.product) {
                fetchData({
                    ...mapApiParams,
                    hidden: {
                        hideExpected: isMarkerHide.expected,
                        hideBid: isMarkerHide.product,
                        hideReal: isMarkerHide.real,
                    },
                });
                renderMarkerList?.map((marker: naver.maps.Marker) => marker?.setMap(null));
            } else {
                fetchData({
                    ...mapApiParams,
                    hidden: { hideExpected: isMarkerHide.expected, hideBid: isMarkerHide.product, hideReal: isMarkerHide.real },
                });
            }
        }
    }, [isMarkerHide.product]);

    useEffect(() => {
        if (currentMap) {
            isMarkerHideRef.current = isMarkerHide;
            if (isMarkerHide.expected) {
                fetchData({
                    ...mapApiParams,
                    hidden: {
                        hideExpected: isMarkerHide.expected,
                        hideBid: isMarkerHide.product,
                        hideReal: isMarkerHide.real,
                    },
                });
                renderExpectedMarkerList?.map((marker: naver.maps.Marker) => marker?.setMap(null));
            } else {
                fetchData({
                    ...mapApiParams,
                    hidden: { hideExpected: isMarkerHide.expected, hideBid: isMarkerHide.product, hideReal: isMarkerHide.real },
                });
            }
        }
    }, [isMarkerHide.expected]);

    useEffect(() => {
        if (currentMap) {
            isMarkerHideRef.current = isMarkerHide;
            if (isMarkerHide.real) {
                renderRealMarkerList?.map((marker: naver.maps.Marker) => marker?.setMap(null));
                renderRectMarkerList?.map((marker: naver.maps.Marker) => marker?.setMap(null));
            } else {
                fetchData({
                    ...mapApiParams,
                    hidden: { hideExpected: isMarkerHide.expected, hideBid: isMarkerHide.product, hideReal: isMarkerHide.real },
                });
            }
        }
    }, [isMarkerHide.real]);

    const handleMarkerHideBtnClick = (type: "product" | "real" | "expected", isActive: boolean) => {
        setIsMarkerHide((prev) => {
            return {
                ...prev,
                [type]: isActive,
            };
        });
    };

    //* 국공유지 필터 관련
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);
    const startStateLand = useOpenStateLandFilterStore((state) => state.startStateLand);
    const setStartStateLand = useOpenStateLandFilterStore((state) => state.setStartStateLand);
    const [openStateLandFilter, setOpenStateLandFilter] = useState<boolean>(false);
    const [checkStateLandBanner, setCheckStateLandBanner] = useState<boolean>(false);

    const startStateLandRef = useRef<boolean>(false);
    const stateLandPolygonList = useRef<any[]>([]);
    const dimStateLandPolygonList = useRef<any>();
    const [stateLandCount, setStateLandCount] = useState<number>();
    const SLIDER_WIDTH = screen.width;

    //* 국공유지 로딩화면 및 줌레벨에 따른 플로팅카드 관련
    const [showStateLandZoomInfo, setShowStateLandZoomInfo] = useState<boolean>(false);
    const [stateLandLoadingOpen, setStateLandLoadingOpen] = useState<boolean>(false);

    const [statusStateLandSubs, setStatusStateLandSubs] = useState<number>();
    //* 1:전국 모든 공유지, 100미터 확대해야함 / 2:지역 구매, 100미터 줌레벨 초과/ 3:줌레벨 ok, 비구매 지역시 /4:줌레벨 30키로 초과/5:줌레벨 ok, 구매지역

    //*국공유지 필터 인벤토리 불러오기
    const { data: userStateLandFilter, refetch: refetchUserStateLandFilter } = useFindOneStateLandFilter();

    //*국공유지 필터 인벤토리 저장
    const saveStateLandFilter = useSaverStateLandFilter();

    //*국공유지 폴리곤 정보
    const {
        data: stateLand,
        refetch: refetchStateLand,
        isFetching,
        remove,
    } = useFindAllStateLandV2({
        bounds: currentMap?.map?.getBounds(),
        filter: { ...stateLandFilter },
    });

    //*국공유지 구매지역 외 딤 처리 폴리곤
    const {
        data: stateLandDimData,
        refetch: refetchDimStateLand,
        isFetching: stateLandDimIsFetching,
        remove: stateLandDimRemove,
    } = useFindDimPolygon({
        bounds: currentMap?.map?.getBounds(),
    });

    //*회원의 국공유지상품 정보
    const { data: stateLandSubs, refetch: refetchStateLandSubs } = useFindOneStateLandSubs();
    const stateLandSubsRef = useRef<any>();

    //*앱에서 국공유지 활성화 후 데이터맵 이동시 국공유지 버튼 활성화
    useEffect(() => {
        if (searchParams.get("stateLand")) {
            setTimeout(() => {
                setStartStateLand(true);
                setCheckStateLandBanner(true);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (openStateLandFilter) {
            refetchStateLandSubs().then((res) => {
                if (res.isFetched) {
                    stateLandSubsRef.current = res?.data;
                }
            });
        }
    }, [openStateLandFilter]);

    //* 국공유지 지역별 구매시 딤처리 지역 리패치
    useEffect(() => {
        if (startStateLand) {
            setIsMarkerHide({
                product: true,
                real: true,
                expected: true,
            });
        } else if (drawingType === 0) {
            setIsMarkerHide({
                product: false,
                real: false,
                expected: false,
            });
        }
        if (currentMap && currentMap.map?.getZoom() < 16) {
            delDimList();
        }
    }, [startStateLand]);

    //*줌 안내메세지
    useEffect(() => {
        if (!stateLand && startStateLand && currentMap && currentMap.map?.getZoom() < 16) {
            setShowStateLandZoomInfo(true);
        } else {
            setShowStateLandZoomInfo(false);
        }
    }, [stateLand, startStateLand]);

    //* 줌 안내메세지 케이스
    useEffect(() => {
        if (currentMap && startStateLand) {
            if (stateLandSubs && !stateLandSubs?.allPnus && currentMap.map?.getZoom() < 16) {
                setStatusStateLandSubs(1);
            } else if (stateLandSubs?.allPnus && currentMap.map?.getZoom() > 9 && currentMap.map?.getZoom() < 16) {
                setStatusStateLandSubs(2); //지역 구매, 100미터 줌레벨 초과
            } else if (stateLandDimData && stateLandDimData?.unpurchasedRegions && currentMap.map?.getZoom() >= 16) {
                setStatusStateLandSubs(3); // 줌레벨 ok, 비구매 지역시
                setShowStateLandZoomInfo(true);
                if (openStateLandFilter) {
                    setShowStateLandZoomInfo(false);
                }
            } else if (stateLandSubs?.allPnus && currentMap.map?.getZoom() < 9) {
                setStatusStateLandSubs(4); //줌레벨 30키로 초과
            } else if (stateLandDimData && !stateLandDimData?.unpurchasedRegions && currentMap.map?.getZoom() >= 16) {
                setStatusStateLandSubs(5); //줌레벨 ok, 구매지역
                setShowStateLandZoomInfo(false);
            }
        }
    }, [startStateLand, stateLandDimData, markerContents, stateLand, openStateLandFilter]);

    const handleClickedStateLand = () => {
        transferToNative("msgaapp://deeplink/product/state_land", "onClickBottomButton");
    };

    const infoStateLandContent = () => {
        switch (statusStateLandSubs) {
            case 1:
                return (
                    <div css={stateLandStyle}>
                        <p className="contentText">전국 모든 국•공유지를 조회할 수 있어요</p>
                        <div>
                            <p className="yellowLine">지도를 100m까지 확대해 주세요</p>
                        </div>
                        <p className="infoText">※ m(미터)는 지도 좌측 하단에서 확인할 수 있습니다</p>
                    </div>
                );
            case 2:
                return (
                    <div css={stateLandStyle}>
                        <p className="contentText">지도를 100m까지 확대해 주세요</p>
                        <div>
                            <p className="yellowLine">조회 가능 지역: {stateLandSubs?.allPnusToString}</p>
                        </div>
                        <p className="infoText">※ m(미터)는 지도 좌측 하단에서 확인할 수 있습니다</p>
                    </div>
                );
            case 3:
                return (
                    <div css={stateLandStyle}>
                        <p className="contentText">구매한 지역만 조회됩니다</p>
                        <div>
                            <p className="yellowLine">조회 가능 지역: {stateLandSubs?.allPnusToString}</p>
                        </div>
                        <button
                            onClick={() => {
                                handleClickedStateLand();
                            }}
                            className="addButton"
                        >
                            조회 지역 추가
                        </button>
                    </div>
                );
            case 4:
                return (
                    <div css={stateLandStyle}>
                        <p className="contentText">
                            구매한 지역만 조회됩니다
                            <br />
                            지도를 100m까지 확대해 주세요
                        </p>
                        <div>
                            <p className="yellowLine">조회 가능 지역: {stateLandSubs?.allPnusToString}</p>
                        </div>
                        <button
                            onClick={() => {
                                handleClickedStateLand();
                            }}
                            className="addButton"
                        >
                            조회 지역 추가
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(
        debounce(() => {
            if (startStateLand && !openStateLandFilter) {
                if (JSON.stringify(userStateLandFilter) !== JSON.stringify(stateLandFilter)) {
                    saveStateLandFilter.mutate(JSON.stringify(stateLandFilter));
                }
                if (currentMap && currentMap?.map?.getZoom() >= 16) {
                    if (stateLandSubsRef.current && stateLandSubsRef.current?.allPnus) {
                        refetchDimStateLand().then((res) => {
                            if (res.isFetched) {
                                !res.data?.unpurchasedRegions && refetchStateLand();
                            }
                        });
                    } else {
                        refetchStateLand();
                    }
                }
            } else if (startStateLand && !stateLand && openStateLandFilter) {
                setShowStateLandZoomInfo(false);
            }
        }, 300),
        [openStateLandFilter, stateLandFilter, checkStateLandBanner],
    );

    useEffect(() => {
        startStateLandRef.current = startStateLand;
        if (currentMap) {
            if (startStateLand && currentMap.map?.getZoom() >= 16) {
                setShowStateLandZoomInfo(false);
                refetchUserStateLandFilter().then((res) => {
                    if (JSON.stringify(res.data) !== JSON.stringify(stateLandFilter)) {
                        setStateLandFilter(res.data);
                    } else {
                        refetchStateLand();
                    }
                });
            } else if (!startStateLand) {
                setShowStateLandZoomInfo(false);
                stateLandPolygonList.current?.map((val) => {
                    naver.maps.Event.clearListeners(val, "renderMode_changed");
                    naver.maps.Event.clearListeners(val, "click");
                    val.map = null;
                    val?._shape?.element?.remove();
                    val?.onRemove();
                });
                stateLandPolygonList.current = [];
                remove();
            }
        }
    }, [startStateLand]);

    //* 필터적용 중 로딩화면
    useEffect(() => {
        if (isFetching && startStateLand) {
            setStateLandLoadingOpen(true);
        }
    }, [isFetching]);

    useEffect(() => {
        if (stateLand && currentMap && !isFetching) {
            //지도내 국유지 겹치는 폴리라인 외 데이터
            const remove = stateLandPolygonList.current.map((val: any) => {
                if (!val.getVisible() || !val?.getBounds().intersects(currentMap.map.getBounds())) {
                    naver.maps.Event.clearListeners(val, "renderMode_changed");
                    naver.maps.Event.clearListeners(val, "click");
                    val.map = null;
                    val?._shape?.element?.remove();
                    val?.onRemove();
                }
            });
            const rest = stateLandPolygonList.current.filter(
                (val: any) => val.getVisible() && val?.getBounds().intersects(currentMap.map.getBounds()),
            );
            const newLand = rest.length ? stateLand?.filter((val: any) => rest.every((v: any) => v?.id !== val.pnu)) : stateLand;
            const highlightTest = /^(대부\/매각대상|매각대상|매각\(입찰\)가능재산|대부\(입찰\)가능재산|대부대상)/;
            const res = newLand?.map((land: any) => {
                const polygon: any = new naver.maps.Polygon({
                    map: currentMap.map,
                    paths: land?.geometry?.coordinates?.map((val: any) => [...val]).flat(),
                    fillColor: highlightTest.test(land.status) ? "#FFF500" : land.jimok === "도로" ? "#09AA4B" : "#FF6933",
                    fillOpacity: highlightTest.test(land.status) ? 0.5 : 0.6,
                    strokeOpacity: 1,
                    clickable: true,
                    strokeColor: highlightTest.test(land.status) ? "#FFF500" : land.jimok === "도로" ? "#09AA4B" : "#FF6933",
                    strokeWeight: 1,
                    strokeLineCap: "square",
                    id: land.pnu,
                    pnu: land.pnu,
                    jimok: land.jimok,
                    ownedBy: land.ownedBy,
                    status: land.status,
                } as any);
                //*국유지 클릭이벤트
                naver.maps.Event.addListener(polygon, "click", (evt) => {
                    const position = evt.coord;
                    const currentZoom = currentMap.map.getZoom();
                    setIsFieldCardShow(false);
                    setIsPopulationStatusCardShow(false);
                    setIsBackToAppShow(false);
                    setFromCheck("");
                    if (isStreetActiveRef.current) {
                        roadViewActive(position);
                        return;
                    }
                    if (currentZoom > 13) {
                        if (prevSelectedRef.current !== null) {
                            const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
                            const prevMarkerIndex = realMarkerListRef.current.findIndex(
                                (q: any) => q.getTitle() === prevSelectedRef.current,
                            );
                            const markerElement = renderToStaticMarkup(
                                Marker({
                                    type: "real",
                                    displayName: prevCluItem[0]._id,
                                    count: prevCluItem[0].count,
                                    item: prevCluItem[0].item,
                                    real: prevCluItem[0].real,
                                    markerType: "real",
                                }),
                            );
                            realMarkerListRef.current[prevMarkerIndex].setOptions({
                                position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                                map: currentMap.map,
                                clickable: true,
                                zIndex: 2,
                                icon: {
                                    content: markerElement,
                                    anchor: new naver.maps.Point(50, 70),
                                    origin: new naver.maps.Point(50, 50),
                                },
                                visible: !isMarkerHide.real,
                                title: prevCluItem[0]._id,
                            });
                            prevSelectedRef.current = null;
                        }
                        pinMarkerRef.current?.map((pin) => pin?.setIcon(smallPinSize));
                        drawPolylineWithLandCard(position, true, polygon.id);
                    } else {
                        const currentZoom = currentMap?.map?.getZoom();
                        const newParam: IPolygonApiParams = {
                            latitude: position.lat(),
                            longitude: position.lng(),
                            zoom: currentZoom,
                        };
                        drawingClickFieldPolygon(newParam);
                    }
                });
                polygon.__proto__.onRemove();
                return polygon;
            });
            //*국유지 표시 항목 합치기
            stateLandPolygonList.current = rest.concat(res);
            setStateLandCount(stateLandPolygonList.current.length);

            //* 국유지 겹치는 폴리곤 지우기
            if (stateLandPolygonList.current.length && currentMap) {
                stateLandPolygonList.current?.map((val) => {
                    if (!val?.getBounds().intersects(currentMap.map.getBounds()) || !stateLand?.some((v) => v.pnu === val?.id)) {
                        naver.maps.Event.clearListeners(val, "renderMode_changed");
                        naver.maps.Event.clearListeners(val, "click");
                        val.map = null;
                        val?._shape?.element?.remove();
                        val?.onRemove();
                        val?.setVisible(false);
                    }
                });
            }
        }
    }, [isFetching]);

    //*국유지 딤처리 리스트 리셋
    const delDimList = () => {
        dimStateLandPolygonList.current?.map((val: any) => val?.setMap(null));
        dimStateLandPolygonList.current = [];
        stateLandDimRemove();
    };

    //* 국유지 구매지역 외 딤 처리
    useEffect(() => {
        if (startStateLand && !stateLandDimIsFetching && currentMap) {
            dimStateLandPolygonList.current?.map((v: any) => v?.setMap(null));
            dimStateLandPolygonList.current = stateLandDimData?.list?.map(
                (land) =>
                    new naver.maps.Polygon({
                        map: currentMap?.map,
                        paths: land?.geometry?.coordinates?.map((val: any) => [...val]).flat(),
                        fillColor: "#9DA6B3",
                        fillOpacity: 0.6,
                        strokeOpacity: 1,
                        clickable: true,
                        strokeColor: "#9DA6B3",
                        strokeWeight: 1,
                        strokeLineCap: "square",
                    }),
            );

            dimStateLandPolygonList.current?.map((q: any) => {
                naver.maps.Event.addListener(q, "click", (evt) => {
                    const position = evt.coord;
                    const currentZoom = currentMap.map.getZoom();
                    setIsFieldCardShow(false);
                    setIsPopulationStatusCardShow(false);
                    setIsBackToAppShow(false);
                    setFromCheck("");
                    if (isStreetActiveRef.current) {
                        roadViewActive(position);
                        return;
                    }
                    if (currentZoom > 13) {
                        if (prevSelectedRef.current !== null) {
                            const prevCluItem = cluItemListRef.current.filter((q: any) => q._id === prevSelectedRef.current);
                            const prevMarkerIndex = realMarkerListRef.current.findIndex(
                                (q: any) => q.getTitle() === prevSelectedRef.current,
                            );
                            const markerElement = renderToStaticMarkup(
                                Marker({
                                    type: "real",
                                    displayName: prevCluItem[0]._id,
                                    count: prevCluItem[0].count,
                                    item: prevCluItem[0].item,
                                    real: prevCluItem[0].real,
                                    markerType: "real",
                                }),
                            );
                            realMarkerListRef.current[prevMarkerIndex].setOptions({
                                position: new naver.maps.LatLng(prevCluItem[0].latd, prevCluItem[0].lntd),
                                map: currentMap.map,
                                clickable: true,
                                zIndex: 2,
                                icon: {
                                    content: markerElement,
                                    anchor: new naver.maps.Point(50, 70),
                                    origin: new naver.maps.Point(50, 50),
                                },
                                visible: !isMarkerHide.real,
                                title: prevCluItem[0]._id,
                            });
                            prevSelectedRef.current = null;
                        }
                        pinMarkerRef.current?.map((pin) => pin?.setIcon(smallPinSize));
                        drawPolylineWithLandCard(position);
                    } else {
                        const currentZoom = currentMap?.map?.getZoom();
                        const newParam: IPolygonApiParams = {
                            latitude: position.lat(),
                            longitude: position.lng(),
                            zoom: currentZoom,
                        };
                        drawingClickFieldPolygon(newParam);
                    }
                });
            });
        } else if (!startStateLand) {
            delDimList();
        }
    }, [stateLandDimIsFetching, startStateLand]);

    const checkCardShow =
        !isFieldCardShow &&
        !isSaleCardShow &&
        !isPopulationStatusCardShow &&
        !isPanoViewActive &&
        !openStateLandFilter &&
        !pinDimShow &&
        !showConvenienceBar &&
        !drawingType &&
        !showCanvas;

    return (
        <>
            <div css={mapWrap}>
                {!showCanvas && !drawingType && (
                    <StyledFilterWrap isBackgroundVisible={isBackgroundVisible} isShowFilter={isShowFilter}>
                        <NewFilter />
                    </StyledFilterWrap>
                )}
                {showCanvas && (
                    <DrawScreen
                        onClose={() => {
                            setShowCanvas(false);
                        }}
                        showConvenienceBar={showConvenienceBar}
                        onChangeShowConvenienceBar={onChangeShowConvenienceBar}
                    />
                )}
                <button
                    ref={cadastralRef}
                    className="hideCadastralButton hidden"
                    onClick={(e) => {
                        e.preventDefault();
                        if (currentMap) {
                            mapLayerHandler.activeCadastralMap(currentMap.map);
                            addHideActiveClassName(e.target);
                            !prevMarkerHide && setPrevMarkerHide(isMarkerHide);
                            setIsMarkerHide({
                                product: true,
                                real: true,
                                expected: true,
                            });
                        }
                    }}
                >
                    {cadastralRef.current?.classList.contains("active") ? "지적도 ON" : "지적도 OFF"}
                </button>
                {!showCanvas && (
                    <NewFloatingMenu
                        map={currentMap?.map ?? null}
                        mapLayerHandler={mapLayerHandler}
                        userLocation={initLocation}
                        handleClickUseButton={(isLayerOn: boolean) => {}}
                        handleMarkerHideBtnClick={handleMarkerHideBtnClick}
                        isMarkerHide={isMarkerHide}
                        onChangeShowConvenienceBar={onChangeShowConvenienceBar}
                        showConvenienceBar={showConvenienceBar}
                        showCanvas={showCanvas}
                        drawingType={drawingType}
                        isCadastralShow={isCadastralShow}
                        onChangeCadastralShow={() => setIsCadastralShow((pre) => !pre)}
                        isStreetActive={isStreetActive}
                        onChangeIsStreetActive={() => setIsStreetActive((pre) => !pre)}
                        isFlightActive={isFlightActive}
                        onChangeIsFlightActive={() => setIsFlightActive((pre) => !pre)}
                        checkMyPosition={checkMyPosition}
                        onChangeCheckMyPosition={() => {
                            checkClickedMyLocation.current = !checkClickedMyLocation.current;
                            setCheckMyPosition((pre) => !pre);
                        }}
                        openStateLandFilter={openStateLandFilter}
                        onChangeStateLandFilter={() => setOpenStateLandFilter((pre) => !pre)}
                        startStateLand={startStateLand}
                        onChangeStartStateLand={() => {
                            if (stateLandSubs?.isActive) {
                                setStartStateLand(!startStateLand);
                            } else {
                                handleClickedStateLand();
                            }
                        }}
                        stateLandSubs={stateLandSubs}
                        refetchStateLandSubs={refetchStateLandSubs}
                    />
                )}
                <ConvenienceBottomSheet
                    showConvenienceBar={showConvenienceBar}
                    showCanvas={showCanvas}
                    drawingType={drawingType}
                    onChangeShowConvenienceBar={onChangeShowConvenienceBar}
                    isHideCard={() => {
                        setIsFieldCardShow(false);
                        setIsSaleCardShow(false);
                        setIsPopulationStatusCardShow(false);
                    }}
                    isStartDraw={() => setShowCanvas(true)}
                    isEndDraw={() => setShowCanvas(false)}
                    isStartMeasure={(i: number) => {
                        setDrawingType(i);
                        if (prevCadastralMapRef.current === null) {
                            prevCadastralMapRef.current = isCadastralShow;
                        }
                        if (!cadastralRef.current?.classList.contains("active") && currentMap && !isCadastralShow) {
                            mapLayerHandler.activeCadastralMap(currentMap.map);
                        }
                        !prevMarkerHide && setPrevMarkerHide(isMarkerHide);
                        setIsMarkerHide({
                            product: true,
                            real: true,
                            expected: true,
                        });
                        addHideActiveClassNameV2(cadastralRef.current);
                    }}
                />
                <div css={panoWrap} className={isPanoViewActive ? "show" : ""}>
                    <div className="panoHeader">
                        <button
                            onClick={() => {
                                setIsPanoViewActive(false);
                                setIsRoadMapInPanoView(false);
                                panoMarkerRef?.current?.setMap(null);
                                panoViewRef.current = null;
                                roadMapInPanoView.current.destroy();
                                const roadPano = document.getElementById("roadPano");
                                while (roadPano?.firstChild) {
                                    roadPano.removeChild(roadPano.firstChild);
                                }
                                setIsStreetActive(false);
                            }}
                        >
                            &lt;
                        </button>
                        <div className="address">{panoAddress}</div>
                    </div>
                    <div className={isRoadMapInPanoView ? "panoView " : "fullScreen"} id="roadPano" />
                    <button
                        className={isRoadMapInPanoView ? "roadMapCloseBtn" : "roadMapCloseBtn inactive"}
                        onClick={() => {
                            setIsRoadMapInPanoView((prev) => !prev);
                        }}
                    >
                        X
                    </button>
                    <div ref={roadMapInPanoView} className="roadMapInPanoView" id="roadMapForPano"></div>
                </div>
                <div className="map" ref={mapRef} css={mapRootStyle} />
                {isSearch && <MobileSearch keyword={mapState?.searchKeyword ?? ""} />}
                {popupContents.isShow && (
                    <PopUp
                        text={popupContents.text}
                        isShowCancelButton={popupContents.isShowCancelButton}
                        confirmButtonTitle={popupContents.confirmButtonTitle}
                        handleConfirmClick={popupContents.handleConfirmClick}
                        handleCancelClick={popupContents.handleCancelClick}
                    />
                )}
                <ToastMessage
                    visible={isShowToast}
                    setToastVisible={() => setIsShowToast(false)}
                    addStyles={{ top: "unset", bottom: "100px" }}
                >
                    위치고정은 세 곳만 가능합니다
                </ToastMessage>
                <ToastMessage
                    visible={isShowToastLandSize}
                    setToastVisible={() => setIsShowToastLandSize(false)}
                    addStyles={{ top: "unset", bottom: "50px" }}
                >
                    6개 이상 필지를 탭할 경우 PC를 이용해주세요
                </ToastMessage>
                {!!drawingType && drawingType !== 3 && (
                    <MeasureCard
                        totalPolylineDistance={totalPolylineDistance}
                        drawingType={drawingType}
                        totalPolygonSize={totalPolygonSize}
                        onClose={onCloseConvenience}
                    />
                )}
                {!!polygonLandList?.length && drawingType === 3 && (
                    <MeasureLandCardList
                        landDataList={polygonLandList}
                        onDelete={onDeleteLandSizePolygon}
                        selectedLandSizePolygonPnu={selectedLandSizePolygonPnu}
                        resetSelectedLandSizePolygonPnu={() => setSelectedLandSizePolygonPnu("")}
                        onChangeMapCenter={(position: naver.maps.LatLng) => {
                            if (currentMap) {
                                currentMap.map.morph(position);
                            }
                        }}
                    />
                )}
                {drawingType === 3 && (
                    <NewMeasureCard
                        totalPolygonLandSize={totalPolygonLandSize}
                        onClose={onCloseConvenience}
                        onDelete={onDeleteLandSizePolygon}
                        length={polygonLandList.length}
                    />
                )}
                {((drawingType === 1 && totalPolylineDistance >= 0) || (drawingType === 2 && totalPolygonSize >= 0)) &&
                    !showCanvas && (
                        <div css={btnListRootStyle}>
                            <button css={deleteBtnStyle} className="deleteBtn" onClick={onClickDeletePoint}>
                                마지막 선택 삭제
                            </button>
                            <button css={allDeleteBtnStyle} className="deleteBtn" onClick={onClickAllDeletePoint}>
                                전체취소
                            </button>
                        </div>
                    )}
                {localStorage.getItem("isDataMapLoadFirst") === "true" &&
                    searchParams.get("search") !== "1" &&
                    !searchParams.get("lat") && (
                        <img
                            src={window.innerWidth > 500 ? TutorialLgImg : TutorialSmImg}
                            alt="데이터맵 튜토리얼"
                            css={TutorialWrapper}
                            onTouchStart={(evt: any) => {
                                if (evt.target.src?.includes("map")) {
                                    evt.target.src = window.innerWidth > 500 ? pinMarkerTutorialLgImg : pinMarkerTutorialSMImg;
                                } else {
                                    evt.target.style.display = "none";
                                    localStorage.setItem("isDataMapLoadFirst", "false");
                                }
                            }}
                        />
                    )}
                {isFieldCardShow && (
                    <Card
                        address={fieldAddress}
                        type={fieldType}
                        location={fieldLocation}
                        isBackToAppShow={isBackToAppShow}
                        handleClickBackToApp={handleClickBackToApp}
                        handleClickRoadViewButton={handleClickRoadViewButton}
                        hideLandCard={hideFieldCard}
                        pnu={fieldPNU}
                        isAutoShow={!fromCheck && !!pnuFromApp}
                        onChangePnuFromApp={() => setPnuFromApp("")}
                        roadAddr={fieldRoadAddress}
                        buildingSize={buildingSize}
                        fieldJimok={fieldJimok}
                        handleClickBackToAiChat={handleClickBackToAiChat}
                        fromCheck={fromCheck}
                    />
                )}
                {isSaleCardShow && (
                    <div css={saleCardDiv} ref={widthRef}>
                        <SalePage
                            items={items}
                            hideLandCard={hideLandCard}
                            setIsSaleCardShow={setIsSaleCardShow}
                            hidePolyline={hidePolyline}
                            onChangeTextInput={(val: string) => setSearchTextFromApp(val)}
                        />
                    </div>
                )}
                {isPopulationStatusCardShow && populationStatusItem && (
                    <div css={saleCardDiv}>
                        <PopulationStatusCard
                            items={populationStatusItem}
                            setIsPopulationStatusCardShow={setIsPopulationStatusCardShow}
                        />
                    </div>
                )}
                <Dim visible={dimVisible} zIndex={990} />
                {pinDimShow && (
                    <div css={pinDimStyle} onClick={() => setPinDimShow(false)}>
                        <div css={pinAddressStyle} id="pinDim">
                            {clickedPinMarker?.buildingName?.replaceAll("-", "") && (
                                <p className="building">{clickedPinMarker?.buildingName}</p>
                            )}
                            <p className="addr">
                                {clickedPinMarker?.roadAddr?.includes("없습니다")
                                    ? clickedPinMarker?.address
                                    : clickedPinMarker?.roadAddr}
                            </p>
                        </div>
                        <img src={pinMarkerIcon} alt="" css={pinDimMarkerStyle} id="pinDimMarker" />
                        <p onClick={deletePinMarker} id="pinbutton" css={pinButtonStyle}>
                            핀 제거
                            <img src={deletePinIcon} alt="" width="24px" height="24px" />
                        </p>
                    </div>
                )}
                <StateLandFilter
                    isShow={openStateLandFilter}
                    onCloseView={() => {
                        transferToNative(JSON.stringify(stateLandFilter), "setFilterDone");
                        setOpenStateLandFilter(false);
                    }}
                    stateLandSubs={stateLandSubs}
                    setOpenStateLandFilter={setOpenStateLandFilter}
                />
                {checkCardShow && showStateLandZoomInfo && (
                    <InfoCard
                        text={infoStateLandContent()}
                        height={64}
                        bottomCheck={isFieldCardShow ? "168px" : isSaleCardShow ? "274px" : "14px"}
                    />
                )}
                {stateLandLoadingOpen && startStateLand && (
                    <LoadingInfoCard
                        open={stateLandLoadingOpen}
                        onCloseView={() => setStateLandLoadingOpen(false)}
                        count={stateLandCount ?? 0}
                        isFetching={isFetching}
                        text={"필터 적용 중"}
                        content={"적용완료"}
                        bottomCheck={isFieldCardShow ? "164px" : isSaleCardShow ? "284px" : "24px"}
                    />
                )}
            </div>
        </>
    );
}
const shakeAnimation = keyframes`
    0% {
        transform: translateX(0) rotate(0);
        opacity: 0;
    }

    20% {
        transform: translateX(-10px) rotate(-10deg);
        opacity: 1;
    }

    30% {
        transform: translateX(10px) rotate(10deg);
    }

    50% {
        transform: translateX(-5px) rotate(-5deg);
    }

    70% {
        transform: translateX(5px) rotate(5deg);
    }

    80% {
        transform: translateX(-2px) rotate(-2deg);
    }

    90% {
        transform: translateX(2px) rotate(2deg);
    }

    100% {
        transform: translateX(0) rotate(0);
    }
`;
const popUpAnimation = keyframes`
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    50% {
        transform: translateY(-10%);
        opacity: 1;
    }

    100% {
        transform: translateY(0%);
    }
`;
const mapWrap = css`
    overscroll-behavior: contain;
    width: 100%;
    height: ${screen.height};
    position: relative;
    overflow: none;
    img[alt="NAVER"] {
        display: none !important;
    }
    a:has(img[alt="NAVER"]) {
        display: none !important;
    }
    .convenienceBox {
        color: #0047ff;
        text-align: center;
        border-radius: 50%;
        background-color: #ffffff;
        height: 16px;
        width: 16px;
        letter-spacing: 0;
        font-size: 10px;
        line-height: 12px;
        border: 2px solid #0047ff;
        font-weight: 500;
    }
    .distanceBox {
        font-size: 10px;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 3px 6px;
        margin: 1px 0 0 --10px;
        position: relative;
        color: #0070d0;
        line-height: 10px;
        height: 16px;
    }
    .customAreaCard {
        width: 22px;
        height: 20px;
        border: 2px solid #0047ff;
        color: #0047ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        box-sizing: border-box;
    }
    .marker_clu {
        min-width: 54px;
        padding: 10px;
        border-radius: 24px;
        background-color: ${Common.colors.aucMarkerColor};
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
        text-align: center;
        p {
            color: ${Common.colors.white};
            text-align: center;
            white-space: nowrap;
        }
        .marker_clu_region {
            ${Common.textStyle.mapMarker_13_medium}
            margin-bottom: 4px;
        }
        .marker_clu_count {
            ${Common.textStyle.mapMarker_14_medium}
        }
    }
    .detailMarker {
        box-sizing: border-box;
        min-width: 76px;
        padding: 8px 12px;
        background-color: #fff;
        border: 2px solid transparent;
        font-size: 12px;
        border-radius: 60px;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.18));
        position: relative;
        &.searchMarker {
            border: 2px solid ${Common.colors.white};
        }
        &::after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border-radius: 3px;
            border-top: 11px solid red;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translate(-50%, -50%);
        }
    }

    .detailMarker:hover {
        border: 2px solid ${Common.colors.white};
    }
    .실거래 .detailMarker {
        border: 2px solid ${Common.colors.white};
    }
    .실거래 .detailMarker:hover {
        border: 2px solid ${Common.colors.white};
    }
    .convertMarkerType:hover {
        border-color: transparent;
    }
    .marker_detail_container {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 4px;

        p {
            white-space: nowrap;
            ${Common.textStyle.mapMarker_13_medium}
            color: ${Common.colors.white};
        }
        span {
            white-space: nowrap;
            ${Common.textStyle.mapMarker_12_medium}
            color: ${Common.colors.white};
        }
        .marker_detail_bidType {
            padding: 2px 4px;
            height: 16px;
            line-height: 16px;
            border-radius: 14px;
            background-color: ${Common.colors.white};
            ${Common.textStyle.mapMarker_12_bold}
        }
        .marker_detail_bidType_bold {
            padding: 2px 4px;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            ${Common.textStyle.mapMarker_12_bold}
        }
        .marker_detail_price {
            ${Common.textStyle.mapMarker_12_bold}
        }
        .marker_detail_square {
            ${Common.textStyle.mapMarker_12_medium}
        }
    }
    .marker_detail_top {
        margin-bottom: 4px;
    }
    .marker_detail_center {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px auto;
        p {
            white-space: nowrap;
            ${Common.textStyle.mapMarker_14_medium}
            color: ${Common.colors.white};
        }
        span {
            white-space: nowrap;
            ${Common.textStyle.mapMarker_13_medium}
            color: ${Common.colors.white};
        }
        .marker_detail_bidType_bold {
            white-space: nowrap;
            font-weight: 700;
            font-size: 13px;
            line-height: 13px;
            color: ${Common.colors.white};
        }
    }
    .marker_detail_use {
        flex-direction: row;
    }
    .marker_detail_btm {
        justify-content: flex-start;
    }
    .marker_detail_btm {
        justify-content: flex-end;
    }
    .marker_detail_count {
        position: absolute;
        right: -3px;
        top: -20px;
        padding: 0px 5px;
        box-sizing: border-box;
        min-width: 20px;
        border-radius: 14px;
        background-color: ${Common.colors.discoColor};
        border: 1px solid ${Common.colors.white};
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }
    .경매,
    .지분경매 {
        &::after {
            border-top-color: ${Common.colors.aucMarkerColor};
        }
        background-color: ${Common.colors.aucMarkerColor};
        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.aucMarkerColor};
        }
        .marker_detail_count {
            background-color: ${Common.colors.aucMarkerColor};
        }
    }
    .공매,
    .지분공매 {
        &::after {
            border-top-color: ${Common.colors.pubMarkerColor};
        }
        background-color: ${Common.colors.pubMarkerColor};

        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.pubMarkerColor};
        }
        .marker_detail_count {
            background-color: ${Common.colors.pubMarkerColor};
        }
    }
    .예정 {
        max-width: 83px;
        min-height: 80px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        text-align: center;
        padding: 8px 16px;
        border-radius: 60px;
        gap: 2px;
        .marker_detail_btm {
            color: ${Common.colors.white};
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
            text-align: center;
        }
        .marker_detail_top {
            margin-bottom: 0;
            flex-direction: column;
        }
        &::after {
            border-top-color: ${Common.colors.expcMarkerColor};
        }
        background-color: ${Common.colors.expcMarkerColor};
        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.expcMarkerColor};
            &::before {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                background-color: ${Common.colors.expcMarkerColor};
                border-radius: 50%;
                position: absolute;
                left: 50%;
                bottom: -14px;
                transform: translateX(-50%);
            }
        }
        .marker_detail_count {
            background-color: ${Common.colors.expcMarkerColor};
        }
        .marker_detail_claim {
            align-items: center;
            text-align: center;
        }
        .marker_detail_use {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 4px;
            width: 66px;
        }
        .marker_detail_amount {
            flex-direction: column;
            align-items: center;
            font-weight: 400;
            font-size: 11px;
        }
        .marker_detail_price {
            ${Common.textStyle.mapMarker_12_bold};
            margin-top: 2px;
        }
    }
    .expGroup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        text-align: center;
        padding: 6px;
        position: absolute;
        width: 72px;
        height: 72px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
        border-radius: 50%;
        .marker_detail_use {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 4px;
            width: 66px;
        }
        &::after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border-radius: 3px;
            border-top: 11px solid red;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translate(-50%, -50%);
            border-top-color: ${Common.colors.expcMarkerColor};
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
        }
        &::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: ${Common.colors.expcMarkerColor};
            border-radius: 50%;
            position: absolute;
            left: 50%;
            bottom: -14px;
            transform: translateX(-50%);
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
        }
        background-color: ${Common.colors.expcMarkerColor};

        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.expcMarkerColor};
        }
        .marker_detail_count {
            flex-direction: column;
            background-color: ${Common.colors.expcMarkerColor};
            p {
                white-space: nowrap;
                ${Common.textStyle.mapMarker_14_medium}
                color: ${Common.colors.white};
            }
        }
    }
    .expBot {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 4px;
        box-sizing: border-box;
        position: absolute;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
        border-radius: 24px;
        &::after {
            border-top-color: ${Common.colors.expcMarkerColor};
        }
        background-color: ${Common.colors.expcMarkerColor};

        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.expcMarkerColor};
        }
        .marker_detail_count {
            background-color: ${Common.colors.expcMarkerColor};
            flex-direction: column;
        }
    }
    .공매임대 {
        &::after {
            border-top-color: #ebebeb;
        }
        p {
            color: ${Common.colors.normalTextColor};
        }
        background-color: #ebebeb;
        .marker_detail_bidType {
            background-color: ${Common.colors.white};
            color: ${Common.colors.normalTextColor};
        }
        .marker_detail_count {
            background-color: #ebebeb;
            color: ${Common.colors.normalTextColor};
        }
    }
    .경매.멀티,
    .지분경매.멀티,
    .공매.멀티,
    .지분공매.멀티 {
        display: block;
    }
    .예정.멀티 {
        padding: 0px;
        display: flex;
        flex: 1;
        min-height: 0;
        min-width: 0;
    }
    .convertMarkerType {
        min-height: 0;
        position: absolute;
        right: 0%;
        top: -30px;
        z-index: 99;
        color: ${Common.colors.white};
        padding: 4px;
        border-radius: 14px;
        border: 2px solid transparent;

        ${Common.textStyle.mapMarker_11_medium}
        &:hover {
            border: 2px solid ${Common.colors.white};
        }
    }
    .convertMarkerType.경매,
    .convertMarkerType.지분경매 {
        background-color: ${Common.colors.aucMarkerColor};
    }
    .convertMarkerType.공매,
    .convertMarkerType.지분공매 {
        background-color: ${Common.colors.pubMarkerColor};
    }
    .convertMarkerType_real {
        position: absolute;
        right: 0%;
        top: -30px;
        z-index: 99;
        color: ${Common.colors.white};
        padding: 4px;
        border-radius: 14px;
        border: 2px solid transparent;
        background-color: ${Common.colors.realMarkerColor};
        ${Common.textStyle.mapMarker_11_medium}
        border: 2px solid transparent;

        &:hover {
            border: 2px solid ${Common.colors.white};
        }
    }
    .실거래 {
        display: block;
        .summ {
            padding: 4px;
            background-color: ${Common.colors.white};
            border-radius: 14px;
            text-align: center;
            border: 1.4px solid transparent;
            .marker_real_price {
                white-space: nowrap;
                margin-bottom: 4px;
                ${Common.textStyle.mapMarker_12_medium}
            }
            .marker_real_contract_date2 {
                ${Common.textStyle.mapMarker_10_medium}
            }
            &.type1 {
                border-color: ${Common.mapColor[0]};
                .marker_real_price {
                    color: ${Common.mapColor[0]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[0]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type2 {
                border-color: ${Common.mapColor[1]};
                .marker_real_price {
                    color: ${Common.mapColor[1]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[1]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type3 {
                border-color: ${Common.mapColor[2]};
                .marker_real_price {
                    color: ${Common.mapColor[2]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[2]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type4 {
                border-color: ${Common.mapColor[3]};
                .marker_real_price {
                    color: ${Common.mapColor[3]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[3]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type5 {
                border-color: ${Common.mapColor[4]};
                .marker_real_price {
                    color: ${Common.mapColor[4]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[4]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type6 {
                border-color: ${Common.mapColor[5]};
                .marker_real_price {
                    color: ${Common.mapColor[5]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[5]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
            &.type7 {
                border-color: ${Common.mapColor[6]};
                .marker_real_price {
                    color: ${Common.mapColor[6]};
                }
                &::after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: ${Common.mapColor[6]};
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
                }
            }
        }
        .detail {
            padding: 10px 14px;
            color: ${Common.colors.white};
            background-color: ${Common.colors.realMarkerColor};
            white-space: nowrap;
            .marker_real_top {
                width: 100%;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 4px;
                margin-bottom: 4px;
            }
            .marker_real_btm {
                text-align: right;
                .marker_real_price {
                    ${Common.textStyle.mapMarker_13_bold}
                }
                p {
                    margin-bottom: 4px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .marker_real_bidType {
                padding: 2px 4px;
                height: 16px;
                line-height: 16px;
                border-radius: 14px;
                background-color: ${Common.colors.white};
                color: ${Common.colors.realMarkerColor};
                ${Common.textStyle.mapMarker_12_bold}
            }
        }
        .detail::after {
            border-top-color: ${Common.colors.realMarkerColor};
        }
    }
    .panoMarker {
        width: 40px;
        height: 60px;
    }
    div[title="pinMarker"] {
        /* animation: ${popUpAnimation} 0.5s ease-out; */
        /* transform-origin: center bottom; */
    }
    div[title="useLocation"] {
        margin-top: 11px;
        margin-left: 11px;
        img {
            display: block !important;
            width: 44px !important;
            height: 44px !important;
        }
    }
    div[title="selectedLocation"] {
        width: 24px !important;
        height: 32px !important;
        margin-top: 36px !important;
        margin-left: 14px !important;
        img {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .filterWrap {
        position: fixed;
        z-index: 991;
        width: 100%;
        padding: 0px 16px;
        background-color: #fff;

        input {
            background-color: #fff;
        }
    }

    .map {
        z-index: 90;
    }
    .roadViewButton {
        position: absolute;
        right: 14px;
        top: -46px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: ${Common.colors.white};
        padding: 6px 10px 6px 24px;
        gap: 4px;

        width: 76px;
        height: 32px;
        border-radius: 24px;
        background: #0a2540 url(${roadViewIcon}) no-repeat;
        background-size: 12px;
        background-position: left 10px center;
    }
    .hideCadastralButton {
        position: absolute;
        top: 110px;
        left: 14px;
        z-index: 991;
        width: 84px;
        height: 32px;
        border-radius: 8px;
        background-color: #fff;
        text-align: center;
        font-size: 12px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        white-space: nowrap;
        background: #fff;
        color: ${Common.colors.navyPrimary};
        visibility: visible;
        opacity: 1;
    }
    .hideCadastralButton.hidden {
        visibility: hidden;
        opacity: 0;
    }
    .hideCadastralButton.active {
        background: ${Common.colors.aucMarkerColor};
        color: #fff;
        border-color: #fff;
    }
`;
const panoWrap = css`
    position: fixed;
    left: -100%;
    top: -100%;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    height: 100vh;
    z-index: 999;
    overflow: visible !important;
    &.show {
        position: fixed;
        left: 0;
        top: 0;
    }
    .panoHeader {
        width: 100%;
        height: 64px;
        position: absolute;
        left: 0%;
        top: 0%;
        z-index: 999;
        padding: 0 14px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        background-color: #fff;
        button {
            height: 24px;
            width: 24px;
            background: url(${backIcon}) no-repeat;
            background-size: 10px 17px;
            background-position: center;
            font-size: 0;
            text-indent: -9999px;
            outline: none;
        }
    }
    .show .panoHeader {
        left: 0;
        top: 0;
    }
    .panoView {
        position: absolute;
        left: 0;
        top: 64px;
        width: 100% !important;
        height: calc(var(--vh, 1vh) * 68 - 64px) !important;
        height: calc(68dvh - 64px);
        height: calc(68vh - 64px);
    }
    .fullScreen {
        margin-top: 64px;
        height: calc(var(--vh, 1vh) * 100 - 64px) !important;
        height: calc(100dvh - 64px) !important;
        height: calc(100vh - 64px) !important;
    }
    .roadMapInPanoView {
        width: 100% !important;
        height: calc(var(--vh, 1vh) * 32);
        height: 32dvh;
        height: 32vh;
        position: absolute;
        left: 0;
        bottom: -64px;
        z-index: 999;
    }
    .address {
        color: ${Common.colors.navyPrimary};
    }
    .roadMapCloseBtn {
        width: 44px;
        height: 44px;
        background: #fff no-repeat;
        border-radius: 8px;
        outline: none;
        background-image: url(${closeIcon});
        background-size: 14px 14px;
        background-position: center;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.24);
        position: absolute;
        right: 14px;
        top: calc(68vh + 14px);
        z-index: 1000;
        font-size: 0;
        text-indent: -9999px;

        &.inactive {
            top: unset;
            bottom: 14px;
            background-image: url(${mapIcon});
            background-size: 17px 17px;
        }
    }
    div[title="useRoadMarker"] {
        width: 75px !important;
        height: 75px !important;
        img {
            width: 75px !important;
            height: 1200px !important;
        }
    }
`;

const deleteBtnStyle = css`
    cursor: pointer;
    background-color: #0047ff;
    color: #ffffff;
    padding: 8px 14px;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    border-radius: 8px;
    margin-right: 4px;
`;

const allDeleteBtnStyle = css`
    cursor: pointer;
    background-color: #ffffff;
    color: #0047ff;
    padding: 8px 14px;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    border-radius: 8px;
`;

const btnListRootStyle = css`
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 4px;
    position: absolute;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 991;
    width: max-content;
`;

const StyledFilterWrap = styled.div<{ isBackgroundVisible: boolean; isShowFilter: boolean }>`
    position: fixed;
    z-index: 991;
    width: 100%;
    background-color: ${(props) => (props.isBackgroundVisible ? "#FFFFFF" : `rgba(255, 255, 255, 0)`)};
    input {
        background-color: #fff;
    }
    & > .searchWrap {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;
        & > .inputWrap {
            width: 100%;

            & > div {
                height: 48px;
                & > input {
                    height: 40px;
                }
                & > div {
                    top: calc(50% - 10px);
                    width: 20px;
                    height: 20px;
                }
            }
        }
        & > .imgDiv {
            width: 40px;
            height: 40px;
            box-sizing: border-box;
            img {
                width: 40px;
                height: 40px;
                box-sizing: border-box;
            }
        }
    }
`;

const TutorialWrapper = css`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 993;
`;

const saleCardDiv = css`
    display: flex;
    overflow: auto;
    white-space: nowrap;
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 993;
`;

const pinDimStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 993;
`;

const pinAddressStyle = css`
    position: fixed;
    background-color: #ffffff;
    padding: 14px 8px;
    border-radius: 8px;
    max-height: 92px;
    width: 200px;
    .building {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
    }
    .addr {
        color: #3c3c3c;
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        word-break: break-word;
        white-space: pre-wrap;
        max-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &::after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border-radius: 3px;
        border-top: 11px solid #ffffff;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translate(-50%, -50%);
    }
`;

const pinButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 24px;
    position: absolute;
    padding: 8px 12px;
    white-space: nowrap;
`;

const pinDimMarkerStyle = css`
    position: absolute;
`;

const mapRootStyle = css`
    width: 100vw;
    width: 100dvw;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    height: 100vh;
    overflow: none;
    position: relative;
`;

const stateLandStyle = (SLIDER_WIDTH: any) => css`
    display: flex;
    position: absolute;
    bottom: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    background-color: #ffffff;
    border-radius: 14px;
    padding: 8px 14px;
    .contentText {
        color: #0c002c;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 4px;
    }
    .infoText {
        color: #0c002c;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
    }
    .yellowLine {
        display: inline;
        background: linear-gradient(to top, #faff00 40%, transparent 50%);
        width: fit-content;
        max-width: ${SLIDER_WIDTH - 48}px;
        color: #0c002c;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .addButton {
        display: flex;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: #2275ee;
        color: #ffffff;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 8px;
    }
`;

export default Map;
