import type { NonNullableLandUsePolygon } from "@src/hooks/landUse/dto/landUse.dto";
import create from "zustand";

interface CompassRotationStore {
    compassRotation: number;
    setCompassRotation: (rotation: number) => void;
}

export const useCompassRotationStore = create<CompassRotationStore>((set) => ({
    compassRotation: 0,
    setCompassRotation: (rotation) => set({ compassRotation: rotation }),
}));

interface BuildingPolygonStore {
    buildingPolygon: NonNullableLandUsePolygon[] | null;
    setBuildingPolygon: (data: NonNullableLandUsePolygon[] | null) => void;
}

export const useBuildingPolygonStore = create<BuildingPolygonStore>((set) => ({
    buildingPolygon: null,
    setBuildingPolygon: (data) => set({ buildingPolygon: data }),
}));

interface LandPolygonStore {
    landPolygon: [number, number][] | null;
    setLandPolygon: (data: [number, number][] | null) => void;
}

export const useLandPolygonStore = create<LandPolygonStore>((set) => ({
    landPolygon: null,
    setLandPolygon: (data) => set({ landPolygon: data }),
}));

interface BuildingHeightStore {
    buildingHeight: number | null;
    setBuildingHeight: (buildingHeight: number | null) => void;
}

export const useBuildingHeightStore = create<BuildingHeightStore>((set) => ({
    buildingHeight: null,
    setBuildingHeight: (data) => set({ buildingHeight: data }),
}));
