import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import {
    initUseFilters,
    initYearFilter,
    initPriceFilter,
    initApiParams,
    initPriceTypeFilter,
    initPyoungTypeFilter,
} from "@src/constants/mapRealPriceFilters";
import { pyeongCaseType } from "@src/store/RealPriceFilterStore";
import { useQuery } from "react-query";

interface realPriceFilterType {
    viewParams: {
        use: { [key: string]: boolean } | {};
        price: any[];
        year: any[];
        price_type: any;
        pyong_type: any;
    };
    apiParams: any;
}
const fetchData = async (): Promise<realPriceFilterType> => {
    const response = await axiosInstance.get(`${axiosPath.searchedList}?name=mapUserRealPriceFilter`);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response?.data?.payload.data;
    let result = {
        viewParams: {
            use: { ...initUseFilters },
            price: [0, initPriceFilter.length - 1],
            year: [0, initYearFilter.length - 1],
            price_type: initPriceTypeFilter,
            pyong_type: initPyoungTypeFilter,
        },
        apiParams: { ...initApiParams },
    };
    if (responseData.length) {
        const value = JSON.parse(responseData[0].value);
        const { apiParams, viewParams } = value;
        const parseViewParams = JSON.parse(viewParams);
        const parseApiParams = JSON.parse(apiParams);
        result.apiParams = parseApiParams;
        if (parseViewParams.length !== 0) {
            result.viewParams = parseViewParams;
        }
    }
    return result;
};

const useGetUserRealPriceFilter = async () => {
    return fetchData();
};

const usePostUserRealPriceFilter = async (params: { apiParams: string; viewParams: string }) => {
    const PARAMS = JSON.stringify(params);
    const response = await axiosInstance.post(`${axiosPath.searchedList}`, { name: "mapUserRealPriceFilter", value: PARAMS });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
};

export { useGetUserRealPriceFilter, usePostUserRealPriceFilter };

//* 경공매 상세 정보
export const usePyeongPriceDetail = (pnu: string | undefined, use_detail: string | undefined) => {
    const fetchPyeongPriceDetail = async () => {
        if (!pnu) throw new Error("pnu가 필요합니다");

        const response = await axiosInstance.get(`${axiosPath.pyeongPricePath}?pnu=${pnu}&use_detail=${use_detail}`, {
            timeout: 10000,
        });
        return response?.data.payload.result.list;
    };

    return useQuery<pyeongCaseType[]>(["auctionDetail", pnu], fetchPyeongPriceDetail, {
        enabled: !!pnu,
        retry: 3,
    });
};
