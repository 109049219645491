import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { useEffect, useMemo, useState } from "react";
import { LocationDataType, LocationsDataType } from "@src/hooks/filter/useGetLocations";
import { css } from "@emotion/react";
import { appearAnimation } from "@src/components/animation/FeadIn";

type LocationFilterPropsType = {
    handleSelect: (selected: string, type: LocationDataType[], selectedName: string) => void;
    resetActiveData: () => void;
    setActiveData: any;
    locationData?: LocationsDataType;
    selectedType: string;
    activeData: LocationsDataType;
    style?: any;
    activePNUState: string[];
    setActivePNUState: (pnu: string[]) => void;
    resetLocationData: () => void;
};
const LocationFilter = ({
    handleSelect,
    locationData,
    activeData,
    style,
    resetActiveData,
    activePNUState,
    setActiveData,
    setActivePNUState,
    resetLocationData,
}: LocationFilterPropsType) => {
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedDvsn, setSelectedDvsn] = useState("");
    const [dvsn, setDvsn] = useState<LocationDataType[]>([]);
    const [sec, setSec] = useState<LocationDataType[]>([]);

    useEffect(() => {
        if (activePNUState.length === 1 && activePNUState[0] === "0000000000All") return;

        if (selectedCity !== "전국") {
            activeData.city.map((item) => {
                if (item.cortarName === selectedCity) {
                    setDvsn(item.children);
                }
            });
            if (selectedDvsn !== "전체") {
                dvsn.map((item) => {
                    if (item.cortarName === selectedDvsn) {
                        setSec(item.children);
                    }
                });
            }
        }
    }, [selectedCity, selectedDvsn, activeData, dvsn, sec]);

    const isReset = () => {
        if (activeData.city[0]?.isActive) {
            return activePNUState[0] === "All" || activePNUState[0] === "0000000000All";
        }
    };

    useEffect(() => {
        if (isReset()) {
            setDvsn([]);
            setSec([]);
        }
    }, [activeData, activePNUState]);

    const resetAll = (cortarName: string) => {
        if (cortarName === "전국") {
            setSelectedDvsn("");
            setActivePNUState([]);
            setDvsn([]);
            setSec([]);
        }
    };

    const resetSec = (cortarName: string) => {
        if (cortarName === "전체") {
            setSec([]);
        }
    };

    return (
        <Container style={style}>
            <Wrapper>
                <ContentWrapper width={"26%"} index={0}>
                    <div className="title">시/도</div>
                    {activeData?.city.map((location) => (
                        <Button
                            key={location.cortarNo}
                            onClick={() => {
                                if (location.cortarName === "전국") {
                                    resetAll(location.cortarName);
                                    setSelectedCity(location.cortarName);
                                    resetActiveData();
                                    resetLocationData();
                                    setActivePNUState(["All"]);
                                } else {
                                    handleSelect(location.cortarNo, activeData?.city, location?.cortarName);
                                    setSec([]);
                                    setSelectedDvsn("");
                                    setSelectedCity(location.cortarName);
                                }
                            }}
                            isActive={location.isActive}
                        >
                            <span>{location.cortarName}</span>
                        </Button>
                    ))}
                </ContentWrapper>
                {dvsn ? (
                    <ContentWrapper width={"37%"} index={1}>
                        <div className="title">시/구/군</div>
                        {dvsn?.map((location: any) => (
                            <Button
                                key={location.cortarNo}
                                onClick={() => {
                                    if (location.cortarName === "전체") {
                                        resetSec(location.cortarName);
                                        setSelectedDvsn(location.cortarName);
                                    }
                                    handleSelect(location.cortarNo, dvsn, location?.cortarName);
                                    setSelectedDvsn(location.cortarName);
                                }}
                                isActive={location.isActive}
                            >
                                <span>{location.cortarName}</span>
                            </Button>
                        ))}
                    </ContentWrapper>
                ) : (
                    <></>
                )}

                {sec ? (
                    <ContentWrapper width={"37%"} index={2}>
                        <div className="title">읍/면/동</div>
                        {sec?.map((location: any) => (
                            <Button
                                key={location.cortarNo}
                                onClick={() => handleSelect(location.cortarNo, sec, location?.cortarName)}
                                isActive={location.isActive}
                            >
                                <span>{location.cortarName}</span>
                            </Button>
                        ))}
                    </ContentWrapper>
                ) : (
                    <></>
                )}
            </Wrapper>
        </Container>
    );
};

export default LocationFilter;

const Container = styled.div`
    height: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    background: #eceff4;
`;

const Button = styled.button<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 100%;
    border-radius: 8px;
    padding: 6px 10px 6px 10px;
    background: #fdfafa;
    margin-top: 8px;
    transition: color 0.3s linear;

    ${({ isActive }) => {
        if (isActive) {
            return css`
                background: ${Common.colors.aucMarkerColor};
                color: #ffffff;
            `;
        }
    }};

    span {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        margin-right: 4px;
        white-space: nowrap;
    }
`;

const ContentWrapper = styled.div<{ width: string; index: number }>`
    max-height: 100%;
    width: ${({ width }) => width};
    overflow: scroll;
    background: white;
    padding: 0px 8px;
    padding-top: 52px;
    padding-bottom: 14px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: ${appearAnimation} 1s;

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    .title {
        font-size: 14px;
        line-height: 24px;
        background: #ffffff;
        position: absolute;
        top: 0;
        left: ${({ index }) => {
            if (index === 1) return "26%";
            if (index === 2) return "63%";
            return 0;
        }};
        display: flex;
        height: 40px;
        width: ${({ width }) => width};
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #b1aeb8;
    }
`;
