import styled from "@emotion/styled";
import icoClose from "@src/assets/close_btn_icon-white.svg";
import datamapFinger from "@src/assets/event/datamap/datamapFinger.png";
import point1 from "@src/assets/event/datamap/POINT_01.png";
import point2 from "@src/assets/event/datamap/POINT_02.png";
import point3 from "@src/assets/event/datamap/POINT_03.png";
import point4 from "@src/assets/event/datamap/POINT_04.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import React from "react";

const basePath = process.env.PUBLIC_URL;

const DatamapNewFnEvent = () => {
    const onClickClose = () => {
        transferToNative("done", "closeWebView");
    };
    return (
        <RootStyle>
            <TitleBoxStyle>
                <div className="title">앱 데이터맵 신기능 출시</div>
                <button className="close-icon" onClick={onClickClose}>
                    <img src={icoClose} alt="close" />
                </button>
            </TitleBoxStyle>
            <div className="contents">
                <h1>
                    <p className="red">3월 17일, 앱 데이터 맵</p>
                    <p>신규기능 출시 !!</p>
                </h1>
                <div className="videoBox datamap">
                    <video autoPlay loop muted playsInline>
                        <source src={`${basePath}/video/event/datamap/datamap.mp4`} type="video/mp4" />
                    </video>
                </div>
                <div className="pointBox">
                    <div className="point">
                        <div className="img_box letter">
                            <img src={point1} alt="point1" />
                        </div>
                        <div className="box">
                            <h2>
                                <p>데이터 맵을 터치 또는 줌인하면</p>
                                <p className="red">지도 고정 모드로 변경돼요</p>
                            </h2>
                            <div className="videoBox">
                                <video autoPlay loop muted playsInline>
                                    <source src={`${basePath}/video/event/datamap/datamapPoint.mp4`} type="video/mp4" />
                                </video>
                                <div className="img_box finger point1">
                                    <img src={datamapFinger} alt="finger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="point">
                        <div className="img_box letter">
                            <img src={point2} alt="point2" />
                        </div>
                        <div className="box">
                            <h2>
                                <p>다시 클릭 시</p>
                                <p className="red">지도 고정 모드로 변경돼요</p>
                            </h2>
                            <div className="videoBox">
                                <video autoPlay loop muted playsInline>
                                    <source src={`${basePath}/video/event/datamap/datamapPoint2.mp4`} type="video/mp4" />
                                </video>
                                <div className="img_box finger point2">
                                    <img src={datamapFinger} alt="finger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="point">
                        <div className="img_box letter">
                            <img src={point3} alt="point3" />
                        </div>
                        <div className="box">
                            <h2>
                                <p>100m 이동하면</p>
                                <p className="red">지도 회전 모드로 변경돼요</p>
                            </h2>
                            <div className="videoBox">
                                <video autoPlay loop muted playsInline>
                                    <source src={`${basePath}/video/event/datamap/datamapPoint3.mp4`} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="point">
                        <div className="img_box letter">
                            <img src={point4} alt="point4" />
                        </div>
                        <div className="box">
                            <h2>
                                <p>지도 회전 모드에서는</p>
                                <p className="red">내 위치기반으로 따라가요</p>
                            </h2>
                            <div className="videoBox">
                                <video autoPlay loop muted playsInline>
                                    <source src={`${basePath}/video/event/datamap/datamapPoint4.mp4`} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </RootStyle>
    );
};

export default DatamapNewFnEvent;

const RootStyle = styled.div`
    position: relative;
    height: 100dvh;
    width: 100%;
    background: #052242;
    display: flex;
    flex-direction: column;
    color: #fff;

    .img_box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .closeBtn {
        position: fixed;
        right: 30px;
        top: 24px;
        cursor: pointer;

        img {
            cursor: pointer;
        }
    }

    .red {
        color: #ff2900;
    }

    .contents {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        padding: 120px 0 180px 0;

        &::-webkit-scrollbar {
            width: 0;
        }

        p {
            white-space: noWrap;
        }

        h1 {
            text-align: center;
            margin-bottom: 32px;
            p {
                font-size: 32px;
                line-height: 1.4;
            }
        }

        .datamap {
            width: 300px;
            height: 300px;
            margin-bottom: 131px;

            video {
                width: 100%;
                height: 100%;
            }
        }

        .pointBox {
            display: flex;
            flex-direction: column;
            gap: 210px;

            .point {
                .letter {
                    width: 83px;
                    height: 17px;
                    margin-bottom: 20px;
                }
                h2 {
                    width: 100%;
                    font-size: 24px;
                    line-height: 1.6;
                    text-align: left;
                }

                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 60px;

                    .videoBox {
                        position: relative;
                        video {
                            width: 300px;
                            height: 590px;
                            border: 4px solid #fff;
                            border-radius: 20px;
                            object-fit: cover;
                        }

                        .finger {
                            position: absolute;
                            width: 94px;
                            height: 94px;

                            &.point1 {
                                right: 28px;
                                top: 308px;
                            }
                            &.point2 {
                                transform: scaleX(-1);
                                right: 32px;
                                top: 110px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const TitleBoxStyle = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px;
    background: #fff;

    & .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
    }

    & .close-icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;
