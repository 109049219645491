import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery, UseQueryOptions } from "react-query";
import { LandUseBuildDto } from "./dto/landUse.dto";
import { useRef } from "react";

export const useGetMaxRatio = ({ pnu }: { pnu: string }) => {
    const getMaxRatio = async () => {
        const query = new URLSearchParams({ pnu });
        const res = await axiosInstance.get(`${axiosPath.landUse}/max/ratio?${query}`, { timeout: 30_000 });
        return res?.data;
    };

    return useQuery<number>(["landUseMaxRatio", pnu], getMaxRatio);
};

export const useGetLandUseBuild = (
    { pnu, buildRatio }: { pnu: string; buildRatio: number },
    options?: UseQueryOptions<LandUseBuildDto>,
) => {
    const controllerRef = useRef<AbortController | null>(null);

    const getLandUseBuild = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();

        const body = JSON.stringify({ pnu, buildRatio });
        const res = await axiosInstance.post(`${axiosPath.landUse}/build`, body, {
            timeout: 30_000,
            signal: controllerRef.current.signal,
        });
        return res?.data;
    };

    return useQuery<LandUseBuildDto>(["landUseBuild", { pnu, buildRatio }], getLandUseBuild, options);
};
