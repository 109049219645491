import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import ico_filter_white from "@src/assets/ico_filter_white.png";
import { Link, useSearchParams } from "react-router-dom";
import { Common } from "@src/styles/Common";
import ResetFilterButton from "@src/components/filter/ResetFilterButton";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import {
    initialFilterParams,
    useSearchedTempNameAndNoList,
    useSearchSavedLocationFilter,
    useSearchSavedUseFilter,
    useTempNameAndNoList,
} from "@src/store/newFilterStore";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import styled from "@emotion/styled";
import { useFilterStore } from "@src/store/filterStore";
import { usePositionStore } from "@src/store/positionStore";
import { getAuctionMasterItemsReversed } from "@src/constants/auctionMasterItemFilters";
import AuctionStatusFilter, { findKeyByValueFromStatus } from "@src/pages/mapFilter/AuctionStatusFilter";
import AuctionSkipFilter, { auctionSkipList } from "@src/pages/mapFilter/AuctionSkipFilter";
import RecentFilterHistory from "@src/pages/mapFilter/RecentFilterHistory";
import AuctionTypeFilter from "@src/pages/mapFilter/AuctionTypeFilter";
import LocationFilterV3Detail from "@src/pages/mapFilter/LocationFilterV3Detail";
import UseFilterV3Detail, { getMinorCategories } from "@src/pages/mapFilter/UseFilterV3Detail";
import AuctionMasterItemFilter from "@src/pages/mapFilter/AuctionMasterItemFilter";
import DueDateFilter from "@src/pages/mapFilter/DueDateFilter";
import EstPriceFilter from "@src/pages/mapFilter/EstPriceFilter";
import MinPriceFilter from "@src/pages/mapFilter/MinPriceFilter";
import AiResetConfirmModal from "@src/pages/aiFilter/AiResetConfirmModal";
import AuctionCounterForceFilter, { getAuctionCounterForceListReversed } from "@src/pages/mapFilter/AuctionCounterForceFilter";

interface Props {
    openOutFilter: string;
    setOpenOutFilter: Dispatch<SetStateAction<string>>;
}

const OutFilter: React.FC<Props> = ({ openOutFilter, setOpenOutFilter }) => {
    const [searchParam, setSearchParam] = useSearchParams();
    const filterState = useFilterStore((state) => state.filterState);
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const outSelectedSido = useFilterStore((state) => state.selectedOutSido);
    const setOutSelectedSido = useFilterStore((state) => state.setSelectedOutSido);
    const setPositionStore = usePositionStore((state) => state.setPosition);

    const savedLocation = useSearchSavedLocationFilter((state) => state.filterLocationState);
    const setSavedLocation = useSearchSavedLocationFilter((state) => state.setFilterLocationState);
    const savedUseList = useSearchSavedUseFilter((state) => state.filterUseState);
    const setSavedUseList = useSearchSavedUseFilter((state) => state.setFilterUseState);
    const setTempNameAndNo = useSearchedTempNameAndNoList((state) => state.setTempNameAndNoListState);

    const tabButtons = [
        {
            title: "최근검색조건",
            key: "history",
            selected: false,
            selectedChild: "",
        },
        {
            title: "진행상태",
            key: "status",
            selected: !!filterState.status.length,
            selectedChild:
                filterState.status.filter((status) => status !== 2).length > 1
                    ? `${findKeyByValueFromStatus(filterState.status[0])} 외 ${
                          filterState.status.filter((status) => status !== 2).length - 1
                      }`
                    : `${findKeyByValueFromStatus(filterState.status[0])}`,
        },
        {
            title: "소재지",
            key: "loc",
            selected: outSelectedSido !== "all" || filterState?.loc?.length > 0,
            selectedChild: "소재지",
        },
        {
            title: "경공매",
            key: "type",
            selected: !!filterState.type,
            selectedChild: filterState.type === 1 ? "경매" : "공매",
        },
        {
            title: "용도",
            key: "use",
            selected: !!filterState.use.length,
            selectedChild: "용도",
        },
        {
            title: "대항력",
            key: "counter_force_list",
            selected: filterState.counter_force_list.length !== 0,
            selectedChild:
                filterState.counter_force_list.length > 1
                    ? `${getAuctionCounterForceListReversed()[filterState.counter_force_list[0]]} 외 ${
                          filterState.counter_force_list.length - 1
                      }`
                    : `${getAuctionCounterForceListReversed()[filterState.counter_force_list[0]]}`,
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: !!filterState.auction_master_item.length,
            selectedChild:
                filterState.auction_master_item.length > 1
                    ? `${getAuctionMasterItemsReversed()[filterState.auction_master_item[0]]} 외 ${
                          filterState.auction_master_item.length - 1
                      }${filterState.auction_master_except ? " 제외" : ""}`
                    : `${getAuctionMasterItemsReversed()[filterState.auction_master_item[0]]}${
                          filterState.auction_master_except ? " 제외" : ""
                      }`,
        },
        {
            title: "유찰횟수",
            key: "skp_type",
            selected: !!filterState.skp_type,
            selectedChild:
                `${auctionSkipList[filterState.skp_type]}` +
                `${filterState.skp_type === 2 ? ` ${filterState.skp_cnt[0]}` : ""}` +
                `${filterState.skp_cnt.some((val) => val) && filterState.skp_cnt[0] !== filterState.skp_cnt[1] ? " ~" : ""}` +
                `${
                    filterState.skp_type === 2 && filterState.skp_cnt[0] !== filterState.skp_cnt[1]
                        ? ` ${filterState.skp_cnt[1]}`
                        : ""
                }`,
        },
        {
            title: "매각기일",
            key: "due_date",
            selected: !!filterState.due_date.every((val) => val),
            selectedChild:
                filterState.due_date[0] !== filterState.due_date[1]
                    ? `${filterState.due_date[0]?.slice(2)} ~ ${filterState.due_date[1]?.slice(2)}`
                    : `${filterState.due_date[0]?.slice(2)}`,
        },
        {
            title: "감정가",
            key: "est_price",
            selected: !!filterState.est_price.some((val) => val),
            selectedChild:
                `감정가 ${
                    itemForAppraisedPrice.filter(
                        (val) => val.value === (filterState.est_price[0] === "30000000000" ? "higher" : filterState.est_price[0]),
                    )?.[0]?.showText
                } ~ ` +
                `${
                    filterState.est_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterState.est_price[1])?.[0]?.title
                }`,
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: !!filterState.min_price.some((val) => val),
            selectedChild:
                `최저입찰가 ${
                    itemForAppraisedPrice.filter(
                        (val) => val.value === (filterState.min_price[0] === "30000000000" ? "higher" : filterState.min_price[0]),
                    )?.[0]?.showText
                } ~ ` +
                `${
                    filterState.min_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterState.min_price[1])?.[0]?.title
                }`,
        },
    ];

    const onClickDone = () => {
        // 얘 혼자만 changeSearchItemsFilter 사용함
        transferToNative(JSON.stringify(filterState), "changeSearchItemsFilter");
        setOpenOutFilter("");
    };

    useEffect(() => {
        WebAppBridge.setUserLocationInMap = (lat: string, lng: string) => {
            setPositionStore({
                lat,
                lng,
            });
        };
    }, []);

    useEffect(() => {
        if (searchParam.get("close")) {
            onClickDone();
            const currentParams = Object.fromEntries(searchParam);
            delete currentParams?.close;
            setSearchParam(currentParams);
        }
    }, [searchParam.get("close")]);

    WebAppBridge.filterDone = () => {
        setOpenOutFilter("");
    };

    useEffect(() => {
        let newLoc = {
            ...filterState,
            loc: savedLocation ? [savedLocation.value] : [],
        };
        setStoreFilterState(newLoc);
    }, [savedLocation]);

    useEffect(() => {
        const updated = savedUseList
            .map((use) => {
                // 해당 용도의 전체일 경우
                if (use.value.length === 1) {
                    // 용도가 기타일 경우 예외 처리
                    if (use.value === "E") {
                        return ["E01"];
                    } else {
                        return getMinorCategories(use.value)
                            .map((item) => item.value)
                            .filter((item) => item.length !== 1);
                    }
                } else {
                    return use.value;
                }
            })
            .flat();
        const copy = { ...filterState, use: updated };
        setStoreFilterState(copy);
    }, [savedUseList]);

    return (
        <div css={rootStyle}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <div css={tabListBoxStyle(isShowFilter)}>
                    <Link
                        to="/allFilter/search"
                        css={allFilterButtonStyle}
                        onClick={() => {
                            transferToNative("", "setFilterStart");
                        }}
                    >
                        <img src={ico_filter_white} />
                        <span>매물상세검색설정</span>
                    </Link>
                    <div className="filter_button_scroll_wrapper">
                        {tabButtons.map((item, index) => (
                            <TabButton
                                isSelected={openOutFilter === item.key || item.selected}
                                disabled={false}
                                key={`${item}_${index}`}
                                onClick={() => {
                                    transferToNative("", "setFilterStart");
                                    if (openOutFilter === item.key) {
                                        onClickDone();
                                    } else {
                                        setOpenOutFilter(item.key);
                                    }
                                }}
                            >
                                <span>{item.selected ? item.selectedChild : item.title}</span>
                            </TabButton>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ background: "#FFF" }}>
                {openOutFilter && (
                    <>
                        <div css={dividerStyle} />
                        <div css={whiteDividerStyle} />
                    </>
                )}
                {openOutFilter === "history" && (
                    <RecentFilterHistory
                        onClose={() => setOpenOutFilter("")}
                        setFilter={setStoreFilterState}
                        setSavedLocation={setSavedLocation}
                        setSavedUseList={setSavedUseList}
                        setTempNameAndNo={setTempNameAndNo}
                    />
                )}
                {openOutFilter === "type" && (
                    <AuctionTypeFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "loc" && (
                    <LocationFilterV3Detail
                        isNotParfOfMainFilter={false}
                        backFn={onClickDone}
                        setSavedLocation={setSavedLocation}
                    />
                )}
                {openOutFilter === "use" && (
                    <UseFilterV3Detail
                        isNotParfOfMainFilter={false}
                        backFn={onClickDone}
                        savedUseList={savedUseList}
                        setSavedUseList={setSavedUseList}
                    />
                )}
                {openOutFilter === "counter_force_list" && (
                    <AuctionCounterForceFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "auction_master_item" && (
                    <AuctionMasterItemFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "status" && (
                    <AuctionStatusFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "skp_type" && (
                    <AuctionSkipFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "due_date" && (
                    <DueDateFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "est_price" && (
                    <EstPriceFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "min_price" && (
                    <MinPriceFilter
                        filterParams={filterState}
                        setFilterParams={setStoreFilterState}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter && openOutFilter !== "history" && (
                    <ResetFilterButton onResetClick={() => setResetModalVisible(true)} onConfirmClick={onClickDone} />
                )}
            </div>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={() => {
                        setStoreFilterState(initialFilterParams);
                        setResetModalVisible(false);
                        setOutSelectedSido("all");
                        setSavedLocation(null);
                        setSavedUseList([]);
                        setOpenOutFilter("");
                        setTempNameAndNo([]);
                        transferToNative(JSON.stringify(initialFilterParams), "setFilterDone");
                    }}
                />
            )}
            {openOutFilter && <div css={{ height: "100%", background: "rgba(0,0,0,1)", opacity: 0.3 }} onClick={onClickDone} />}
        </div>
    );
};

export default OutFilter;

const rootStyle = css`
    width: 100vw;
    height: calc(100dvh - 62px);
    display: flex;
    flex-direction: column;
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 0 14px;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255,2 55, 0"};
`;

const tabListBoxStyle = (isShowFilter: boolean) => css`
    display: flex;
    align-items: center;
    height: ${isShowFilter ? "56px" : "0px"};
    opacity: ${isShowFilter ? 1 : 0};
    visibility: ${isShowFilter ? "visible" : "hidden"};
    transition: all 0.2s ease;
    z-index: 3;

    button + button {
        margin-left: 8px;
    }
    & > a {
        padding: 8px 16px;
        min-width: fit-content;
        & > button {
            opacity: ${isShowFilter ? 1 : 0};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${isShowFilter ? "visible" : "hidden"};
            height: ${isShowFilter ? "30px !important" : "0px"};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${isShowFilter ? "32px" : "0px"};
        opacity: ${isShowFilter ? 1 : 0};
        visibility: ${isShowFilter ? "visible" : "hidden"};
        transition: all 0.2s ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const allFilterButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }
    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const dividerStyle = css`
    height: 12px;
    background: rgb(236, 239, 244);
`;

const whiteDividerStyle = css`
    height: 10px;
    background-color: #ffffff;
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled }) => (disabled ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled }) =>
        disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled }) =>
            disabled ? `${Common.colors.grey04}` : isSelected ? ` ${Common.colors.white}` : `${Common.colors.normalTextColor}`};
    }
`;
