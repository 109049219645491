import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { GetItemListType } from "@src/hooks/item/useGetItem";
import { transferToNative } from "@src/lib/WebAppBridge";
import create from "zustand";
import { configurePersist } from "zustand-persist";
import { IFilterParamsType, initialFilterParams } from "./newFilterStore";

type useFilterStoreType = {
    filterState: IFilterParamsType;
    setStoreFilterState: (filterState: IFilterParamsType, isSave?: boolean) => void;
    setDimVisible: (visible: boolean) => void;
    setFilterVisible: (visible: boolean) => void;
    getFilterParams: () => IFilterParamsType;
    listData: GetItemListType[];
    dimVisible: boolean;
    updateHistoryFilterFlag: boolean;
    setUpdateHistoryFilterFlag: (flag: boolean) => void;
    filterHistory: {
        updated_at: string;
        value: IFilterParamsType;
        no: string;
    }[];
    filterVisible: boolean;
    getFilterHistory: () => void;
    searchKeyword: string;
    setSearchKeyword: (searchKeyword: string) => void;
    postFilterHistory: (filterState: any) => void;
    selectedOutSido: string;
    setSelectedOutSido: (val: string) => void;
    // setFilterHistory: (filterState: any) => void;
};

const { persist } = configurePersist({
    storage: localStorage, // use `AsyncStorage` in react native
    rootKey: "filterState", // optional, default value is `root`
});

export const useFilterStore = create<useFilterStoreType>(
    // persist(
    //     {
    //         key: "filterState",
    //         allowlist: ["filterState"],
    //         denylist: [],
    //     },
    (set: any) =>
        ({
            filterState: initialFilterParams,
            listData: [],
            selectedOutSido: "all",
            dimVisible: false,
            filterVisible: false,
            updateHistoryFilterFlag: false,
            searchKeyword: "",
            setSelectedOutSido: (val: string) => {
                set(() => {
                    return { selectedOutSido: val };
                });
            },
            setFilterVisible: (filterVisible: boolean) => {
                set(() => {
                    return { filterVisible: filterVisible };
                });
            },
            setDimVisible: (visible: boolean) => {
                set(() => {
                    return { dimVisible: visible };
                });
            },

            getFilterParams: async () => {
                const response = await axiosInstance.get(axiosPath.saveFilter);
                return response.data.payload.data.value;
            },

            postFilterHistory: async (filterState: any) => {
                await axiosInstance.post(axiosPath.RecentFilter, { value: filterState });
            },

            setStoreFilterState: async (filterState: any, isSave: boolean) => {
                // if (isSave) {
                //     await axiosInstance.post(axiosPath.saveAiFilterV2, {
                //         value: { ...filterState, use: makeUseParam(filterState.use), loc: removeAll(filterState.loc) },
                //     });
                // }

                set(() => {
                    return {
                        filterState: {
                            ...filterState,
                            est_price: makePriceParam(filterState.est_price),
                            min_price: makePriceParam(filterState.min_price),
                        },
                    };
                });
            },

            filterHistory: [],
            getFilterHistory: async () => {
                const response = await axiosInstance.get(axiosPath.RecentFilter);
                const resultResponse = response?.data?.payload?.list?.filter((val: any) => val?.value?.type !== undefined);

                set(() => {
                    return {
                        filterHistory: resultResponse,
                    };
                });
            },
            setUpdateHistoryFilterFlag: (flag: boolean) => {
                set(() => {
                    return {
                        updateHistoryFilterFlag: flag,
                    };
                });
            },
            setSearchKeyword: (text: string) => {
                set(() => {
                    return { searchKeyword: text };
                });
            },
        } as any),
    // ) as any,
);

export const makePriceParam = (array: string[]) => {
    if (array.includes("higher")) return ["30000000000", ""];

    return array;
};

export const removeAll = (array: string[]) => {
    const result = array.map((item) => {
        return item.replaceAll("All", "");
    });

    return result;
};

type useSidoFilterStoreType = {
    selectedSido: any;
    setSelectedSido: (sido: any) => void;
};

export const useSidoFilterStore = create<useSidoFilterStoreType>(
    (set) =>
        ({
            selectedSido: "all",
            setSelectedSido: (sido: any) => {
                set(() => {
                    return { selectedSido: sido };
                });
            },
        } as any),
);
