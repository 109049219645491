import React, { Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import LineChart from "@src/components/chart/LineChart";
import Table from "@src/components/table/Table";
import Title from "@src/components/title/Title";
import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import convertStrToPrice from "@src/util/convertStrToPrice";
import { useFindOneRoadWidth } from "@src/hooks/map/useFindRoadWidth";
import stateLandServiceShowBox from "@src/assets/fieldDetail/stateLandServiceShowBox.png";
import { landChangeHeaderInLandDetail, landPriceHeaderInLandDetail } from "./getColums";
import { useFindOneStateLandInfo } from "@src/hooks/stateLand/useGetStateLand";
import { format } from "date-fns";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";
import callIcon from "@src/assets/basicIcon/callIcon.svg";
import sliderIcon from "@src/assets/sliderIcon.svg";
import BuildingSimulation from "@src/components/three";
import { Slider, SliderThumb, styled } from "@mui/material";
import { useGetMaxRatio } from "@src/hooks/landUse/useLandUse";
import { useLandUseData } from "../../components/three/hooks/useLandUseData";
import Lottie from "lottie-react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import forbiddenImg from "@src/assets/forbidden.png";
import phoneImg from "@src/assets/phone.png";

interface landDetailProps {
    data: any;
    pnu: string;
    landInfoRef: any;
    stateLandInfoRef: any;
    areaSize?: { pyong: string; square: string };
    fieldJimok: string;
    eumMapLinks: any;
}

//* 국유지
const onbidURL = "https://www.onbid.co.kr/op/ipa/gvwsmn/governmentOwnershipPropertyDetail.do?cltrNo=";
//* 공유지
const onbidURL2 = "https://www.onbid.co.kr/op/ipa/infomn/informationPublicPropertyDetail.do?searchDetailId=";
// 건축 제한 사유
const CONSTRUCTION_BAN_REASONS = {
    0: {
        image: forbiddenImg,
        text: "서버에 연결할 수 없습니다.\n잠시 후 다시 시도해주세요.",
    },
    1: {
        image: forbiddenImg,
        text: "이 토지는 국가 또는 지방자치단체 소유의 공유지로, 건축이 제한됩니다.",
    },
    2: {
        image: forbiddenImg,
        text: "이 토지는 법정 기준(4m 이상 도로에 2m 이상 접함)을 충족하지 못해 건축이 제한됩니다.",
    },
    3: {
        image: forbiddenImg,
        text: "이 토지는 농업진흥구역 내 농지로,\n농업 목적 외 건축이 제한됩니다.",
    },
    4: {
        image: forbiddenImg,
        text: "이 토지는 생태적 가치가 높은 비오톱 1등급 지역으로, 환경 보호를 위해 건축이 제한됩니다.",
    },
    5: {
        image: forbiddenImg,
        text: "이 토지는 보전 산지로 지정되어 있어 건축이 제한됩니다.",
    },
    6: {
        image: forbiddenImg,
        text: "이 토지는 급경사 임야로, 건축이 제한됩니다.",
    },
    7: {
        image: forbiddenImg,
        text: "다세대, 아파트, 집합건물에 접한 토지는 시뮬레이션을 제공하지 않습니다.",
    },
    8: {
        image: forbiddenImg,
        text: "이 토지는 해당 용도지역의 최소 필지 크기 기준에 미달하여 건축이 제한됩니다.",
    },
    9: {
        image: forbiddenImg,
        text: "이 토지는 개발제한구역(그린벨트) 내에 위치하여 건축이 제한됩니다.",
    },
    10: {
        image: forbiddenImg,
        text: "이 토지는 하천부지로 지정되어 있어,\n수자원 보호를 위해 건축이 제한됩니다.",
    },
    11: {
        image: forbiddenImg,
        text: "이 토지는 비행안전 1구역 내에 위치하여 항공기 안전을 위해 건축이 제한됩니다.",
    },
    12: {
        image: forbiddenImg,
        text: "이 토지는 경관보전지구 1등급으로 지정되어 있어, 자연경관 보호를 위해 건축이 제한됩니다.",
    },
    13: {
        image: phoneImg,
        text: "해당 필지는 몇 층까지 건축 가능한 토지인지 알 수 없습니다. 건축사에게 문의하세요.",
    },
};

const highlightTest = /^(대부\/매각대상|매각대상|매각\(입찰\)가능재산|대부\(입찰\)가능재산|대부대상)/;
const rentTest = /(대부\(입찰\)가능재산|대부대상)/;
const allTest = /(대부\/매각대상)/;

function LandDetail({ data, pnu, landInfoRef, stateLandInfoRef, areaSize, fieldJimok, eumMapLinks }: landDetailProps) {
    const INVALID = "-";
    let { base, landUsePlan, officialPrice } = data;
    const [sizeUnit, setSizeUnit] = useState<"pyeong" | "meter">("meter");
    const handleCovert = (type: "pyeong" | "meter") => {
        setSizeUnit(type === "meter" ? "pyeong" : "meter");
    };
    const dateConvert = (date: any) => {
        if (typeof date === "string") {
            if (date !== " ") return date.replaceAll("-", ".");
        }
        return INVALID;
    };
    const stringValidChecker = (input: any): string => {
        if (typeof input === "string") {
            if (input !== " " && input !== "") return input;
            return INVALID;
        }
        return INVALID;
    };
    //토지이용계획
    const LandChangeTableHeader = landChangeHeaderInLandDetail();
    const LandPriceTableHeader = landPriceHeaderInLandDetail(sizeUnit);
    const [landChangeAllTable, setLandChangeAllTable] = useState([{ date: "1990.11.11", reason: "" }]);
    const [landChangeViewingData, setLandChangeViewingData] = useState<any[]>([]);

    //개별공시지가
    const [officialPriceChart, setOfficialPriceChart] = useState<{
        xAxis: string[];
        chartData: string[];
    }>({ xAxis: [], chartData: [] });
    const [officialPriceTable, setOfficialPriceTable] = useState([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
    const [officialPriceViewingData, setOfficialPriceViewingData] = useState<any[]>([]);

    //토지 활용도 분석
    const [ratio, setRatio] = useState(20); // 건폐율
    const [debouncedRatio, setDebouncedRatio] = useState(ratio);
    const [isSliderSelected, setIsSliderSelected] = useState(false);
    const { data: maxRatio } = useGetMaxRatio({ pnu: pnu ?? "" });
    const {
        data: landUseData,
        isLoading: isLandUseLoading,
        isFetching: isLandUseFetching,
        isError: isLandUseError,
        error: landUseError,
    } = useLandUseData({ pnu: pnu ?? "", debouncedRatio });

    const officialPriceChartData = () => {
        if (!officialPrice?.length) {
            setOfficialPriceChart({
                xAxis: [],
                chartData: [],
            });
        }
        let xAxis: string[] = [];
        let chartData: string[] = [];
        officialPrice.map((item: any) => {
            xAxis.push(item.stdrYear);
            chartData.push(item.pblntfPclnd);
        });

        setOfficialPriceChart({ xAxis, chartData });
    };
    const officialPyeongPriceChartData = () => {
        if (!officialPrice?.length) {
            setOfficialPriceChart({
                xAxis: [],
                chartData: [],
            });
        }
        let xAxis: string[] = [];
        let chartData: string[] = [];
        officialPrice.map((item: any) => {
            xAxis.push(item.stdrYear);
            chartData.push(Math.round(+item.pblntfPclndPyeong).toString());
        });

        setOfficialPriceChart({ xAxis, chartData });
    };
    const officialPriceTableData = () => {
        if (!officialPrice?.length) setOfficialPriceTable([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
        let result: any[] = [];
        officialPrice.map((item: any) => {
            const { stdrYear, stdrMt, pblntfPclnd } = item;
            result.push({
                stdr: `${stdrYear}.${stdrMt}`,
                pblntfPclnd: `${convertStrToPrice(pblntfPclnd)}원`,
            });
        });

        setOfficialPriceTable(result.reverse());
        setOfficialPriceViewingData(result?.slice(0, 5) ?? []);
    };
    const officialPyeongPriceTableData = () => {
        if (!officialPrice?.length) setOfficialPriceTable([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
        let result: any[] = [];
        officialPrice.map((item: any) => {
            const { stdrYear, stdrMt, pblntfPclndPyeong } = item;
            result.push({
                stdr: `${stdrYear}.${stdrMt}`,
                pblntfPclnd: `${convertStrToPrice(pblntfPclndPyeong)}원`,
            });
        });

        setOfficialPriceTable(result.reverse());
        setOfficialPriceViewingData(result?.slice(0, 5) ?? []);
    };

    //* roadWidth
    const { data: roadWidth, refetch: refetchRoadWidth } = useFindOneRoadWidth(pnu);

    //* 국공유지 정보
    const { data: stateLandInfo, refetch: refetchStateLandInfo } = useFindOneStateLandInfo(pnu);

    const onClickToOnbid = () => {
        if (stateLandInfo) {
            const newTitle = rentTest.test(stateLandInfo?.status ?? "")
                ? "대부(임대) 신청하기"
                : allTest.test(stateLandInfo?.status ?? "")
                ? "대부/매각 신청하기"
                : "불하(매각) 신청하기";
            const newUrl = stateLandInfo?.propType?.includes("공유")
                ? `${onbidURL2}${stateLandInfo.kamcoId}`
                : `${onbidURL}${stateLandInfo.kamcoId}`;
            const params = {
                title: newTitle,
                url: newUrl,
            };
            transferToNative(JSON.stringify(params), "openExternalMap");
        }
    };

    useEffect(() => {
        refetchStateLandInfo();
        if (base.lndcgrCodeNm === "도로") {
            refetchRoadWidth();
        }
    }, [data]);

    useEffect(() => {
        officialPyeongPriceChartData();
        officialPyeongPriceTableData();
    }, [officialPrice]);
    useEffect(() => {
        setLandChangeViewingData(landUsePlan.table.slice(0, 5));
        setLandChangeAllTable(landUsePlan.table);
    }, [landUsePlan]);

    useEffect(() => {
        if (sizeUnit === "meter") {
            officialPriceChartData();
            officialPriceTableData();
        } else {
            officialPyeongPriceChartData();
            officialPyeongPriceTableData();
        }
    }, [sizeUnit]);

    return (
        <div css={landDetailWrap} id={"land"}>
            <section>
                <div className="titleBoxWithButton">
                    <Title title={"토지정보"} size={"large"} />
                    <ConvertPyeongButton handleConvert={handleCovert} />
                </div>
                <ul className="base" ref={landInfoRef}>
                    <li>
                        <span className="name">지목</span>
                        <span className="value">{stringValidChecker(fieldJimok)}</span>
                    </li>
                    {base?.lndcgrCodeNm === "도로" && (
                        <li>
                            <p className="newBox">
                                <span className="name newName">도로폭</span>
                                {/* <span className="new">NEW</span> */}
                            </p>
                            <span className="value">{roadWidth ? `${roadWidth}m` : INVALID}</span>
                        </li>
                    )}
                    <li>
                        <span className="name">면적</span>
                        <span className="value">
                            {sizeUnit === "meter"
                                ? `${
                                      isShowLabel(base?.lndpclAr?.square ?? "")
                                          ? base?.lndpclAr?.square
                                          : isShowLabel(areaSize?.square ?? "")
                                          ? areaSize?.square
                                          : "-"
                                  }`
                                : `${
                                      isShowLabel(base?.lndpclAr?.pyong ?? "")
                                          ? base?.lndpclAr?.pyong
                                          : isShowLabel(areaSize?.pyong ?? "")
                                          ? areaSize?.pyong
                                          : "-"
                                  }`}
                            {/* {sizeUnit === "meter"
                                ? `${
                                      isShowLabel(areaSize?.square ?? "")
                                          ? areaSize?.square
                                          : isShowLabel(base?.lndpclAr?.square ?? "")
                                          ? base?.lndpclAr?.square
                                          : "-"
                                  }`
                                : `${
                                      isShowLabel(areaSize?.pyong ?? "")
                                          ? areaSize?.pyong
                                          : isShowLabel(base?.lndpclAr?.pyong ?? "")
                                          ? base?.lndpclAr?.pyong
                                          : "-"
                                  }`} */}
                        </span>
                    </li>
                    <li>
                        <span className="name">이용상황</span>
                        <span className="value">{stringValidChecker(base.ladUseSittnNm)}</span>
                    </li>
                    <li>
                        <span className="name">용도지역</span>
                        <span className="value">{stringValidChecker(base.use)}</span>
                    </li>
                    <li>
                        <p className="newBox">
                            <span className="name newName">고도</span>
                            {/* <span className="new">NEW</span> */}
                        </p>
                        <span className="value">{stringValidChecker(base.altitude)}</span>
                    </li>
                    {/* <li>
                        <span className="name">공시지가</span>
                        <p>
                            <span className="value blue">
                                {convertStrToPrice(sizeUnit === "meter" ? base.pblntfPclnd : base.pblntfPclndPyeong)}원 (
                                {sizeUnit === "meter" ? "㎡" : "평"}당)
                            </span>
                            <span className="date">기준일자 {dateConvert(base.lastUpdtDt)}</span>
                        </p>
                    </li> */}
                    <li>
                        <span className="name">지형</span>
                        <span className="value">{stringValidChecker(base.tpgrphHgCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">형상</span>
                        <span className="value">{stringValidChecker(base.tpgrphFrmCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">도로</span>
                        <span className="value">{stringValidChecker(base.roadSideCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">소유구분</span>
                        <span className="value">
                            {stringValidChecker(base.posesnSeCodeNm)}
                            {base.cnrsPsnCo && stringValidChecker(base.cnrsPsnCo)}
                        </span>
                    </li>
                    <li>
                        <span className="name">소유권변동일</span>
                        <span className="value">{dateConvert(base.ownshipChgDe)}</span>
                    </li>
                    <li>
                        <span className="name">소유권변동원인</span>
                        <span className="value">{stringValidChecker(base.ownshipChgCauseCodeNm)}</span>
                    </li>
                </ul>
            </section>
            {stateLandInfo?.isShow && (
                <section style={{ position: "relative" }}>
                    <div css={stateLandScrollBoxStyle} ref={stateLandInfoRef} />
                    <p css={stateLandTitleStyle}>
                        국∙공유지 정보
                        {!!stateLandInfo?.updatedAt && (
                            <span>{format(new Date(stateLandInfo?.updatedAt ?? new Date()), "yy년 M월 d일")} 업데이트됨</span>
                        )}
                    </p>
                    <div css={stateLandBoxStyle(highlightTest.test(stateLandInfo?.status ?? ""))}>
                        <div className="box">
                            <p className="title">소유구분</p>
                            <p className="desc">{stateLandInfo?.ownedBy}</p>
                        </div>
                        <div className="box">
                            <p className="title">관리상태</p>
                            <p className="desc">
                                <span className="status">{stateLandInfo?.status ?? "-"}</span>
                            </p>
                        </div>
                        <div className="box alignStart" css={{ height: "fit-content !important" }}>
                            <p className="title">재산관리기관</p>
                            <p className="desc wrapStyle">
                                {stateLandInfo?.managedBy ?? "-"}
                                <br />
                                <span>
                                    <img src={callIcon} alt="call" width={16} />
                                    <a href={`tel://${stateLandInfo?.contact}`}>{stateLandInfo?.contact}</a>
                                    {stateLandInfo?.contactSuffix}
                                </span>
                            </p>
                        </div>
                        {!stateLandInfo?.isPremeumUser && (
                            <img
                                src={stateLandServiceShowBox}
                                alt=""
                                className="lockImg"
                                width={180}
                                height={112}
                                onClick={() => {
                                    transferToNative("msgaapp://deeplink/product/state_land", "onClickBottomButton");
                                }}
                            />
                        )}
                        {highlightTest.test(stateLandInfo?.status ?? "") && (
                            <button css={stateLandButtonStyle} onClick={onClickToOnbid}>
                                {rentTest.test(stateLandInfo?.status ?? "")
                                    ? "대부(임대) 신청하기"
                                    : allTest.test(stateLandInfo?.status ?? "")
                                    ? "대부/매각 신청하기"
                                    : "불하(매각) 신청하기"}
                            </button>
                        )}
                    </div>
                </section>
            )}
            <section className="landUsage">
                <Title title={"이 땅 몇 층까지 가능? 3D 시뮬레이션"} size={"large"} />

                <div className="threeWrapper">
                    {(() => {
                        // 첫 로딩 시
                        if (isLandUseLoading) {
                            return (
                                <div className="loadingBox">
                                    <Lottie animationData={lottie_loading} />
                                </div>
                            );
                        }
                        // 건축 가능한 경우
                        if (landUseData?.result === 200) {
                            return (
                                <>
                                    <BuildingSimulation />
                                    {isLandUseFetching && (
                                        <div className="calculatingSpinner">
                                            <Spinner size="md" />
                                        </div>
                                    )}
                                </>
                            );
                        }
                        // 타임 아웃
                        if (isLandUseError && (landUseError as any).code === "ECONNABORTED") {
                            return (
                                <div className="forbidden">
                                    <img src={forbiddenImg} alt="건축 불가" width={140} height={140} />
                                    <span>
                                        요청이 시간 초과되었습니다.
                                        <br />
                                        네트워크 상태를 확인하고 다시 시도해 주세요.
                                    </span>
                                </div>
                            );
                        }
                        // 건축이 불가능한 경우
                        return (
                            <div className="forbidden">
                                <img
                                    src={CONSTRUCTION_BAN_REASONS[landUseData?.result ?? 0].image}
                                    alt="건축 불가"
                                    width={140}
                                    height={140}
                                />
                                <span>
                                    {CONSTRUCTION_BAN_REASONS[landUseData?.result ?? 0].text.split("\n").map((line, index) => (
                                        <Fragment key={index}>
                                            {line}
                                            <br />
                                        </Fragment>
                                    ))}
                                </span>
                            </div>
                        );
                    })()}
                </div>

                {(isLandUseLoading || landUseData?.result === 200) && (
                    <div className="content">
                        {data?.ancientLights && (
                            <span className="info" style={{ marginTop: "8px" }}>
                                ※ 해당 입지는 일조권 적용 대상입니다.
                            </span>
                        )}

                        <table className="firstTable">
                            <colgroup>
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td className="navy">건물규모</td>
                                    <td className="navy">용적률</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isLandUseFetching) {
                                                    return <Spinner />;
                                                }
                                                if (landUseData?.result === 200) {
                                                    return <span className="floor">지상 {landUseData?.totalFloor}층</span>;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isLandUseFetching) {
                                                    return <Spinner />;
                                                }
                                                if (landUseData?.result === 200) {
                                                    return `약 ${landUseData?.floorAreaRatio}%`;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="secondTable">
                            <colgroup>
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td className="navy">1층 면적</td>
                                    <td className="navy">건폐율</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isLandUseFetching) {
                                                    return <Spinner />;
                                                }
                                                if (landUseData?.result === 200) {
                                                    return landUseData?.buildPolygon?.[0]?.area_pyong;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        {landUseData?.result === 200 ? <>약 {Math.floor(ratio)}%</> : <>-</>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="slider">
                            <span className="minRatio">
                                <span>최소</span>
                                <br />
                                20%
                            </span>
                            <StyledSlider
                                value={ratio}
                                min={20}
                                max={maxRatio}
                                disabled={landUseData?.result !== 200 || maxRatio === 20}
                                slots={{ thumb: StyledSliderThumb }}
                                step={10}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value: number) => {
                                    return `${value}%`;
                                }}
                                onChange={(_, newValue) => setRatio(newValue as number)}
                                onPointerDown={() => {
                                    setIsSliderSelected(true);
                                }}
                                onChangeCommitted={(_, newValue) => {
                                    setIsSliderSelected(false);
                                    setDebouncedRatio(newValue as number);
                                }}
                            />
                            <span className="maxRatio">
                                <span>최대</span>
                                <br />
                                {maxRatio ?? " - "}%
                            </span>
                        </div>
                        <span className="info">
                            ※ 산정된 층수는 모든 법률적 요건을 계산한 결과가 아닙니다.
                            <br /> 본 서비스는 법적 효력이 없으며, 참고 자료로만 활용이 가능합니다.
                        </span>
                    </div>
                )}
            </section>
            <section>
                <Title title={"토지이용계획"} size={"large"} />
                <ul>
                    <li>
                        <Title title={"국토의 계획 및 이용에 관한 법률"} size={"small"} />
                    </li>
                    <li>
                        <span className="name">포함</span>
                        <span className="value">{landUsePlan.landLaw.inclusion}</span>
                    </li>
                    <li>
                        <span className="name">저촉</span>
                        <span className="value">{landUsePlan.landLaw.conflict}</span>
                    </li>
                    <li>
                        <span className="name">접함</span>
                        <span className="value">{landUsePlan.landLaw.connect}</span>
                    </li>
                </ul>
                <ul
                    style={{
                        borderTop: `6px solid ${Common.colors.grey02}`,
                        borderBottom: `6px solid ${Common.colors.grey02}`,
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        marginBottom: "0px",
                    }}
                >
                    <li>
                        <Title title={"기타법률"} size={"small"} />
                    </li>
                    <li>
                        <span className="name">포함</span>
                        <span className="value">{landUsePlan.etcLaw.inclusion}</span>
                    </li>
                    <li>
                        <span className="name">저촉</span>
                        <span className="value">{landUsePlan.etcLaw.conflict}</span>
                    </li>
                    <li>
                        <span className="name">접함</span>
                        <span className="value">{landUsePlan.etcLaw.connect}</span>
                    </li>
                </ul>
                <div className="titleBoxWithButton indLandPrice">
                    <Title title="개별공시지가" size={"large"} />
                    <ConvertPyeongButton handleConvert={handleCovert} />
                </div>
                {officialPriceChart.chartData.length ? (
                    <>
                        <div style={{ padding: "0", marginBottom: "0" }}>
                            <LineChart
                                xAxis={officialPriceChart.xAxis}
                                chartData={officialPriceChart.chartData}
                                isPyeong={sizeUnit === "pyeong"}
                            />
                        </div>
                        <div css={landPriceTableWrap({ tableWrap })}>
                            <Title title="연도별 공시지가" size={"small"} />
                            <Table
                                color={"gray"}
                                columns={LandPriceTableHeader}
                                data={officialPriceViewingData}
                                dataList={officialPriceTable}
                                setData={setOfficialPriceViewingData}
                            />
                        </div>
                    </>
                ) : (
                    <div css={nullBox} style={{ paddingBottom: "24px" }}>
                        개별공시지가 정보가 존재하지 않습니다
                    </div>
                )}
            </section>
            <section>
                <div css={tableWrap}>
                    <Title title="토지 이동(변동) 사유" size={"small"} />

                    {landChangeAllTable.length !== 0 ? (
                        <Table
                            color={"gray"}
                            columns={LandChangeTableHeader}
                            data={landChangeViewingData}
                            dataList={landChangeAllTable}
                            setData={setLandChangeViewingData}
                        />
                    ) : (
                        <div css={nullBox} className={"indLandPrice"}>
                            토지 이동(변동) 사유 정보가 존재하지 않습니다
                        </div>
                    )}
                </div>

                <div className="buttons">
                    <button
                        className="greenButton"
                        onClick={() => {
                            const params = {
                                title: "토지이용계획 열람",
                                url: eumMapLinks.land_use_plan,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        토지이용계획 열람
                    </button>
                    <button
                        className="greenButton"
                        onClick={() => {
                            const params = {
                                title: "도시계획 열람",
                                url: eumMapLinks.city_plan,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        도시계획 열람
                    </button>
                </div>
                <div className="buttonBox">
                    <button
                        className="greenButton box"
                        onClick={() => {
                            const params = {
                                title: "씨리얼지도",
                                url: `https://seereal.lh.or.kr/SeerealMAP/seerealCommon/mainPage.do?redirect=true&pnu=${pnu}`,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        씨리얼 지도
                    </button>
                </div>
            </section>
        </div>
    );
}

function Spinner({ size = "sm" }: { size?: "sm" | "md" }) {
    return <div css={spinnerStyle(size)} />;
}

const spinnerStyle = (size: "sm" | "md") => css`
    width: ${size === "sm" ? "14px" : "24px"};
    height: ${size === "sm" ? "14px" : "24px"};
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #0047ff;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const landDetailWrap = css`
    padding: 0 0 14px;
    height: auto;
    overflow: auto;
    .indLandPrice {
        margin-bottom: 0;
    }
    & > section {
        height: 100%;
    }
    & > section > div {
        padding: 24px 14px 0;
    }
    section {
        border-bottom: 6px solid ${Common.colors.grey02};
        ul {
            padding: 0 14px;
            margin-bottom: 14px;
        }
        li {
            padding: 8px 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            .newBox {
                display: inline-flex;
                align-items: center;
                & > .newName {
                    min-width: max-content;
                    width: max-content;
                    margin-top: 1px;
                }
            }
            span.name {
                text-align: left;
                min-width: 100px;
                width: 100px;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
            }
            span.new {
                margin-left: 4px;
                border-radius: 100px;
                background-color: #f00;
                padding: 0 4px;
                font-weight: 400;
                font-size: 12px;
                color: #fff;
                line-height: 20px;
                display: inline-block;
                padding-bottom: 1px;
            }
            span.value {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: ${Common.colors.normalTextColor};
            }
            span.date {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
            }
            span.blue {
                color: ${Common.colors.blue};
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
        }
        ul.base li {
            border-bottom: 1px solid ${Common.colors.grey03};
            &:last-of-type {
                border-bottom: none;
            }
        }
        &:first-of-type ul li:first-of-type {
            align-items: baseline;
        }
        .buttons {
            margin-top: -20px;
            margin-bottom: 10px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            gap: 10px;
        }
        .buttonBox {
            padding: 0 14px;
            margin-bottom: 24px;
        }

        .greenButton {
            width: 100%;
            height: 56px;
            text-align: center;
            border-radius: 8px;
            background-color: ${Common.colors.solidGreen};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: ${Common.colors.white};
        }

        &.landUsage {
            padding-bottom: 14px;

            .threeWrapper {
                height: 362px;
                position: relative;

                .loadingBox {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    svg {
                        width: 100%;
                        height: 100px !important;
                    }
                }

                .calculatingSpinner {
                    position: absolute;
                    top: 32px;
                    right: 16px;
                }

                .forbidden {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    gap: 24px;

                    span {
                        padding: 0 36px;
                        text-align: center;
                    }
                }
            }

            .content {
                padding: 0 14px;

                table {
                    width: 100%;
                    text-align: center;
                    border-collapse: collapse;
                    &.firstTable {
                        margin-top: 14px;
                    }
                    &.secondTable {
                        margin: 26px 0;
                    }

                    td {
                        line-height: 24px;
                        font-size: 13px;

                        .tdWrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &:not(.active) {
                            .floor {
                                background-color: #00ff00;
                                width: max-content;
                                margin: 0 auto;
                                padding: 0 6px;
                                border-radius: 100px;
                            }
                        }
                    }

                    td,
                    th {
                        border: 1px solid #8d8d8d;
                        height: 28px;
                        vertical-align: middle;
                    }

                    .navy {
                        background-color: #17366d;
                        color: white;
                        font-weight: 600;
                    }
                    .active {
                        color: #0047ff;
                        font-weight: 600;
                    }
                }

                .slider {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    justify-content: space-between;
                    margin-bottom: 14px;
                    .minRatio,
                    .maxRatio {
                        text-align: center;
                        font-size: 12px;
                        min-width: 28px;
                        color: #0c002c;
                        line-height: 120%;
                        span {
                            font-weight: 600;
                            font-size: 11px;
                            color: #505050;
                        }
                    }
                }

                .info {
                    display: inline-block;
                    color: #8d8d8d;
                    font-size: 12px;
                    line-height: 22px;
                }
            }
        }
    }
`;

const StyledSlider = styled(Slider)({
    color: "#0047ff",
    height: 6,

    "& .MuiSlider-thumb": {
        width: 32,
        height: 32,
        border: "1px solid white",
        "&:hover": {
            boxShadow: "none!important",
        },
    },
    "& .Mui-focusVisible": {
        boxShadow: "none!important",
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        color: "#0047FF",
        fontWeight: 600,
        padding: "2px 6px",
        height: 26,
        borderRadius: 4,
        backgroundColor: "#DFF0FF",
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-6px",
            left: "50%",
            transform: "translateX(-50%)",
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: "6px solid #DFF0FF",
        },
    },
});

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

const StyledSliderThumb = (props: ThumbComponentProps) => {
    const { children, ...other } = props;

    return (
        <SliderThumb {...other}>
            {children}
            <img src={sliderIcon} alt="sliderIcon" />
        </SliderThumb>
    );
};

const tableWrap = css`
    padding: 0 !important;
    margin-bottom: 14px;
    .title.small {
        padding: 24px 14px;
    }
    table {
        width: 100%;
        thead > tr > th {
            text-align: center;

            &:nth-of-type(1) {
                width: 100px;
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px);
            }
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: keep-all;
            }
            &:nth-of-type(1) {
                width: 100px;
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px);
            }
        }
        thead tr th:first-of-type div {
            text-align: left !important;
        }
        tr td:first-of-type div {
            text-align: left !important;
        }
    }
`;

const landPriceTableWrap = (props: any) => css`
    ${props.tableWrap},
    thead tr th:last-of-type div {
        text-align: right !important;
    }
    tr td:last-of-type div {
        text-align: right !important;
    }
`;
const nullBox = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${Common.colors.greyPrimary};
    background-color: ${Common.colors.grey02};
    border-radius: 14px;
    text-align: center;
    padding: 24px 0;
    margin: 14px;

    &.indLandPrice {
        padding-bottom: 24px !important;
        margin: 14px !important;
    }
    &.landChangeAll {
        margin-top: 0 !important;
    }
`;

const stateLandBoxStyle = (highlightCheck: boolean) => css`
    position: relative;
    margin: 0 !important;
    padding: 0px 14px 24px !important;
    & > .alignStart {
        align-items: flex-start !important;
    }
    & > .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.6px solid #c9c9c9;
        background-color: #ffffff;
        padding: 6px 14px;
        height: 39px;
        box-sizing: border-box;
        & > .title {
            color: #8d8d8d;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
        & .desc {
            color: #0c002c;
            text-align: right;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
        }
        & > .wrapStyle {
            white-space: pre;
            span {
                display: inline-flex;
                align-items: center;
                gap: 4px;
            }
        }
        & .status {
            ${highlightCheck && {
                background: "linear-gradient(to top,#fff500 50%,transparent 50%)",
            }}
        }
    }
    .box:last-of-type {
        border-bottom: none;
    }
    & .lockImg {
        position: absolute;
        bottom: 3px;
        right: 6px;
    }
`;

const stateLandButtonStyle = css`
    display: flex;
    flex: 1;
    width: 100%;
    margin-top: 28px;
    align-content: center;
    justify-content: center;
    height: 56px;
    text-align: center;
    column-gap: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #0047ff;
    color: var(--basic-white, #fff);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    box-sizing: border-box;
`;

const stateLandScrollBoxStyle = css`
    position: absolute;
    top: -130px;
    width: 100%;
    margin: 0;
`;

const stateLandTitleStyle = css`
    color: #0c002c;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding: 14px 28px;
    span {
        margin-left: 4px;
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

export default LandDetail;
