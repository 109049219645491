import { css } from "@emotion/react";
import { Slider, SliderThumb, styled } from "@mui/material";
import BuildingSimulation from "@src/components/three";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import sliderIcon from "@src/assets/sliderIcon.svg";
import Lottie from "lottie-react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useGetMaxRatio } from "@src/hooks/landUse/useLandUse";
import { useLandUseData } from "@src/components/three/hooks/useLandUseData";
import forbiddenImg from "@src/assets/forbidden.png";
import phoneImg from "@src/assets/phone.png";

const CONSTRUCTION_BAN_REASONS = {
    0: {
        image: forbiddenImg,
        text: "서버에 연결할 수 없습니다.\n잠시 후 다시 시도해주세요.",
    },
    1: {
        image: forbiddenImg,
        text: "이 토지는 국가 또는 지방자치단체 소유의 공유지로, 건축이 제한됩니다.",
    },
    2: {
        image: forbiddenImg,
        text: "이 토지는 법정 기준(4m 이상 도로에 2m 이상 접함)을 충족하지 못해 건축이 제한됩니다.",
    },
    3: {
        image: forbiddenImg,
        text: "이 토지는 농업진흥구역 내 농지로,\n농업 목적 외 건축이 제한됩니다.",
    },
    4: {
        image: forbiddenImg,
        text: "이 토지는 생태적 가치가 높은 비오톱 1등급 지역으로, 환경 보호를 위해 건축이 제한됩니다.",
    },
    5: {
        image: forbiddenImg,
        text: "이 토지는 보전 산지로 지정되어 있어 건축이 제한됩니다.",
    },
    6: {
        image: forbiddenImg,
        text: "이 토지는 급경사 임야로, 건축이 제한됩니다.",
    },
    7: {
        image: forbiddenImg,
        text: "다세대, 아파트, 집합건물에 접한 토지는 시뮬레이션을 제공하지 않습니다.",
    },
    8: {
        image: forbiddenImg,
        text: "이 토지는 해당 용도지역의 최소 필지 크기 기준에 미달하여 건축이 제한됩니다.",
    },
    9: {
        image: forbiddenImg,
        text: "이 토지는 개발제한구역(그린벨트) 내에 위치하여 건축이 제한됩니다.",
    },
    10: {
        image: forbiddenImg,
        text: "이 토지는 하천부지로 지정되어 있어,\n수자원 보호를 위해 건축이 제한됩니다.",
    },
    11: {
        image: forbiddenImg,
        text: "이 토지는 비행안전 1구역 내에 위치하여 항공기 안전을 위해 건축이 제한됩니다.",
    },
    12: {
        image: forbiddenImg,
        text: "이 토지는 경관보전지구 1등급으로 지정되어 있어, 자연경관 보호를 위해 건축이 제한됩니다.",
    },
    13: {
        image: phoneImg,
        text: "해당 필지는 몇 층까지 건축 가능한 토지인지 알 수 없습니다. 건축사에게 문의하세요.",
    },
};

export default function ThreePage() {
    const [searchParams] = useSearchParams();
    const pnu = searchParams.get("pnu") ?? "";

    const [ratio, setRatio] = useState(20); // 건폐율
    const [debouncedRatio, setDebouncedRatio] = useState(ratio);
    const { data: maxRatio } = useGetMaxRatio({ pnu });
    const [isSliderSelected, setIsSliderSelected] = useState(false);

    const { data, isLoading, isFetching, isError, error } = useLandUseData({ pnu, debouncedRatio });

    useEffect(() => {
        transferToNative("done", "onLoad");
    }, []);

    return (
        <div css={rootBox}>
            <div className="landUsage">
                <h1>이 땅 몇 층까지 가능? 3D 시뮬레이션</h1>

                <div className="threeWrapper">
                    {(() => {
                        // 첫 로딩 시
                        if (isLoading) {
                            return (
                                <div className="loadingBox">
                                    <Lottie animationData={lottie_loading} />
                                </div>
                            );
                        }
                        // 건축 가능한 경우
                        if (data?.result === 200) {
                            return (
                                <>
                                    <BuildingSimulation />
                                    {isFetching && (
                                        <div className="calculatingSpinner">
                                            <Spinner size="md" />
                                        </div>
                                    )}
                                </>
                            );
                        }
                        // 타임 아웃
                        if (isError && (error as any).code === "ECONNABORTED") {
                            return (
                                <div className="forbidden">
                                    <img src={forbiddenImg} alt="건축 불가" width={140} height={140} />
                                    <span>
                                        요청이 시간 초과되었습니다.
                                        <br />
                                        네트워크 상태를 확인하고 다시 시도해 주세요.
                                    </span>
                                </div>
                            );
                        }
                        // 건축이 불가능한 경우
                        return (
                            <div className="forbidden">
                                <img
                                    src={CONSTRUCTION_BAN_REASONS[data?.result ?? 0].image}
                                    alt="건축 불가"
                                    width={140}
                                    height={140}
                                />
                                <span>
                                    {CONSTRUCTION_BAN_REASONS[data?.result ?? 0].text.split("\n").map((line, index) => (
                                        <Fragment key={index}>
                                            {line}
                                            <br />
                                        </Fragment>
                                    ))}
                                </span>
                            </div>
                        );
                    })()}
                </div>

                {(isLoading || data?.result === 200) && (
                    <div className="content">
                        {data?.ancientLights && (
                            <span className="info" style={{ marginTop: "8px" }}>
                                ※ 해당 입지는 일조권 적용 대상입니다.
                            </span>
                        )}

                        <table className="firstTable">
                            <colgroup>
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td className="navy">건물규모</td>
                                    <td className="navy">용적률</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isFetching) {
                                                    return <Spinner />;
                                                }
                                                if (data?.result === 200) {
                                                    return <span className="floor">지상 {data?.totalFloor}층</span>;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isFetching) {
                                                    return <Spinner />;
                                                }
                                                if (data?.result === 200) {
                                                    return `약 ${data?.floorAreaRatio}%`;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="secondTable">
                            <colgroup>
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td className="navy">1층 면적</td>
                                    <td className="navy">건폐율</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        <div className="tdWrapper">
                                            {(() => {
                                                if (isFetching) {
                                                    return <Spinner />;
                                                }
                                                if (data?.result === 200) {
                                                    return data?.buildPolygon?.[0]?.area_pyong;
                                                }
                                                return <>알 수 없음</>;
                                            })()}
                                        </div>
                                    </td>
                                    <td className={`${isSliderSelected ? "active" : ""}`}>
                                        {data?.result === 200 ? <>약 {Math.floor(ratio)}%</> : <>-</>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="slider">
                            <span className="minRatio">
                                <span>최소</span>
                                <br />
                                20%
                            </span>
                            <StyledSlider
                                value={ratio}
                                min={20}
                                max={maxRatio}
                                disabled={data?.result !== 200 || maxRatio === 20}
                                slots={{ thumb: StyledSliderThumb }}
                                step={10}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value: number) => {
                                    return `${value}%`;
                                }}
                                onChange={(_, newValue) => setRatio(newValue as number)}
                                onPointerDown={() => {
                                    setIsSliderSelected(true);
                                    transferToNative("true", "disableNativeScroll");
                                }}
                                onChangeCommitted={(_, newValue) => {
                                    setIsSliderSelected(false);
                                    transferToNative("false", "disableNativeScroll");
                                    setDebouncedRatio(newValue as number);
                                }}
                            />
                            <span className="maxRatio">
                                <span>최대</span>
                                <br />
                                {maxRatio ?? " - "}%
                            </span>
                        </div>
                        <span className="info">
                            ※ 산정된 층수는 모든 법률적 요건을 계산한 결과가 아닙니다.
                            <br /> 본 서비스는 법적 효력이 없으며, 참고 자료로만 활용이 가능합니다.
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

function Spinner({ size = "sm" }: { size?: "sm" | "md" }) {
    return <div css={spinnerStyle(size)} />;
}

const spinnerStyle = (size: "sm" | "md") => css`
    width: ${size === "sm" ? "14px" : "24px"};
    height: ${size === "sm" ? "14px" : "24px"};
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #0047ff;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const rootBox = css`
    width: 100%;
    height: fit-content;
    position: relative;
    padding: 0 14px 14px;

    .landUsage {
        h1 {
            color: #0c002c;
            line-height: 28px;
            font-size: 20px;
            padding-top: 24px;
        }

        .threeWrapper {
            height: 362px;
            position: relative;
            padding-top: 24px;

            .loadingBox {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                svg {
                    width: 100%;
                    height: 100px !important;
                }
            }

            .calculatingSpinner {
                position: absolute;
                top: 32px;
                right: 12px;
            }

            .forbidden {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                gap: 24px;

                span {
                    padding: 0 36px;
                    text-align: center;
                }
            }
        }

        .content {
            table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;
                &.firstTable {
                    margin-top: 14px;
                }
                &.secondTable {
                    margin: 26px 0;
                }

                td {
                    line-height: 24px;
                    font-size: 13px;

                    .tdWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &:not(.active) {
                        .floor {
                            background-color: #00ff00;
                            width: max-content;
                            margin: 0 auto;
                            padding: 0 6px;
                            border-radius: 100px;
                        }
                    }
                }

                td,
                th {
                    border: 1px solid #8d8d8d;
                    height: 28px;
                    vertical-align: middle;
                }

                .navy {
                    background-color: #17366d;
                    color: white;
                    font-weight: 600;
                }
                .active {
                    color: #0047ff;
                    font-weight: 600;
                }
            }

            .slider {
                padding: 0;
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
                margin-bottom: 14px;
                .minRatio,
                .maxRatio {
                    text-align: center;
                    font-size: 12px;
                    min-width: 28px;
                    color: #0c002c;
                    line-height: 120%;
                    span {
                        font-weight: 600;
                        font-size: 11px;
                        color: #505050;
                    }
                }
            }

            .info {
                display: inline-block;
                color: #8d8d8d;
                font-size: 12px;
                line-height: 22px;
            }
        }
    }
`;

const StyledSlider = styled(Slider)({
    color: "#0047ff",
    height: 6,

    "& .MuiSlider-thumb": {
        width: 32,
        height: 32,
        border: "1px solid white",
        "&:hover": {
            boxShadow: "none!important",
        },
    },
    "& .Mui-focusVisible": {
        boxShadow: "none!important",
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        color: "#0047FF",
        fontWeight: 600,
        padding: "2px 6px",
        height: 26,
        borderRadius: 4,
        backgroundColor: "#DFF0FF",
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-6px",
            left: "50%",
            transform: "translateX(-50%)",
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: "6px solid #DFF0FF",
        },
    },
});

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

const StyledSliderThumb = (props: ThumbComponentProps) => {
    const { children, ...other } = props;

    return (
        <SliderThumb {...other}>
            {children}
            <img src={sliderIcon} alt="sliderIcon" />
        </SliderThumb>
    );
};
