import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import { Common } from "@src/styles/Common";
import { Grid } from "@mui/material";
import { getLocationCortar } from "@src/hooks/newFilter/useGetLocation";
import ShowSelectedUseLocValueBox from "../../mapFilter/ShowSelectedUseLocValueBox";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import EmptyLocation from "@src/components/filter/EmptyLocation";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    showToast: () => void;
    from?: string;
    onClickDone?: () => void;
}

const GoStopNewLocationFilter: React.FC<Props> = ({ filterParams, setFilterParams, showToast, from = "all", onClickDone }) => {
    const [sidoList, setSidoList] = useState<any>();
    const [sigunguList, setSigunguList] = useState<any>();
    const [emdList, setEmdList] = useState<any>();
    const [selectedSido, setSelectedSido] = useState<any>();
    const [selectedSigungu, setSelectedSigungu] = useState<any>();
    const [showLocText, setShowLocText] = useState<any[]>([]);
    const [lastRegionForFirst, setLastRegionForFirst] = useState<string | null>();
    const sidoRef = useRef<any>();
    const makeLocText = async (loc: string) => {
        let sidoText = "";
        let sigunguText = "";
        let emdText = "";
        sidoText = await getLocationCortar("0000000000").then((res) => {
            const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === loc.slice(0, 2));
            return selectedSido?.[0]?.cortarName ?? "";
        });
        if (loc.slice(2) !== "00000000") {
            sigunguText = await getLocationCortar(`${loc.slice(0, 2)}00000000`).then((res) => {
                const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === loc.slice(0, 5));
                return selectedSigungu?.[0]?.cortarName;
            });
        } else {
            return { name: `${sidoText} > 전체`, value: loc };
        }
        if (loc.slice(5) !== "00000") {
            emdText = await getLocationCortar(`${loc.slice(0, 5)}00000`).then((res) => {
                const selectedEmd = res.filter((val: any) => val?.cortarNo === loc);
                return selectedEmd?.[0]?.cortarName;
            });
        } else {
            emdText = "전체";
        }
        return { name: `${sidoText} > ${sigunguText} > ${emdText}`, value: loc };
    };
    const settingLocText = async () => {
        const result = await Promise.all(
            filterParams?.loc?.map(async (val: any) => {
                return await makeLocText(val);
            }),
        );
        setShowLocText(result);
    };
    useEffect(() => {
        if (filterParams?.loc?.length) {
            settingLocText();
        } else {
            setShowLocText([]);
        }
    }, [filterParams?.loc]);

    useEffect(() => {
        getLocationCortar("0000000000").then((res) => {
            setSidoList(res);
            if (filterParams.loc.length) {
                const lastInvestmentFilterRegion = filterParams.loc[filterParams.loc.length - 1];
                setLastRegionForFirst(lastInvestmentFilterRegion);
                const newSido = res.filter((val: any) => val.cortarNo.slice(0, 2) === lastInvestmentFilterRegion.slice(0, 2));
                setSelectedSido(newSido?.[0]);
            } else {
                setSigunguList([]);
                setEmdList([]);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedSido) {
            getLocationCortar(selectedSido.cortarNo)
                .then((res: any) => {
                    setSigunguList(res);
                    setEmdList([]);
                    if (lastRegionForFirst) {
                        const newSigungu = res.filter((val: any) => val.cortarNo.slice(0, 5) === lastRegionForFirst.slice(0, 5));
                        setSelectedSigungu(newSigungu?.[0]);
                    }
                })
                .then(() => {
                    setLastRegionForFirst(null);
                });
        } else {
            setSigunguList([]);
        }
    }, [selectedSido]);

    useEffect(() => {
        if (selectedSigungu) {
            getLocationCortar(selectedSigungu.cortarNo).then((res) => {
                setEmdList(res);
            });
        } else {
            setEmdList([]);
        }
    }, [selectedSigungu]);

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>
                <p className="titleText">소재지</p>
                {from === "out" && (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            {from === "out" && (
                <div css={summaryBoxStyle}>
                    {showLocText.length ? (
                        showLocText.map((locData: any) => (
                            <ShowSelectedUseLocValueBox
                                value={locData.name}
                                onDeleteValue={() => {
                                    const newLoc = [...filterParams.loc].filter((val) => val !== locData.value);
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                }}
                                key={locData.name}
                            />
                        ))
                    ) : (
                        <EmptyLocation />
                    )}
                </div>
            )}
            <Grid container css={subTitleBoxStyle}>
                <Grid item xs={3.5}>
                    <p className="subTitleText">시/도</p>
                </Grid>
                <Grid item xs={4}>
                    <p className="subTitleText">시/구/군</p>
                </Grid>
                <Grid item xs={4.5}>
                    <p className="subTitleText">동/읍/면</p>
                </Grid>
            </Grid>
            <Grid container css={selectBoxRootStyle}>
                <Grid item xs={3.5} css={selectBoxStyle(from)} ref={sidoRef}>
                    <button
                        css={selectButtonStyle(!filterParams.loc.length)}
                        onClick={() => {
                            setSelectedSigungu(null);
                            setSelectedSido(null);
                            setFilterParams({ ...filterParams, loc: [] });
                        }}
                    >
                        전국
                    </button>
                    {sidoList?.map((item: any, idx: number) => {
                        let checkActive: boolean = false;
                        if (filterParams.loc.length) {
                            checkActive = filterParams.loc.some((val: any) => val?.startsWith(item.cortarNo.slice(0, 2)));
                        }
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    setSelectedSido(item);
                                    let newLoc: string[] = [];
                                    if (checkActive) {
                                        newLoc = [...filterParams.loc].filter((v) => !v.startsWith(item.cortarNo.slice(0, 2)));
                                        setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    } else {
                                        newLoc = [...filterParams.loc, item.cortarNo];
                                        setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    }
                                    !newLoc.length && sidoRef.current.scrollTo(0, 0);
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={4} css={selectBoxStyle(from)}>
                    {!!sigunguList?.length && (
                        <button
                            css={selectButtonStyle(filterParams.loc.some((val: any) => val === selectedSido?.cortarNo))}
                            onClick={() => {
                                if (filterParams.loc.some((val: any) => val === selectedSido?.cortarNo)) {
                                    const newLoc = [...filterParams.loc].filter((v) => v !== selectedSido?.cortarNo);
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    setSelectedSido(null);
                                    setSelectedSigungu(null);
                                } else {
                                    const newLoc = [
                                        ...filterParams.loc.filter((v: any) => !v.startsWith(selectedSido?.cortarNo.slice(0, 2))),
                                        selectedSido?.cortarNo,
                                    ];
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    setSelectedSigungu(null);
                                }
                            }}
                        >
                            전체
                        </button>
                    )}
                    {sigunguList?.map((item: any) => {
                        const activeListLengthCheck = filterParams.loc.some((val: any) => val === selectedSido?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = filterParams.loc.some((val: any) => val.slice(0, 5) === item.cortarNo.slice(0, 5)));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    setSelectedSigungu(item);
                                    if (checkActive) {
                                        const newLoc = [...filterParams.loc].filter(
                                            (v) => !v.startsWith(item.cortarNo.slice(0, 5)),
                                        );
                                        !newLoc.some((v) => v.startsWith(selectedSido?.cortarNo?.slice(0, 2))) &&
                                            setSelectedSigungu(null);
                                        setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    } else {
                                        const newLoc = [
                                            ...filterParams.loc.filter((v: any) => v !== selectedSido?.cortarNo),
                                            item.cortarNo,
                                        ];
                                        const checkMajor = item.cortarNo.slice(0, 2);
                                        const dulplicateCheck = new Set(newLoc.map((v) => v.slice(0, 5)));
                                        const checkActiveList = Array.from(dulplicateCheck).filter(
                                            (val) =>
                                                val.startsWith(checkMajor) &&
                                                val.slice(0, 5) !== selectedSido?.cortarNo.slice(0, 5),
                                        );
                                        const listLengthCheck = sigunguList.length === checkActiveList.length;
                                        if (listLengthCheck) {
                                            const changeLoc = [
                                                ...filterParams.loc.filter((v: any) => !v.startsWith(checkMajor)),
                                                selectedSido?.cortarNo,
                                            ];
                                            if (changeLoc.length > 10) {
                                                showToast();
                                                return;
                                            }
                                            setFilterParams({ ...filterParams, loc: [...changeLoc] });
                                        } else {
                                            if (newLoc.length > 10) {
                                                showToast();
                                                return;
                                            }
                                            setFilterParams({ ...filterParams, loc: [...newLoc] });
                                        }
                                    }
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={4.5} css={selectBoxStyle(from)}>
                    {!!emdList?.length && (
                        <button
                            css={selectButtonStyle(filterParams.loc.some((val: any) => val === selectedSigungu?.cortarNo))}
                            onClick={() => {
                                if (filterParams.loc.some((val: any) => val === selectedSigungu?.cortarNo)) {
                                    const newLoc = [...filterParams.loc].filter((v) => v !== selectedSigungu?.cortarNo);
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                    setSelectedSigungu(null);
                                } else {
                                    const newLoc = [
                                        ...filterParams.loc.filter(
                                            (v: any) => !v.startsWith(selectedSigungu?.cortarNo.slice(0, 5)),
                                        ),
                                        selectedSigungu?.cortarNo,
                                    ];
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                }
                            }}
                        >
                            전체
                        </button>
                    )}
                    {emdList?.map((item: any) => {
                        const activeListLengthCheck = filterParams.loc.some((val: any) => val === selectedSigungu?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = filterParams.loc.some((val: any) => val === item.cortarNo));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    // setSelectedSigungu(item);
                                    if (checkActive) {
                                        const newLoc = [...filterParams.loc].filter((v) => v !== item.cortarNo);
                                        setFilterParams({ ...filterParams, loc: [...newLoc] });
                                        !newLoc.some((v) => v.startsWith(selectedSigungu?.cortarNo?.slice(0, 5))) &&
                                            setSelectedSigungu(null);
                                    } else {
                                        const newLoc = [
                                            ...filterParams.loc.filter((v: any) => v !== selectedSigungu?.cortarNo),
                                            item.cortarNo,
                                        ];
                                        const checkMajor = item.cortarNo.slice(0, 5);
                                        const checkActiveList = newLoc.filter(
                                            (val) => val.startsWith(checkMajor) && val !== selectedSigungu?.cortarNo,
                                        );
                                        const listLengthCheck = emdList.length === checkActiveList.length;
                                        if (listLengthCheck) {
                                            const changeLoc = [
                                                ...filterParams.loc.filter((v: any) => !v.startsWith(checkMajor)),
                                                selectedSigungu?.cortarNo,
                                            ];
                                            if (changeLoc.length > 10) {
                                                showToast();
                                                return;
                                            }
                                            setFilterParams({ ...filterParams, loc: [...changeLoc] });
                                        } else {
                                            if (newLoc.length > 10) {
                                                showToast();
                                                return;
                                            }
                                            setFilterParams({ ...filterParams, loc: [...newLoc] });
                                        }
                                    }
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default GoStopNewLocationFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const subTitleBoxStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
    font-size: 14px;
    .subTitleText {
        padding: 8px 14px;
        background-color: #ffffff;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        box-sizing: border-box;
        width: 100%;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: center;
`;

const selectBoxStyle = (from: string) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 12px 10px;
    gap: 8px;
    /* height: 460px; */
    height: ${from === "out" ? "230px" : "180px"};
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: #f0f0f0;
    transition: color 0.3s linear;
    font-size: 14px;
    font-weight: 500;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: "#ffffff",
    }}
`;

const summaryBoxStyle = css`
    padding: 8px 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;
