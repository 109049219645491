import { css } from "@emotion/react";
import React from "react";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { Common } from "@src/styles/Common";
import { filter } from "lodash";
import { IFilterParamsType } from "@src/store/newFilterStore";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const ACUTION_COUNTER_FORCE_MAP = {
    "대항력 없음": 1,
    "대항력 있음": 2,
    미상: 3,
};

export const getAuctionCounterForceListReversed = () => {
    return Object.entries(ACUTION_COUNTER_FORCE_MAP).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [value]: key as keyof typeof ACUTION_COUNTER_FORCE_MAP,
        }),
        {} as Record<number, keyof typeof ACUTION_COUNTER_FORCE_MAP>,
    );
};

export const counterForceKeys = Object.keys(ACUTION_COUNTER_FORCE_MAP);
export const counterForceValues = Object.values(ACUTION_COUNTER_FORCE_MAP);

const AuctionCounterForceFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    const handleClickCounterForceAll = () => {
        setFilterParams({ ...filterParams, counter_force_list: [] });
    };
    const handleClickCounterForce = (id: number) => {
        // 필드를 추가하는 경우
        if (filterParams.counter_force_list === undefined) {
            setFilterParams({ ...filterParams, counter_force_list: [id] });
            return;
        }

        // 기존 필드에 아이템을 추가하는 경우
        if (filterParams.counter_force_list !== undefined && !filterParams.counter_force_list.includes(id)) {
            // 새 아이템을 추가함으로써 전체를 선택하는 경우
            if (filterParams.counter_force_list.length === counterForceKeys.length - 1) {
                setFilterParams({ ...filterParams, counter_force_list: [] });
                return;
            }
            setFilterParams({ ...filterParams, counter_force_list: [...filterParams.counter_force_list, id] });
            return;
        }

        // 기존 필드에서 제외하는 경우
        if (filterParams.counter_force_list !== undefined && filterParams.counter_force_list.includes(id)) {
            const filtered = filterParams.counter_force_list.filter((forceId) => forceId !== id);
            setFilterParams({ ...filterParams, counter_force_list: filtered });
        }
    };

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(filterParams.counter_force_list?.length === 0)}>
                <p className="titleText">대항력</p>
                {allFilter ? (
                    <button className="allButton" onClick={handleClickCounterForceAll}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <p css={descTextStyle}>*권리분석의 내용은 입력 착오로 사실과 다를 수 있으며 면책 조건으로 제공됩니다.</p>
            <div css={selectButtonBoxStyle}>
                {!allFilter && (
                    <button
                        css={selectButtonStyle(filterParams.counter_force_list?.length == 0)}
                        onClick={handleClickCounterForceAll}
                    >
                        전체
                    </button>
                )}
                {counterForceValues.map((forceId) => (
                    <button
                        key={forceId}
                        css={selectButtonStyle(!!filterParams.counter_force_list?.includes(forceId))}
                        onClick={() => handleClickCounterForce(forceId)}
                    >
                        {getAuctionCounterForceListReversed()[forceId]}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default AuctionCounterForceFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const descTextStyle = css`
    padding: 0px 14px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${Common.colors.grey02};
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;
